import {
	AfterViewInit,
	Component,
	ElementRef,
	HostListener,
	Input,
	OnInit,
	ViewChild,
} from '@angular/core';
import { AttendanceWindowType } from '@app/01.global/helpers/attendance-window-type.enum';
import { AttendanceStatusService } from '@app/01.global/status-services/attendance-status.service';
import { AttendanceVideoComponent } from '../attendance-video/attendance-video.component';

const enum Status {
	OFF = 0,
	RESIZE = 1,
	MOVE = 2,
}

@Component({
	selector: 'app-attendance-modal',
	templateUrl: './attendance-modal.component.html',
	styleUrls: ['./attendance-modal.component.scss'],
})
export class AttendanceModalComponent implements OnInit, AfterViewInit {
	@Input() width: number;
	@Input() height: number;
	@Input() left: number;
	@Input() top: number;
	@Input() title: string;
	@Input() id: string;
	@Input() channelId: number;
	@Input() type: AttendanceWindowType;
	@Input() isActive: boolean;
	@ViewChild('box') public box: ElementRef;
	@ViewChild(AttendanceVideoComponent) videoComponent: AttendanceVideoComponent;
	AttendanceWindowType = AttendanceWindowType;
	private boxPosition: { left: number; top: number };
	private containerPos: {
		left: number;
		top: number;
		right: number;
		bottom: number;
	};
	public mouse: { x: number; y: number };
	public status: Status = Status.OFF;
	private mouseClick: { x: number; y: number; left: number; top: number };


	get canResize(): boolean {
		return this.type != AttendanceWindowType.Booking
			&& this.type != AttendanceWindowType.VideoCall
	}

	get canMinimize(): boolean {
		return this.type != AttendanceWindowType.VideoCall
	}

	constructor(private statusService: AttendanceStatusService) { }

	ngOnInit() { }

	ngAfterViewInit() {
		this.loadBox();
		this.loadContainer();
	}

	private loadBox() {
		const { left, top } = this.box.nativeElement.getBoundingClientRect();
		this.boxPosition = { left, top };
	}

	private loadContainer() {
		const left = this.boxPosition.left - this.left;
		const top = this.boxPosition.top - this.top;
		const right = left + (window.innerWidth - 240);
		const bottom = top + window.innerHeight;
		this.containerPos = { left, top, right, bottom };
	}

	setStatus(event: MouseEvent, status: number) {
		if (status === 1) event.stopPropagation();
		else if (status === 2)
			this.mouseClick = {
				x: event.clientX,
				y: event.clientY,
				left: this.left,
				top: this.top,
			};
		else this.loadBox();
		this.status = status;
	}

	@HostListener('window:mousemove', ['$event'])
	onMouseMove(event: MouseEvent) {
		this.mouse = { x: event.clientX, y: event.clientY };

		if (this.status === Status.RESIZE) this.resize();
		else if (this.status === Status.MOVE) this.move();
	}

	minimize() {
		this.statusService.minimizeWindow(this.id);
	}

	maximize() {
		this.width = window.innerWidth - 240;
		this.height = window.innerHeight;
		this.left = 0;
		this.top = 0;
		this.statusService.maximizeWindow(this.id);
	}

	close() {
		if (this.type == AttendanceWindowType.VideoCall) {
			this.videoComponent.endCall();
		} else {
			this.statusService.closeWindow(this.id);
		}
	}

	focusWindow() {
		this.statusService.setWindowAsActive(this.id);
	}

	bodyClick(e) {
		e.stopPropagation();
	}

	private resize() {
		if (this.resizeCondMeet()) {
			this.width = Number(this.mouse.x > this.boxPosition.left)
				? this.mouse.x - this.boxPosition.left
				: 0;
			this.height = Number(this.mouse.y > this.boxPosition.top)
				? this.mouse.y - this.boxPosition.top
				: 0;
		}
	}

	private resizeCondMeet() {
		return (
			this.mouse.x < this.containerPos.right &&
			this.mouse.y < this.containerPos.bottom
		);
	}

	private move() {
		if (this.moveCondMeet()) {
			this.left =
				this.mouseClick.left + (this.mouse.x - this.mouseClick.x);
			this.top = this.mouseClick.top + (this.mouse.y - this.mouseClick.y);
		}
	}

	private moveCondMeet() {
		const offsetLeft = this.mouseClick.x - this.boxPosition.left;
		const offsetRight = this.width - offsetLeft;
		const offsetTop = this.mouseClick.y - this.boxPosition.top;
		const offsetBottom = this.height - offsetTop;
		return (
			this.mouse.x > this.containerPos.left + offsetLeft &&
			this.mouse.x < this.containerPos.right - offsetRight &&
			this.mouse.y > this.containerPos.top + offsetTop &&
			this.mouse.y < this.containerPos.bottom - offsetBottom
		);
	}
}
