import { Injectable } from '@angular/core';
import {
	Router,
	CanActivate,
	ActivatedRouteSnapshot,
	RouterStateSnapshot,
} from '@angular/router';
import { AuthenticationService } from '@app/01.global/services/authentication.service';
import { JwtHelperService } from '@auth0/angular-jwt';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { IUser } from '../interfaces/IUser';
import { RouteList } from './route-enum';

@Injectable({ providedIn: 'root' })
export class AuthGuard implements CanActivate {
	routeList = RouteList;

	constructor(
		private router: Router,
		private authenticationService: AuthenticationService,
		private modalService: NgbModal,
		private jwtHelper: JwtHelperService
	) {}

	canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
		const user =
			this.authenticationService.userValue ||
			(JSON.parse(localStorage.getItem('currentIUser')) as IUser);
		const jwt =
			this.authenticationService.tokenValue ||
			(JSON.parse(localStorage.getItem('token')) as string);
		const roles =
			this.authenticationService.rolesValue ||
			(JSON.parse(localStorage.getItem('roles')) as string[]);

		if (user && !this.jwtHelper.isTokenExpired(jwt)) {
			// check if route is restricted by role
			if (
				route.data.allowedRoles.some((r: string) => roles.includes(r))
			) {
				// authorised so return true
				return true;
			} else {
				// role not authorised so redirect to unauthorized
				this.router.navigate([this.routeList.unauthorized]);
				return false;
			}
		} else {
			// not logged in so redirect to login page with the return url
			this.modalService.dismissAll();

			this.router.navigate([this.routeList.login], {
				queryParams: {
					returnUrl: state.url,
				},
			});
			return false;
		}
	}
}
