import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { RouteList } from '@app/01.global/helpers/route-enum';
import { IUser } from '@app/01.global/interfaces/IUser';
import { IChannel } from '@app/01.global/interfaces/iChannel';
import { AuthenticationService } from '@app/01.global/services/authentication.service';
import { Subscription, take } from 'rxjs';
import { NgbModal, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { ISpecialty } from '@app/01.global/interfaces/ISpecialty';
import { ConsultationStatusService } from '@app/01.global/status-services/consultation-status.service';
import { ActiveChannel } from '@app/01.global/models/active-channel';

@Component({
	selector: 'app-chat-top-bar',
	templateUrl: './top-bar.component.html',
	styleUrls: ['./top-bar.component.scss'],
})
export class TopBarComponent implements OnInit, OnDestroy {
	activeChannel: IChannel;
	routeList = RouteList;
	currentUser: IUser;
	subs: Subscription[] = [];
	showVideo: boolean;
	showSharedFiles: boolean;
	showMeetingNotes: boolean;
	showPatientHistory: boolean;
	showAddUser: boolean;
	hasPermissionsToMeetingNotes: boolean = false;
	hasPermissionsToPatientHistory: boolean = false;
	hasPermissionsToStartVideoCall: boolean = false;
	hasPermissionsToAddUser: boolean = false;
	hasPermissionsToBookSession: boolean = false;
	isGroup: boolean = false;
	canEditName: boolean = false;
	isEditingName: boolean = false;
	canJoinOnGoingVideoCall: boolean = false;
	readOnly: boolean = false;
	specialty: ISpecialty;
	archiveMode: boolean;
	isGuest: boolean = false;
	newChannelName: string;

	@ViewChild('launcherModal') launcherModal;
	launcherModalResult: NgbModalRef;

	@ViewChild('bookSessionModal') bookSessionModal;
	bookSessionModalResult: NgbModalRef;

	constructor(
		private route: ActivatedRoute,
		private router: Router,
		private authenticationService: AuthenticationService,
		private modalService: NgbModal,
		private statusService: ConsultationStatusService
	) {}

	ngOnInit(): void {
		this.subs.push(
			this.authenticationService.user.subscribe((u) => {
				this.currentUser = u;
				this.isGuest = this.authenticationService.isGuest;
			})
		);

		this.subs.push(
			this.statusService.activeChannelObs.subscribe(
				(response: ActiveChannel) => {
					this.activeChannel = response?.channel;
					if (this.activeChannel) {
						this.newChannelName = this.activeChannel.name;
					}
					this.readOnly = response?.readOnly ?? false;
					this.hasPermissionsToMeetingNotes =
						response?.hasPermissionsToMeetingNotes ?? false;
					this.hasPermissionsToPatientHistory =
						response?.hasPermissionsToPatientHistory ?? false;
					this.hasPermissionsToStartVideoCall =
						response?.hasPermissionsToStartVideoCall ?? false;
					this.hasPermissionsToAddUser =
						response?.hasPermissionsToAddUser ?? false;
					this.hasPermissionsToBookSession =
						response?.hasPermissionsToBookSession ?? false;
					this.canJoinOnGoingVideoCall =
						response?.canJoinOnGoingVideoCall ?? false;
					this.isGroup = response?.isGroup ?? false;
					this.canEditName = response?.canEditName ?? false;
				}
			)
		);

		this.subs.push(
			this.statusService.settingsObs.subscribe((response) => {
				this.showVideo = response?.showVideo ?? false;
				this.showSharedFiles = response?.showSharedFiles ?? false;
				this.showMeetingNotes = response?.showMeetingNotes ?? false;
				this.showPatientHistory = response?.showPatientHistory ?? false;
				this.showAddUser = response?.showAddUser ?? false;
			})
		);

		this.route.data.subscribe((data) => {
			this.archiveMode = !!data.archiveMode;
		});
	}

	ngOnDestroy(): void {
		this.subs.forEach((s) => s?.unsubscribe());
	}

	joinVideoCall(value: boolean) {
		this.statusService.toggleVideoCall(value);
	}

	startVideoCall(value: boolean) {
		if (value) {
			this.launcherModalResult = this.modalService.open(
				this.launcherModal,
				{ size: 'xl' }
			);
		} else {
			this.statusService.toggleVideoCall(false);
		}
	}

	bookSession() {
		this.bookSessionModalResult = this.modalService.open(
			this.bookSessionModal,
			{ size: 'xl' }
		);
	}

	onTypeChosen() {
		this.launcherModalResult.close();
	}

	onSetSchedule() {
		this.bookSessionModalResult.close();
	}

	SharedFiles(value: boolean) {
		this.statusService.toggleShowSharedFiles(value);
	}

	MeetingNotes(value: boolean) {
		this.statusService.toggleShowMeetingNotes(value);
	}

	PatientHistory(value: boolean) {
		this.statusService.toggleShowPatientHistory(value);
	}

	AddUserToChannel(value: boolean) {
		this.statusService.toggleShowAddUser(value);
	}

	editingName(event) {
		this.isEditingName = true;
		if (event.target.innerText == this.currentUser.displayName) {
			this.isEditingName = false;
		} else {
			this.newChannelName = event.target.innerText;
		}
	}

	onKeydown(event) {
		if (event.keyCode == 13) {
			event.stopPropagation();
			this.updateChannelName();
		}
	}

	updateChannelName() {
		this.statusService.updateChannelName(this.newChannelName);
	}

	archiveChannel(modal) {
		this.modalService
			.open(modal, {
				ariaLabelledBy: 'modal-basic-title',
				centered: true,
				size: 'md',
			})
			.result.then(() => {
				this.statusService.toggleArchiveChannel(true);
			})
			.catch(() => {});
	}

	unarchiveChannel() {
		let channelId = this.activeChannel.id;
		this.statusService.toggleArchiveChannel(false);
		this.router.navigate([RouteList.consultation]);
		this.statusService
			.setActiveChannel(channelId)
			.pipe(take(1))
			.subscribe();
	}

	isCurrentUserDoctor(): boolean {
		return this.activeChannel.doctors.some(
			(ap) => ap.id == this.currentUser.id
		);
	}

	isCurrentUserParticipant(): boolean {
		return this.activeChannel.users.some(
			(ap) => ap.id == this.currentUser.id
		);
	}

	goToWaitingRoom() {
		console.log(this.activeChannel);
		this.router.navigate([
			RouteList.attendance,
			this.activeChannel.doctors[0]?.specialty
				?.providerAccountPublicIdentifier,
			this.activeChannel.doctors[0]?.publicIdentifier,
		]);
	}
}
