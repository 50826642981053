import { Injectable } from '@angular/core';
import {
	HttpRequest,
	HttpHandler,
	HttpEvent,
	HttpInterceptor,
} from '@angular/common/http';
import { Observable } from 'rxjs';

import { environment } from '@environments/environment';
import { AuthenticationService } from '@app/01.global/services/authentication.service';

@Injectable()
export class JwtInterceptor implements HttpInterceptor {
	constructor(private authenticationService: AuthenticationService) { }

	intercept(
		request: HttpRequest<any>,
		next: HttpHandler
	): Observable<HttpEvent<any>> {
		// add auth header with jwt if user is logged in and request is to the api url
		const user = this.authenticationService.userValue;
		const token = this.authenticationService.tokenValue;
		const isLoggedIn = user && token;
		const isApiBaseURL = request.url.startsWith(environment.ApiBaseURL);

		if (isLoggedIn && isApiBaseURL) {
			request = request.clone({
				setHeaders: {
					Authorization: `Bearer ${token}`,
				},
			});
		}

		return next.handle(request);
	}
}
