export enum RecordStates {
	deleted = 0,
	active = 1,
	Archived = 2,
	revoked = 3,
	inProgress = 4,
	pending = 5,
	ReadOnly = 6,
	deletedVisibleForUser = 7,
}
