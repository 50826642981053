<button class="{{ btn.btnClass }} mr-2"
	[ngClass]="{ loading: (statusService.getLoadingObservable(tableIdentifier) | async)  }"
	[disabled]="(statusService.getLoadingObservable(tableIdentifier) | async) || btn.disabled" data-toggle="tooltip"
	data-placement="top" title="{{ btn.text }}" [style]="btn.style" (click)="setTableListButtonClicked($event);$event.stopPropagation()">
	<span *ngIf="!(statusService.getLoadingObservable(tableIdentifier) | async) && btn.icon && btn.icon != ''"
		[ngClass]="{ 'mr-2': btn.showText }">
		<i *ngIf="btn.icon" class="text-20 btn-icon {{btn.icon}}"></i>
	</span>
	<span *ngIf="!(statusService.getLoadingObservable(tableIdentifier) | async) && btn.showText">{{btn.text}}</span>
	<span *ngIf="btn.loading && (statusService.getLoadingObservable(tableIdentifier) | async) && btn.showText">
		{{ btn.loadingText }}
	</span>
</button>