<div class="container">
	<div class="text-center">
		<h2>{{triageFormUser?.triageForm?.title}}</h2>
		<span *ngIf="triageFormUser?.triageForm?.description" [innerHTML]="triageFormUser.triageForm.description"
			class="mb-0"></span>
		<hr class="w-20 d-flex mx-auto my-4">
	</div>
	<div class="form-group row" *ngFor="let question of triageFormUser?.triageFormQuestions; let i = index">
		<div class="col">
			<hr *ngIf="i != 0" class="w-80 d-flex mx-auto">
			<label class="d-block" style="font-size: 18px;">{{question.title}}</label>
			<em>{{question.description}}</em>
			<container-element [ngSwitch]="question.type">
				<div *ngSwitchCase="QuestionType.singleChoice">
					<app-form-control *ngFor="let availableAnswer of question.availableAnswers"
						[type]="ControlTypeEnum.RadioOutline" [control]="form.get('radio-' + question.id.toString())"
						[value]="availableAnswer.id" [name]="'radio-' + question.id.toString()"
						[label]="availableAnswer.label" [submitted]="submitted">
					</app-form-control>
				</div>
				<div *ngSwitchCase="QuestionType.multipleChoice">
					<app-form-control *ngFor="let availableAnswer of question.availableAnswers"
						[type]="ControlTypeEnum.CheckboxOutline"
						[control]="form.get(question.id.toString()).get(availableAnswer.id.toString())"
						[name]="availableAnswer.label" [name]="availableAnswer.label" [submitted]="submitted"
						[label]="availableAnswer.label">
					</app-form-control>
				</div>
				<app-form-control *ngSwitchCase="QuestionType.textField" [type]="ControlTypeEnum.Textarea"
					[showLabel]="false" [control]="form.get(question.id.toString())"
					[name]="question.title + ' ' + question.description" [submitted]="submitted">
				</app-form-control>
				<app-form-control *ngSwitchDefault [type]="ControlTypeEnum.Textarea" [showLabel]="false"
					[control]="form.get(question.id.toString())" [name]="question.title + ' ' + question.description"
					[submitted]="submitted">
				</app-form-control>
			</container-element>
		</div>
	</div>
</div>