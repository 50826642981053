<div *ngIf="statusService.getRowsObservable(tableIdentifier) | async as rows">
	<div *ngIf="statusService.getFiltersObservable(tableIdentifier) | async as filters">
		<div *ngIf="rows.length > 0">
			<ngx-datatable #datatableId class="material expandable box-shadow-1" [rows]="rows" [headerHeight]="50"
				[footerHeight]="50" rowHeight="auto" [externalPaging]="true" [count]="filters.totalItems"
				[offset]="filters.currentPage" [columnMode]="'force'" [limit]=" filters.pageSize"
				(page)="readPage($event)" (sort)="onSort($event)">
				<ngx-datatable-column *ngFor="let column of rows[0].columns; let i = index" [name]="column.name"
					[resizeable]="false" [draggable]="false"
					[canAutoResize]="column.displayMode != TableListColumnMode.thumbnail"
					[sortable]="column.displayMode != TableListColumnMode.thumbnail && column.sortable"
					[cellClass]="getCellClass" [width]="getColumnWith(column)">
					<ng-template let-col="column" ngx-datatable-header-template>
						<span *ngIf="column.displayMode != 0" data-toggle="tooltip" data-placement="top"
							title="{{column.tooltip ?? column.name}}">
							{{column.name}}
						</span>
					</ng-template>
					<ng-template ngx-datatable-cell-template let-value="value" let-row="row">
						<div *ngIf="column.displayMode == TableListColumnMode.thumbnail">
							<table-list-thumbnail *ngIf="row.columns[i].value" [source]="row.columns[i].value">
							</table-list-thumbnail>
							<div *ngIf="!row.columns[i].value" style="width: 3.125rem;">
								<i class="text-30 ml-3 {{tableSettings.icon}}"></i>
							</div>
						</div>
						<div *ngIf="column.displayMode == TableListColumnMode.title">
							<h5 class="mb-0" data-toggle="tooltip" data-placement="top"
								title="{{row.columns[i].value}}"><strong>{{row.columns[i].value}}</strong></h5>
						</div>
						<div *ngIf="row.columns[i].displayMode == TableListColumnMode.text">
							<span data-toggle="tooltip" data-placement="top"
								title="{{row.columns[i].value}}">{{row.columns[i].value}}</span>
						</div>
						<div *ngIf="column.displayMode == TableListColumnMode.checkbox">
							<div class="form-check">
								<input class="form-check-input" type="checkbox" checked="{{row.columns[i].value}}"
									disabled>
							</div>
						</div>
						<div *ngIf="column.displayMode == TableListColumnMode.html">
							<span [innerHtml]="row.columns[i].value"></span>
						</div>
						<div *ngIf="column.displayMode == TableListColumnMode.list">
							<ul class="list-group list-group-flush">
								<li class="list-group-item" *ngFor="let item of row.columns[i].value">
									{{item.text}}
								</li>
							</ul>
						</div>
						<div *ngIf="column.displayMode == TableListColumnMode.checkboxSwitch">
							<div class="form-check form-switch">
								<input class="form-check-input" type="checkbox" role="switch" disabled
									[checked]="row?.columns[i]?.value"> 
							</div>
						</div>
					</ng-template>
				</ngx-datatable-column>
				<ngx-datatable-column *ngIf="rows[0].actionButtons.length > 0" [width]="getButtonsColumnWith(rows)"
					[resizeable]="false" [sortable]="false" [draggable]="false" [canAutoResize]="false"
					[cellClass]="'my-auto text-end pr-1'">
					<ng-template let-column="column" ngx-datatable-header-template>
						<span data-toggle="tooltip" data-placement="top" title="{{column.tooltip ?? column.name}}">
							{{column.name}}
						</span>
					</ng-template>
					<ng-template ngx-datatable-cell-template let-value="value" let-row="row">
						<ng-container *ngFor="let btn of row.actionButtons">
							<table-list-btn [btn]="btn" [rowId]="row.id" [tableIdentifier]="tableIdentifier">
							</table-list-btn>
						</ng-container>
					</ng-template>
				</ngx-datatable-column>
			</ngx-datatable>
		</div>
	</div>
</div>