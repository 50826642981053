import {
	Component,
	OnDestroy,
	OnInit,
	ViewChild,
} from '@angular/core';
import { NotificationsEnum } from '@app/01.global/helpers/notifications-enum';
import { IChatMessage } from '@app/01.global/interfaces/iChatMessage';
import { INotification } from '@app/01.global/interfaces/iNotification';
import { IUser } from '@app/01.global/interfaces/IUser';
import { IMenuItem } from '@app/01.global/interfaces/helpers/iIMenuItem';
import { NavigationService } from '@app/01.global/services/navigation.service';
import { SearchService } from '@app/01.global/services/search.service';
import { AuthenticationService } from '@app/01.global/services/authentication.service';
import { NotificationsService } from '@app/01.global/services/notifications.service';
import { RouteList } from '@app/01.global/helpers/route-enum';
import { SignalrService } from '@app/01.global/services/signalr.service';
import { IAppointment } from '@app/01.global/interfaces/IAppointment';
import { Subscription } from 'rxjs';
import { ActivatedRoute, Router } from '@angular/router';
import { NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { PwaService } from '@app/01.global/services/pwa.service';
import { LoaderService } from '@app/01.global/services/loader.service';
import { ISelectListItem } from '@app/01.global/interfaces/helpers/iSelectListItem';
import { DropdownService } from '@app/01.global/services/dropdown.service';
import { UsersService } from '@app/01.global/services/users.service';
import { BookingStatusService } from '@app/01.global/status-services/booking-status.service';
import { DatatableFilters } from '@app/01.global/models/DatatableFilters';
import { HttpCancelService } from '@app/01.global/services/http-cancel.service';
import { BookingService } from '@app/01.global/services/booking.service';
import { AttendanceStatusService } from '@app/01.global/status-services/attendance-status.service';

@Component({
	selector: 'app-header',
	templateUrl: './header.component.html',
	styleUrls: ['./header.component.scss'],
})
export class HeaderComponent implements OnInit, OnDestroy {
	notifications: INotification[] = [];
	currentUser: IUser;
	subs: Subscription[] = [];
	routeList = RouteList;
	availableLanguages: ISelectListItem[] = [];
	isOnline: boolean = false;
	isProvider: boolean = false;
	isEmployee: boolean = false;
	isAdmin: boolean = false;
	isDoctor: boolean = false;
	isTherapist: boolean = false;
	isGuest: boolean = false;
	pageNotifications: DatatableFilters = {
		pageSize: 4,
		currentPage: 0,
	};

	constructor(
		private navService: NavigationService,
		public searchService: SearchService,
		private authenticationService: AuthenticationService,
		private notificationService: NotificationsService,
		private signalRService: SignalrService,
		private router: Router,
		public pwaService: PwaService,
		public loaderService: LoaderService,
		private dropdownService: DropdownService,
		public userService: UsersService,
		private bookingStatusService: BookingStatusService,
		private route: ActivatedRoute,
		private httpCancelService: HttpCancelService,
		private bookingService: BookingService,
		private attendanceStatusService: AttendanceStatusService
	) { }

	ngOnInit() {
		this.currentUser = this.authenticationService.userValue;
		this.isEmployee = this.authenticationService.isEmployee;
		this.isTherapist = this.authenticationService.isTherapist;
		this.isProvider = this.authenticationService.isProvider;
		this.isGuest = this.authenticationService.isGuest;
		this.isDoctor = this.authenticationService.isDoctor;
		this.isAdmin = this.authenticationService.isAdmin;

		this.subs.push(
			this.dropdownService
				.readLanguages()
				.subscribe((languages: ISelectListItem[]) => {
					this.availableLanguages = languages;
				})
		);

		this.subs.push(
			this.signalRService.notificationsChanges.subscribe(() => {
				this.notificationService
					.readAll(this.pageNotifications)
					.subscribe((result: INotification[]) => {
						this.notifications = result;
					});
			})
		);

		this.subs.push(
			this.signalRService.appearAsOffline.subscribe(
				(isActive: boolean) => {
					if (isActive == null) {
						return;
					}
					this.authenticationService.updateAppearAsOffline(isActive);
					if (isActive) {
						this.isOnline = false;
					} else {
						this.isOnline = true;
					}
				}
			)
		);
	}

	ngOnDestroy(): void {
		this.subs.forEach((s) => s?.unsubscribe());
	}

	toggleSidebar() {
		const state = this.navService.sidebarState;
		if (state.childnavOpen && state.sidenavOpen) {
			return (state.childnavOpen = false);
		}
		if (!state.childnavOpen && state.sidenavOpen) {
			return (state.sidenavOpen = false);
		}
		// item has child items
		if (
			!state.sidenavOpen &&
			!state.childnavOpen &&
			this.navService.selectedItem?.type === 'dropDown'
		) {
			state.sidenavOpen = true;
			setTimeout(() => {
				state.childnavOpen = true;
			}, 50);
		}
		// item has no child items
		if (!state.sidenavOpen && !state.childnavOpen) {
			state.sidenavOpen = true;
		}
	}

	changeStatus() {
		this.userService.changeAppearOfflineStatus().subscribe((x: IUser) => {
			this.currentUser = x;
			this.authenticationService.updateCurrentUser(this.currentUser);
		});
	}

	signout() {
		this.loaderService.enable();
		this.signalRService.disconnect();
		this.attendanceStatusService.reset();
		this.authenticationService.logout().subscribe(() => {
			this.httpCancelService.cancelPendingRequests();
			this.subs?.forEach((s) => s?.unsubscribe());
			this.router.navigate(['./']);
		});
	}


	installPwa(): void {
		this.pwaService.promptEvent.prompt();
	}

	setLanguage(languageId: string) {
		var id = Number(languageId);
		this.userService
			.updateDisplayLanguage(id)
			.subscribe((updatedUser: IUser) => {
				if (updatedUser != null && updatedUser.languageId > 0) {
					this.authenticationService.updateCurrentUser(updatedUser);
					this.authenticationService.setLanguage();
				}
			});
	}

	onUrgencyClick() {
		this.bookingStatusService.setNavigation(1);
		this.router.navigate([RouteList.bookingUrgency]);
	}

	onBookingClick() {
		this.bookingStatusService.setNavigation(this.isGuest ? 2 : 5);
		this.router.navigate([
			this.isGuest
				? RouteList.bookingAppointment
				: RouteList.bookingPatients,
		]);
	}

	onOneClickDoctorClick() {
		this.loaderService.enable();
		this.bookingService.oneClickDoctor().subscribe((app: IAppointment) => {
			this.loaderService.disable();
			this.router.navigate([
				RouteList.attendance,
				app.medicalOffice.branch.account.publicIdentifier ??
				app.medicalOffice.branch.mainProvider.account
					.publicIdentifier,
				app.medicalOffice.doctor.publicIdentifier,
			]);
		});
	}

	onWaitingRoomClick() {
		this.router.navigate([RouteList.patientQueue], {
			relativeTo: this.route,
		});
	}

	onNotificationsScroll() {
		this.pageNotifications.currentPage++;
		this.notificationService
			.readAll(this.pageNotifications)
			.subscribe((results: INotification[]) => {
				this.notifications.push(...results);
			});
	}

	navigateNotification(element) {
		switch (element.type) {
			case NotificationsEnum.chatMessage:
			case NotificationsEnum.chatAttachment:
				this.router.navigate([RouteList.consultation], {
					queryParams: { channelId: element.entityId },
				});
				break;
			case NotificationsEnum.appointmentDatetimeChanged:
			case NotificationsEnum.appointmentStatusChanged:
				this.router.navigate([
					RouteList.calendar,
					this.routeList._routeCrudEnum.Details,
					element.entityId.toString(),
				]);
				break;
			default:
				break;
		}
	}

	chat() {
		this.router.navigate([RouteList.consultation]);
	}
}
