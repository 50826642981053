import { Component, EventEmitter, Input, OnDestroy, OnInit, Output, ViewChild } from '@angular/core';
import { UntypedFormControl } from '@angular/forms';
import { Router, ActivatedRoute } from '@angular/router';
import { SharedAnimations } from '@app/table-list/animations/shared-animations';
import { TableListMode, TableListSettings } from '@app/table-list/models/table-list';
import { TableListFilters } from '@app/table-list/models/table-list-filters';
import { TableListStatusService } from '@app/table-list/services/table-list-status.service';
import { debounceTime, distinctUntilChanged, Subscription, switchMap, take } from 'rxjs';
@Component({
	selector: 'table-list-card',
	templateUrl: './table-list-card.component.html',
	styleUrls: ['./table-list-card.component.scss'],
	animations: [SharedAnimations],
})
export class TableListCardComponent implements OnInit, OnDestroy {
	constructor(private router: Router, private activatedRoute: ActivatedRoute, public statusService: TableListStatusService) { }

	@Input('tableIdentifier') tableIdentifier: string;
	private subs: Subscription[] = [];
	tableSettings: TableListSettings;
	tableFilters: TableListFilters;
	TableListMode = TableListMode;
	@ViewChild('datatableId') table: any;
	searchTerm: UntypedFormControl = new UntypedFormControl('');
	loading: boolean = false;
	@Output('onLoading') onLoading: EventEmitter<boolean> = new EventEmitter();

	ngOnInit(): void {
		this.loading = true;
		this.onLoading.emit(true);
		this.statusService.getFiltersObservable(this.tableIdentifier).pipe(take(1))
			.subscribe((filters: TableListFilters) => {
				this.tableFilters = filters;
			});
		this.subs.push(this.statusService.getSettingsObservable(this.tableIdentifier)
			.subscribe(settings => this.tableSettings = settings))

		this.statusService.getFiltersObservable(this.tableIdentifier).pipe(take(1))
			.subscribe((filters: TableListFilters) => {
				this.searchTerm.setValue(filters?.searchTerm ?? '');
			})

		this.subs.push(this.searchTerm.valueChanges
			.pipe(
				debounceTime(500),
				distinctUntilChanged(),
				switchMap((value: string) => {
					this.statusService.search(this.tableIdentifier, value);
					return value;
				})
			)
			.subscribe(() => { }));

		this.subs.push(this.statusService.getLoadingObservable(this.tableIdentifier).subscribe((isLoading: boolean) => {
			this.loading = isLoading;
			this.onLoading.emit(isLoading);
		}));
	}
	ngOnDestroy(): void {
		this.subs.forEach(s => s?.unsubscribe());
	}

	onScroll(): void {
		if (this.tableFilters.currentPage + 1 < this.tableFilters.totalPages) {
			this.statusService.readPage(this.tableIdentifier, ++this.tableFilters.currentPage);
		}
	}
}
