import { Injectable } from '@angular/core';
import { map, Observable, of, Subscription, take, tap } from 'rxjs';
import { BehaviorSubject } from 'rxjs';
import { MessageTypes } from '../helpers/message-types';
import { RecordStates } from '../helpers/record-states-enum';
import { RoleEnum } from '../helpers/role-enum';
import { Utils } from '../helpers/utils';
import { DatatableFilters } from '../models/DatatableFilters';
import { IChannel } from '../interfaces/iChannel';
import { IChatMessage } from '../interfaces/iChatMessage';
import { ActiveChannel } from '../models/active-channel';
import { ConsultationSettings } from '../models/consultation-settings';
import { AppointmentService } from '../services/appointment.service';
import { AuthenticationService } from '../services/authentication.service';
import {
	ChannelService,
	ChannelUsersService,
} from '../services/channel.service';
import { SignalrService } from '../services/signalr.service';
import * as OT from '@opentok/client';
import { ChannelType } from '../helpers/channel-type';
import { IVideoRoom } from '../interfaces/IVideoRoom';
import { IUser } from '../interfaces/IUser';
import { TranslateService } from '@ngx-translate/core';
import { IChatMessageRecipient } from '../interfaces/IChatMessageRecipient';

@Injectable({
	providedIn: 'root',
})
export class ConsultationStatusService {
	private subs: Subscription[] = [];

	private channelsSubject = new BehaviorSubject<IChannel[]>([]);
	channelsObs: Observable<IChannel[]> = this.channelsSubject.asObservable();
	private get channels() {
		return this.channelsSubject.getValue();
	}

	private settingsSubject = new BehaviorSubject<ConsultationSettings>({
		showVideo: false,
		showSharedFiles: false,
		showMeetingNotes: false,
		showPatientHistory: false,
		showAddUser: false,
		archiveMode: false,
		hasPermissionsToCreateAChannel: this.authService.hasSomeRole(
			RoleEnum.Doctor,
			RoleEnum.Therapist
		),
	});

	settingsObs: Observable<ConsultationSettings> =
		this.settingsSubject.asObservable();

	private previousActiveChannels: ActiveChannel[] = [];
	private activeChannelSubject = new BehaviorSubject<ActiveChannel>(null);
	activeChannelObs: Observable<ActiveChannel> =
		this.activeChannelSubject.asObservable();
	private get activeChannel() {
		return this.activeChannelSubject.getValue();
	}

	private activeChannelNewMessageSubject = new BehaviorSubject<IChatMessage>(
		null
	);
	activeChannelNewMessageObs: Observable<IChatMessage> =
		this.activeChannelNewMessageSubject.asObservable();

	private activeChannelNewDocumentMessageSubject =
		new BehaviorSubject<IChatMessage>(null);
	activeChannelNewDocumentMessageObs: Observable<IChatMessage> =
		this.activeChannelNewDocumentMessageSubject.asObservable();

	private newMessageSubject = new BehaviorSubject<IChatMessage>(null);
	newMessageObs: Observable<IChatMessage> =
		this.newMessageSubject.asObservable();

	private activeChannelMessageReadSubject = new BehaviorSubject<IChatMessage>(
		null
	);
	activeChannelMessageReadObs: Observable<IChatMessage> =
		this.activeChannelMessageReadSubject.asObservable();

	private messageReadSubject = new BehaviorSubject<IChatMessage>(null);
	messageReadObs: Observable<IChatMessage> =
		this.messageReadSubject.asObservable();

	private activeChannelIsTypingSubject = new BehaviorSubject<string>(null);
	activeChannelIsTypingObs: Observable<string> =
		this.activeChannelIsTypingSubject.asObservable();

	private IsTypingSubject = new BehaviorSubject<{
		channelId: number;
		userName: string;
	}>(null);
	IsTypingObs: Observable<{ channelId: number; userName: string }> =
		this.IsTypingSubject.asObservable();

	private currentAppointmentId: number;
	private activeChannelIdKey = 'channel-id';
	private settingsKey = 'chat-settings';
	private videoRoom: IVideoRoom;
	private session: OT.Session;

	private pagination: DatatableFilters = {
		pageSize: 12,
		totalItems: 0,
		totalPages: 0,
		currentPage: 0,
	};

	constructor(
		private channelService: ChannelService,
		private signalRService: SignalrService,
		private authService: AuthenticationService,
		private appointmentService: AppointmentService,
		private channelUsersService: ChannelUsersService,
		private translateService: TranslateService
	) { }

	setDoctorChannelAsActiveChannel(doctorId: number): Observable<void> {
		return this.channelService.readConsultationChannelId(doctorId)
			.pipe(map((response: IChannel) => {
				this.resetSettings();
				this.videoRoom = response.videoRoom;
				this.updateActiveChannel(response);
			}))
	}

	createChannel(model: IChannel) {
		return this.channelService.create(model).subscribe((c: IChannel) => {
			this.updateActiveChannel(c);
			this.toggleShowAddUser(true);
		});
	}

	answerCall(channelId: number) {
		this.setActiveChannel(channelId).subscribe(() => {
			this.toggleVideoCall(true);
		});
	}

	initSession(channelId: number): Observable<OT.Session> {
		if (this.videoRoom) {
			this.session = OT.initSession(
				this.videoRoom.apiKey,
				this.videoRoom.sessionId
			);
			this.session.connect(this.videoRoom.token, (err) => {
				if (err) {
					throw err;
				}
			});
			return of(this.session);
		} else {
			return this.channelService.readVideoRoom(channelId).pipe(
				map((room: IVideoRoom) => {
					this.session = OT.initSession(room.apiKey, room.sessionId);
					this.videoRoom = room;
					this.session.connect(this.videoRoom.token, (err) => {
						if (err) {
							throw err;
						}
					});
					return this.session;
				})
			);
		}
	}

	end(channelId: number) {
		return this.channelService.end(channelId).pipe(
			tap(() => {
				this.videoRoom = null;
			})
		);
	}

	setActiveChannel(id: number): Observable<null> {
		if (id == 0) {
			this.updateActiveChannel(null);
			return of(null);
		}

		if (this.activeChannel && this.activeChannel.channel.id != id) {
			this.resetSettings();
		}

		if (!this.activeChannel || this.activeChannel?.channel.id != id) {
			let previouslyReadChannel = this.previousActiveChannels.find(
				(c) => c.channel.id == id
			);
			if (!!previouslyReadChannel) {
				this.updateActiveChannel(
					previouslyReadChannel.channel,
					previouslyReadChannel
				);
				return of(null);
			} else {
				return this.channelService.read(id).pipe(
					tap((response: IChannel) => {
						this.videoRoom = response.videoRoom;
						this.updateActiveChannel(response);
					}),
					map((response) => null)
				);
			}
		}
		return of(null);
	}

	reset() {
		this.subs.forEach((s) => s?.unsubscribe());
		this.updateActiveChannel(null);
		this.activeChannelIsTypingSubject.next(null);
		this.activeChannelMessageReadSubject.next(null);
		this.activeChannelNewDocumentMessageSubject.next(null);
		this.activeChannelNewMessageSubject.next(null);
		this.newMessageSubject.next(null);
		this.messageReadSubject.next(null);
		this.IsTypingSubject.next(null);
		this.channelsSubject.next([]);
		this.previousActiveChannels = [];
		this.currentAppointmentId = 0;
		this.resetSettings();
		this.pagination.searchTerm = null;
	}

	setChannels(pagination: DatatableFilters) {
		if (this.channels.length == 0) {
			this.resetSettings();
		}
		this.pagination.searchTerm = pagination.searchTerm;
		this.channelService
			.readAll(pagination)
			.subscribe((results: IChannel[]) => {
				results.forEach((c) => {
					this.lastMessage(c);
					c.status = c.doctors.concat(c.users)
						.some(u => u.id != this.authService.userValue.id && u.isOnline)
						? 'online'
						: 'offline';
				});
				if (pagination.currentPage == 0) {
					this.channelsSubject.next(
						Utils.arrayUnique([...results, ...this.channels], 'id')
					);
				} else {
					this.channelsSubject.next(
						Utils.arrayUnique([...this.channels, ...results], 'id')
					);
				}
			});
	}

	toggleVideoCall(start: boolean, appointmentId?: number) {
		if (start) {
			if (!!appointmentId) {
				this.currentAppointmentId = appointmentId;
				this.appointmentService.start(appointmentId).subscribe();
			}
		} else {
			if (this.currentAppointmentId) {
				this.appointmentService
					.finish(this.currentAppointmentId)
					.subscribe(() => (this.currentAppointmentId = null));
			}
		}
		let settings = this.settingsSubject.getValue();
		settings.showVideo = start;
		this.setSettings(settings);
	}

	toggleShowSharedFiles(show: boolean) {
		let settings = this.settingsSubject.getValue();
		settings.showSharedFiles = show;
		if (show) {
			settings.showAddUser = false;
			settings.showMeetingNotes = false;
			settings.showPatientHistory = false;
		}
		this.setSettings(settings);
	}

	toggleShowMeetingNotes(show: boolean) {
		let settings = this.settingsSubject.getValue();
		settings.showMeetingNotes = show;
		if (show) {
			settings.showAddUser = false;
			settings.showSharedFiles = false;
			settings.showPatientHistory = false;
		}
		this.setSettings(settings);
	}

	toggleShowPatientHistory(show: boolean) {
		let settings = this.settingsSubject.getValue();
		settings.showPatientHistory = show;
		if (show) {
			settings.showAddUser = false;
			settings.showSharedFiles = false;
			settings.showMeetingNotes = false;
		}
		this.setSettings(settings);
	}

	toggleShowAddUser(show: boolean) {
		let settings = this.settingsSubject.getValue();
		settings.showAddUser = show;
		if (show) {
			settings.showMeetingNotes = false;
			settings.showSharedFiles = false;
			settings.showPatientHistory = false;
		}
		this.setSettings(settings);
	}

	updateChannelName(channelName: string) {
		let activeChannel = this.activeChannel;
		activeChannel.channel.name = channelName;
		this.channelService
			.update(activeChannel.channel.id, activeChannel.channel)
			.subscribe((channel: IChannel) => {
				this.updateActiveChannel(channel);
			});
	}

	toggleArchiveChannel(archiving: boolean) {
		var request;
		if (archiving) {
			request = this.channelUsersService.archive(
				this.activeChannel.channel.id
			);
		} else {
			request = this.channelUsersService.unarchive(
				this.activeChannel.channel.id
			);
		}
		request.subscribe(() => {
			this.resetSettings();
			this.channelsSubject.next(
				this.channels.filter(
					(c) => c.id != this.activeChannel.channel.id
				)
			);
			this.updateActiveChannel(null);
		});
	}

	toggleArchiveMode(archiveMode: boolean) {
		var oldArchiveMode = this.settingsSubject.getValue()?.archiveMode;
		if (oldArchiveMode == archiveMode) {
			return;
		}
		this.previousActiveChannels = [];
		this.channelsSubject.next([]);
		this.updateActiveChannel(null);
		this.resetSettings();
		let settings = this.settingsSubject.getValue();
		settings.archiveMode = archiveMode;
		this.setSettings(settings);
	}

	addUserToChannel(userId: number): Observable<void> {
		return this.channelUsersService
			.add(this.activeChannel.channel.id, userId)
			.pipe(
				map((channel: IChannel) => {
					this.updateActiveChannel(channel);
				})
			);
	}

	removeUserFromChannel(userId: number): Observable<void> {
		return this.channelUsersService
			.remove(this.activeChannel.channel.id, userId)
			.pipe(
				map((channel: IChannel) => {
					if (channel.doctors.length + channel.users.length > 1) {
						this.updateActiveChannel(channel);
					} else {
						this.resetSettings();
						this.channelsSubject.next(
							this.channels.filter(
								(c) => c.id != this.activeChannel.channel.id
							)
						);
						this.updateActiveChannel(null);
					}
				})
			);
	}

	readLocalStorage() {
		var activeChannelId = localStorage.getItem(this.activeChannelIdKey);
		if (!!activeChannelId) {
			this.setActiveChannel(+activeChannelId)
				.pipe(take(1))
				.subscribe(() => {
					var settings = localStorage.getItem(this.settingsKey);
					if (!!settings) {
						this.setSettings(
							JSON.parse(settings) as ConsultationSettings
						);
					}
				});
		}
	}

	private updateActiveChannel(
		channel: IChannel,
		activeChannel: ActiveChannel = null
	) {
		if (channel) {
			localStorage.setItem(this.activeChannelIdKey, channel.id + '');
			let currentUser = this.authService.userValue;
			let isChannelDoctorOrTherapist =
				(this.authService.isDoctor || this.authService.isTherapist) &&
				channel.doctors.some((d) => d.id == currentUser?.id);

			var nextChannel: ActiveChannel = {
				channel: channel,
				readOnly:
					activeChannel?.readOnly ??
					channel.participantStatus.find(
						(p) => p.userId == currentUser?.id
					).recordState == RecordStates.ReadOnly,
				hasPermissionsToStartVideoCall:
					activeChannel?.hasPermissionsToStartVideoCall ??
					(isChannelDoctorOrTherapist &&
						channel.doctors.length + channel.users.length > 1),
				hasPermissionsToMeetingNotes:
					activeChannel?.hasPermissionsToMeetingNotes ??
					isChannelDoctorOrTherapist,
				hasPermissionsToPatientHistory:
					activeChannel?.hasPermissionsToPatientHistory ??
					(isChannelDoctorOrTherapist && channel.users.length == 1),
				canJoinOnGoingVideoCall:
					activeChannel?.canJoinOnGoingVideoCall ?? !!this.videoRoom,
				canEditName:
					activeChannel?.canEditName ??
					(isChannelDoctorOrTherapist &&
						channel.doctors.length + channel.users.length > 2 &&
						!(
							channel.participantStatus.find(
								(p) => p.userId == currentUser?.id
							).recordState == RecordStates.ReadOnly
						)),
				isGroup:
					activeChannel?.isGroup ??
					channel.channelType == ChannelType.General,
				hasPermissionsToAddUser:
					activeChannel?.hasPermissionsToAddUser ??
					isChannelDoctorOrTherapist,
				hasPermissionsToBookSession:
					activeChannel?.hasPermissionsToBookSession ??
					(channel.channelType == ChannelType.General &&
						isChannelDoctorOrTherapist),
			};

			this.previousActiveChannels.push(nextChannel);
			this.activeChannelSubject.next(nextChannel);
		} else {
			this.videoRoom = null;
			localStorage.removeItem(this.activeChannelIdKey);
			this.activeChannelSubject.next(null);
		}
	}

	private setSettings(settings: ConsultationSettings) {
		if (!!settings) {
			localStorage.setItem(this.settingsKey, JSON.stringify(settings));
		} else {
			localStorage.removeItem(this.settingsKey);
		}
		this.settingsSubject.next(settings);
	}

	subscribeToSignalR() {
		this.subs.push(
			this.signalRService.newChatMessage.subscribe(
				(message: IChatMessage) => {
					if (!message) {
						return;
					}

					this.newMessageSubject.next(message);
					if (
						message &&
						this.activeChannel?.channel.id == message.channelId
					) {
						this.activeChannelNewMessageSubject.next(message);
					}

					var channel = this.channels.find(
						(c) => c.id == message.channelId
					);

					if (channel) {
						channel.messages = [message];
						this.lastMessage(channel);
						this.channelsSubject.next([
							channel,
							...this.channels.filter(
								(c) => c.id != message.channelId
							),
						]);
					} else {
						this.setChannels(this.pagination);
					}

					if (this.activeChannel?.channel.id == message.channelId) {
						let activeChannel = this.activeChannel;
						switch (message.typeId) {
							case MessageTypes.VideoCallStarted:
								activeChannel.canJoinOnGoingVideoCall = true;
								break;
							case MessageTypes.VideoCallEnded:
								activeChannel.canJoinOnGoingVideoCall = false;
								this.videoRoom = null;
								break;
							case MessageTypes.UploadDocumentMessage:
								this.activeChannelNewDocumentMessageSubject.next(
									message
								);
								break;
							case MessageTypes.JoinedTheGroup:
							case MessageTypes.RemovedFromTheGroup:
								return;
							default:
								break;
						}
						this.activeChannelSubject.next(activeChannel);
					}
				}
			)
		);

		this.subs.push(
			this.signalRService.chatMessageRead.subscribe(
				(message: IChatMessage) => {
					if (!message) {
						return;
					}
					this.messageReadSubject.next(message);

					if (
						this.activeChannel?.channel.id == message.channelId &&
						message.senderId == this.authService.userValue?.id
					) {
						this.activeChannelMessageReadSubject.next(message);
					}
				}
			)
		);

		this.subs.push(
			this.signalRService.iAmTyping.subscribe(
				(model: { channelId: number; userName: string }) => {
					if (
						model == null ||
						model.userName == this.authService.userValue.userName
					) {
						return;
					}

					this.IsTypingSubject.next(model);

					if (
						!!this.activeChannel &&
						model.channelId == this.activeChannel.channel.id
					) {
						this.activeChannelIsTypingSubject.next(model.userName);
					}
				}
			)
		);

		this.subs.push(
			this.signalRService.iAmOnline.subscribe((userName: string) => {
				let channels = this.channels;
				channels.forEach((element) => {
					if (element.doctors.some((d) => d.userName == userName)) {
						element.status = 'online';
					}
					if (element.users.some((d) => d.userName == userName)) {
						element.status = 'online';
					}
				});
				this.channelsSubject.next(channels);
			})
		);

		this.subs.push(
			this.signalRService.iAmOffline.subscribe((userName: string) => {
				let channels = this.channels;
				channels.forEach((element) => {
					if (element.doctors.some((d) => d.userName == userName)) {
						element.status = 'offline';
					}
					if (element.users.some((d) => d.userName == userName)) {
						element.status = 'offline';
					}
				});
				this.channelsSubject.next(channels);
			})
		);
	}

	private lastMessage(channel: IChannel) {
		var currentUser = this.authService.userValue;
		var lastMessage = channel.messages[0];
		if (lastMessage) {
			var messageRecipientCurrentUser: IChatMessageRecipient =
				lastMessage.recipients.find(
					(r) => r.recipient.id == currentUser.id
				);

			var content =
				lastMessage.content.length < 25
					? lastMessage.content
					: lastMessage.content.substring(0, 25) + '...';

			if (
				(messageRecipientCurrentUser &&
					messageRecipientCurrentUser.isRead &&
					messageRecipientCurrentUser.readDate) ||
				lastMessage.senderId == currentUser.id
			) {
				lastMessage.content = content;
			} else {
				lastMessage.content = '<strong>' + content + '</strong>';
			}
		}
	}

	private resetSettings() {
		this.setSettings({
			showVideo: false,
			showSharedFiles: false,
			showMeetingNotes: false,
			showPatientHistory: false,
			showAddUser: false,
			archiveMode: this.settingsSubject.getValue().archiveMode,
			hasPermissionsToCreateAChannel: this.authService.hasSomeRole(
				RoleEnum.Doctor,
				RoleEnum.Therapist
			),
		});
	}

	hideParticipantsDisplayName(users: IUser[]) {
		var currentUser = this.authService.userValue;
		var isCurrentUserDoctorOrTherapist =
			this.authService.isDoctor || this.authService.isTherapist;

		if (!isCurrentUserDoctorOrTherapist) {
			users
				.filter(
					(x) =>
						x.employeeInfoAccountId == 0 && x.id != currentUser.id
				)
				.forEach((x) => {
					x.displayName =
						this.translateService.instant('Participant');
					x.photo.url = './assets/images/default-user.png';
				});
		}
	}
}
