import {
	Component,
	InjectionToken,
	Input,
	OnDestroy,
	OnInit,
	ViewChild,
} from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { RouteList } from '@app/01.global/helpers/route-enum';
import { IAddress } from '@app/01.global/interfaces/iAddress';
import { IAgreementCard } from '@app/01.global/interfaces/iAgreementCard';
import { IAppointment } from '@app/01.global/interfaces/IAppointment';
import { IUser } from '@app/01.global/interfaces/IUser';
import { DatatableSettings } from '@app/01.global/models/data-table';
import { AgreementCardsService } from '@app/01.global/services/agreement-cards.service';
import { AppointmentService } from '@app/01.global/services/appointment.service';
import { BookingService } from '@app/01.global/services/booking.service';
import { FaqService } from '@app/01.global/services/faq.service';
import { UsersService } from '@app/01.global/services/users.service';
import { BookingStatusService } from '@app/01.global/status-services/booking-status.service';
import { ConsultationStatusService } from '@app/01.global/status-services/consultation-status.service';
import { TableList } from '@app/table-list/models/table-list';
import { TableListActionButtonClicked } from '@app/table-list/models/table-list-action-button';
import { TableListRow } from '@app/table-list/models/table-list-row';
import { ITableListService } from '@app/table-list/services/iservice';
import { ITableListMapperService } from '@app/table-list/services/mapper.service';
import { TableListStatusService } from '@app/table-list/services/table-list-status.service';
import { NgbModal, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { TranslateService } from '@ngx-translate/core';
import { ToastrService } from 'ngx-toastr';
import { Subscription, take } from 'rxjs';

@Component({
	selector: 'app-data-table-container',
	templateUrl: './data-table-container.component.html',
	styleUrls: ['./data-table-container.component.scss'],
})
export class DataTableContainerComponent implements OnInit, OnDestroy {
	constructor(
		public route: ActivatedRoute,
		private router: Router,
		public statusService: TableListStatusService,
		public bookingStatusService: BookingStatusService,
		public consultationStatusService: ConsultationStatusService,
		public appointmentService: AppointmentService,
		public faqService: FaqService,
		public userService: UsersService,
		private toastr: ToastrService,
		private translate: TranslateService,
		public modalService: NgbModal,
		private agreementCardsService: AgreementCardsService,
		private bookingService: BookingService
	) { }

	@Input('table') table: TableList;
	@Input('serviceToken') serviceToken: InjectionToken<ITableListService>;
	@Input('mapperToken') mapperToken: InjectionToken<ITableListMapperService>;

	@ViewChild('actionModal') actionModal: NgbModalRef;
	@ViewChild('mapModal') mapModal: NgbModalRef;

	modalTitle: string;
	location: IAddress;

	identifier: string;
	initialSettings: DatatableSettings;
	patientAgreementCards: IAgreementCard[];
	private subs: Subscription[] = [];

	ngOnInit(): void {
		if (this.table) {
			this.initialSettings = this.table.settingsSubject.getValue();
			this.identifier = this.initialSettings.identifier;
			var translatedInitialSettings = this.translateLabels(
				JSON.parse(JSON.stringify(this.initialSettings))
			);
			this.table.settingsSubject.next(translatedInitialSettings);

			this.subs.push(
				this.translate.onLangChange.subscribe(() => {
					this.statusService
						.getSettingsObservable(this.identifier)
						.pipe(take(1))
						.subscribe((settings) => {
							var translatedSettings =
								this.translateLabels(settings);
							this.statusService.updateSettings(
								this.identifier,
								translatedSettings
							);
						});
					this.statusService.readPageAndUpdateCount(
						this.identifier,
						this.table.filtersSubject.getValue().currentPage
					);
				})
			);

			this.subs.push(
				this.statusService.tableListButtonClickedObs.subscribe(
					(clicked: TableListActionButtonClicked) => {
						if (clicked && clicked.identifier == this.identifier) {
							this.modalTitle = clicked.modalTitle;
							this.statusService.setTableListButtonClicked(null);
							var renderInModal: boolean = false;
							var handleNavigation: boolean = true;

							switch (clicked.btnRoute) {
								case RouteList._routeCrudEnum.InviteEmployee:
									renderInModal = true;
									break;
								case RouteList._routeCrudEnum.Sync:
									handleNavigation = false;
									this.sync();
									break;
								case RouteList._routeCrudEnum.ReSchedule:
									renderInModal = true;
									this.router.navigate(
										[clicked.joinedRoute],
										{
											relativeTo: this.route,
										}
									);
									break;
								case RouteList._routeCrudEnum.Map:
									handleNavigation = false;
									this.setCenter(clicked.row.source);
									break;
								case RouteList._routeCrudEnum.Urgency:
									handleNavigation = false;
									this.setUrgencyEmployee(clicked.row);
									break;
								case RouteList._routeCrudEnum.AddUserToChannel:
									handleNavigation = false;
									this.setAddUserToChannel(clicked.row);
									break;
								case RouteList._routeCrudEnum.Select:
									handleNavigation = false;
									if (
										clicked.identifier.indexOf(
											RouteList._route1Enum
												.BookingPatients
										) > -1
									) {
										this.setPatient(clicked.row.source);
									}
									if (
										clicked.identifier.indexOf(
											RouteList._route2Enum
												.BookingRebookAppointment
										) > -1
									) {
										this.setPreviousAppointment(
											clicked.row.source
										);
									}
									break;
								case RouteList._routeCrudEnum.BookingByRequest:
									handleNavigation = false;
									const modalRef = this.modalService.open(
										this.actionModal,
										{
											ariaLabelledBy: 'modal-basic-title',
											size: 'xl',
										}
									);

									this.router.navigate(
										[
											RouteList._routeCrudEnum
												.BookingByRequest,
											clicked.row.id,
										],
										{ relativeTo: this.route }
									);

									modalRef.result.then(
										(result) => { },
										(reason) => {
											if (reason != 'navigate') {
												this.router.navigate(['./'], {
													relativeTo: this.route,
												});
											}
										}
									);
									break;
								case RouteList.attendance:
									handleNavigation = false;
									var sourceIsAppointment = clicked.row.source.startDate != null;

									var doctorId = sourceIsAppointment
										? (clicked.row.source as IAppointment).medicalOffice.doctorId
										: (clicked.row.source as IUser).id

									this.bookingService.oneClickDoctor(doctorId)
										.subscribe((app: IAppointment) => {
											this.router.navigate([
												RouteList.attendance,
												app.medicalOffice.branch.account.publicIdentifier,
												app.medicalOffice.doctor.publicIdentifier,
											]);
										})
									break;
								default:
									// handleNavigation = false;
									break;
							}
							if (handleNavigation) {
								if (clicked.renderInModal || renderInModal) {
									const modalRef = this.modalService.open(
										this.actionModal,
										{
											ariaLabelledBy: 'modal-basic-title',
											size: 'xl',
										}
									);

									var r = '';
									var rWhile = this.route.snapshot;

									while (rWhile != null) {
										r = RouteList.joinRoutes([
											rWhile.url.join('/'),
											r,
										]);
										rWhile = rWhile.parent;
									}

									r = RouteList.joinRoutes([
										r + clicked.joinedRoute,
									]);
									if (clicked.row) {
										r = RouteList.joinRoutes([
											r,
											clicked.row.targetId ??
											clicked.row.source?.id,
										]);
									}

									this.router.navigate([r], {
										relativeTo: this.route,
									});

									modalRef.result
										.then(
											(result) => { },
											(reason) => { }
										)
										.finally(() => {
											this.router.navigate(['./'], {
												relativeTo: this.route,
											});
										});
								} else {
									var r = '';
									var rWhile = this.route.snapshot.parent;

									while (rWhile != null) {
										r = RouteList.joinRoutes([
											rWhile.url.join('/'),
											r,
										]);
										rWhile = rWhile.parent;
									}

									r = RouteList.joinRoutes([
										r + clicked.joinedRoute,
									]);
									if (clicked.row) {
										r = RouteList.joinRoutes([
											r,
											clicked.row.targetId ??
											clicked.row.source?.id,
										]);
									}

									this.router.navigate([r], {
										relativeTo: this.route,
									});
								}
							}
						}
					}
				)
			);
		}
	}

	ngOnDestroy(): void {
		this.subs.forEach((s) => s?.unsubscribe());
	}

	onDeactivate(event) {
		if (event.keepModalOpen) {
			return;
		}
		this.modalService.dismissAll();
		this.statusService.readPageAndUpdateCount(
			this.identifier,
			this.table.filtersSubject.getValue().currentPage
		);
	}

	private sync() {
		this.faqService.deploy();
	}

	private setPatient(patient: IUser) {
		this.bookingStatusService.selectPatient(patient);
		this.bookingStatusService.setNavigation(2);

		this.agreementCardsService
			.readUserCards(patient?.id)
			.subscribe((agreementCards: IAgreementCard[]) => {
				this.patientAgreementCards = agreementCards;
				if (
					agreementCards &&
					agreementCards.some((ac) => ac.id == patient.defaultCardId)
				) {
					this.bookingStatusService.setAgreement(
						agreementCards.find(
							(ac) => ac.id == patient.defaultCardId
						)
					);
					this.router.navigate([RouteList.bookingAppointment]);
				} else if (agreementCards && agreementCards[0]) {
					this.bookingStatusService.setAgreement(agreementCards[0]);
					this.router.navigate([RouteList.bookingAppointment]);
				} else {
					this.toastr.error(
						this.translate.instant(
							'SelectedPatientDoesNotHaveAnyAgreement'
						),
						this.translate.instant('UnauthorizedOperation'),
						{ positionClass: 'toast-bottom-right' }
					);
				}
			});
	}

	private setPreviousAppointment(app: IAppointment) {
		this.bookingStatusService.selectRebook(app);
		this.router.navigate([this.bookingStatusService.goToNextStep()]);
	}

	private setUrgencyEmployee(row: TableListRow) {
		if (row?.source) {
			var app = row.source as IAppointment;
			this.bookingStatusService.selectUrgency(app);
			this.router.navigate([RouteList.bookingAgreement]);
		}
	}

	private setAddUserToChannel(row) {
		this.consultationStatusService
			.addUserToChannel(row.id)
			.subscribe(() => {
				this.statusService.readPage(this.identifier, 0);
			});
	}

	private setCenter(provider: IUser) {
		this.modalTitle =
			provider.providerInfo?.commercialName ?? provider.displayName;
		this.location = provider.providerInfo?.address;
		const modalRef = this.modalService.open(this.mapModal, {
			ariaLabelledBy: 'modal-basic-title',
			size: 'lg',
		});

		modalRef.result
			.then(
				() => { },
				() => { }
			)
			.finally(() => { });
	}

	private translateLabels(settings) {
		if (settings.messages != null) {
			if (this.initialSettings.messages?.emptyMessage) {
				settings.messages.emptyMessage = this.translate.instant(
					this.initialSettings.messages?.emptyMessage
				);
			}
			if (this.initialSettings.messages?.searchboxPlaceholder) {
				settings.messages.searchboxPlaceholder = this.translate.instant(
					this.initialSettings.messages?.searchboxPlaceholder
				);
			}
			if (this.initialSettings.messages?.selectedMessage) {
				settings.messages.selectedMessage = this.translate.instant(
					this.initialSettings.messages?.selectedMessage
				);
			}
			if (this.initialSettings.messages?.titleMessage) {
				settings.messages.titleMessage = this.translate.instant(
					this.initialSettings.messages?.titleMessage
				);
			}
			if (this.initialSettings.messages?.totalMessage) {
				settings.messages.totalMessage = this.translate.instant(
					this.initialSettings.messages?.totalMessage
				);
			}
		}

		settings.actionButtons?.forEach((element) => {
			var initialElement = this.initialSettings.actionButtons.find(
				(b) => b.order == element.order
			);
			if (initialElement.text != null) {
				element.text = this.translate.instant(initialElement.text);
			}
			if (initialElement.loadingText != null) {
				element.loadingText = this.translate.instant(
					initialElement.loadingText
				);
			}
		});

		return settings;
	}
}
