enum Route0Enum {
	AgreementGroup = 'agreement-group',
	Calendar = 'calendar',
	Consultation = 'consultation',
	ContactInfo = 'contact-info',
	ConsultationTypeStandard = 'consultation-type-standard',
	SpecialtiesStandard = 'specialties-standard',
	MedicalProcedure = 'medical-procedure',
	Dashboard = 'dashboard',
	Inbox = 'inbox',
	Files = 'files',
	Profile = 'profile',
	Providers = 'providers',
	Login = 'login',
	Logs = 'logs',
	Register = 'register',
	ForgotPassword = 'forgot-password',
	ResetPassword = 'reset-password',
	PrivacyPolicy = 'privacy-policy',
	TermsService = 'terms-service',
	Unauthorized = '401',
	NotFound = '404',
	Lockout = 'lockout',
	Booking = 'booking',
	Attendance = '',
	ConfirmEmail = 'confirm-email/:receiverId/:token',
}

enum Route1Enum {
	Agreements = 'agreements',
	AddUserAgreement = 'add-user-agreement',
	UserSentFiles = 'user-sent-files',
	UserPrescription = 'user-prescription',
	Specialties = 'specialties',
	Employees = 'employees',
	Faqs = 'faqs',
	Patients = 'patients',
	MedicalOffices = 'medicaloffices',
	Channels = 'channels',
	Message = 'message',
	TherapyGroups = 'therapygroups',
	Colors = 'colors',
	History = 'history',
	EventsList = 'eventsList',
	Compose = 'compose',
	Reply = 'reply',
	ReplyAll = 'replyall',
	Branches = 'branches',
	ConsultationTypes = 'consultation-types',
	Forward = 'forward',
	Appointment = 'appointment',
	BookingSummary = 'summary',
	BookingUrgency = 'urgency',
	BookingPatients = 'availablePatients',
	ConsultationSettings = 'consultation-settings',
	BookingRequests = 'booking-requests',
	PatientQueue = 'patient-queue',
	Reviews = 'reviews',
}

enum Route2Enum {
	DoctorOffices = 'doctor-offices',
	UrgentConsultations = 'urgent-consultations',
	TriageAnswers = 'triageanswers',
	TriageForms = 'triageforms',
	Participants = 'participants',
	Sessions = 'sessions',
	FavoriteDoctors = 'favorite-doctors',
	BookingAgreement = 'booking-agreement',
	BookingConsultationType = 'consultation-type',
	BookingSchedule = 'schedule',
	BookingRebookAppointment = 'RebookAppointment',
}

enum RouteCrudEnum {
	Accept = 'accept',
	Reject = 'reject',
	Search = 'search',
	Select = 'select',
	Urgency = 'urgency',
	Map = 'map',
	Sync = 'sync',
	List = 'list',
	Add = 'add',
	Edit = 'edit',
	Delete = 'delete',
	Details = 'details',
	InProgress = 'in-progress',
	Archived = 'archived',
	ReSchedule = 'reSchedule',
	EditconsultationTypesModal = 'editconsultationTypesModal',
	AddFromProvider = 'addFromProvider',
	AddUserToChannel = 'AddUserToChannel',
	BookingByRequest = 'BookingByRequest',
	InviteEmployee = 'invite-employee',
}

export class RouteList {
	static _route0Enum = Route0Enum;
	static _route1Enum = Route1Enum;
	static _route2Enum = Route2Enum;
	static _routeCrudEnum = RouteCrudEnum;
	static agreementGroup: string = '/' + Route0Enum.AgreementGroup;
	static attendance: string = '';
	static patientQueue: string = '/' + Route1Enum.PatientQueue;
	static agreements: string =
		'/' + Route0Enum.AgreementGroup + '/' + Route1Enum.Agreements;
	static calendar: string = '/' + Route0Enum.Calendar;
	static specialtiesStandard: string = '/' + Route0Enum.SpecialtiesStandard;
	static medicalProcedure: string = '/' + Route0Enum.MedicalProcedure;
	static consultationTypeStandard: string =
		'/' + Route0Enum.ConsultationTypeStandard;
	static history: string =
		'/' + Route0Enum.Calendar + '/' + Route1Enum.History;
	static eventsList: string =
		'/' + Route0Enum.Calendar + '/' + Route1Enum.EventsList;
	static consultation: string = '/' + Route0Enum.Consultation;
	static contactInfo: string = '/' + Route0Enum.ContactInfo;
	static dashboard: string = '/' + Route0Enum.Dashboard;
	static inbox: string = '/' + Route0Enum.Inbox;
	static files: string = '/' + Route0Enum.Files;
	static profile: string = '/' + Route0Enum.Profile;
	static addUserAgreement: string =
		'/' + Route0Enum.Profile + '/' + Route1Enum.AddUserAgreement;
	static providers: string = '/' + Route0Enum.Providers;
	static lockout: string = '/' + Route0Enum.Lockout;
	static login: string = '/' + Route0Enum.Login;
	static logs: string = '/' + Route0Enum.Logs;
	static register: string = '/' + Route0Enum.Register;
	static privacyPolicy: string = '/' + Route0Enum.PrivacyPolicy;
	static termsService: string = '/' + Route0Enum.TermsService;
	static forgotPassword: string = '/' + Route0Enum.ForgotPassword;
	static resetPassword: string = '/' + Route0Enum.ResetPassword;
	static unauthorized: string = '/' + Route0Enum.Unauthorized;
	static notFound: string = '/' + Route0Enum.NotFound;
	static userSentFiles: string =
		'/' + Route0Enum.Files + '/' + Route1Enum.UserSentFiles;
	static userPrescription: string =
		'/' + Route0Enum.Files + '/' + Route1Enum.UserPrescription;
	static specialties: string =
		'/' + Route0Enum.Providers + '/' + Route1Enum.Specialties;
	static employees: string =
		'/' + Route0Enum.Providers + '/' + Route1Enum.Employees;
	static doctorOffices: string =
		'/' +
		Route0Enum.Providers +
		'/' +
		Route1Enum.Employees +
		'/' +
		Route2Enum.DoctorOffices;
	static consultationTypes: string =
		'/' + Route0Enum.Providers + '/' + Route1Enum.ConsultationTypes;
	static branches: string =
		'/' + Route0Enum.Providers + '/' + Route1Enum.Branches;
	static faqs: string = '/' + Route0Enum.Providers + '/' + Route1Enum.Faqs;
	static patients: string =
		'/' + Route0Enum.Providers + '/' + Route1Enum.Patients;
	static colors: string =
		'/' + Route0Enum.Providers + '/' + Route1Enum.Colors;
	static ProviderAgreements: string =
		'/' + Route0Enum.Providers + '/' + Route1Enum.Agreements;
	static ProfileConsultationSettings: string =
		'/' + Route0Enum.Profile + '/' + Route1Enum.ConsultationSettings;
	static medicalOffices: string =
		'/' + Route0Enum.Providers + '/' + Route1Enum.MedicalOffices;
	static urgentConsultations: string =
		'/' +
		Route0Enum.Providers +
		'/' +
		Route1Enum.Employees +
		'/' +
		Route2Enum.UrgentConsultations;
	static triageForms: string =
		'/' +
		Route0Enum.Providers +
		'/' +
		Route1Enum.Specialties +
		'/' +
		Route2Enum.TriageForms;
	static triageAnswers: string =
		'/' +
		Route0Enum.Providers +
		'/' +
		Route1Enum.Specialties +
		'/' +
		Route2Enum.TriageAnswers;
	static sessions: string =
		'/' +
		Route0Enum.Providers +
		'/' +
		Route1Enum.TherapyGroups +
		'/' +
		Route2Enum.Sessions;
	static message: string = '/' + Route0Enum.Inbox + '/' + Route1Enum.Message;
	static compose: string = '/' + Route0Enum.Inbox + '/' + Route1Enum.Compose;
	static reply: string = '/' + Route0Enum.Inbox + '/' + Route1Enum.Reply;
	static replyAll: string =
		'/' + Route0Enum.Inbox + '/' + Route1Enum.ReplyAll;
	static forward: string = '/' + Route0Enum.Inbox + '/' + Route1Enum.Forward;
	static booking: string = '/' + Route0Enum.Booking;
	static bookingAppointment: string =
		'/' + Route0Enum.Booking + '/' + Route1Enum.Appointment;
	static bookingAgreement: string =
		'/' +
		Route0Enum.Booking +
		'/' +
		Route1Enum.Appointment +
		'/' +
		Route2Enum.BookingAgreement;
	static bookingConsultationType: string =
		'/' +
		Route0Enum.Booking +
		'/' +
		Route1Enum.Appointment +
		'/' +
		Route2Enum.BookingConsultationType;
	static bookingSchedule: string =
		'/' +
		Route0Enum.Booking +
		'/' +
		Route1Enum.Appointment +
		'/' +
		Route2Enum.BookingSchedule;
	static bookingRebookAppointment: string =
		'/' +
		Route0Enum.Booking +
		'/' +
		Route1Enum.Appointment +
		'/' +
		Route2Enum.BookingRebookAppointment;
	static bookingSummary: string =
		'/' + Route0Enum.Booking + '/' + Route1Enum.BookingSummary;
	static bookingUrgency: string =
		'/' + Route0Enum.Booking + '/' + Route1Enum.BookingUrgency;
	static bookingPatients: string =
		'/' + Route0Enum.Booking + '/' + Route1Enum.BookingPatients;
	static bookingRequests: string =
		'/' + Route0Enum.Booking + '/' + Route1Enum.BookingRequests;

	static joinRoutes(routes: string[]): string {
		return routes.join('/');
	}
}
