import { NgModule } from '@angular/core';
import { CommonModule, DatePipe } from '@angular/common';
import { BtnLoadingComponent } from './btn-loading/btn-loading.component';
import { FeatherIconComponent } from './feather-icon/feather-icon.component';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { RouterModule } from '@angular/router';
import { SharedPipesModule } from '../pipes/shared-pipes.module';
import { SearchModule } from './search/search.module';
import { SharedDirectivesModule } from '../directives/shared-directives.module';
import { PerfectScrollbarModule } from 'ngx-perfect-scrollbar';
import { TranslateModule } from '@ngx-translate/core';
import { InfiniteScrollModule } from 'ngx-infinite-scroll';
import { NgxPaginationModule } from 'ngx-pagination';
import { NgxDatatableModule } from '@swimlane/ngx-datatable';
import { LocalLoaderComponent } from './local-loader/local-loader.component';
import { NgxDropzoneModule } from 'ngx-dropzone';
import { UserFormComponent } from './user-form/user-form.component';
import { TagInputModule } from 'ngx-chips';
import { LabelWithPopoverComponent } from './label-with-popover/label-with-popover.component';
import { CalendarComponent } from './calendar/calendar.component';
import {
	CalendarDateFormatter,
	CalendarModule,
	DateAdapter,
} from 'angular-calendar';
import { adapterFactory } from 'angular-calendar/date-adapters/date-fns';
import { CalendarHeaderComponent } from './calendar/calendar-header/calendar-header.component';
import { CustomDateFormatter } from '@app/01.global/helpers/date-formater';
import { ModalComponent } from './modal/modal.component';
import { CustomFormControlComponent } from './form-control/form-control.component';
import { ImageCropperModule } from 'ngx-img-cropper';
import { CropperModalComponent } from './form-control/cropper-modal/cropper-modal.component';
import { MapComponent } from './map/map.component';
import { DropzoneImagePreviewComponent } from './form-control/dropzone-image-preview/dropzone-image-preview.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { GoogleMapsModule } from '@angular/google-maps';
import { HttpClientJsonpModule } from '@angular/common/http';
import { AddressPickerComponent } from './form-control/address-picker/address-picker.component';
import { AppointmentTimelineComponent } from './appointment-timeline/appointment-timeline.component';
import { UsersWidgetComponent } from '@app/02.dashboard/users-widget/users-widget.component';
import { BtnSlideTextComponent } from './btn-slide-text/btn-slide-text.component';
import { TableListModule } from '@app/table-list/table-list.module';
import { DataTableContainerComponent } from './data-table-container/data-table-container.component';
import { AddressComponent } from './display-templates/address/address.component';
import { CardOverlayComponent } from './card-overlay/card-overlay.component';
import { DetailsHeroSectionComponent } from './details-hero-section/details-hero-section.component';
import { BookingEmployeesReviewsComponent } from './booking-employees-reviews/booking-employees-reviews.component';

const components = [
	BtnLoadingComponent,
	BtnSlideTextComponent,
	FeatherIconComponent,
	DataTableContainerComponent,
	LocalLoaderComponent,
	DropzoneImagePreviewComponent,
	UserFormComponent,
	LabelWithPopoverComponent,
	CalendarComponent,
	CalendarHeaderComponent,
	ModalComponent,
	CustomFormControlComponent,
	CropperModalComponent,
	MapComponent,
	AddressPickerComponent,
	AppointmentTimelineComponent,
	UsersWidgetComponent,
	AddressComponent,
	CardOverlayComponent,
	DetailsHeroSectionComponent,
	BookingEmployeesReviewsComponent,
];

@NgModule({
	imports: [
		CommonModule,
		RouterModule,
		SharedPipesModule,
		SharedDirectivesModule,
		SearchModule,
		PerfectScrollbarModule,
		TranslateModule,
		InfiniteScrollModule,
		FormsModule,
		ReactiveFormsModule,
		NgxPaginationModule,
		TagInputModule,
		NgxDatatableModule,
		NgxDropzoneModule,
		NgbModule,
		ImageCropperModule,
		CalendarModule.forRoot(
			{
				provide: DateAdapter,
				useFactory: adapterFactory,
			},
			{
				dateFormatter: {
					provide: CalendarDateFormatter,
					useClass: CustomDateFormatter,
				},
			}
		),
		GoogleMapsModule,
		HttpClientJsonpModule,
		TableListModule,
	],
	declarations: components,
	exports: components,
	providers: [DatePipe],
})
export class SharedComponentsModule {}
