<div class="row">
	<div class="col">
		<form [formGroup]="bookingByConsultationTypeForm" (ngSubmit)="submitForm()">
			<div class="row mb-1">
				<div class="col form-group">
					<app-form-control [type]="ControlTypeEnum.Typeahead" [showLabel]="false"
						[placeholder]="'SelectAProvider' | translate" [size]="'lg'"
						[formatterTypeahead]="formatterProvider"
						[control]="bookingByConsultationTypeForm.get('provider')"
						(typeaheadSearchChange)="updateSearchProvider($event)" [serviceReadMethod]="searchProvider">
					</app-form-control>
				</div>
			</div>
			<div class="row mb-3">
				<div class="col form-group">
					<app-form-control [type]="ControlTypeEnum.Typeahead" [showLabel]="false"
						[placeholder]="'SelectAConsultationType' | translate" [size]="'lg'"
						[formatterTypeahead]="formatterConsultationType"
						[control]="bookingByConsultationTypeForm.get('consultationType')"
						(typeaheadSearchChange)="updateSearchConsultationType($event)"
						[serviceReadMethod]="searchConsultationType">
					</app-form-control>
				</div>
			</div>
			<div class="row mb-3">
				<div class="col form-group">
					<app-form-control [type]="ControlTypeEnum.Typeahead" [showLabel]="false"
						[placeholder]="'SelectAEmployee' | translate" [size]="'lg'"
						[formatterTypeahead]="formatterEmployee"
						[control]="bookingByConsultationTypeForm.get('employee')"
						(typeaheadSearchChange)="updateSearchEmployee($event)" [serviceReadMethod]="searchEmployee">
					</app-form-control>
				</div>
			</div>
			<div class="row mb-3" *ngIf="bookingByConsultationTypeForm.get('consultationType').value != null"
				[@animate]="{
				value: '*',
				params: { delay: 250 + 'ms', y: '-250px' }
			}">
				<div class="col">
					<!-- region slots list -->
					<div class="card" *ngIf="daysWithSluts?.length > 0 || nextAppointments?.length > 0">
						<div class="card-body">
							<div class="row">
								<div class="col-auto" style="border-right: 1px solid #ccc;">
									<ng-template #customDay let-date="date" let-currentMonth="currentMonth"
										let-currentYear="currentYear" let-selected="selected">
										<span class="day" [class.available-slots]="hasEmptySlots(date)"
											[class.selected]="selected">
											{{ date.day }}
										</span>
									</ng-template>

									<ngb-datepicker ngbDatepicker [displayMonths]="1" outsideDays="hidden"
										[weekdays]="false" navigation="arrows" [dayTemplate]="customDay"
										[minDate]="dateNow" (dateSelect)="chosenDay($event)"></ngb-datepicker>
								</div>
								<div class="col" style="min-height: 16.875rem; max-height: 16.875rem;"
									[perfectScrollbar]="{ suppressScrollX: true }">
									<div *ngFor="let app of nextAppointments; let i = index"
										class="d-flex align-items-center border-bottom-dotted-dim"
										[ngClass]="i == nextAppointments.length-1 ? '' : 'pb-3 mb-3'">
										<ng-container *ngIf="bookingConsultationTypeModel?.employee">
											<div class="">
												<p class="m-0">
													<span class="text-14"><strong>{{ app.startDate | localizedDate:
															'fullDate'
															}}</strong></span>
													<br>
													<i class="i-Clock-Back text-primary text-16"></i>
													<span class="text-12">
														{{ app.startDate | localizedDate: 'shortTime' }}
													</span>
													&nbsp;&nbsp;-&nbsp;&nbsp;
													<i class="i-Clock-Forward text-primary text-16"></i>
													<span class="text-12">
														{{ app.endDate | localizedDate: 'shortTime' }}
													</span>
													<br>
													<span
														class=" text-small text-muted">{{app.medicalOffice?.branch?.name}}</span>
												</p>
											</div>
											<div class="ml-auto">
												<button *ngIf="previousChoice?.id != app.id"
													class="btn btn-outline-info btn-rounded" (click)="setSchedule(app)">
													{{ "Choose" | translate }}
												</button>
											</div>
										</ng-container>
										<ng-container *ngIf="!bookingConsultationTypeModel?.employee">
											<div class="avatar-md mr-3 img-bg-setup"
												style="height: 5.5rem; width: 5.5rem" [ngStyle]="{
												'background-image':
													'url(' +
													(app.medicalOffice.doctor?.photo
														?.url ??
														'./assets/images/default-user.png') +
													')'
											}"></div>
											<div class="flex-grow-1">
												<h6 class="m-0">
													{{
													app.medicalOffice.doctor?.displayName
													}}
												</h6>
												<p class="m-0">
													<span class="text-14"><strong>{{ app.startDate | localizedDate:
															'fullDate'
															}}</strong></span>
													<br>
													<i class="i-Clock-Back text-primary text-16"></i>
													<span class="text-12">
														{{ app.startDate | localizedDate: 'shortTime' }}
													</span>
													&nbsp;&nbsp;-&nbsp;&nbsp;
													<i class="i-Clock-Forward text-primary text-16"></i>
													<span class="text-12">
														{{ app.endDate | localizedDate: 'shortTime' }}
													</span>
													<br>
													<span
														class=" text-small text-muted">{{app.medicalOffice?.branch?.name}}</span>
												</p>
											</div>
											<div>
												<button *ngIf="previousChoice?.id != app.id"
													class="btn btn-outline-info btn-rounded" (click)="setSchedule(app)">
													{{ "Choose" | translate }}
												</button>
											</div>
										</ng-container>
									</div>
								</div>
							</div>
						</div>
					</div>
					<!-- endregion slots list -->
					<div class="card" *ngIf="daysWithSluts?.length == 0 && nextAppointments?.length == 0" [@animate]="{
						value: '*',
						params: { delay: 250 + 'ms', y: '-250px' }
					}">
						<div class="card-body">
							<div class="card-title">
							</div>
							<div class="alert-card alert alert-warning">
								<span>{{ "MustSetYourWorkingScheduleFirst" | translate }}</span>
							</div>
						</div>
					</div>
				</div>
			</div>
		</form>
	</div>
</div>

<ng-template #confirmBookSessionModal let-modal>
	<app-modal (onClose)="modal.dismiss($event)" (onConfirmClose)="modal.close($event)"
		[confirmButtonClass]="'secondary'">
		<div modal-header>{{ 'ConfirmBookSessionModal' | translate }}</div>
		<div modal-body>
			{{ 'ConfirmBookSessionModal' | translate }}
		</div>
	</app-modal>
</ng-template>