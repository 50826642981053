import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { AuthGuard } from '@app/01.global/helpers/auth.guard';
import { ChannelResolver } from '@app/01.global/resolvers/channel.resolver';
import { ConsultationComponent } from './consultation/consultation.component';

const routes: Routes = [
	{
		path: '',
		pathMatch: 'full',
		component: ConsultationComponent,
		canActivate: [AuthGuard],
		data: {
			archiveMode: false
		},
	},
	{
		path: 'archived',
		pathMatch: 'full',
		component: ConsultationComponent,
		canActivate: [AuthGuard],
		data: {
			archiveMode: true
		},
	},
];

@NgModule({
	imports: [RouterModule.forChild(routes)],
	exports: [RouterModule],
})
export class ConsultationRoutingModule { }
