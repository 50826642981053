import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from '@environments/environment';
import { TranslateService } from '@ngx-translate/core';
import { ToastrService } from 'ngx-toastr';
import { map, Observable, of } from 'rxjs';
import { ITableListService } from '../../table-list/services/iservice';
import { DatatableFilters } from '../models/DatatableFilters';
import { BaseService } from './base.service';

@Injectable({
	providedIn: 'root',
})
export class MapService extends BaseService implements ITableListService {
	ApiBaseURL = environment.ApiBaseURL;
	apiLoadedloadGoogleApi: boolean = false;
	apiLoadedloadGoogleApiWithAutoComplete: boolean = false;
	constructor(
		private http: HttpClient,
		private translate: TranslateService,
		private toastr: ToastrService
	) {
		super();
	}

	loadGoogleApi(): Observable<object> {
		if (!this.apiLoadedloadGoogleApi) {
			this.apiLoadedloadGoogleApi = true;
			return this.http.jsonp(
				'https://maps.googleapis.com/maps/api/js?key=' +
					environment.MapsJavascriptApi +
					'&libraries=geometry',
				'callback'
			) as Observable<object>;
		}

		return of(null);
	}

	loadGoogleApiWithAutoComplete(): Observable<boolean> {
		if (!this.apiLoadedloadGoogleApiWithAutoComplete) {
			this.apiLoadedloadGoogleApiWithAutoComplete = true;
			return this.http.jsonp(
				'https://maps.googleapis.com/maps/api/js?key=' +
					environment.MapsJavascriptApi +
					'&libraries=places',
				'callback'
			) as Observable<boolean>;
		}

		return of(true);
	}

	getLocation(): Promise<google.maps.LatLngLiteral> {
		if (navigator.geolocation) {
			return new Promise((resolve, reject) => {
				navigator.geolocation.getCurrentPosition((position) => {
					resolve({
						lat: position.coords.latitude,
						lng: position.coords.longitude,
					});
				});
			});
		} else {
			this.toastr.warning(
				this.translate.instant('NoSupportForGeolocation'),
				this.translate.instant('NoSupportForGeolocation'),
				{ positionClass: 'toast-bottom-right' }
			);
		}
		return null;
	}

	readCount(page: DatatableFilters): Observable<number> {
		throw new Error('Method not implemented.');
	}
	readAll(page: DatatableFilters): Observable<any> {
		throw new Error('Method not implemented.');
	}
	read(id: number): Observable<any> {
		throw new Error('Method not implemented.');
	}
}
