import {
	Component,
	OnInit,
	HostListener,
	ViewChildren,
	QueryList,
} from '@angular/core';
import { Router, NavigationEnd } from '@angular/router';
import { PerfectScrollbarDirective } from 'ngx-perfect-scrollbar';

import { filter } from 'rxjs/operators';
import { IMenuItem } from '@app/01.global/interfaces/helpers/iIMenuItem';
import { NavigationService } from '@app/01.global/services/navigation.service';
import { SignalrService } from '@app/01.global/services/signalr.service';
import { ToastrService } from 'ngx-toastr';
import { TranslateService } from '@ngx-translate/core';
import { Utils } from '@app/01.global/helpers/utils';
import { AuthenticationService } from '@app/01.global/services/authentication.service';
import { AttendanceStatusService } from '@app/01.global/status-services/attendance-status.service';

@Component({
	selector: 'app-sidebar',
	templateUrl: './sidebar.component.html',
	styleUrls: ['./sidebar.component.scss'],
})
export class SidebarComponent implements OnInit {
	selectedItem: IMenuItem;
	clickedItem: IMenuItem;
	nav: IMenuItem[];
	@ViewChildren(PerfectScrollbarDirective)
	psContainers: QueryList<PerfectScrollbarDirective>;
	psContainerSecSidebar: PerfectScrollbarDirective;
	isGuest: boolean;
	isDoctorOrTherapist: boolean;

	constructor(
		public router: Router,
		public navService: NavigationService,
		private translateService: TranslateService,
		private toastrService: ToastrService,
		private signalRService: SignalrService,
		private authenticationService: AuthenticationService,
		private attendanceStatusService: AttendanceStatusService
	) {
		setTimeout(() => {
			this.psContainerSecSidebar = this.psContainers.toArray()[1];
		});
	}

	ngOnInit() {
		this.updateSidebar();
		this.router.events
			.pipe(filter((event) => event instanceof NavigationEnd))
			.subscribe((routeChange) => {
				this.closeChildNav();
				if (Utils.isMobile()) {
					this.navService.sidebarState.sidenavOpen = false;
				}
			});

		this.navService.publishNavigationChange();
		this.navService.menuItems$.subscribe((items) => {
			this.nav = items;
			this.setActiveFlag();
		});

		this.isGuest = this.authenticationService.isGuest;
		this.isDoctorOrTherapist =
			this.authenticationService.isDoctor ||
			this.authenticationService.isTherapist;

		this.attendanceStatusService.updateSidebarMainLayout();
	}

	selectItem(item) {
		if (item.active && this.clickedItem == item) {
			this.clickedItem = null;
			return this.closeChildNav();
		}
		this.clickedItem = item;
		this.navService.sidebarState.childnavOpen = true;
		this.navService.selectedItem = item;
		this.setActiveMainItem(item);

		// Scroll to top secondary sidebar
		setTimeout(() => {
			this.psContainerSecSidebar.update();
			this.psContainerSecSidebar.scrollToTop(0, 400);
		});
	}
	closeChildNav() {
		this.navService.sidebarState.childnavOpen = false;
		this.setActiveFlag();
	}

	onClickChangeActiveFlag(item) {
		this.setActiveMainItem(item);
	}
	setActiveMainItem(item) {
		this.nav.forEach((i) => {
			i.active = false;
		});
		item.active = true;
	}

	setActiveFlag() {
		if (window && window.location) {
			const activeRoute =
				window.location.hash || window.location.pathname;
			this.nav.forEach((item) => {
				item.active = false;
				if (activeRoute.indexOf(item.state) !== -1) {
					this.navService.selectedItem = item;
					item.active = true;
				}
				if (item.sub) {
					item.sub.forEach((subItem) => {
						subItem.active = false;
						if (activeRoute.indexOf(subItem.state) !== -1) {
							this.navService.selectedItem = item;
							item.active = true;
						}
						if (subItem.sub) {
							subItem.sub.forEach((subChildItem) => {
								if (
									activeRoute.indexOf(subChildItem.state) !==
									-1
								) {
									this.navService.selectedItem = item;
									item.active = true;
									subItem.active = true;
								}
							});
						}
					});
				}
			});
		}
	}

	updateSidebar() {
		if (Utils.isMobile()) {
			this.navService.sidebarState.sidenavOpen = false;
			this.navService.sidebarState.childnavOpen = false;
		} else {
			this.navService.sidebarState.sidenavOpen = true;
		}
	}

	@HostListener('window:resize', ['$event'])
	onResize(event) {
		this.updateSidebar();
	}
}
