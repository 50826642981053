import { Component, ElementRef, Input, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { RouteList } from '@app/01.global/helpers/route-enum';
import { IReview } from '@app/01.global/interfaces/IReview';
import { IUser } from '@app/01.global/interfaces/IUser';
import { AuthenticationService } from '@app/01.global/services/authentication.service';
import { ReviewsService } from '@app/01.global/services/reviews.service';
import { ReviewStatusService } from '@app/01.global/status-services/review-status.service';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
	selector: 'app-booking-employees-reviews',
	templateUrl: './booking-employees-reviews.component.html',
	styleUrls: ['./booking-employees-reviews.component.scss'],
})
export class BookingEmployeesReviewsComponent implements OnInit {
	currentUser: IUser;

	private _professionalId: number;
	@Input() get professionalId(): number {
		return this._professionalId;
	}
	set professionalId(value: number) {
		if (this._professionalId > 0) {
			this.statusService.readReviews(value);
		}
		this._professionalId = value;
	}

	@ViewChild('editModal ') editModal: ElementRef;
	@ViewChild('deleteModal ') deleteModal: ElementRef;

	constructor(
		private service: ReviewsService,
		public statusService: ReviewStatusService,
		private authService: AuthenticationService,
		private modalService: NgbModal,
		private router: Router,
		private route: ActivatedRoute
	) {
		this.currentUser = this.authService.userValue;
	}

	ngOnInit(): void {
		this.statusService.readReviews(this._professionalId);
	}

	onScroll(): void {
		this.statusService.readMoreReviews();
	}

	deleteReview(id: number) {
		this.modalService
			.open(this.deleteModal, {
				size: 'sm',
			})
			.result.then(
				(result) => {
					this.statusService.deleteReview(id);
				},
				(reason) => {}
			)
			.finally(() => {});
	}

	editReview(review: IReview) {
		this.router.navigate([RouteList._routeCrudEnum.Edit, review.id], {
			relativeTo: this.route,
		});

		this.modalService
			.open(this.editModal, {
				size: 'xl',
			})
			.result.then(
				(result) => {},
				(reason) => {}
			)
			.finally(() => {});
	}
}
