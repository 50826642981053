import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { DatatableFilters } from '@app/01.global/models/DatatableFilters';
import { IApiResponse } from '../interfaces/helpers/iResponseWrapper';
import { environment } from '@environments/environment';
import '@app/01.global/helpers/observable-extensions';
import { Observable } from 'rxjs';
import { ITableListService } from '@app/table-list/services/iservice';
import { BaseService } from './base.service';
import { IUser } from '../interfaces/IUser';

@Injectable({
	providedIn: 'root',
})
export class SearchService extends BaseService implements ITableListService {
	apiBaseUrl = environment.ApiBaseURL;
	public searchOpen: boolean;

	constructor(private http: HttpClient) {
		super();
	}
	read(id: number): Observable<any> {
		throw new Error('Method not implemented.');
	}

	readCount(page: DatatableFilters): Observable<number> {
		let params = this.paginationToHttpParams(page);
		return this.http
			.get<IApiResponse>(
				`${this.apiBaseUrl}/dashboard/countSearchResults`,
				{
					params,
				}
			)
			.ToApiResult();
	}

	readAll(page: DatatableFilters): Observable<IUser[]> {
		let params = this.paginationToHttpParams(page);

		return this.http
			.get<IApiResponse>(
				`${this.apiBaseUrl}/dashboard/readSearchResults`,
				{
					params,
				}
			)
			.ToApiResult();
	}
	delete(id: number) {
		throw new Error('Method not implemented.');
	}
}
