import { Component, Input, OnInit } from '@angular/core';
import { UntypedFormGroup, UntypedFormControl, Validators, UntypedFormBuilder } from '@angular/forms';
import { ControlTypeEnum } from '@app/01.global/helpers/control-enum';
import { QuestionType } from '@app/01.global/helpers/question-type';
import { ITriageFormUser } from '@app/01.global/interfaces/iTriageFormUser';
import { AttendanceStatusService } from '@app/01.global/status-services/attendance-status.service';

@Component({
	selector: 'app-attendance-triage-answer',
	templateUrl: './attendance-triage-answer.component.html',
	styleUrls: ['./attendance-triage-answer.component.scss']
})
export class AttendanceTriageAnswerComponent implements OnInit {

	triageFormUser: ITriageFormUser;
	QuestionType = QuestionType;
	ControlTypeEnum = ControlTypeEnum;
	form: UntypedFormGroup;

	@Input() channelId: number

	constructor(private statusService: AttendanceStatusService,
		private formBuilder: UntypedFormBuilder) { }

	ngOnInit(): void {
		var forms = this.statusService.getChannelTriageAnswer(this.channelId);
		this.triageFormUser = forms?.length > 0 ? forms[0] : null;
		this.buildForm();
	}

	private buildForm() {
		this.form = this.formBuilder.group({});

		this.triageFormUser.triageFormQuestions.forEach((element) => {
			switch (element.type) {
				case QuestionType.singleChoice:
					this.form.addControl(
						'radio-' + element.id.toString(),
						new UntypedFormControl(
							this.triageFormUser.userAnswers?.length > 0
								? this.triageFormUser.userAnswers
									.find((a) => a.questionId == element.id)
									?.choiseAnswerId.toString()
								: '0'
						)
					);
					break;

				case QuestionType.multipleChoice:
					var fg = new UntypedFormGroup({});
					element.availableAnswers.forEach(
						(availableAnswer, index) => {
							fg.addControl(
								availableAnswer.id,
								new UntypedFormControl(
									this.triageFormUser.userAnswers?.some(
										(a) =>
											a.choiseAnswerId ==
											availableAnswer.id
									)
										? true
										: false
								)
							);
						}
					);
					this.form.addControl(element.id.toString(), fg);
					break;

				case QuestionType.textField:
					this.form.addControl(
						element.id.toString(),
						new UntypedFormControl(
							this.triageFormUser.userAnswers.find(
								(a) => a.questionId == element.id
							)
								? this.triageFormUser.userAnswers.find(
									(a) => a.questionId == element.id
								)?.openTextAnswer
								: '',
							Validators.required
						)
					);
					break;

				default:
					this.form.addControl(
						element.id.toString(),
						new UntypedFormControl(
							this.triageFormUser.userAnswers.find(
								(a) => a.questionId == element.id
							)
								? this.triageFormUser.userAnswers.find(
									(a) => a.questionId == element.id
								)?.openTextAnswer
								: '',
							Validators.required
						)
					);
					break;
			}
		});
	}

}
