import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { IApiResponse } from '../interfaces/helpers/iResponseWrapper';
import { ISelectListItem } from '@app/01.global/interfaces/helpers/iSelectListItem';
import { Observable, of } from 'rxjs';
import { environment } from 'src/environments/environment';
import '@app/01.global/helpers/observable-extensions';
import { ISpecialty } from '../interfaces/ISpecialty';
import { IConsultationType } from '../interfaces/iConsultation-type';

@Injectable({ providedIn: 'root' })
export class DropdownService {
	apiBaseURL = environment.ApiBaseURL;

	constructor(private http: HttpClient) { }

	readAgreements(providerId: number): Observable<ISelectListItem[]> {
		return this.http
			.get<IApiResponse>(
				`${this.apiBaseURL}/dropdown/agreements/${providerId}/`
			)
			.ToApiResult();
	}

	readDoctors(): Observable<ISelectListItem[]> {
		return this.http
			.get<IApiResponse>(`${this.apiBaseURL}/dropdown/doctors`)
			.ToApiResult();
	}

	readTheRapists(): Observable<ISelectListItem[]> {
		return this.http
			.get<IApiResponse>(`${this.apiBaseURL}/dropdown/therapists`)
			.ToApiResult();
	}

	readAppointmentsDuration(): Observable<ISelectListItem[]> {
		return this.http
			.get<IApiResponse>(
				`${this.apiBaseURL}/dropdown/appointmentsDuration`
			)
			.ToApiResult();
	}

	readTitles(): Observable<ISelectListItem[]> {
		return this.http
			.get<IApiResponse>(`${this.apiBaseURL}/dropdown/titles`)
			.ToApiResult();
	}

	readMedicalOffices(): Observable<ISelectListItem[]> {
		return this.http
			.get<IApiResponse>(`${this.apiBaseURL}/dropdown/medicaloffices`)
			.ToApiResult();
	}

	readLanguages(): Observable<ISelectListItem[]> {
		return this.http
			.get<IApiResponse>(`${this.apiBaseURL}/dropdown/languages`)
			.ToApiResult();
	}

	readSpokenLanguages(): Observable<ISelectListItem[]> {
		return this.http
			.get<IApiResponse>(`${this.apiBaseURL}/dropdown/spokenlanguages`)
			.ToApiResult();
	}

	readPosition(): Observable<ISelectListItem[]> {
		return this.http
			.get<IApiResponse>(`${this.apiBaseURL}/dropdown/positions`)
			.ToApiResult();
	}

	readGenders(): Observable<ISelectListItem[]> {
		return this.http
			.get<IApiResponse>(`${this.apiBaseURL}/dropdown/genders`)
			.ToApiResult();
	}

	readConsultationTypes(
		providerAccountId: number
	): Observable<IConsultationType[]> {
		return this.http
			.get<IApiResponse>(`${this.apiBaseURL}/dropdown/consultationTypes/${providerAccountId}`)
			.ToApiResult();
	}

	readSpecialties(providerAccountId: number): Observable<ISpecialty[]> {
		return this.http
			.get<IApiResponse>(
				`${this.apiBaseURL}/dropdown/specialties/` + providerAccountId
			)
			.ToApiResult();
	}

	readAppointmentStatusMachine(): Observable<ISelectListItem[]> {
		return this.http
			.get<IApiResponse>(`${this.apiBaseURL}/dropdown/appointmentStatus`)
			.ToApiResult();
	}

	readPatients(): Observable<ISelectListItem[]> {
		return this.http
			.get<IApiResponse>(`${this.apiBaseURL}/dropdown/patients`)
			.ToApiResult();
	}

	readProviders(): Observable<ISelectListItem[]> {
		return this.http
			.get<IApiResponse>(`${this.apiBaseURL}/dropdown/providers`)
			.ToApiResult();
	}

	readBranches(
		providerAccountId: number,
		doctorId: number
	): Observable<ISelectListItem[]> {
		return this.http
			.get<IApiResponse>(
				`${this.apiBaseURL}/dropdown/branches/${providerAccountId}/${doctorId}`
			)
			.ToApiResult();
	}

	readCountryCodes(): Observable<ISelectListItem[]> {
		return this.http
			.get<IApiResponse>(`${this.apiBaseURL}/dropdown/countryCodes`)
			.ToApiResult();
	}

	readSpecialtiesStandard(
		providerAccountId: number
	): Observable<ISelectListItem[]> {
		return this.http
			.get<IApiResponse>(
				`${this.apiBaseURL}/dropdown/specialtiesStandard/${providerAccountId}`
			)
			.ToApiResult();
	}

	readConsultationTypesStandard(
		providerAccountId: number
	): Observable<IConsultationType[]> {
		return this.http
			.get<IApiResponse>(
				`${this.apiBaseURL}/dropdown/consultationtypesStandard/${providerAccountId}`
			)
			.ToApiResult();
	}

	readMedicalProcedures(): Observable<ISelectListItem[]> {
		return this.http
			.get<IApiResponse>(`${this.apiBaseURL}/dropdown/medicalProcedures`)
			.ToApiResult();
	}

	readWeekDays(): Observable<ISelectListItem[]> {
		return this.http
			.get<IApiResponse>(`${this.apiBaseURL}/dropdown/weekDays`)
			.ToApiResult();
	}

	ReadDayPeriods(): Observable<ISelectListItem[]> {
		return this.http
			.get<IApiResponse>(`${this.apiBaseURL}/dropdown/dayPeriods`)
			.ToApiResult();
	}
}
