import { Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { RoleEnum } from '@app/01.global/helpers/role-enum';
import { RouteList } from '@app/01.global/helpers/route-enum';
import { IUser } from '@app/01.global/interfaces/IUser';
import { AuthenticationService } from '@app/01.global/services/authentication.service';
import { BookingStatusService } from '@app/01.global/status-services/booking-status.service';
import { Subscription, take } from 'rxjs';

@Component({
	selector: 'app-booking-dashboard',
	templateUrl: './booking-dashboard.component.html',
	styleUrls: ['./booking-dashboard.component.scss'],
})
export class BookingDashboardComponent implements OnInit, OnDestroy {
	RouteList = RouteList;
	active: number = 0;
	hoverActive: number = 0;
	subs: Subscription[] = [];
	isGuest: boolean;
	isEmployeeCantHandleAppointments: boolean;
	hasPatientSelected: boolean;
	bookingFromAttendance: boolean;

	constructor(
		private route: ActivatedRoute,
		private router: Router,
		private authenticationService: AuthenticationService,
		private service: BookingStatusService
	) { }

	ngOnInit(): void {
		this.route.parent.parent?.data.subscribe(d => this.bookingFromAttendance = !!d.bookingFromAttendance);
		this.isGuest = this.authenticationService.isGuest;
		this.isEmployeeCantHandleAppointments =
			this.authenticationService.isEmployee &&
			!this.authenticationService.userValue.branch
				.employeesCanHandleDoctorsAppointments;

		this.subs.push(
			this.service.navigationObs.subscribe((index: number) => {
				this.active = index;
			})
		);

		this.subs.push(
			this.service.patientObs.subscribe((patient: IUser) => {
				this.hasPatientSelected = !!patient;
			})
		);
	}

	ngOnDestroy(): void {
		this.subs.forEach((s) => s?.unsubscribe());
		this.service.reset();
	}

	navigate(index: number) {
		if (index == this.active) {
			if (this.isGuest) {
				this.active = 0;
				this.router.navigate([RouteList.booking]);
			} else {
				this.active = 5;
				this.router.navigate([
					RouteList.booking,
					RouteList._route1Enum.BookingPatients,
				]);
			}
		} else {
			switch (index) {
				case 1:
					this.router.navigate([
						RouteList.booking,
						RouteList._route1Enum.BookingUrgency,
					]);
					break;
				case 2:
					this.router.navigate([
						RouteList.booking,
						RouteList._route1Enum.Appointment,
					]);
					break;
				case 3:
					this.router.navigate([
						RouteList.booking,
						RouteList._route1Enum.Branches,
						RouteList._routeCrudEnum.List,
					]);
					break;
				case 4:
					this.router.navigate([
						RouteList.booking,
						RouteList._route1Enum.Employees,
						RouteList._routeCrudEnum.List,
					]);
					break;
				case 5:
					this.router.navigate([
						RouteList.booking,
						RouteList._route1Enum.BookingPatients,
					]);
					break;
				case 6:
					this.router.navigate([
						RouteList.booking,
						RouteList._route1Enum.BookingRequests,
					]);
					break;

				default:
					this.router.navigate([RouteList.booking]);
					break;
			}

			this.active = index;
		}

		this.service.setNavigation(this.active);
	}

	hover(index: number, mouseenter: boolean) {
		if (mouseenter) {
			this.hoverActive = index;
		} else {
			this.hoverActive = 0;
		}
	}
}
