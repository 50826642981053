import { Component, Input, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { RouteList } from '@app/01.global/helpers/route-enum';
import { TableListSettings } from '@app/table-list/models/table-list';
import {
	TableListColumn,
	TableListColumnMode,
} from '@app/table-list/models/table-list-column';
import { TableListRow } from '@app/table-list/models/table-list-row';
import { TableListStatusService } from '@app/table-list/services/table-list-status.service';
import { Subscription } from 'rxjs';

@Component({
	selector: 'table-list-data',
	templateUrl: './table-list-data.component.html',
	styleUrls: ['./table-list-data.component.scss'],
})
export class TableListTableComponent implements OnInit, OnDestroy {
	constructor(
		public statusService: TableListStatusService,
		private router: Router,
		private activatedRoute: ActivatedRoute
	) {}

	@Input('tableIdentifier') tableIdentifier: string;
	private subs: Subscription[] = [];
	tableSettings: TableListSettings;
	TableListColumnMode = TableListColumnMode;
	@ViewChild('datatableId') table: any;

	ngOnInit(): void {
		this.subs.push(
			this.statusService
				.getSettingsObservable(this.tableIdentifier)
				.subscribe((settings) => (this.tableSettings = settings))
		);
	}
	ngOnDestroy(): void {
		this.subs.forEach((s) => s?.unsubscribe());
	}

	readPage(pageInfo: {
		count: number;
		pageSize: number;
		limit: number;
		offset: number;
	}) {
		this.statusService.readPage(this.tableIdentifier, pageInfo.offset);
	}

	onSort(event: { sorts; column; prevValue; newValue }) {
		// event was triggered, start sort sequence
		console.info('Sort Event', event);
	}

	getCellClass({ row, column, value }): any {
		var col = row.columns.find((c) => c.name == column.name);

		return {
			'my-auto': true,
			'p-0 m-0 ml-2 pr-2': col.displayMode == TableListColumnMode.thumbnail,
		};
	}

	getColumnWith(column: TableListColumn): number {
		if (column.displayMode == TableListColumnMode.thumbnail) {
			return 50;
		}
		return 0;
	}

	getButtonsColumnWith(rows: TableListRow[]): number {
		rows = rows.sort(
			(a, b) => b.actionButtons.length - a.actionButtons.length
		);

		var width: number = 0;
		rows[0].actionButtons.forEach((element) => {
			if (element.showText) {
				//Buton with text increase 6.5 px for each letter in text field
				width = width + element.text.length * 6.5;
				//Padding for text button
				width = width + 24;
			}
			if (element.icon) {
				//Button with icon has 34px width
				width = width + 34;
			}
			//Margin right for each button
			width = width + 8;
			//Column padding 1.2rem left and 0.25rem on right -> 1.45rem x 16px
			width = width + 23.2;
		});

		return width;
	}
}
