export enum DocTypesEnum {
	ProfilePhoto = 1,
	ChatAttachment = 2,
	Prescription = 3,
	UploadedFiles = 4,
	MedicalOfficeLogo = 5,
	AgreementLogo = 6,
	AgreementCardImage = 7,
	ChannelLogo = 8
}
