import {
	Component,
	ElementRef,
	AfterViewInit,
	ViewChild,
	Input,
	OnDestroy,
} from '@angular/core';

const publish = () => { };

@Component({
	selector: 'app-publisher',
	templateUrl: './publisher.component.html',
	styleUrls: ['./publisher.component.scss'],
})
export class PublisherComponent implements AfterViewInit, OnDestroy {
	@ViewChild('publisherDiv') publisherDiv: ElementRef;
	@Input() session: OT.Session;
	publisher: OT.Publisher;
	publishing: Boolean;

	constructor() {
		this.publishing = false;
	}

	ngAfterViewInit() {
		this.publisher = OT.initPublisher(this.publisherDiv.nativeElement, {
			insertMode: 'append',
		});

		if (this.session) {
			if (this.session['isConnected']()) {
				this.publish();
			}
			this.session.on('sessionConnected', () => this.publish());
		}
	}

	publish() {
		this.session.publish(this.publisher, (err) => {
			if (err) {
				console.error(err.message);
			} else {
				this.publishing = true;
			}
		});
	}

	ngOnDestroy(): void {
		if (this.publishing) {
			this.session.unpublish(this.publisher);
		}
	}
}
