import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { DatatableFilters } from '@app/01.global/models/DatatableFilters';
import { IApiResponse } from '../interfaces/helpers/iResponseWrapper';
import { INotification } from '@app/01.global/interfaces/iNotification';
import { environment } from '@environments/environment';
import { map } from 'rxjs/operators';
import '@app/01.global/helpers/observable-extensions';
import { of } from 'rxjs';
import { Observable } from 'rxjs';
import { ITableListService } from '@app/table-list/services/iservice';
import { BaseService } from './base.service';
import { TableListFilters } from '@app/table-list/models/table-list-filters';

@Injectable({
	providedIn: 'root',
})
export class NotificationsService
	extends BaseService
	implements ITableListService
{
	apiBaseUrl = environment.ApiBaseURL;

	constructor(private http: HttpClient) {
		super();
	}

	// create(notification: INotification) {
	// 	return this.http
	// 		.post<IApiResponse>(
	// 			`${this.apiBaseUrl}/notifications/`,
	// 			notification
	// 		)
	// 		.ToApiResult();
	// }

	// markAllAsRead() {
	// 	return this.http
	// 		.put<IApiResponse>(
	// 			`${this.apiBaseUrl}/notifications/markAllAsRead`,
	// 			{}
	// 		)
	// 		.ToApiResult();
	// }

	// markChatMessagesFromAsRead(activeContactId: number) {
	// 	return this.http
	// 		.put<IApiResponse>(
	// 			`${this.apiBaseUrl}/notifications/markChatMessagesFromAsRead/${activeContactId}`,
	// 			{}
	// 		)
	// 		.ToApiResult();
	// }

	// update(id: number, model: INotification) {
	// 	return this.http
	// 		.put<IApiResponse>(`${this.apiBaseUrl}/notifications/${id}`, model)
	// 		.ToApiResult();
	// }

	// delete(id: number) {
	// 	return this.http
	// 		.delete<IApiResponse>(`${this.apiBaseUrl}/notifications/${id}`)
	// 		.ToApiResult();
	// }

	// read(id: number) {
	// 	return this.http
	// 		.get<IApiResponse>(`${this.apiBaseUrl}/notifications/${id}`)
	// 		.ToApiResult();
	// }

	// count() {
	// 	return this.http
	// 		.get<IApiResponse>(`${this.apiBaseUrl}/notifications/count`)
	// 		.ToApiResult();
	// }
	readCount(page: TableListFilters): Observable<number> {
		throw new Error('Method not implemented.');
	}
	read(id: number): Observable<any> {
		throw new Error('Method not implemented.');
	}
	readAll(page: DatatableFilters): Observable<INotification[]> {
		let params = this.paginationToHttpParams(page);
		return this.http
			.get<IApiResponse>(`${this.apiBaseUrl}/notifications`, {
				params,
			})
			.ToApiResult();
	}

	loginNotifications(): Observable<void> {
		return this.http
			.get<IApiResponse>(`${this.apiBaseUrl}/notifications/login`)
			.ToApiResult();
	}
}
