import { Component, Input, OnInit } from '@angular/core';
import { IAddress } from '@app/01.global/interfaces/iAddress';

@Component({
	selector: 'app-display-template-address',
	templateUrl: './address.component.html',
	styleUrls: ['./address.component.scss'],
})
export class AddressComponent implements OnInit {
	@Input('address') address: IAddress = {
		city: '',
		country: '',
		district: '',
		id: 0,
		line1: '',
		postalCode: '',
	};
	constructor() {}

	ngOnInit(): void {}
}
