import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { IFAQ } from '@app/01.global/interfaces/iFaq';
import { DatatableFilters } from '@app/01.global/models/DatatableFilters';
import { IApiResponse } from '../interfaces/helpers/iResponseWrapper';
import { tap } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import '@app/01.global/helpers/observable-extensions';
import { ITableListService } from '../../table-list/services/iservice';
import { Observable } from 'rxjs';
import { ToastrService } from 'ngx-toastr';
import { TranslateService } from '@ngx-translate/core';
import { BaseService } from './base.service';

@Injectable({
	providedIn: 'root',
})
export class FaqService extends BaseService implements ITableListService {
	ApiBaseURL = environment.ApiBaseURL;

	constructor(
		private http: HttpClient,
		private toastr: ToastrService,
		private translate: TranslateService
	) {
		super();
	}

	create(model: IFAQ) {
		let transformedProvider = {
			id : model.provider[0].value,
			name: model.provider[0].text,
		};
		model.providerAccountId = model.provider[0].value;
		let transformedModel = { ...model, provider: transformedProvider };
		return this.http
			.post<IApiResponse>(`${this.ApiBaseURL}/FAQ`, transformedModel)
			.ToApiResult();
	}

	readAll(page: DatatableFilters): Observable<IFAQ[]> {
		let params = this.paginationToHttpParams(page);
		return this.http
			.get<IApiResponse>(`${this.ApiBaseURL}/FAQ`, {
				params,
			})
			.ToApiResult();
	}
	deploy(): Observable<any> {
		this.toastr.info(
			this.translate.instant('DeploymentInProgress'),
			this.translate.instant('DeploymentCanTakeSeveralMinutes'),
			{ positionClass: 'toast-bottom-right' }
		);
		return this.http
			.post<IApiResponse>(`${this.ApiBaseURL}/FAQ/deploy`, {})
			.ToApiResult()
			.pipe(
				tap(() => {
					this.toastr.success(
						this.translate.instant('DeploymentSuccess'),
						this.translate.instant('DeploymentSuccessfully'),
						{ positionClass: 'toast-top-right' }
					);
				})
			);
	}

	read(id: number): Observable<IFAQ> {
		return this.http
			.get<IApiResponse>(`${this.ApiBaseURL}/FAQ/${id}`)
			.ToApiResult();
	}

	update(id: number, model: IFAQ) {
		let transformedProvider = {
			id : model.provider[0].value,
			name: model.provider[0].text,
			provider: model.provider,
		};
		model.providerAccountId = model.provider[0].value;
		let transformedModel = { ...model, provider: transformedProvider };
		return this.http
			.put<IApiResponse>(`${this.ApiBaseURL}/FAQ/${id}`, transformedModel)
			.ToApiResult();
	}

	delete(id: number) {
		return this.http
			.delete<IApiResponse>(`${this.ApiBaseURL}/FAQ/${id}`)
			.ToApiResult();
	}

	readCount(page: DatatableFilters): Observable<number> {
		let params = this.paginationToHttpParams(page);
		return this.http
			.get<IApiResponse>(`${this.ApiBaseURL}/FAQ/count`, {
				params,
			})
			.ToApiResult();
	}
}
