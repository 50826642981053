<div class="d-flex flex-row">
	<div class="instructions-container">
		<div class="card mb-4">
			<div class="card-body">
				<h5 class="card-title text-primary">{{'WelcomeDoctor' | translate:{'0':
					authService.userValue.displayName} }}</h5>
				<p class="card-text">{{'InviteToWaitingRoom' | translate}}</p>
				<div class="input-group">
					<input type="text" #link class="form-control" [formControl]="copyLinkControl" [readOnly]="true"
						(click)="link.select()">
					<button class="btn btn-secondary" type="button" (click)="copyText()">{{'Copy' | translate}}</button>
					<div ngbDropdown class="d-inline-block">
						<button class="btn btn-outline-secondary" id="dropdownBasic1" ngbDropdownToggle>{{'Share' |
							translate}}</button>
						<div ngbDropdownMenu aria-labelledby="dropdownBasic1">
							<button ngbDropdownItem (click)="openSMSForm()">SMS</button>
							<button ngbDropdownItem (click)="openEmailForm()">{{'Email' |translate}}</button>
							<button ngbDropdownItem (click)="navigateToGoogleCalendar()">{{'Calendar' |translate}}</button>
						</div>
					</div>
				</div>
			</div>
		</div>
		<div class="card mb-4">
			<div class="card-body">
				<h2 class="">{{'TipsForAGreatVideoCall' | translate}}</h2>
				<ul class="list-group list-group-flush">
					<li class="list-group-item text-primary text-16">
						<i class="i-Information text-info mr-4 text-20"></i>
						<i class="i-Wifi text-48 mr-3"></i>{{'UseStrongInternetSignal' | translate}}.
					</li>
					<li class="list-group-item text-primary text-16">
						<i class="i-Information text-info mr-4 text-20"></i>
						<i class="i-Close-Window text-48 mr-3"></i>{{'CloseUnusedPrograms' |
						translate}}.
					</li>
					<li class="list-group-item text-primary text-16">
						<i class="i-Information text-info mr-4 text-20"></i>
						<i class="i-Internet text-48 mr-3"></i>{{'UpdateYourWebBrowserToLatestVersion' |
						translate}}.
					</li>
				</ul>
			</div>
		</div>
		<div class="card mb-4">
			<div class="card-body">
				<h5 class="card-title text-primary mb-0">{{'TechnicalIssues' | translate}}&nbsp;<a
						href="mailto:support@kooli.io">{{'ClickingHere' | translate}}.</a></h5>
			</div>
		</div>
	</div>
	<div class="flex-grow-1"></div>
	<div class="devices-preview-container">
		<webcam *ngIf="!cameraNotFound && !cameraWasDenied" [width]="320" (initError)="handleWebcamInitError($event)">
		</webcam>
		<!-- https://github.com/basst314/ngx-webcam -->
		<div *ngIf="cameraNotFound || cameraWasDenied"
			class="devices-denied-warning-container p-3 text-left shadow d-flex flex-column">
			<div class="text-white text-16 mb-4">{{'TurnOnCameraAndMicTutorial' | translate}}</div>
			<div class="d-flex flex-row justify-content-around mt-1">
				<div class="webcam-icon-container">
					<img class="webcam-icon" src="./assets/images/webcam.png" />
					<img class="webcam-blocked-icon" src="./assets/images/block.png" />
				</div>
				<div class="webcam-icon-container">
					<img class="microphone-icon" src="./assets/images/microphone.png" />
					<img class="webcam-blocked-icon" src="./assets/images/block.png" />
				</div>
			</div>
		</div>
	</div>
</div>

<ng-template #smsModal let-modal>
	<app-modal (onClose)="modal.dismiss($event)" (onConfirmClose)="modal.close($event)" [showDefaultFooter]="false">
		<div modal-header>{{ 'AttendanceInviteBySMS' | translate }}</div>
		<div modal-body>
			<div class="row mb-3">
				<div class="col">
					<app-form-control [submitted]="submitted" [type]="ControlTypeEnum.Text" name="{{ 'PhoneNumber' | translate }}"
						[placeholder]="'999999999'" [control]="smsControl"></app-form-control>
				</div>
			</div>
			<div class="row">
				<div class="col">
					<button type="buttonm" class="btn btn-primary" [disabled]="smsLoading"
						(click)="submitSMSForm()">
						{{'Submit' | translate}}
					</button>
				</div>
			</div>
		</div>
	</app-modal>
</ng-template>

<ng-template #emailModal let-modal>
	<app-modal (onClose)="modal.dismiss($event)" (onConfirmClose)="modal.close($event)" [showDefaultFooter]="false">
		<div modal-header>{{ 'AttendenceInviteByEmail' | translate }}</div>
		<div modal-body>
			<div class="row mb-3">
				<div class="col">
					<app-form-control [submitted]="submitted" [type]="ControlTypeEnum.Text" name="email"
						[placeholder]="'john.doe@example.com'" [control]="emailControl"></app-form-control>
				</div>
			</div>
			<div class="row">
				<div class="col">
					<button type="buttonm" class="btn btn-primary" [disabled]="emailLoading"
						(click)="submitEmailForm()">
						{{'Submit' | translate}}
					</button>
				</div>
			</div>
		</div>
	</app-modal>
</ng-template>