import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import {
	UntypedFormBuilder,
	UntypedFormGroup,
	Validators,
} from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { ControlTypeEnum } from '@app/01.global/helpers/control-enum';
import { RouteList } from '@app/01.global/helpers/route-enum';
import { DatatableFilters } from '@app/01.global/models/DatatableFilters';
import { EditableINote, INote } from '@app/01.global/interfaces/iNote';
import { IUser } from '@app/01.global/interfaces/IUser';
import { IChannel } from '@app/01.global/interfaces/iChannel';
import { AuthenticationService } from '@app/01.global/services/authentication.service';
import { ChannelService } from '@app/01.global/services/channel.service';
import { PerfectScrollbarDirective } from 'ngx-perfect-scrollbar';
import { finalize, forkJoin, Observable, share, Subscription } from 'rxjs';
import { ConsultationStatusService } from '@app/01.global/status-services/consultation-status.service';

@Component({
	selector: 'app-notes',
	templateUrl: './notes.component.html',
	styleUrls: ['./notes.component.scss'],
})
export class NotesComponent implements OnInit, OnDestroy {
	activeChannel: IChannel;
	loading: boolean = false;
	saving: boolean = false;
	loadingUp: boolean = false;
	routeList = RouteList;
	currentUser: IUser;
	showMeetingNotes: boolean = false;
	meetingNotes: EditableINote[];
	subs: Subscription[] = [];
	throttle = 0;
	distance = 2;
	filters: DatatableFilters = {
		totalItems: 0,
		pageSize: 25,
		currentPage: 0,
		totalPages: 0,
	};
	request$: Observable<INote[]>;
	currentDate = new Date();
	userJoinDate: Date;
	activeNoteId: number;
	hasPermissionsToMeetingNotes: boolean = false;
	ControlTypeEnum = ControlTypeEnum;
	form: UntypedFormGroup;

	@ViewChild(PerfectScrollbarDirective, { static: false })
	perfectScrollbarDirectiveRef?: PerfectScrollbarDirective;

	constructor(
		private route: ActivatedRoute,
		private router: Router,
		private authenticationService: AuthenticationService,
		private service: ChannelService,
		private fb: UntypedFormBuilder,
		private statusService: ConsultationStatusService
	) {}

	ngOnInit(): void {
		this.buildForm();

		this.subs.push(
			this.authenticationService.user.subscribe((u) => {
				this.currentUser = u;
				this.userJoinDate = this.currentUser?.createdDate;
			})
		);

		this.subs.push(
			this.statusService.activeChannelObs.subscribe((response) => {
				var oldActiveChannel = this.activeChannel;
				this.activeChannel = response?.channel;
				this.hasPermissionsToMeetingNotes =
					response?.hasPermissionsToMeetingNotes ?? false;
				if (
					oldActiveChannel == null ||
					this.activeChannel == null ||
					oldActiveChannel.id != this.activeChannel.id
				) {
					this.filters.currentPage = 0;
				}
				this.filters.readFromWhoId = this.activeChannel?.id ?? 0;
			})
		);

		this.subs.push(
			this.statusService.settingsObs.subscribe((response) => {
				this.showMeetingNotes = !!response?.showMeetingNotes;
				if (this.showMeetingNotes) {
					this.readAll();
				}
			})
		);
	}

	ngOnDestroy(): void {
		this.subs.forEach((s) => s?.unsubscribe());
	}

	readAll() {
		this.loading = true;
		this.getNotes().subscribe({
			next: (response: INote[]) => {
				this.meetingNotes = response;
				this.loading = false;
			},
		});
	}

	buildForm() {
		this.form = this.fb.group({
			title: ['', Validators.required],
			description: ['', Validators.required],
		});
	}

	private getNotes(): Observable<INote[]> {
		if (this.request$) {
			return this.request$;
		} else {
			this.request$ = this.service.readMeetingNotes(this.filters).pipe(
				share(),
				finalize(() => (this.request$ = null))
			);
			return this.request$;
		}
	}

	onScroll(): void {
		this.loadingUp = true;
		++this.filters.currentPage;
		this.getNotes().subscribe((response: INote[]) => {
			this.loadingUp = false;
			this.meetingNotes = response.concat(this.meetingNotes);
		});
	}

	edit(id: number) {
		var note = this.meetingNotes.find((n) => n.id == id);
		if (note.form) {
			return;
		}
		note.form = this.fb.group({
			title: [note.title, Validators.required],
			description: [note.description, Validators.required],
		});
	}

	stopEditing(id: number) {
		this.meetingNotes.find((n) => n.id == id).form = null;
	}

	updateNote(id: number, form: UntypedFormGroup) {
		var note = this.meetingNotes.find((n) => n.id == id);

		if (note.form.invalid) {
			return;
		}
		note.saving = true;
		this.service
			.updateNote(note.id, note.form.value)
			.subscribe((response: INote) => {
				this.saving = false;
				this.readAll();
			});
	}

	createNote() {
		if (this.form.invalid) {
			return;
		}
		this.saving = true;
		var note = this.form.value;
		this.form.disable();
		this.service
			.createNote(this.activeChannel.id, note)
			.subscribe((response: INote) => {
				this.form.reset();
				this.form.enable();
				this.saving = false;
				this.readAll();
			});
	}

	deleteNote(id: number) {
		this.service.deleteNote(id).subscribe(() => {
			this.readAll();
		});
	}
}
