import { Component, OnInit } from '@angular/core';
import { UntypedFormControl } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { ControlTypeEnum } from '@app/01.global/helpers/control-enum';
import { DetailsHeroSectionType } from '@app/01.global/helpers/details-hero-section-type.enum';
import { PositionEnum } from '@app/01.global/helpers/position-enum';
import { RoleEnum } from '@app/01.global/helpers/role-enum';
import { RouteList } from '@app/01.global/helpers/route-enum';
import { IAgreement } from '@app/01.global/interfaces/iAgreement';
import { IAgreementGroup } from '@app/01.global/interfaces/iAgreementGroup';
import { IAppointment } from '@app/01.global/interfaces/IAppointment';
import { IMedicalOffice } from '@app/01.global/interfaces/iMedicalOffice';
import { ISpecialty } from '@app/01.global/interfaces/ISpecialty';
import { IUser } from '@app/01.global/interfaces/IUser';
import { AuthenticationService } from '@app/01.global/services/authentication.service';
import { BookingStatusService } from '@app/01.global/status-services/booking-status.service';
import { TranslateService } from '@ngx-translate/core';

@Component({
	selector: 'app-details-hero-section',
	templateUrl: './details-hero-section.component.html',
	styleUrls: ['./details-hero-section.component.scss'],
})
export class DetailsHeroSectionComponent implements OnInit {
	source: any;
	type: DetailsHeroSectionType;
	photoUrl: string;
	title: string;
	subTitlePhotoUrl: string;
	subTitle: string;
	showConnectionOptions: boolean = false;
	isAdmin: boolean = false;
	showBooking: boolean = false;
	showCardBody: boolean = false;
	position: PositionEnum;
	paymentsSuspendedControl: UntypedFormControl = new UntypedFormControl();
	PositionEnum = PositionEnum;
	DetailsHeroSectionType = DetailsHeroSectionType;
	ControlTypeEnum = ControlTypeEnum;
	RouteList = RouteList;

	constructor(
		private route: ActivatedRoute,
		private router: Router,
		private authService: AuthenticationService,
		private bookingStatusService: BookingStatusService,
		private translateService: TranslateService
	) { }

	ngOnInit() {
		this.route.data.subscribe((data) => {
			this.type = data.detailsHeroSectionType;
			this.source = data.entity;
			this.setViewInfo();
		});

		this.isAdmin = this.authService.isAdmin;
	}
	showAppointmentCalendar(): boolean {
		return this.source.employeeId.AcceptOnlineBooking && this.source.employeeId.VideoConsultation ? true : false;
	}

	setViewInfo() {
		switch (this.type) {
			case DetailsHeroSectionType.agreement:
				this.photoUrl = './assets/images/default-agreement.png';
				this.title = this.translateService.instant((this.source as IAgreementGroup).name);
				break;
			case DetailsHeroSectionType.employee:
				this.photoUrl =
					(this.source as IUser).photo?.url ??
					'./assets/images/default-user.png';
				this.title = (this.source as IUser).displayName;
				if (
					(this.source as IUser).roles.some(
						(r) => r == RoleEnum.Doctor
					)
				) {
					this.position = PositionEnum.Doctor;
				}
				if (
					(this.source as IUser).roles.some(
						(r) => r == RoleEnum.Therapist
					)
				) {
					this.position = PositionEnum.Therapist;
				}
				if (
					(this.source as IUser).roles.some(
						(r) => r == RoleEnum.Employee
					)
				) {
					this.position = PositionEnum.Employee;
				}
				switch (this.position) {
					case PositionEnum.Doctor:
					case PositionEnum.Therapist:
						this.subTitle = this.translateService.instant((this.source as IUser).specialty.name);
						this.subTitlePhotoUrl =
							(this.source as IUser).specialty.logo?.url ??
							'./assets/images/default-specialty.png';
						this.showConnectionOptions = true;
						this.showBooking =
							(this.source as IUser).employeeInfo
								.acceptOnlineBooking &&
							this.authService.isGuest;
						break;
					case PositionEnum.Employee:
						this.subTitle = (
							this.source as IUser
						).branch.commercialName;
						this.subTitlePhotoUrl =
							(this.source as IUser).branch.account.photo?.url ??
							'./assets/images/default-user.png';
						break;
					default:
						throw new Error('falta enviar position');
				}
				break;
			case DetailsHeroSectionType.patient:
				this.photoUrl =
					(this.source as IUser).photo?.url ??
					'./assets/images/default-user.png';
				this.title = (this.source as IUser).displayName;
				this.showCardBody = true;
				break;
			case DetailsHeroSectionType.provider:
				this.photoUrl =
					(this.source as IUser).photo?.url ??
					'./assets/images/default-user.png';
				this.title = (this.source as IUser).displayName;
				this.showCardBody = true;
				this.paymentsSuspendedControl.setValue(
					(this.source as IUser).providerInfo.paymentsSuspended
				);
				this.paymentsSuspendedControl.disable();
				break;
			case DetailsHeroSectionType.medicalOffice:
				this.photoUrl =
					(this.source as IMedicalOffice).branch.account.photo?.url ??
					'./assets/images/default-office.png';
				this.title = (
					this.source as IMedicalOffice
				).branch.commercialName;
				this.subTitle = (
					this.source as IMedicalOffice
				).doctor.displayName;
				this.subTitlePhotoUrl =
					(this.source as IMedicalOffice).doctor.photo?.url ??
					'./assets/images/default-user.png';
				this.showCardBody = true;
				this.paymentsSuspendedControl.setValue(
					(this.source as IMedicalOffice).branch.paymentsSuspended
				);
				this.paymentsSuspendedControl.disable();
				break;
			case DetailsHeroSectionType.specialty:
				this.photoUrl =
					(this.source as ISpecialty).logo?.url ??
					'./assets/images/default-specialty.png';
				this.title = this.translateService.instant(
					(this.source as ISpecialty).name
				);
				break;
			default:
				throw new Error('falta DetailsHeroSectionType na configuraçao');
		}
	}

	bookFromDoctorDetails() {
		this.bookingStatusService.bookFromDoctorDetails(this.source);
		this.router.navigate([RouteList.bookingAgreement]);
	}

	bookFromMedicalOfficeEmptySlot(slot: IAppointment) {
		this.bookingStatusService.bookFromMedicalOfficeEmptySlot(slot);
		this.router.navigate([RouteList.bookingAgreement]);
	}
}
