export enum MessageTypes {
	SystemNotificationMessage = 0,
	UploadDocumentMessage = 1,
	UserMessage = 2,
	VideoCallStarted = 3,
	VideoCallEnded = 4,
	LeftVideoCall = 5,
	CreatedTheGroup = 6,
	JoinedTheGroup = 7,
	LeftTheGroup = 8,
	RemovedFromTheGroup = 9,
	JoinedVideoCall = 10,
	NewAppointment = 11,
	AppointmentCanceled = 12,
	NewSession = 13,
}
