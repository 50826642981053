import {
	ChangeDetectorRef,
	Component,
	Input,
	OnDestroy,
	OnInit,
} from '@angular/core';
import { AppointmentService } from '@app/01.global/services/appointment.service';
import { AuthenticationService } from '@app/01.global/services/authentication.service';
import { DatatableFilters } from '@app/01.global/models/DatatableFilters';
import { RouteList } from '@app/01.global/helpers/route-enum';
import { Subscription } from 'rxjs';
import { IAppointment } from '@app/01.global/interfaces/IAppointment';
import { UntypedFormGroup } from '@angular/forms';
import { IUser } from '@app/01.global/interfaces/IUser';
import { Router } from '@angular/router';
import { formatDate } from '@angular/common';

@Component({
	selector: 'app-appointment-timeline',
	templateUrl: './appointment-timeline.component.html',
	styleUrls: ['./appointment-timeline.component.scss'],
})
export class AppointmentTimelineComponent implements OnInit, OnDestroy {
	@Input() parentSentData: boolean = false;
	@Input('maxHeight') maxHeight: string = '500px';
	@Input('overflow-y') verticalOverflow:
		| 'auto'
		| 'clip'
		| 'hidden'
		| 'inherit'
		| 'initial'
		| 'overlay'
		| 'revert'
		| 'scroll'
		| 'unset'
		| 'visible';
	@Input() userJoinDate: Date;
	_appointments: IAppointment[];
	@Input() get appointments(): IAppointment[] {
		return this._appointments;
	}

	set appointments(v: IAppointment[]) {
		this._appointments = v;
	}

	throttle = 0;
	distance = 9.9;
	page: DatatableFilters = {
		totalItems: 0,
		pageSize: 10,
		currentPage: 0,
		totalPages: 0,
	};
	currentDate = new Date();
	form: UntypedFormGroup;
	submitted: boolean;
	currentUser: IUser;
	activeAppointment: IAppointment;
	routeList = RouteList;
	userSub: Subscription;

	loading: boolean = true;

	constructor(
		private authenticationService: AuthenticationService,
		private appointmentService: AppointmentService,
		private cdr: ChangeDetectorRef,
		private router: Router
	) {}

	ngOnInit(): void {
		this.userSub = this.authenticationService.user.subscribe((u: IUser) => {
			if (u == null) {
				return;
			}

			this.currentUser = u;
			if (!this.parentSentData) {
				this.read();
				this.userJoinDate = this.currentUser.createdDate;
			} else {
				this.loading = false;
			}
		});
	}

	ngOnDestroy(): void {
		this.userSub.unsubscribe();
	}

	read() {
		this.appointmentService.readAll(this.page).subscribe({
			next: (response: IAppointment[]) => {
				this.appointments = response;
				this.loading = false;
				this.cdr.detectChanges();
			},
			error: (error) => {
				this.loading = false;
				this.cdr.detectChanges();
			},
		});
	}
	onScroll(): void {
		++this.page.currentPage;
		this.appointmentService
			.readAll(this.page)
			.subscribe((appointments: IAppointment[]) => {
				this.appointments.push(...appointments);
				this.cdr.detectChanges();
			});
	}

	getAppointmentColor(app: IAppointment): string {
		if (app.appointmentStatusesMachine.length > 0) {
			const lastStatus = app.appointmentStatusesMachine[0];
			if (
				app.medicalOffice &&
				app.medicalOffice.branch &&
				app.medicalOffice.branch.mainProvider &&
				app.medicalOffice.branch.mainProvider.providerAppointmentColors
			) {
				const customColor =
					app.medicalOffice.branch.mainProvider.providerAppointmentColors.find(
						(ac) => ac.statusMachineId == lastStatus.id
					);
				if (customColor) {
					return customColor.color;
				}
			}

			return lastStatus.machineStatus.color;
		}

		return '#bbb';
	}

	isCurrentUserParticipant(appointment: IAppointment): boolean {
		return appointment.participants.some(
			(ap) => ap.id == this.currentUser.id
		);
	}

	navigateToCalendar(item: IAppointment) {
		this.router.navigate([
			RouteList.calendar,
			formatDate(item.startDate, 'YYYY-MM-dd', 'pt'),
			RouteList._routeCrudEnum.Details,
			item.id,
		]);
	}
}
