import {
	ChangeDetectorRef,
	Component,
	OnDestroy,
	OnInit,
	Provider,
} from '@angular/core';
import { UntypedFormControl } from '@angular/forms';
import { SharedAnimations } from '../../../01.global/animations/shared-animations';

import { Router } from '@angular/router';
import { IUser } from '@app/01.global/interfaces/IUser';
import { SearchService } from '@app/01.global/services/search.service';
import { RouteList } from '@app/01.global/helpers/route-enum';
import { DatatableFilters } from '@app/01.global/models/DatatableFilters';
import {
	debounceTime,
	distinctUntilChanged,
	filter,
	Subscription,
	switchMap,
} from 'rxjs';

@Component({
	selector: 'app-search',
	templateUrl: './search.component.html',
	styleUrls: ['./search.component.scss'],
	animations: [SharedAnimations],
})
export class SearchComponent implements OnInit, OnDestroy {
	filters: DatatableFilters = {
		pageSize: 5,
		totalItems: 0,
		totalPages: 0,
		currentPage: 0,
	};

	searchTerm: UntypedFormControl = new UntypedFormControl('');
	searchSub: Subscription;
	loading: boolean = false;
	users: IUser[] = [];

	constructor(
		public service: SearchService,
		private router: Router,
		private cdr: ChangeDetectorRef
	) {}

	ngOnInit() {
		this.readCount();
		this.read();
		this.searchSub = this.searchTerm.valueChanges
			.pipe(
				debounceTime(500),
				distinctUntilChanged(),
				switchMap((value: string) => {
					this.filters.searchTerm = value;
					this.readCount();
					this.read();
					if (value == '') {
						return 'Ok';
					}
					return value;
				})
			)
			.subscribe(() => {});
	}
	ngOnDestroy(): void {
		this.searchSub.unsubscribe();
	}

	read() {
		this.loading = true;
		this.service.readAll(this.filters).subscribe({
			next: (response) => {
				this.users = response;
				this.loading = false;
				this.cdr.detectChanges();
			},
			error: (error) => {
				this.loading = false;
				this.cdr.detectChanges();
			},
		});
	}
	readCount() {
		this.loading = true;
		this.service.readCount(this.filters).subscribe({
			next: (response: any) => {
				this.paginationHandling(response);
				this.loading = false;
				this.cdr.detectChanges();
			},
			error: (error) => {
				this.loading = false;
				this.cdr.detectChanges();
			},
		});
	}

	pageChange(page: any) {
		this.filters.currentPage = page - 1;
		this.read();
	}

	navigateToDetails(target: IUser) {
		if (target.employeeInfoAccountId > 0) {
			this.router.navigate([
				RouteList.joinRoutes([
					RouteList.employees,
					RouteList._routeCrudEnum.Details + '/' + target.id,
				]),
			]);
		} else if (target.providerInfoAccountId > 0) {
			this.router.navigate([
				RouteList.joinRoutes([
					RouteList.providers,
					RouteList._routeCrudEnum.Details + '/' + target.id,
				]),
			]);
		} else {
			this.router.navigate([RouteList.dashboard]);
		}
		this.service.searchOpen = false;
	}

	private paginationHandling(totalItems: any) {
		this.filters.totalItems = totalItems;

		const totalPagesPreCalculated =
			this.filters.totalItems / this.filters.pageSize;

		this.filters.totalPages =
			this.filters.totalItems % this.filters.pageSize == 0
				? totalPagesPreCalculated
				: totalPagesPreCalculated + 1;
	}
}
