import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from '@environments/environment';
import { Observable } from 'rxjs';
import { DatatableFilters } from '../models/DatatableFilters';
import { IApiResponse } from '../interfaces/helpers/iResponseWrapper';
import { ITableListService } from '../../table-list/services/iservice';
import { BaseService } from './base.service';
import { IBookingRequest } from '../interfaces/iBookingRequest';

@Injectable({
	providedIn: 'root',
})
export class BookingRequestService
	extends BaseService
	implements ITableListService
{
	ApiBaseURL = environment.ApiBaseURL;

	constructor(private http: HttpClient) {
		super();
	}

	create(appRequest: IBookingRequest): Observable<IBookingRequest> {
		return this.http
			.post<IApiResponse>(
				`${this.ApiBaseURL}/booking/createrequest`,
				appRequest
			)
			.ToApiResult();
	}

	readCount(page: DatatableFilters): Observable<number> {
		let params = this.paginationToHttpParams(page);
		return this.http
			.get<IApiResponse>(`${this.ApiBaseURL}/booking/countrequests`, {
				params,
			})
			.ToApiResult();
	}

	readAll(page: DatatableFilters): Observable<IBookingRequest[]> {
		let params = this.paginationToHttpParams(page);
		return this.http
			.get<IApiResponse>(`${this.ApiBaseURL}/booking/readrequests`, {
				params,
			})
			.ToApiResult();
	}

	read(id: number): Observable<IBookingRequest> {
		return this.http
			.get<IApiResponse>(`${this.ApiBaseURL}/booking/readrequest/${id}`)
			.ToApiResult();
	}
}
