<ngb-accordion [closeOthers]="true" activeIds="followUpCall">
	<ngb-panel id="startApp">
		<ng-template ngbPanelTitle>
			<span>
				{{ "StartAppointment" | translate }}
			</span>
		</ng-template>
		<ng-template ngbPanelContent>
			<div class="row">
				<div class="col-6 appointment-wrapper o-hidden" *ngFor="let appointment of appointmentsToStart">
					<div class="appointment-card card card-icon-bg card-icon-bg-primary dashboard-widget mb-3 rounded">
						<div class="card-body pb-0">
							{{ appointment.startDate | localizedDate: 'fullDate' }}
							<div class="status-logo ml-auto px-2" style="position: relative;">
								<!-- TODO estava na div acima mas nao trazemos esta info. de qq das maneiras a query actualmente só traz confirmeds -->
								<!-- [ngStyle]="{
								'background-color':appointment.appointmentStatusesMachine[0].machineStatusColor
							}" -->
								<i class="i-Loading-3 text-white" style="font-size: 1.5rem;line-height: 2rem;"></i>
								<span class="status-name text-white text-12"
									style="line-height: 2rem;vertical-align: bottom;">
									{{
									'Confirmed'
									| translate
									}}
									<!-- TODO mesma situação aqui -->
									<!-- appointment.appointmentStatusesMachine[0].machineStatusDescription -->
								</span>
							</div>
						</div>
						<div class="card-body text-center">
							<i class="i-Clock-Back mr-4"></i>
							<h5>{{appointment.name}}</h5>
							<p class="card-text mb-0 text-12">
								<i class="i-Clock-Back appointment-date-icon text-primary" style="font-size: 18px;"></i>
								{{ appointment.startDate | localizedDate: 'fullTime' }}
							</p>
							<p class="card-text mb-0 text-12">
								<i class="i-Clock-Forward text-primary" style="font-size: 18px;"></i>
								{{ appointment.endDate | localizedDate: 'fullTime' }}
							</p>
						</div>
					</div>
					<!-- estava na div abaixo, mesma situação quie acima -->
					<!-- *ngIf="appointment.appointmentStatusesMachine[0].machineStatusName == AppointmentStatusEnum.Confirmed" -->
					<div class="appointment-select bg-success card rounded text-white d-flex justify-content-center flex-row"
						(click)="close('startApp', appointment.id)" style="z-index: 20;">
						<div class="rounded-circle my-auto mr-3 p-2 text-center"
							style="border: 4px solid white; width: 60px; height: 60px;">
							<i class="rounded-circle text-white text-30 i-Telephone" style="line-height: 35px;"></i>
						</div>
						<h6 class="text-white my-auto text-20">
							{{ 'Call' | translate }}
						</h6>
					</div>
				</div>
			</div>
			<ng-container *ngIf="!appointmentsToStart || appointmentsToStart.length===0">
				<h5 class="m-0 text-primary text-center">
					{{'NoData' | translate}}
				</h5>
			</ng-container>
		</ng-template>
	</ngb-panel>
	<!-- TODO Booking -->
	<!-- <ngb-panel id="newApp" *ngIf="activeChannel.channelType !== ChannelType.General">
		<ng-template ngbPanelTitle>
			<span>{{ "NewAppointment" | translate }}</span>
		</ng-template>
		<ng-template ngbPanelContent>
			<button class="btn btn-success" (click)="close('newApp')">
				{{'GoToBooking' | translate}}
			</button>
		</ng-template>
	</ngb-panel> -->
	<ngb-panel id="followUpCall">
		<ng-template ngbPanelTitle>
			<span>{{ "FollowUpCall" | translate }}</span>
		</ng-template>
		<ng-template ngbPanelContent>
			<div class="alert-card alert alert-warning">
				{{ 'ThisWillNotBeCharged' | translate }}
			</div>
			<button class="btn btn-success" (click)="close('followUpCall')">
				{{'StartCall' | translate}}
			</button>
		</ng-template>
	</ngb-panel>
</ngb-accordion>