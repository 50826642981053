import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { AttendanceWindowType } from '@app/01.global/helpers/attendance-window-type.enum';
import { IAppointment } from '@app/01.global/interfaces/IAppointment';
import { AttendanceStatusService } from '@app/01.global/status-services/attendance-status.service';

@Component({
	selector: 'app-attendance-call-launcher',
	templateUrl: './attendance-call-launcher.component.html',
	styleUrls: ['./attendance-call-launcher.component.scss']
})
export class AttendanceCallLauncherComponent implements OnInit {

	appointmentsToStart: IAppointment[] = [];

	@Input() channelId: number;
	@Output() onTypeChosen = new EventEmitter<string>();

	constructor(private statusService: AttendanceStatusService) { }

	ngOnInit(): void {
		this.appointmentsToStart = this.statusService.getChannelAppointments(this.channelId);
	}

	close(type: 'startApp' | 'newApp' | 'followUpCall', appointmentId?: number) {
		switch (type) {
			case 'startApp':
				this.statusService.openWindow(this.channelId, AttendanceWindowType.VideoCall, appointmentId);
				break;
			case 'newApp':
				// TODO
				break;
			case 'followUpCall':
				this.statusService.openWindow(this.channelId, AttendanceWindowType.VideoCall);
				break;
			default:
				break;
		}
		this.onTypeChosen.emit(type);
	}

}
