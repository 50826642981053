<div [ngSwitch]="settings?.mode">
	<div *ngSwitchCase="TableListMode.Datatable">
		<div class="container-fluid" *ngIf="table">
			<!--TABLE ACTIONS-->
			<div class="row gy-2 gy-md-3 mb-3 align-items-center">
				<!--TABLE BUTTONS-->
				<div class="col col-md-6 text-md-start">
					<div class="d-flex flex-row">
						<i class="{{settings.icon}} text-30 text-info d-flex my-auto mr-2"></i>
						<h5 class="my-auto mr-3">
							<span style="line-height: 30px;">{{settings.messages.titleMessage}}</span>
						</h5>
						<ng-container *ngFor="let btn of settings.actionButtons">
							<table-list-btn [btn]="btn" [tableIdentifier]="identifier"></table-list-btn>
						</ng-container>
						<div class="form-group d-flex my-auto" *ngIf="settings.showSearch">
							<input [placeholder]="settings.messages.searchboxPlaceholder" [formControl]="searchTerm"
								class="form-control form-control-rounded" type="text" style="min-width: 10.5rem;" />
						</div>
					</div>
				</div>
				<!--TABLE COUNT-->
				<div class="col col-md-6 text-start text-md-end"
					*ngIf="this.table.totalItemsObservable | async as total">
					<strong>{{ settings.messages.totalMessage
						}}:</strong>&nbsp;{{total}}
				</div>
			</div>
			<!--TABLE DATA-->
			<div class="row" *ngIf="isLoading">
				<div class="col">
					<div class="d-flex justify-content-center" style="margin-top: 15rem;">
						<div class="spinner-grow text-info mx-2" role="status">
							<span class="visually-hidden">Loading...</span>
						</div>
						<div class="spinner-grow text-info mx-2" role="status">
							<span class="visually-hidden">Loading...</span>
						</div>
						<div class="spinner-grow text-info mx-2" role="status">
							<span class="visually-hidden">Loading...</span>
						</div>
					</div>
				</div>
			</div>
			<div class="row" *ngIf="!isLoading">
				<div class="col">
					<table-list-data [tableIdentifier]="identifier"></table-list-data>
				</div>
			</div>
		</div>
	</div>
	<div *ngSwitchCase="TableListMode.DatatableCardContact">
		<table-list-card [tableIdentifier]="identifier"></table-list-card>
	</div>
	<div *ngSwitchCase="TableListMode.DatatableEventCard">
		<table-list-card [tableIdentifier]="identifier"></table-list-card>
	</div>
	<div *ngSwitchCase="TableListMode.DatatableMiniCard">
		<table-list-card [tableIdentifier]="identifier"></table-list-card>
	</div>
	<div *ngSwitchCase="TableListMode.DatatableCardImage">
		<table-list-card [tableIdentifier]="identifier"></table-list-card>
	</div>
	<div *ngSwitchCase="TableListMode.DatatableCircleCard">
		<table-list-card [tableIdentifier]="identifier"></table-list-card>
	</div>
	<div *ngSwitchCase="TableListMode.DatatableTileCard">
		<table-list-tiles [tableIdentifier]="identifier"></table-list-tiles>
	</div>
	<div *ngSwitchDefault>
		<div class="text-center">
			<div class="alert alert-danger" style="display: inline;" role="alert">
				{{settings?.messages?.emptyMessage}}
			</div>
		</div>
	</div>
</div>