<div class="sticky d-flex bg-secondary box-shadow-2 text-white badge-top-container" [ngClass]="{active: showRightPane}"
	(click)="onIconClick()">
	<ng-container *ngIf="inBotMode">
		<i class="i-Support"></i>
		<span class="bot-name text-14">{{'LiveChat' | translate}}</span>
	</ng-container>
	<ng-container *ngIf="inChannelMode">
		<div class="profile-picture profileImgContainer" [ngStyle]="{
			'background-image':
				'url(' +
				(channel?.logo?.url ?? './assets/images/default-chat-bubbles-group.png') +
				')'
		}"></div>
		<span *ngIf="channel?.unReadMessagesCount > 0"
			class="badge badge-primary">{{channel?.unReadMessagesCount}}</span>
		<span class="bot-name text-14">{{ channel?.name}}</span>
	</ng-container>
</div>
<div class="shout-box-container" [ngClass]="{active: showRightPane}">
	<div class="card box-shadow-2 chat-sidebar-container">
		<div class="card-body chat-content-wrap">
			<div class="card-title pt-3 pl-3 mb-0">
				<div class="shout-close cursor-pointer" (click)="onCloseClick()">
					<div class="d-flex align-items-center" *ngIf="channel">
						<span>
							<img [src]="channel.logo?.url ?? './assets/images/default-chat-bubbles-group.png'"
								[alt]="channel.name" class="avatar-sm rounded-circle mr-2">
							<span class="m-0 text-title text-16 flex-grow-1">{{channel.name}}</span>
						</span>

						<i class="i-Arrow-Forward-2 ml-auto mr-3"></i>
					</div>
				</div>
				<div class="row" *ngIf="inChannelMode">
					<div class="col">
						<button class="btn btn-icon btn-rounded btn-outline-info mt-3 mb-3 ml-0" placement="bottom"
							[ngbTooltip]="'OpenChat' | translate" (click)="navigate()">
							<i class="i-Maximize-Window"></i>
						</button>
					</div>
				</div>
			</div>
			<app-shout-box *ngIf="inBotMode"></app-shout-box>
			<app-chat *ngIf="inChannelMode && showRightPane"></app-chat>
		</div>
	</div>
</div>