import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
	name: 'removeString'
})
export class RemoveStringPipe implements PipeTransform { 
	transform(input: string, stringToRemove: string): string {
		if (!input || !stringToRemove) {
			return input;
		}

		return input.replace(stringToRemove, '');		
	}
}
