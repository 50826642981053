import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from '@environments/environment';
import { Observable } from 'rxjs';
import { DatatableFilters } from '../models/DatatableFilters';
import { IAgreementCard } from '../interfaces/iAgreementCard';
import { IAppointment } from '../interfaces/IAppointment';
import { IConsultationType } from '../interfaces/iConsultation-type';
import { IApiResponse } from '../interfaces/helpers/iResponseWrapper';
import { IUser } from '../interfaces/IUser';
import { ITableListService } from '../../table-list/services/iservice';
import { BaseService } from './base.service';

@Injectable({
	providedIn: 'root',
})
export class BookingService extends BaseService implements ITableListService {
	ApiBaseURL = environment.ApiBaseURL;

	constructor(private http: HttpClient) {
		super();
	}

	readCount(page: DatatableFilters): Observable<number> {
		let params = this.paginationToHttpParams(page);
		return this.http
			.get<IApiResponse>(
				`${this.ApiBaseURL}/booking/userPreviousAppointmentscount`,
				{
					params,
				}
			)
			.ToApiResult();
	}

	readAll(page: DatatableFilters): Observable<IAppointment[]> {
		let params = this.paginationToHttpParams(page);
		return this.http
			.get<IApiResponse>(
				`${this.ApiBaseURL}/booking/userPreviousAppointments`,
				{
					params,
				}
			)
			.ToApiResult();
	}

	delete(id: number) {
		throw new Error('Method not implemented.');
	}

	cancel(schedule: IAppointment): Observable<IAppointment> {
		return this.http
			.post<IApiResponse>(`${this.ApiBaseURL}/booking/cancel`, schedule)
			.ToApiResult();
	}
	finish(schedule: IAppointment): Observable<IAppointment> {
		return this.http
			.post<IApiResponse>(`${this.ApiBaseURL}/booking/finish`, schedule)
			.ToApiResult();
	}

	finishMultiple(schedule: IAppointment): Observable<IAppointment> {
		return this.http
			.post<IApiResponse>(
				`${this.ApiBaseURL}/booking/finishMultiple`,
				schedule
			)
			.ToApiResult();
	}

	readUserAgreements(userId = 0): Observable<IAgreementCard[]> {
		return this.http
			.get<IApiResponse>(
				`${this.ApiBaseURL}/booking/userAgreements/${userId}`
			)
			.ToApiResult();
	}

	readConsultationTypes(
		page: DatatableFilters
	): Observable<IConsultationType[]> {
		let params = this.paginationToHttpParams(page);
		return this.http
			.get<IApiResponse>(`${this.ApiBaseURL}/booking/consultationTypes`, {
				params,
			})
			.ToApiResult();
	}

	readProviders(page: DatatableFilters): Observable<IUser[]> {
		let params = this.paginationToHttpParams(page);
		return this.http
			.get<IApiResponse>(`${this.ApiBaseURL}/booking/providers`, {
				params,
			})
			.ToApiResult();
	}

	readEmployees(page: DatatableFilters): Observable<IUser[]> {
		let params = this.paginationToHttpParams(page);
		return this.http
			.get<IApiResponse>(`${this.ApiBaseURL}/booking/employees`, {
				params,
			})
			.ToApiResult();
	}

	readNextEmptySlots(page: DatatableFilters): Observable<IAppointment[]> {
		let params = this.paginationToHttpParams(page);
		return this.http
			.get<IApiResponse>(
				`${this.ApiBaseURL}/booking/readNextEmptySlots`,
				{
					params,
				}
			)
			.ToApiResult();
	}

	readAvailableDays(page: DatatableFilters): Observable<Date[]> {
		let params = this.paginationToHttpParams(page);
		return this.http
			.get<IApiResponse>(`${this.ApiBaseURL}/booking/availableDays`, {
				params,
			})
			.ToApiResult();
	}

	read(id: number): Observable<any> {
		throw new Error('Method not implemented.');
	}

	oneClickDoctor(doctorId: number = 0): Observable<IAppointment> {
		return this.http
			.post<IApiResponse>(`${this.ApiBaseURL}/booking/oneClickDoctor`, doctorId)
			.ToApiResult();
	}
}
