import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { IChatMessage } from '@app/01.global/interfaces/iChatMessage';
import { DatatableFilters } from '@app/01.global/models/DatatableFilters';
import { IApiResponse } from '../interfaces/helpers/iResponseWrapper';
import { environment } from '@environments/environment';
import '@app/01.global/helpers/observable-extensions';
import { ITableListService } from '../../table-list/services/iservice';
import { Observable } from 'rxjs';
import { BaseService } from './base.service';

@Injectable({
	providedIn: 'root',
})
export class ChatMessageService
	extends BaseService
	implements ITableListService
{
	ApiBaseURL = environment.ApiBaseURL;

	constructor(private http: HttpClient) {
		super();
	}

	readCount(page: DatatableFilters): Observable<number> {
		let params = this.paginationToHttpParams(page);

		return this.http
			.get<IApiResponse>(`${this.ApiBaseURL}/ChatMessages/count`, {
				params,
			})
			.ToApiResult();
	}

	readAll(page: DatatableFilters): Observable<IChatMessage[]> {
		let params = this.paginationToHttpParams(page);

		return this.http
			.get<IApiResponse>(`${this.ApiBaseURL}/ChatMessages/`, { params })
			.ToApiResult();
	}

	create(model: IChatMessage): Observable<IChatMessage> {
		return this.http
			.post<IApiResponse>(`${this.ApiBaseURL}/ChatMessages/`, model)
			.ToApiResult();
	}

	upload(attachment: File, channelId: number) {
		const uploadData = new FormData();
		uploadData.append('myFile', attachment, attachment.name);
		return this.http
			.post<IApiResponse>(
				`${this.ApiBaseURL}/ChatMessages/upload/` + channelId,
				uploadData
			)
			.ToApiResult();
	}

	read(id: number): Observable<any> {
		throw new Error('Method not implemented.');
	}
	delete(id: number) {
		throw new Error('Method not implemented.');
	}
}
