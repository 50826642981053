import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { RouteList } from '@app/01.global/helpers/route-enum';
import { DatatableFilters } from '@app/01.global/models/DatatableFilters';
import { IDocument } from '@app/01.global/interfaces/iDocument';
import { IUser } from '@app/01.global/interfaces/IUser';
import { IChannel } from '@app/01.global/interfaces/iChannel';
import { AuthenticationService } from '@app/01.global/services/authentication.service';
import { ChannelService } from '@app/01.global/services/channel.service';
import { PerfectScrollbarDirective } from 'ngx-perfect-scrollbar';
import { finalize, Observable, share, Subscription } from 'rxjs';
import { SignalrService } from '@app/01.global/services/signalr.service';
import { ConsultationStatusService } from '@app/01.global/status-services/consultation-status.service';

@Component({
	selector: 'app-files',
	templateUrl: './files.component.html',
	styleUrls: ['./files.component.scss'],
})
export class FilesComponent implements OnInit, OnDestroy {
	loading: boolean = false;
	loadingUp: boolean = false;
	activeChannel: IChannel;
	routeList = RouteList;
	currentUser: IUser;
	showSharedFiles: boolean;
	sharedFiles: IDocument[];
	request$: Observable<IDocument[]>;

	throttle = 0;
	distance = 2;
	filters: DatatableFilters = {
		totalItems: 0,
		pageSize: 10,
		currentPage: 0,
		totalPages: 0,
	};

	currentDate = new Date();
	userJoinDate: Date;
	subs: Subscription[] = [];

	@ViewChild(PerfectScrollbarDirective, { static: false })
	perfectScrollbarDirectiveRef?: PerfectScrollbarDirective;

	constructor(
		private authenticationService: AuthenticationService,
		private service: ChannelService,
		private statusService: ConsultationStatusService
	) { }

	ngOnInit(): void {
		this.subs.push(
			this.authenticationService.user.subscribe((u) => {
				this.currentUser = u;
				this.userJoinDate = this.currentUser?.createdDate;
			})
		);

		this.subs.push(
			this.statusService.activeChannelObs.subscribe((response) => {
				this.activeChannel = response?.channel;
				this.filters.currentPage = 0;
				if (this.activeChannel) {
					this.filters.readFromWhoId = this.activeChannel.id;
				}
			})
		);

		this.subs.push(
			this.statusService.settingsObs.subscribe((response) => {
				this.showSharedFiles = response.showSharedFiles;
				if (this.showSharedFiles) {
					this.readAll();
				}
			})
		);

		this.subs.push(
			this.statusService.activeChannelNewDocumentMessageObs.subscribe(
				() => {
					this.readAll();
				}
			)
		);
	}

	ngOnDestroy(): void {
		this.subs.forEach((s) => s?.unsubscribe());
	}

	readAll() {
		this.loading = true;
		this.getFiles().subscribe({
			next: (response: IDocument[]) => {
				this.sharedFiles = response;
				setTimeout(() => {
					this.loading = false;
				});
			},
		});
	}

	onScroll(): void {
		this.loadingUp = true;
		++this.filters.currentPage;
		this.getFiles().subscribe((response: IDocument[]) => {
			this.loadingUp = false;
			this.sharedFiles = response.concat(this.sharedFiles);
		});
	}

	private getFiles(): Observable<IDocument[]> {
		if (this.request$) {
			return this.request$;
		} else {
			this.request$ = this.service.readSharedFiles(this.filters).pipe(
				share(),
				finalize(() => (this.request$ = null))
			);
			return this.request$;
		}
	}

	isUrlImage(url: string) {
		if (
			url.endsWith('.gif') ||
			url.endsWith('.jpeg') ||
			url.endsWith('.png') ||
			url.endsWith('.jpg')
		) {
			return true;
		}
	}
}
