<div class="row">
	<div class="col-12 p-2 p-md-3 p-lg-2">
		<div class="row" style="background-color: whitesmoke;">
			<div class="col-12 text-center">
				<div class="card card-icon-bg card-icon-bg-info o-hidden py-3 py-md-2 py-lg-3 rounded-3 search-hero-section"
					style="box-shadow: none;background-color: whitesmoke;">
					<div class="card-body">
						<i class="text-info text-64 {{tableSettings.icon}}" style="opacity: 0.75;"></i>
						<div class="container-fluid">
							<div class="row mb-3">
								<div class="col">
									<h1 class="display-5 fw-bold" style="margin-left: -65px;">
										{{tableSettings.messages.titleMessage}}
									</h1>
								</div>
							</div>
							<div class="row" *ngIf="tableSettings.showSearch">
								<div class="col">
									<div class="form-group search-container">
										<input type="text" class="form-control form-control-lg search-input"
											[formControl]="searchTerm"
											placeholder="{{tableSettings.messages.searchboxPlaceholder}}" />
										<span class="search-icon">
											<img src="https://cdns.iconmonstr.com/wp-content/releases/preview/2012/240/iconmonstr-magnifier-2.png"
												alt="Search">
										</span>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
		<div class="row" *ngIf="tableSettings.actionButtons.length > 0">
			<div class="col-12 py-3 text-center">
				<ng-container *ngFor="let btn of tableSettings.actionButtons">
					<table-list-btn [btn]="btn" [tableIdentifier]="tableSettings.identifier"></table-list-btn>
				</ng-container>
			</div>
		</div>
		<div class="row" style="background-color: whitesmoke;" *ngIf="tableFilters.totalItems">
			<div class="col-12 py-3 text-center">
				<h3>{{ tableSettings.messages.totalMessage + ': ' + tableFilters.totalItems }}</h3>
			</div>
		</div>
	</div>
</div>
<div *ngIf="statusService.getRowsObservable(tableIdentifier) | async as rows">
	<div *ngIf="statusService.getFiltersObservable(tableIdentifier) | async as filters">
		<div *ngIf="rows.length > 0">
			<div class="container">
				<div class="row">
					<div class="col-xs-6 col-sm-3 col-md-3 tile" (click)="!isGuestRolePresent() ? setTableListButtonClicked(row) : null"
						*ngFor="let row of rows">
						<div class="overlay"></div>
						<ng-container *ngFor="let column of row.columns; let i = index">
							<div *ngIf="column.displayMode == TableListColumnMode.thumbnail">
								<div class="table-list-thumbnail" [ngStyle]="{
									'background-image':
									'url(' +
									(row.columns[i].value) +
									')'
								}"></div>
							</div>
							<div class="title-wrapper" *ngIf="column.displayMode == TableListColumnMode.title">
								<h5 class="my-auto title" data-toggle="tooltip" data-placement="top"
									title="{{row.columns[i].value}}">
									{{row.columns[i].value}}
								</h5>
							</div>
						</ng-container>
						<div class="d-flex justify-content-center row-action-buttons-wrapper">
							<ng-container *ngFor="let btn of row.actionButtons">
								<table-list-btn [btn]="btn" [rowId]="row.id" [tableIdentifier]="tableIdentifier">
								</table-list-btn>
							</ng-container>
						</div>
					</div>
				</div>
				<div class="row mt-5"  *ngIf="tableFilters.totalItems" >
					<div class="col text-center" *ngIf="tableFilters.totalItems > 8">
						<button class="btn btn-rounded btn-secondary load-more-button" (click)="loadMore()">
							<i class="text-30" [ngClass]="{'i-Loading-3' : loading,'i-Download' : !loading }"></i>
						</button>
					</div>
				</div>
			</div>
		</div>
	</div>
</div>