import { FormControl, UntypedFormControl, ValidationErrors, ValidatorFn } from '@angular/forms';
import { ActivatedRoute, ActivatedRouteSnapshot } from '@angular/router';
import { NgbDate } from '@ng-bootstrap/ng-bootstrap';
import { RouteData } from '../models/route-data';

export class Utils {
	static isMobile() {
		return window && window.matchMedia('(max-width: 767px)').matches;
	}
	static ngbDateToDate(ngbDate: { month; day; year }) {
		if (!ngbDate) {
			return null;
		}
		return new Date(ngbDate.year, ngbDate.month - 1, ngbDate.day);
	}
	static ngbTimeToDate(
		ngbDate: { month; day; year },
		ngbTime: { hour; minute; second }
	) {
		if (!ngbDate || !ngbTime) {
			return null;
		}

		return new Date(
			ngbDate.year,
			ngbDate.month - 1,
			ngbDate.day,
			ngbTime.hour,
			ngbTime.minute,
			ngbTime.second
		);
	}
	static dateToNgbDate(date: Date): NgbDate {
		if (!date) {
			return null;
		}
		date = new Date(date);
		return {
			month: date.getMonth() + 1,
			day: date.getDate(),
			year: date.getFullYear(),
		} as NgbDate;
	}
	static dateToNgbTime(date: Date) {
		if (!date) {
			return null;
		}
		date = new Date(date);
		return {
			hour: date.getHours(),
			minute: date.getMinutes(),
			second: date.getSeconds(),
		};
	}

	static dateDiff(end: Date, start: Date): number {
		var endDate: any = new Date(end);
		var startDate: any = new Date(start);
		return endDate - startDate;
	}

	static isStartDateBiggerThanEndDate(startDate: Date, endDate: Date): boolean {
		if (!startDate || !endDate) {
			return false;
		}
		if (startDate.getTime() >= endDate.getTime()) {
			return true;
		}
		return false;
	}

	static dateRangeValidator(startDateControlName: string): ValidatorFn {
		return (formControl: UntypedFormControl): ValidationErrors | null => {
			if (!formControl.parent) {
				return;
			}

			var formGroup = formControl.parent;

			var startDateControl = formGroup.get(startDateControlName);

			if (!startDateControl) {
				return null;
			}

			const start: Date = this.ngbDateToDate(startDateControl.value);

			const end: Date = this.ngbDateToDate(formControl.value);

			if (start && end) {
				const isRangeValid = (end.getTime() - start.getTime() > 0);

				return isRangeValid ? null : { startDateIsBiggerThanEndDate: true };
			}

			return null;
		}
	}

	static scrollToTop(selector: string) {
		if (document) {
			const element = <HTMLElement>document.querySelector(selector);
			element.scrollTop = 0;
		}
	}
	static genId() {
		let text = '';
		const possible =
			'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
		for (let i = 0; i < 5; i++) {
			text += possible.charAt(
				Math.floor(Math.random() * possible.length)
			);
		}
		return text;
	}

	static getRouteData(
		route: ActivatedRoute,
		prop: string,
		onlyParents = false
	): any {
		if (!!route.snapshot.data[prop] && !onlyParents) {
			return route.snapshot.data[prop];
		}
		if (!!route.parent?.snapshot.data[prop]) {
			return route.parent?.snapshot.data[prop];
		}
		if (!!route.parent?.parent?.snapshot.data[prop]) {
			return route.parent?.parent?.snapshot.data[prop];
		}
		if (!!route.snapshot.params[prop] && !onlyParents) {
			return route.snapshot.params[prop];
		}
		if (!!route.parent?.snapshot.params[prop]) {
			return route.parent?.snapshot.params[prop];
		}
		if (!!route.parent?.parent?.snapshot.params[prop]) {
			return route.parent?.parent?.snapshot.params[prop];
		}
		return null;
	}

	static readRoute(d): RouteData {
		var result = {
			isAddMode: !!d.isAddMode,
			isEditMode: !!d.isEditMode,
			isDeleteMode: !!d.isDeleteMode,
			isDetailsMode: !!d.isDetailsMode,
			isModalMode: !!d.isModalMode,
			isEditconsultationTypesMode: !!d.isEditconsultationTypesMode,
			isEmployee: !!d.isEmployee,
			isProvider: !!d.isProvider,
			isPatient: !!d.isPatient,
			isEditParticipantsMode: !!d.isEditParticipantsMode,
		};

		return result;
	}

	/**
	* Returns the index of the last element in the array where predicate is true, and -1
	* otherwise.
	* @param array The source array to search in
	* @param predicate find calls predicate once for each element of the array, in descending
	* order, until it finds one where predicate returns true. If such an element is found,
	* findLastIndex immediately returns that element index. Otherwise, findLastIndex returns -1.
	*/
	static findLastIndex<T>(array: Array<T>, predicate: (value: T, index: number, obj: T[]) => boolean): number {
		let l = array.length;
		while (l--) {
			if (predicate(array[l], l, array))
				return l;
		}
		return -1;
	}

	// https://stackoverflow.com/a/1584377
	static arrayUnique(array, prop: string = null) {
		if (!!array) {
			var a = array.concat();
			for (var i = 0; i < a.length; ++i) {
				for (var j = i + 1; j < a.length; ++j) {
					if (prop == null && a[i] === a[j])
						a.splice(j--, 1);
					if (prop != null && a[i][prop] === a[j][prop])
						a.splice(j--, 1);
				}
			}
			return a;
		}
		return []
	}

	static noWhitespaceValidator(control: FormControl) {
		const isWhitespace = (control.value || '').trim().length === 0;
		const isValid = !isWhitespace;
		return isValid ? null : { 'whitespace': true };
	}

	static getAllRouteData(snap: ActivatedRouteSnapshot): any {
		var data = {}
		let aux = snap;
		while (aux) {
			Object.keys(aux.data).forEach(function (key, index) {
				data[key] = aux.data[key];
			});
			aux = aux.parent;
		}

		return data;
	}
}
