<div appSidebarContainer class="card chat-sidebar-container"
	[ngClass]="{'chat-sketch-to-right': showAddUser || showVideo || showSharedFiles || showMeetingNotes || showPatientHistory}">
	<app-chat-top-bar></app-chat-top-bar>
	<div appSidebarContent class="chat-content-wrap">
		<app-chat></app-chat>
	</div>
	<div *ngIf="(authenticationService.isEmployee || authenticationService.isDoctor || authenticationService.isTherapist) && !archiveMode"
		appSidebarContent class="users-content-wrap bg-white mt-2 border-right"
		[ngClass]="{showAddUser:showAddUser,'video-on-going':showVideo}">
		<app-add-user-to-channel></app-add-user-to-channel>
	</div>
	<!-- <div appSidebarContent class="video-content-wrap" *ngIf="!archiveMode"
		[ngClass]="{showVideo:showVideo, showSharedFiles:showSharedFiles, showMeetingNotes:showMeetingNotes}">
		<app-video></app-video>
	</div> -->
	<div appSidebarContent class="files-content-wrap"
		[ngClass]="{showSharedFiles:showSharedFiles,'video-on-going':showVideo}">
		<app-files></app-files>
	</div>
	<div appSidebarContent class="notes-content-wrap"
		*ngIf="authenticationService.isEmployee || authenticationService.isDoctor || authenticationService.isTherapist"
		[ngClass]="{showMeetingNotes:showMeetingNotes,'video-on-going':showVideo}">
		<app-notes></app-notes>
	</div>
	<div appSidebarContent class="patientHistory-content-wrap" *ngIf="authenticationService.isDoctor"
		[ngClass]="{showPatientHistory:showPatientHistory,'video-on-going':showVideo}">
		<app-appointment-timeline [parentSentData]="true" [appointments]="appointments" [userJoinDate]="userJoinDate"
			[maxHeight]="'calc(100vh - 21.2rem)'" [overflow-y]="'auto'">
		</app-appointment-timeline>
	</div>
	<div appSidebar="chat-sidebar" class="chat-sidebar-wrap">
		<app-consultation-sidebar></app-consultation-sidebar>
	</div>
</div>