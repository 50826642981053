import { Component, OnInit } from '@angular/core';
import { ResolveEnd, ResolveStart, RouteConfigLoadEnd, RouteConfigLoadStart, Router } from '@angular/router';
import { RouteList } from '@app/01.global/helpers/route-enum';
import { LoaderService } from '@app/01.global/services/loader.service';
import { Subscription } from 'rxjs';

@Component({
	selector: 'app-attendance-layout',
	templateUrl: './attendance-layout.component.html',
	styleUrls: ['./attendance-layout.component.scss']
})
export class AttendanceLayoutComponent implements OnInit {
	moduleLoading: boolean = false;
	loaderSub: Subscription;

	constructor(private router: Router,
		private loaderService: LoaderService) { }

	ngOnInit() {
		this.router.events.subscribe((event) => {
			if (
				event instanceof RouteConfigLoadStart ||
				(event instanceof ResolveStart &&
					!event.url.includes(RouteList.login))
			) {
				this.moduleLoading = true;
			}
			if (
				event instanceof RouteConfigLoadEnd ||
				(event instanceof ResolveEnd && !event.url.includes(RouteList.login))
			) {
				this.moduleLoading = false;
				// this.perfectScrollbar.scrollToTop(0, 250)
			}
		});

		this.loaderSub = this.loaderService.loaderSubject.subscribe(
			(loaderVisible) => {
				this.moduleLoading = loaderVisible;
			}
		);
	}

	ngOnDestroy(): void {
		this.loaderSub.unsubscribe();
	}
}
