<div class="side-content-wrap">
	<div class="sidebar-left open">
		<app-guest-sidebar *ngIf="isGuest && consultationChannelId" [consultationChannelId]="consultationChannelId">
		</app-guest-sidebar>
		<app-doctor-sidebar *ngIf="isDoctorOrTherapist"></app-doctor-sidebar>
	</div>
</div>
<div class="main-content-wrap d-flex flex-column sidenav-open">
	<app-guest-background-instructions *ngIf="isGuest && consultationChannelId" [channelId]="consultationChannelId">
	</app-guest-background-instructions>
	<app-doctor-background-instructions *ngIf="isDoctorOrTherapist">
	</app-doctor-background-instructions>
	<div class="modal-container">
		<app-attendance-modal *ngFor="let attendanceWindow of notMinimizedWindows; let i = index"
			[id]="attendanceWindow.id" [type]="attendanceWindow.type" [width]=" attendanceWindow.width"
			[height]="attendanceWindow.height" [left]="attendanceWindow.left" [top]="attendanceWindow.top"
			[title]="attendanceWindow.title" [isActive]="attendanceWindow.isActive"
			[channelId]="attendanceWindow.channelId">
		</app-attendance-modal>
	</div>
	<div class="row minimized-container">
		<div class="col d-flex justify-content-center">
			<app-attendance-ball *ngFor="let attendanceWindow of minimizedWindows" class="mx-3"
				[id]="attendanceWindow.id" [type]="attendanceWindow.type" [title]="attendanceWindow.title"
				[channelId]="attendanceWindow.channelId">
			</app-attendance-ball>
		</div>
	</div>
</div>
<ng-template #authModal let-modal>
	<div class="modal-body p-0">
		<router-outlet></router-outlet>
	</div>
</ng-template>
<ng-template #devicesModal let-modal>
	<app-attendance-devices-check [modalIsOpen]="openedDevicesModal"></app-attendance-devices-check>
</ng-template>