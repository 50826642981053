<div class="d-flex flex-row">
	<div class="instructions-container">
		<div class="card mb-4">
			<div class="card-body">
				<h5 class="card-title text-primary">{{'Welcome' | translate}}</h5>
				<p class="text-16">{{'YoureInDoctorWaitingRoom' | translate:{'0':
					doctor.displayName} }}</p>
				<ng-container *ngIf="!hasAppointment">
					<p class="card-text text-primary text-16">{{'NoAppointmentScheduledWith' | translate:{'0':
						doctor.displayName} }}</p>
					<button class="btn btn-rounded btn-info open-window-button mb-2 px-5" (click)="openBookingWindow()">
						{{ 'BookAppointmentWithDoctor' | translate }}
					</button>
				</ng-container>
				<ng-container *ngIf="hasAppointment">
					<p class="card-text text-primary text-16 mb-0">{{'YouHaveAnAppointmentWithYourDoctor' | translate }}
					</p>
					<p class="">
						<strong>
							{{ channel.appointments[0].startDate | localizedDate : 'long' | uppercase}}
						</strong>
					</p>
					<button class="btn btn-rounded btn-info open-window-button mb-2 px-5" (click)="openBookingWindow()">
						{{ 'BookAnotherAppointment' | translate }}
					</button>
					<p class="card-text text-primary text-16 mt-2">{{'DoctorWillStartTheCallInAMoment' |
						translate}}</p>
				</ng-container>
			</div>
		</div>
		<div class="card mb-4">
			<div class="card-body">
				<h2 class="">{{'TipsForAGreatVideoCall' | translate}}</h2>
				<ul class="list-group list-group-flush">
					<li class="list-group-item text-primary text-16">
						<i class="i-Information text-info mr-4 text-20"></i>
						<i class="i-Wifi text-48 mr-3"></i>{{'UseStrongInternetSignal' | translate}}.
					</li>
					<li class="list-group-item text-primary text-16">
						<i class="i-Information text-info mr-4 text-20"></i>
						<i class="i-Close-Window text-48 mr-3"></i>{{'CloseUnusedPrograms' |
						translate}}.
					</li>
					<li class="list-group-item text-primary text-16">
						<i class="i-Information text-info mr-4 text-20"></i>
						<i class="i-Internet text-48 mr-3"></i>{{'UpdateYourWebBrowserToLatestVersion' |
						translate}}.
					</li>
				</ul>
			</div>
		</div>
		<div class="card mb-4">
			<div class="card-body">
				<h5 class="card-title text-primary mb-0">{{'TechnicalIssues' | translate}}&nbsp;<a
						href="mailto:support@kooli.io">{{'ClickingHere' | translate}}.</a></h5>
			</div>
		</div>
	</div>
	<div class="flex-grow-1"></div>
	<div class="devices-preview-container">
		<webcam *ngIf="!cameraNotFound && !cameraWasDenied" [width]="320" (initError)="handleWebcamInitError($event)">
		</webcam>
		<!-- https://github.com/basst314/ngx-webcam -->
		<div *ngIf="cameraNotFound || cameraWasDenied"
			class="devices-denied-warning-container p-3 text-left shadow d-flex flex-column">
			<div class="text-white text-16 mb-4">{{'TurnOnCameraAndMicTutorial' | translate}}</div>
			<div class="d-flex flex-row justify-content-around mt-1">
				<div class="webcam-icon-container">
					<img class="webcam-icon" src="./assets/images/webcam.png" />
					<img class="webcam-blocked-icon" src="./assets/images/block.png" />
				</div>
				<div class="webcam-icon-container">
					<img class="microphone-icon" src="./assets/images/microphone.png" />
					<img class="webcam-blocked-icon" src="./assets/images/block.png" />
				</div>
			</div>
		</div>
	</div>
</div>