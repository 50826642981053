<div class="card text-white o-hidden shadow" [ngClass]="bgClass" [ngStyle]="{'height': '6rem'}">
	<div class="card-img" [ngStyle]="{
		'background-image':
			'url(' + (photoUrl) +')'
	}"></div>
	<div class="card-img-overlay position-absolute">
		<div class="text-center">
			<div class="card-title mb-2 text-white">
				<ng-content select="[slot=header]"></ng-content>
			</div>
			<div class="separator border-top mb-2"></div>
			<ng-content select="[slot=body]"></ng-content>
		</div>
	</div>
</div>