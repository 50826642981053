import { Component, Input, OnInit } from '@angular/core';

@Component({
	selector: 'table-list-thumbnail',
	templateUrl: './table-list-thumbnail.component.html',
	styleUrls: ['./table-list-thumbnail.component.scss']
})
export class TableListThumbnailComponent implements OnInit {
	constructor() { }

	@Input('source') source: string;
	@Input('rounded') rounded: boolean = false;

	ngOnInit(): void {
	}

}
