
export abstract class TableListColumn {
	constructor() {
		this.value = '';
		this.tooltip = this.name ?? this.value;
		this.displayMode = TableListColumnMode.text;
	}
	value?: any;
	text?: string;
	tooltip?: string;
	name: string;
	sortable: boolean;
	displayMode: TableListColumnMode;
}

export enum TableListColumnMode {
	thumbnail = 0,
	title = 1,
	text = 2,
	html = 3,
	list = 4,
	checkbox = 5,
	checkboxSwitch = 6,
	date = 7,
}