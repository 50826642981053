import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { TableListActionButton } from '@app/table-list/models/table-list-action-button';
import { TableListStatusService } from '@app/table-list/services/table-list-status.service';
import { Subscription } from 'rxjs';

@Component({
	selector: 'table-list-btn',
	templateUrl: './table-list-btn.component.html',
	styleUrls: ['./table-list-btn.component.scss']
})
export class TableListBtnComponent implements OnInit, OnDestroy {
	constructor(public statusService: TableListStatusService, private router: Router, private route: ActivatedRoute) { }

	@Input('btn') btn: TableListActionButton;
	@Input('rowId') rowId: number;
	@Input('tableIdentifier') tableIdentifier: string;
	loading: boolean = false;
	private subs: Subscription[] = []

	ngOnInit(): void {
	}

	ngOnDestroy(): void {
		this.subs.forEach(s => s?.unsubscribe())
	}

	setTableListButtonClicked(event) {
		this.statusService.setTableListButtonClicked(this.btn, this.tableIdentifier, this.rowId);
	}

}
