<div class="queue-container w-100 p-0 m-0">
	<div class="queue-item w-100 p-0 m-0" *ngFor="let item of appointments$ | async" placement="top"
		ngbTooltip="{{getTranslatableTitle(item.title)}}">
		<div class="queue-details p-0">
			<div class="queue-thumbnail" [ngStyle]="{
				'background-image':
				'url(' +
				(item.appointmentLogo?.url) +
				')'
			}"></div>
			<div class="p-1 text-start queue-title o-hidden">
				{{getTranslatableTitle(item.title)}}
			</div>
		</div>
		<div class="queue-button d-flex" (click)="onWaitingRoomClick()">
			<span class="mx-auto">{{ 'SeeWaitingRoom' | translate }}</span>
		</div>
	</div>
</div>