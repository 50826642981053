import { Injectable, OnDestroy } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { BehaviorSubject, Subscription } from 'rxjs';
import { RoleEnum } from '@app/01.global/helpers/role-enum';
import { IChildItem } from '@app/01.global/interfaces/helpers/iChildItem';
import { IMenuItem } from '@app/01.global/interfaces/helpers/iIMenuItem';
import { ISidebarState } from '@app/01.global/interfaces/helpers/iSidebarState';
import { AuthenticationService } from './authentication.service';
import { RouteList } from '../helpers/route-enum';
import { IUser } from '../interfaces/IUser';

@Injectable({
	providedIn: 'root',
})
export class NavigationService implements OnDestroy {
	public sidebarState: ISidebarState = {
		sidenavOpen: true,
		childnavOpen: false,
	};
	selectedItem: IMenuItem;
	roles: string[];
	roleSub: Subscription;
	userSub: Subscription;
	languageSub: Subscription;
	currentUser: IUser = {
		jwtToken: '',
		refreshToken: '',
		language: undefined,
		roles: [],
		userLoginsProvider: [],
		connections: [],
		position: undefined,
		consultationTypes: [],
		specialtyId: 0,
		firstName: '',
		birthDateIgnoringTimeZone: new Date(),
		genderId: 0,
		languageId: 0,
		lastName: '',
		phoneNumber: '',
		phoneNumberCountryCode: '',
		phoneNumberConfirmed: false,
		positionId: 0,
		providerInfoAccountId: 0,
		titleId: 0,
		displayName: '',
		id: 0,
		email: '',
		createdDate: undefined,
		appearOffline: false,
		appointmentIsAccepted: false,
		bICC: '',
		confirmPassword: '',
		isOnline: false,
		isReadOnly: false,
		nIF: '',
		password: '',
		requesterId: 0,
		userName: '',
	};
	commonMenu: IMenuItem[];
	clientMenu: IMenuItem[];
	menu: IMenuItem[];

	constructor(
		private authenticationService: AuthenticationService,
		private translateService: TranslateService
	) {
		this.userSub = this.authenticationService.user.subscribe((u: IUser) => {
			if (u) {
				this.currentUser = u;

				this.roleSub?.unsubscribe();
				this.roleSub = this.authenticationService.roles.subscribe(
					(r: string[]) => {
						if (r) {
							this.roles = r;
							this.updateMenu();
							this.publishNavigationChange();
						}
					}
				);
			}
		});

		this.languageSub = this.translateService.onLangChange.subscribe(() => {
			this.updateMenu();
			this.publishNavigationChange();
		});
	}

	ngOnDestroy(): void {
		this.roleSub.unsubscribe();
		this.userSub.unsubscribe();
		this.languageSub.unsubscribe();
	}

	updateMenu() {
		this.commonMenu = [
			{
				name: this.translateService.instant('Dashboard'),
				description: this.translateService.instant(
					'DashboardDescription'
				),
				type: 'link',
				icon: 'i-Bar-Chart',
				state: RouteList.dashboard,
				allowedRoles: [
					RoleEnum.Guest,
					RoleEnum.Employee,
					RoleEnum.Doctor,
					RoleEnum.Therapist,
				],
			},
		];

		this.clientMenu = [
			{
				name: this.translateService.instant('Planner'),
				type: 'dropDown',
				icon: 'i-Calendar-4',
				state: RouteList.calendar,
				sub: [
					{
						name: this.translateService.instant('Calendar'),
						type: 'link',
						icon: 'i-Calendar',
						state: RouteList.calendar,
					},
					{
						name: this.translateService.instant('EventsList'),
						type: 'link',
						icon: 'i-Split-Horizontal-2-Window',
						state: RouteList.eventsList,
					},
				],
				allowedRoles: [RoleEnum.Guest],
				disallowedRoles: [RoleEnum.Admin, RoleEnum.Provider],
			},
			{
				name: this.translateService.instant('Consultation'),
				description: this.translateService.instant(
					'ConsultationDescription'
				),
				type: 'dropDown',
				icon: 'i-Consulting',
				sub: [
					{
						name: this.translateService.instant('Mailbox'),
						type: 'link',
						icon: 'i-Mail-2',
						state: RouteList.inbox,
						allowedRoles: [
							RoleEnum.Guest,
							RoleEnum.Provider,
							RoleEnum.Employee,
						],
						disallowedRoles: [RoleEnum.Admin],
					},
					{
						name: this.translateService.instant('FavoriteDoctors'),
						type: 'link',
						icon: 'i-Doctor',
						state: RouteList.joinRoutes([
							RouteList._route0Enum.Providers,
							RouteList._route1Enum.Employees,
							RouteList._route2Enum.FavoriteDoctors,
						]),
						allowedRoles: [RoleEnum.Guest],
						disallowedRoles: [
							RoleEnum.Admin,
							RoleEnum.Provider,
							RoleEnum.Doctor,
							RoleEnum.Therapist,
							RoleEnum.Employee,
						],
					},
					{
						icon: 'i-Stethoscope',
						name: this.translateService.instant('TriageAnswers'),
						type: 'dropDown',
						sub: [
							{
								icon: 'i-Magnifi-Glass1',
								name: this.translateService.instant(
									'TriageAnswersSubmitted'
								),
								state: RouteList.joinRoutes([
									RouteList.triageAnswers,
									RouteList._routeCrudEnum.List,
								]),
								type: 'link',
							},
							{
								icon: 'i-File-Horizontal-Text',
								name: this.translateService.instant(
									'TriageAnswersInProgress'
								),
								state: RouteList.joinRoutes([
									RouteList.triageAnswers,
									RouteList._routeCrudEnum.InProgress,
								]),
								type: 'link',
							},
						],
						allowedRoles: [
							RoleEnum.Doctor,
							RoleEnum.Therapist,
							RoleEnum.Employee,
							RoleEnum.Guest,
						],
						disallowedRoles: [RoleEnum.Admin, RoleEnum.Provider],
					},
				],
				allowedRoles: [RoleEnum.Guest],
				disallowedRoles: [
					RoleEnum.Doctor,
					RoleEnum.Therapist,
					RoleEnum.Employee,
					RoleEnum.Provider,
					RoleEnum.Admin,
				],
			},
			{
				name: this.translateService.instant('Files'),
				type: 'dropDown',
				icon: 'i-File-Horizontal-Text',
				sub: [
					{
						icon: 'i-Upload',
						name: this.translateService.instant(
							'AppointmentUploadDocuments'
						),
						state: RouteList.userSentFiles,
						type: 'link',
					},
					{
						icon: 'i-Medicine',
						name: this.translateService.instant(
							'AppointmentListPrescription'
						),
						state: RouteList.userPrescription,
						type: 'link',
					},
				],
				allowedRoles: [
					// RoleEnum.Doctor,
					// RoleEnum.Therapist,
					// RoleEnum.Employee,
					RoleEnum.Guest,
				],
				disallowedRoles: [RoleEnum.Admin, RoleEnum.Provider],
			},
			// {
			// 	name: this.translateService.instant("Wearables"),
			// 	description: this.translateService.instant(
			// 		"WearablesDescription"
			// 	),
			// 	type: "dropDown",
			// 	icon: "i-Cloud-Laptop",
			// 	sub: [
			// 		{
			// 			icon: "i-Search-on-Cloud",
			// 			name: this.translateService.instant(
			// 				"ViewMyImportedData"
			// 			),
			// 			state: "#",
			// 			type: "link",
			// 			allowedRoles: [RoleEnum.Guest],
			// 			disallowedRoles: [RoleEnum.Admin, RoleEnum.Provider],
			// 		},
			// 		{
			// 			icon: "i-Share-on-Cloud",
			// 			name: this.translateService.instant(
			// 				"ShareData"
			// 			),
			// 			state: "#",
			// 			type: "link",
			// 			allowedRoles: [RoleEnum.Guest],
			// 			disallowedRoles: [RoleEnum.Admin, RoleEnum.Provider],
			// 		},
			// 		{
			// 			icon: "i-Block-Cloud",
			// 			name: this.translateService.instant(
			// 				"SyncSettings"
			// 			),
			// 			state: "/wearables/syncsettings",
			// 			type: "link",
			// 			allowedRoles: [RoleEnum.Guest],
			// 			disallowedRoles: [RoleEnum.Admin, RoleEnum.Provider],
			// 		},
			// 	],
			// 	allowedRoles: [RoleEnum.Guest],
			// 	disallowedRoles: [
			// 		RoleEnum.Doctor,
			// 		RoleEnum.Therapist,
			// 		RoleEnum.Employee,
			// 		RoleEnum.Provider,
			// 		RoleEnum.Admin,
			// 	],
			// },
		];

		this.menu = [
			...this.commonMenu,
			...this.clientMenu,
			// {
			// 	name: this.translateService.instant('AppContactInfo'),
			// 	description: this.translateService.instant(
			// 		'AppContactInfoDescription'
			// 	),
			// 	type: 'dropDown',
			// 	icon: 'i-Ambulance',
			// 	sub: [
			// 		{
			// 			icon: 'i-Magnifi-Glass1',
			// 			name: this.translateService.instant('RecordList'),
			// 			state: RouteList.joinRoutes([
			// 				RouteList.contactInfo,
			// 				RouteList._routeCrudEnum.List,
			// 			]),
			// 			type: 'link',
			// 		},
			// 		{
			// 			icon: 'i-Add',
			// 			name: this.translateService.instant('CreateNewRecord'),
			// 			state: RouteList.joinRoutes([
			// 				RouteList.contactInfo,
			// 				RouteList._routeCrudEnum.Add,
			// 			]),
			// 			type: 'link',
			// 		},
			// 	],
			// 	allowedRoles: [RoleEnum.Admin],
			// 	disallowedRoles: [
			// 		RoleEnum.Beneficiary,
			// 		RoleEnum.Developer,
			// 		RoleEnum.Doctor,
			// 		RoleEnum.Employee,
			// 		RoleEnum.Guest,
			// 		RoleEnum.Provider,
			// 		RoleEnum.Therapist,
			// 	],
			// },
			{
				name: this.translateService.instant('ConsultationType'),
				description: this.translateService.instant(
					'ConsultationTypeDescription'
				),
				type: 'dropDown',
				icon: 'i-Stethoscope',
				sub: [
					{
						icon: 'i-Magnifi-Glass1',
						name: this.translateService.instant('RecordList'),
						state: RouteList.joinRoutes([
							RouteList.consultationTypeStandard,
							RouteList._routeCrudEnum.List,
						]),
						type: 'link',
					},
					{
						icon: 'i-Add',
						name: this.translateService.instant('CreateNewRecord'),
						state: RouteList.joinRoutes([
							RouteList.consultationTypeStandard,
							RouteList._routeCrudEnum.Add,
						]),
						type: 'link',
					},
				],
				allowedRoles: [RoleEnum.Admin],
				disallowedRoles: [
					RoleEnum.Beneficiary,
					RoleEnum.Developer,
					RoleEnum.Doctor,
					RoleEnum.Employee,
					RoleEnum.Guest,
					RoleEnum.Provider,
					RoleEnum.Therapist,
				],
			},
			{
				name: this.translateService.instant('Specialties'),
				description: this.translateService.instant(
					'SpecialtiesDescription'
				),
				type: 'link',
				icon: 'i-Medicine',
				state: RouteList.joinRoutes([
					RouteList.specialtiesStandard,
					RouteList._routeCrudEnum.List,
				]),
				allowedRoles: [RoleEnum.Admin],
				disallowedRoles: [
					RoleEnum.Beneficiary,
					RoleEnum.Developer,
					RoleEnum.Doctor,
					RoleEnum.Employee,
					RoleEnum.Guest,
					RoleEnum.Provider,
					RoleEnum.Therapist,
				],
			},
			,
			{
				name: this.translateService.instant('MedicalProcedure'),
				description: this.translateService.instant('MedicalProcedure'),
				type: 'dropDown',
				icon: 'i-Library',
				sub: [
					{
						icon: 'i-Magnifi-Glass1',
						name: this.translateService.instant('RecordList'),
						state: RouteList.joinRoutes([
							RouteList.medicalProcedure,
							RouteList._routeCrudEnum.List,
						]),
						type: 'link',
					},
					{
						icon: 'i-Add',
						name: this.translateService.instant('CreateNewRecord'),
						state: RouteList.joinRoutes([
							RouteList.medicalProcedure,
							RouteList._routeCrudEnum.Add,
						]),

						type: 'link',
					},
				],
				allowedRoles: [RoleEnum.Admin],
				disallowedRoles: [
					RoleEnum.Beneficiary,
					RoleEnum.Developer,
					RoleEnum.Doctor,
					RoleEnum.Employee,
					RoleEnum.Guest,
					RoleEnum.Provider,
					RoleEnum.Therapist,
				],
			},
			{
				name: this.translateService.instant('Provider'),
				description: this.translateService.instant(
					'ProviderDescription'
				),
				type: 'dropDown',
				icon: 'i-Building',
				sub: [
					{
						icon: 'i-File-Horizontal-Text',
						name: this.translateService.instant('RecordList'),
						state: RouteList.joinRoutes([
							RouteList.providers,
							RouteList._routeCrudEnum.List,
						]),
						type: 'link',
					},
					{
						icon: 'i-File-Horizontal-Text',
						name: this.translateService.instant(
							'RecordListInProgress'
						),
						state: RouteList.joinRoutes([
							RouteList.providers,
							RouteList._routeCrudEnum.InProgress,
						]),
						type: 'link',
					},
					{
						icon: 'i-Add',
						name: this.translateService.instant('CreateNewRecord'),
						state: RouteList.joinRoutes([
							RouteList.providers,
							RouteList._routeCrudEnum.Add,
						]),
						type: 'link',
					},
					{
						icon: 'i-Add',
						name: this.translateService.instant(
							'CreateNewSpecialtyRecord'
						),
						state: RouteList.joinRoutes([
							RouteList.specialties,
							RouteList._routeCrudEnum.Add,
						]),
						type: 'link',
						disallowedRoles: [RoleEnum.Admin],
					},
					{
						icon: 'i-Add',
						name: this.translateService.instant(
							'CreateNewFAQRecord'
						),
						state: RouteList.joinRoutes([
							RouteList.faqs,
							RouteList._routeCrudEnum.Add,
						]),
						type: 'link',
					},
				],
				allowedRoles: [RoleEnum.Admin],
				disallowedRoles: [
					RoleEnum.Beneficiary,
					RoleEnum.Developer,
					RoleEnum.Doctor,
					RoleEnum.Employee,
					RoleEnum.Guest,
					RoleEnum.Provider,
					RoleEnum.Therapist,
				],
			},
			{
				name: this.translateService.instant('Agreements'),
				description: this.translateService.instant(
					'AgreementsDescription'
				),
				type: 'link',
				state: RouteList.joinRoutes([
					RouteList.agreementGroup,
					RouteList._routeCrudEnum.List,
				]),
				icon: 'i-Letter-Open',
				allowedRoles: [RoleEnum.Admin],
				disallowedRoles: [
					RoleEnum.Beneficiary,
					RoleEnum.Developer,
					RoleEnum.Doctor,
					RoleEnum.Employee,
					RoleEnum.Guest,
					RoleEnum.Provider,
					RoleEnum.Therapist,
				],
			},
			{
				name: this.translateService.instant('Branches'),
				description: this.translateService.instant(
					'BranchesDescription'
				),
				type: 'dropDown',
				icon: 'i-Stethoscope',
				sub: [
					{
						icon: 'i-File-Horizontal-Text',
						name: this.translateService.instant('RecordList'),
						state: RouteList.joinRoutes([
							RouteList.branches,
							RouteList._routeCrudEnum.List,
						]),
						type: 'link',
					},
					{
						icon: 'i-File-Horizontal-Text',
						name: this.translateService.instant(
							'RecordListInProgress'
						),
						state: RouteList.joinRoutes([
							RouteList.branches,
							RouteList._routeCrudEnum.InProgress,
						]),
						type: 'link',
					},
					{
						icon: 'i-Add',
						name: this.translateService.instant('CreateNewRecord'),
						state: RouteList.joinRoutes([
							RouteList.branches,
							RouteList._routeCrudEnum.Add,
						]),
						type: 'link',
					},
				],
				allowedRoles: [RoleEnum.Provider],
				allowed:
					this.currentUser.providerInfo?.mainProviderAccountId === 0,
				disallowedRoles: [
					RoleEnum.Beneficiary,
					RoleEnum.Developer,
					RoleEnum.Doctor,
					RoleEnum.Employee,
					RoleEnum.Guest,
					RoleEnum.Therapist,
					RoleEnum.Admin,
				],
			},
			{
				name: this.translateService.instant('Employees'),
				description: this.translateService.instant(
					'EmployeesDescription'
				),
				type: 'dropDown',
				icon: 'i-Management',
				sub: [
					{
						icon: 'i-Magnifi-Glass1',
						name: this.translateService.instant('RecordList'),
						state: RouteList.joinRoutes([
							RouteList.employees,
							RouteList._routeCrudEnum.List,
						]),
						type: 'link',
					},
					{
						icon: 'i-Magnifi-Glass1',
						name: this.translateService.instant(
							'DoctorsWithOffice'
						),
						state: RouteList.joinRoutes([RouteList.doctorOffices]),
						type: 'link',
						allowed:
							this.currentUser.providerInfo
								?.mainProviderAccountId != 0,
					},
					{
						icon: 'i-File-Horizontal-Text',
						name: this.translateService.instant(
							'RecordListInProgress'
						),
						state: RouteList.joinRoutes([
							RouteList.employees,
							RouteList._routeCrudEnum.InProgress,
						]),
						type: 'link',
						allowed:
							this.currentUser.providerInfo
								?.mainProviderAccountId == 0,
					},
					{
						icon: 'i-Add',
						name: this.translateService.instant('CreateNewRecord'),
						state: RouteList.joinRoutes([
							RouteList.employees,
							RouteList._routeCrudEnum.Add,
						]),
						type: 'link',
						allowed:
							this.currentUser.providerInfo
								?.mainProviderAccountId == 0,
					},
				],

				allowedRoles: [RoleEnum.Provider],
				disallowedRoles: [
					RoleEnum.Admin,
					RoleEnum.Beneficiary,
					RoleEnum.Developer,
					RoleEnum.Doctor,
					RoleEnum.Employee,
					RoleEnum.Guest,
					RoleEnum.Therapist,
				],
			},
			{
				name: this.translateService.instant('Specialties'),
				description: this.translateService.instant(
					'SpecialtiesDescription'
				),
				type: 'dropDown',
				icon: 'i-Conference',
				sub: [
					{
						icon: 'i-Magnifi-Glass1',
						name: this.translateService.instant('RecordList'),
						state: RouteList.joinRoutes([
							RouteList.specialties,
							RouteList._routeCrudEnum.List,
						]),
						type: 'link',
					},
					{
						icon: 'i-Add',
						name: this.translateService.instant('CreateNewRecord'),
						state: RouteList.joinRoutes([
							RouteList.specialties,
							RouteList._routeCrudEnum.Add,
						]),
						type: 'link',
					},
					{
						name: this.translateService.instant(
							'ConsultationTypes'
						),
						type: 'dropDown',
						icon: 'i-Stethoscope',
						sub: [
							{
								icon: 'i-Magnifi-Glass1',
								name: this.translateService.instant(
									'RecordList'
								),
								state: RouteList.joinRoutes([
									RouteList.consultationTypes,
									RouteList._routeCrudEnum.List,
								]),
								type: 'link',
							},
						],
						allowedRoles: [RoleEnum.Provider],
						allowed:
							this.currentUser.providerInfo
								?.mainProviderAccountId === 0,
						disallowedRoles: [
							RoleEnum.Beneficiary,
							RoleEnum.Developer,
							RoleEnum.Doctor,
							RoleEnum.Employee,
							RoleEnum.Guest,
							RoleEnum.Therapist,
							RoleEnum.Admin,
						],
					},
				],
				allowedRoles: [RoleEnum.Provider],
				allowed:
					this.currentUser.providerInfo?.mainProviderAccountId == 0,
				disallowedRoles: [
					RoleEnum.Admin,
					RoleEnum.Beneficiary,
					RoleEnum.Developer,
					RoleEnum.Doctor,
					RoleEnum.Employee,
					RoleEnum.Guest,
					RoleEnum.Therapist,
				],
			},
			{
				name: this.translateService.instant('FAQ'),
				description: this.translateService.instant('FAQDescription'),
				type: 'dropDown',
				icon: 'i-Speach-Bubble-Asking',
				sub: [
					{
						icon: 'i-Magnifi-Glass1',
						name: this.translateService.instant('RecordList'),
						state: RouteList.joinRoutes([
							RouteList.faqs,
							RouteList._routeCrudEnum.List,
						]),
						type: 'link',
					},
					{
						icon: 'i-Add',
						name: this.translateService.instant('CreateNewRecord'),
						state: RouteList.joinRoutes([
							RouteList.faqs,
							RouteList._routeCrudEnum.Add,
						]),
						type: 'link',
					},
				],
				allowedRoles: [RoleEnum.Admin, RoleEnum.Provider],
				allowed:
					this.currentUser.providerInfo?.mainProviderAccountId == 0,
				disallowedRoles: [
					RoleEnum.Beneficiary,
					RoleEnum.Developer,
					RoleEnum.Doctor,
					RoleEnum.Employee,
					RoleEnum.Guest,
					RoleEnum.Therapist,
				],
			},
			{
				name: this.translateService.instant('Colors'),
				description: this.translateService.instant('ColorsDescription'),
				type: 'link',
				state: RouteList.joinRoutes([
					RouteList.colors,
					RouteList._routeCrudEnum.List,
				]),
				icon: 'i-Paint-Brush',
				allowedRoles: [RoleEnum.Provider],
				allowed:
					this.currentUser.providerInfo?.mainProviderAccountId == 0,
				disallowedRoles: [
					RoleEnum.Admin,
					RoleEnum.Beneficiary,
					RoleEnum.Developer,
					RoleEnum.Doctor,
					RoleEnum.Employee,
					RoleEnum.Guest,
					RoleEnum.Therapist,
				],
			},
			{
				name: this.translateService.instant('ProviderAgreements'),
				description: this.translateService.instant(
					'ProviderAgreementDescription'
				),
				type: 'link',
				state: RouteList.joinRoutes([
					RouteList.ProviderAgreements,
					RouteList._routeCrudEnum.List,
				]),
				icon: 'i-Letter-Open',
				allowedRoles: [RoleEnum.Provider],
				allowed:
					this.currentUser.providerInfo?.mainProviderAccountId === 0,
				disallowedRoles: [
					RoleEnum.Admin,
					RoleEnum.Beneficiary,
					RoleEnum.Developer,
					RoleEnum.Doctor,
					RoleEnum.Employee,
					RoleEnum.Guest,
					RoleEnum.Therapist,
				],
			},
			{
				name: this.translateService.instant('Planner'),
				type: 'dropDown',
				icon: 'i-Calendar-4',
				state: RouteList.calendar,
				sub: [
					{
						name: this.translateService.instant('Calendar'),
						type: 'link',
						icon: 'i-Calendar',
						state: RouteList.calendar,
					},
					{
						name: this.translateService.instant('EventsList'),
						type: 'link',
						icon: 'i-Split-Horizontal-2-Window',
						state: RouteList.eventsList,
						allowed:
							this.roles.some((r) => r != RoleEnum.Employee) ||
							this.currentUser.branch
								.employeesCanHandleDoctorsAppointments,
					},
				],
				allowedRoles: [
					RoleEnum.Doctor,
					RoleEnum.Therapist,
					RoleEnum.Employee,
				],
			},
			{
				name: this.translateService.instant('Patients'),
				description: this.translateService.instant(
					'PatientsDescription'
				),
				type: 'dropDown',
				icon: 'i-MaleFemale',
				sub: [
					{
						icon: 'i-Calendar-2',
						name: this.translateService.instant('Booking'),
						state: RouteList.bookingPatients,
						type: 'link',
						disallowedRoles: [RoleEnum.Admin, RoleEnum.Provider],
					},
					{
						icon: 'i-Speach-Bubble-3',
						name: this.translateService.instant('Chat'),
						state: RouteList.consultation,
						type: 'link',
						disallowedRoles: [
							RoleEnum.Admin,
							RoleEnum.Provider,
							RoleEnum.Employee,
						],
					},
					{
						icon: 'i-Align-Justify-All',
						name: this.translateService.instant('SeeWaitingRoom'),
						state: RouteList.patientQueue,
						type: 'link',
						disallowedRoles: [
							RoleEnum.Admin,
							RoleEnum.Provider,
							RoleEnum.Employee,
						],
					},
					{
						icon: 'i-Folder-Zip',
						name: this.translateService.instant('ArchivedChat'),
						state: RouteList.joinRoutes([
							RouteList.consultation,
							RouteList._routeCrudEnum.Archived,
						]),

						type: 'link',
						allowedRoles: [
							RoleEnum.Doctor,
							RoleEnum.Therapist,
							RoleEnum.Employee,
						],
						disallowedRoles: [RoleEnum.Admin, RoleEnum.Provider],
					},
					{
						icon: 'i-Magnifi-Glass1',
						name: this.translateService.instant('RecordList'),
						state: RouteList.joinRoutes([
							RouteList.patients,
							RouteList._routeCrudEnum.List,
						]),
						type: 'link',
						disallowedRoles: [],
					},
				],

				allowedRoles: [
					RoleEnum.Employee,
					RoleEnum.Doctor,
					RoleEnum.Therapist,
				],
				disallowedRoles: [RoleEnum.Admin],
			},
			{
				name: this.translateService.instant('MedicalOffice'),
				description: this.translateService.instant(
					'MedicalOfficesDescription'
				),
				// type: "dropDown",
				type: 'link',
				icon: 'i-Home1',
				state: RouteList.joinRoutes([
					RouteList.medicalOffices,
					RouteList._routeCrudEnum.List,
				]),
				allowedRoles: [RoleEnum.Doctor, RoleEnum.Therapist],
				disallowedRoles: [
					RoleEnum.Admin,
					RoleEnum.Provider,
					RoleEnum.Beneficiary,
					RoleEnum.Developer,
					RoleEnum.Guest,
				],
			},
			{
				name: this.translateService.instant('AppLog'),
				description: this.translateService.instant('AppLogDescription'),
				type: 'link',
				state: RouteList.joinRoutes([
					RouteList.logs,
					RouteList._routeCrudEnum.List,
				]),
				icon: 'i-Align-Justify-All',
				allowedRoles: [RoleEnum.Admin],
				disallowedRoles: [
					RoleEnum.Beneficiary,
					RoleEnum.Developer,
					RoleEnum.Doctor,
					RoleEnum.Employee,
					RoleEnum.Guest,
					RoleEnum.Provider,
					RoleEnum.Therapist,
				],
			},
		];
	}

	// sets iconMenu as default;
	menuItems = new BehaviorSubject<IMenuItem[]>([]);
	// navigation component has subscribed to this Observable
	menuItems$ = this.menuItems.asObservable();

	// You can customize this method to supply different menu for
	// different user type.
	publishNavigationChange() {
		var availableMenu = this.menu?.filter(
			(m: IMenuItem) =>
				m.allowedRoles.some((ar) => this.roles.includes(ar)) &&
				!m.disallowedRoles?.some((ar) => this.roles.includes(ar)) &&
				(m.allowed == null || (m.allowed != null && m.allowed))
		);

		availableMenu?.forEach((item) => {
			item.sub = item.sub?.filter(
				(m: IChildItem) =>
					(m.allowedRoles?.some((ar) => this.roles.includes(ar)) ||
						!m.disallowedRoles?.some((ar) =>
							this.roles.includes(ar)
						)) &&
					(m.allowed == null || (m.allowed != null && m.allowed))
			);
		});

		this.menuItems.next(availableMenu);
	}
}
