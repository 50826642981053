import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from '@environments/environment';
import { BehaviorSubject, Observable } from 'rxjs';
import {
	IChatbotActivity,
	IChatbotLoginResponse,
} from '../interfaces/iChatbot';
import { IApiResponse } from '../interfaces/helpers/iResponseWrapper';
import * as signalR from '@microsoft/signalr';
import { AuthenticationService } from './authentication.service';

@Injectable({
	providedIn: 'root',
})
export class ChatbotService {
	ApiBaseURL = environment.ApiBaseURL;
	loginResponse: IChatbotLoginResponse;
	private connection: signalR.HubConnection;

	private newMessageSubject: BehaviorSubject<IChatbotActivity> =
		new BehaviorSubject<IChatbotActivity>(null);
	public newMessage: Observable<IChatbotActivity> =
		this.newMessageSubject.asObservable();

	constructor(
		private http: HttpClient,
		private authenticationService: AuthenticationService
	) {}

	connect() {
		this.connection = new signalR.HubConnectionBuilder()
			.configureLogging(signalR.LogLevel.Warning)
			.withUrl(environment.ApiBaseURL + '/hubs/chatbot', {
				accessTokenFactory: () => this.authenticationService.tokenValue,
				skipNegotiation: true,
				transport: signalR.HttpTransportType.WebSockets,
			})
			.build();

		this.connection
			.start()
			.then(() => {
				this.listenToBroadcasts();
			})
			.catch(function (err) {
				return console.error(err);
			});
	}

	startConversation() {
		this.connection.send(
			'StartConversation',
			this.loginResponse.token,
			this.authenticationService.userValue.userName
		);
	}

	disconnect() {
		this.connection.stop();
		this.connection = null;
		this.newMessageSubject.next(null);
	}

	private listenToBroadcasts() {
		this.connection.on(
			'BroadcastChatbotLogin',
			(token: IChatbotLoginResponse) => {
				this.loginResponse = token;
			}
		);

		this.connection.on(
			'BroadcastChatbotActivity',
			(message: IChatbotActivity) => {
				this.newMessageSubject.next(message);
			}
		);
	}

	askQuestion(question: string): Observable<string> {
		return this.http
			.post<IApiResponse>(`${this.ApiBaseURL}/chatbot/ask`, {
				...this.loginResponse,
				question,
			})
			.ToApiResult();
	}
}
