<div class="patient-waiting-card mb-4 w-100 shadow" style="position: relative;">
	<ng-container *ngIf="channel.appointments && channel.appointments.length > 0">
		<div class="d-flex flex-row justify-content-center w-100 shadow p-2 py-3 text-16 mb-2 border-top border-bottom border-secondary"
			style="line-height: 1;">
			<span class="mr-1"><strong>{{channel.appointments[0].startDate | localizedDate:'HH:mm:ss'}}</strong></span>
			<span class="mr-1"><strong>-</strong></span>
			<span class=""><strong>{{channel.appointments[0].endDate | localizedDate:'HH:mm:ss'}}</strong></span>
		</div>
	</ng-container>
	<div class="d-flex flex-row p-2">
		<div class="avatar-lg profileImgContainer rounded-circle shadow-sm my-auto" [ngStyle]="{
					'background-image':
					'url(' +
					(channel.logo?.url ?? './assets/images/default-chat-bubbles-group.png') +
					')'
				}" [ngClass]="{
					online: (isOnline$ | async) == true,
					offline: (isOnline$ | async) == false
				}"></div>
		<div class="d-flex flex-column my-auto pl-2 channel-title-container">
			<h5 class="mb-1">{{channel.name}}</h5>
			<ng-container *ngIf="patientsWaiting$ | async as patientsWaiting">
				<ng-container *ngIf="patientsWaiting.length > 0">
					<div class="d-flex flex-row justify-content-between"
						*ngIf="channel.channelType == ChannelType.Consultation">
						<span class="text-18" [ngClass]="{
						'text-success': patientsWaiting[0].userMicChecked,
						'text-danger': !patientsWaiting[0].userMicChecked
					}">
							<i class="i-Microphone-3 mr-2"></i>
							<span class="text-26" style="line-height: 1;"
								*ngIf="patientsWaiting[0].userMicChecked">✔</span>
							<span class="text-26" style="line-height: 1;"
								*ngIf="!patientsWaiting[0].userMicChecked"><strong>&times;</strong></span>
						</span>
						<span class="text-18" [ngClass]="{
					'text-success': patientsWaiting[0].userCameraChecked,
					'text-danger': !patientsWaiting[0].userCameraChecked
				}">
							<i class="i-Male-21 mr-2"></i>
							<span class="text-26" style="line-height: 1;"
								*ngIf="patientsWaiting[0].userCameraChecked">✔</span>
							<span class="text-26" style="line-height: 1;"
								*ngIf="!patientsWaiting[0].userCameraChecked"><strong>&times;</strong></span>
						</span>
					</div>
					<div class="text-14">
						<span class="mr-1">{{ 'JoinedAt' | translate }}</span>
						<strong>
							<cd-timer [ngClass]="{
					'text-success': secondsSincePatientEntered < 300,
					'text-warning': secondsSincePatientEntered > 300 && secondsSincePatientEntered < 3600,
					'text-danger': secondsSincePatientEntered > 3600
				}" [startTime]="secondsSincePatientEntered" format="hms">
							</cd-timer>
						</strong>
						<!-- {{channel.patientsWaiting[0].lastEnterDate | localizedDate:'HH:mm:ss'}} -->
					</div>
				</ng-container>
				<ng-container *ngIf="(patientsWaiting$ | async)?.length == 0">
					<div class="d-flex flex-row justify-content-between"
						*ngIf="channel.channelType == ChannelType.Consultation">
						{{'patientIsNotInWaitingRoom' | translate}}
					</div>
				</ng-container>
			</ng-container>
		</div>
	</div>
	<div class="d-flex flex-row justify-content-center p-2">
		<button class="btn btn-icon btn-rounded btn-success mr-2 text-20" placement="top"
			[ngbTooltip]="'CallPatient' | translate"
			*ngIf="(patientsWaiting$ | async)?.length > 0 && !statusService.hasOnGoingCall && !channel.videoRoom"
			(click)="startVideoCall()">
			<i class="i-Telephone"></i>
		</button>
		<button class="btn btn-icon btn-rounded btn-success mr-2 text-20" placement="top"
			[ngbTooltip]="'JoinCall' | translate"
			*ngIf="(patientsWaiting$ | async)?.length > 0 && !statusService.hasOnGoingCall && channel.videoRoom"
			(click)="statusService.answerVideoCall(channel.id)">
			<i class="i-Add-User"></i>
		</button>
		<button class="btn btn-icon btn-rounded btn-outline-secondary mr-2 text-16 position-relative" placement="top"
			[ngbTooltip]="'ChatWithPatient' | translate" (click)="openWindow(AttendanceWindowType.Chat)">
			<i class="i-Speach-Bubbles"></i>
			<span *ngIf="unreadMessageCount$ | async as unreadMessageCount" class="message-count">
				<span *ngIf="unreadMessageCount > 0">
					{{unreadMessageCount}}
				</span>
			</span>
		</button>
		<button class="btn btn-icon btn-rounded btn-outline-info mr-2 text-16" placement="top"
			[ngbTooltip]="'SharedFiles' | translate" (click)="openWindow(AttendanceWindowType.Documents)">
			<i class="i-Files"></i>
		</button>
		<button class="btn btn-icon btn-rounded btn-outline-warning mr-2 text-16" placement="top"
			[ngbTooltip]="'MeetingNotes' | translate" (click)="openWindow(AttendanceWindowType.Notes)">
			<i class="i-Pen-5"></i>
		</button>
		<button class="btn btn-icon btn-rounded btn-outline-warning text-16" placement="top"
			[ngbTooltip]="'TriageAnswer' | translate" (click)="openWindow(AttendanceWindowType.TriageAnswer)">
			<i class="i-Speach-Bubble-Asking"></i>
		</button>
	</div>
</div>
<ng-template #launcherModal let-modal>
	<app-modal (onClose)="modal.dismiss($event)" (onConfirmClose)="modal.close($event)" [showDefaultFooter]="false">
		<div modal-header>{{ 'LaunchCall' | translate }}</div>
		<div modal-body>
			<app-attendance-call-launcher [channelId]="channel.id" (onTypeChosen)="onCallTypeChosen($event)">
			</app-attendance-call-launcher>
		</div>
	</app-modal>
</ng-template>