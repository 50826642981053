<div>
	<div class="chat-content rtl-ps-none" *ngIf="chatCollection" [perfectScrollbar]="{ suppressScrollX: true }"
		ngxInfiniteScroller strategy="scrollingToTop" initialScrollPosition="BOTTOM" (onScrollUp)="onScroll()">
		<span *ngIf="loadingUp" class="loader-bubble loader-bubble-info ml-4 mt-n2"></span>
		<div *ngFor="let message of chatCollection">
			<app-chat-message [message]="message"></app-chat-message>
		</div>
		<div *ngIf="readOnly" class="align-items-center">
			<p class="alert-card mb-3 mx-auto p-2 px-5 d-flex alert alert-danger" style="width: fit-content;">
				<i class="i-Remove-User mr-3" style="font-size: 1rem;"></i>{{currentUser?.displayName}}
				{{'DoesNotHaveAccessToThisChannelAnymore' | translate}}
			</p>
		</div>
	</div>
	<div *ngIf="!!whoIsTyping">
		<!-- to do label like string format -->
		<p class="m-1 ml-4 position-relative">{{whoIsTyping}} {{'IsTyping' | translate }} <span
				class=" loader-bubble loader-bubble-info ml-4 mt-n2"></span></p>
	</div>

	<div class="pl-3 pr-3 pt-3 pb-3 box-shadow-1 chat-input-area"
		*ngIf="!archiveMode && chatCollection && !loading && !readOnly">
		<form [formGroup]="form" class="inputForm" (ngSubmit)="sendMessage($event)" (keyup.enter)="sendMessage($event)">
			<div class="form-group">
				<textarea class="form-control form-control-rounded" formControlName="content"
					placeholder="{{ 'TypeYourMessage' | translate }}" cols="30" rows="3"></textarea>
			</div>
			<div class="d-flex">
				<div class="flex-grow-1"></div>
				<btn-loading [loading]="loading" btnClass="btn btn-icon btn-rounded btn-primary mr-2" title="{{ 'SendMessage' | translate }}">
					<i class="i-Paper-Plane"></i>
				</btn-loading>
				<input style="display: none" type="file" (change)="onFileChanged($event)" #fileInput
					accept="application/msword, application/vnd.ms-excel, application/vnd.ms-powerpoint, text/plain, application/pdf, image/*, video/*" />
				<button class="btn btn-icon btn-rounded btn-outline-info" type="button" (click)="fileInput.click()" title="{{ 'SendFile' | translate }}">
					<i class="i-Add-File"></i>
				</button>
			</div>
		</form>
	</div>

	<app-local-loader [enabled]="loading"></app-local-loader>

	<div class="app-inro-circle" *ngIf="!chatCollection && !loading && archiveMode">
		<div class="border rounded-circle big-bubble  bg-light"
			[@animate]="{value:'*',params:{scale:'.2',delay:'400ms', duration: '400ms'}}">
			<i class="i-Folder-Zip"></i>
		</div>
		<p class="text-16" [@animate]="{value:'*',params:{y:'120px',delay:'600ms', duration: '400ms'}}">{{
			'ReviewYourArchivedFiles' | translate }}</p>
	</div>

	<div class="app-inro-circle" *ngIf="!chatCollection && !loading && !archiveMode">
		<div class="border rounded-circle big-bubble"
			[@animate]="{value:'*',params:{scale:'.2',delay:'400ms', duration: '400ms'}}">
			<i class="i-Speach-Bubbles"></i>
		</div>
		<p class="text-16" [@animate]="{value:'*',params:{y:'120px',delay:'600ms', duration: '400ms'}}">{{
			'SelectAChannelAndTypeYourMessage' | translate }}</p>
	</div>
</div>