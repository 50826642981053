import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { AttendanceWindowType } from '@app/01.global/helpers/attendance-window-type.enum';
import { ChannelType } from '@app/01.global/helpers/channel-type';
import { IChannel } from '@app/01.global/interfaces/iChannel';
import { IPatientWaiting } from '@app/01.global/interfaces/iPatientWaiting';
import { AttendanceStatusService } from '@app/01.global/status-services/attendance-status.service';
import { NgbModal, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { differenceInSeconds } from 'date-fns';
import { Observable } from 'rxjs';

@Component({
	selector: 'app-doctor-sidebar-card',
	templateUrl: './doctor-sidebar-card.component.html',
	styleUrls: ['./doctor-sidebar-card.component.scss'],
})
export class DoctorSidebarCardComponent implements OnInit {
	ChannelType = ChannelType;
	AttendanceWindowType = AttendanceWindowType;
	hasPatientWaiting: boolean = false;
	unreadMessageCount$: Observable<number>;
	channel: IChannel;
	isOnline$: Observable<boolean>;
	patientsWaiting$: Observable<IPatientWaiting[]>;

	@Input() channelId;

	@ViewChild('launcherModal') launcherModal: NgbModal;
	launcherModalResult: NgbModalRef;
	secondsSincePatientEntered: number = 0;
	constructor(
		public statusService: AttendanceStatusService,
		private modalService: NgbModal
	) { }

	ngOnInit(): void {
		this.statusService
			.getChannel(this.channelId)
			.subscribe((c: IChannel) => {
				this.channel = c;
				if (
					this.channel.patientsWaiting &&
					this.channel.patientsWaiting.length > 0
				) {
					this.hasPatientWaiting = true;
					this.secondsSincePatientEntered = differenceInSeconds(
						new Date(),
						new Date(this.channel.patientsWaiting[0].lastEnterDate)
					);
				}
			});

		this.unreadMessageCount$ =
			this.statusService.getChannelUnreadMessageCount$(this.channelId);

		this.isOnline$ =
			this.statusService.getChannelOnline$(
				this.channelId
			);
		this.patientsWaiting$ =
			this.statusService.getPatientsWaiting$(
				this.channelId
			);
	}

	openWindow(type: AttendanceWindowType) {
		this.statusService.openWindow(this.channel.id, type);
	}

	startVideoCall() {
		this.launcherModalResult = this.modalService.open(this.launcherModal, {
			size: 'xl',
		});
	}

	onCallTypeChosen() {
		this.launcherModalResult.close();
	}
}
