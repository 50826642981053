import { Injectable } from '@angular/core';
import { Factory } from '@app/table-list/helpers/table-list-factory';
import { TableListMode } from '@app/table-list/models/table-list';
import { TableListActionButton } from '@app/table-list/models/table-list-action-button';
import { TableListFilters } from '@app/table-list/models/table-list-filters';
import { TableListRow } from '@app/table-list/models/table-list-row';
import { TranslateService } from '@ngx-translate/core';
import { BehaviorSubject } from 'rxjs';
import { RouteList } from '../helpers/route-enum';
import { AppDatatable, DatatableSettings } from '../models/data-table';
import { DatatableFilters } from '../models/DatatableFilters';
import { AuthenticationService } from '../services/authentication.service';

@Injectable({
	providedIn: 'root',
})
export class DatatableFactory implements Factory {
	constructor(
		private authService: AuthenticationService,
		private translateService: TranslateService
	) {}

	public build(
		settings: DatatableSettings,
		filters: DatatableFilters = {
			pageSize: 10,
			currentPage: 0,
		}
	): AppDatatable {
		settings.showAdd =
			settings.showAdd && settings.rolesAllowedToAdd.length > 0
				? this.authService.hasSomeRole(...settings.rolesAllowedToAdd)
				: settings.showAdd;
		settings.showInviteEmployeeButton =
			settings.showInviteEmployeeButton &&
			settings.rolesAllowedToInviteEmployee.length > 0
				? this.authService.hasSomeRole(
						...settings.rolesAllowedToInviteEmployee
				  )
				: settings.showInviteEmployeeButton;
		settings.showSyncButton =
			settings.showSyncButton && settings.rolesAllowedToSync.length > 0
				? this.authService.hasSomeRole(...settings.rolesAllowedToSync)
				: settings.showSyncButton;

		if (settings.showAdd) {
			var btn: TableListActionButton = {
				order: 0,
				loading: false,
				text: 'Add',
				route: RouteList._routeCrudEnum.Add,
				showText:
					settings.mode == TableListMode.Datatable ? false : true,
				icon:
					settings.mode == TableListMode.Datatable
						? 'i-Add-File'
						: '',
				btnClass:
					settings.mode == TableListMode.Datatable
						? 'btn btn-rounded btn-icon btn-outline-primary text-20'
						: 'btn btn-lg btn-rounded btn-secondary text-20 px-5',
			};
			settings.actionButtons.push(btn);
		}

		if (settings.showInviteEmployeeButton) {
			var btn: TableListActionButton = {
				order: 1,
				loading: false,
				text: 'InviteEmployee',
				route: RouteList._routeCrudEnum.InviteEmployee,
				showText: true,
				btnClass: 'btn btn-rounded btn-info text-20',
			};
			settings.actionButtons.push(btn);
		}

		if (settings.showSyncButton) {
			var btn: TableListActionButton = {
				order: 2,
				loading: true,
				text: 'Sync',
				icon: 'i-Share-on-Cloud',
				loadingText: 'Syncing',
				route: RouteList._routeCrudEnum.Sync,
				showText: false,
				btnClass: 'btn btn-rounded btn-icon btn-warning text-20',
			};
			settings.actionButtons.push(btn);
		}

		if (settings.messages && !settings.messages.titleMessage) {
			settings.messages.titleMessage = settings.basePath;
		}
		if (settings.messages && !settings.messages.emptyMessage) {
			settings.messages.emptyMessage = 'NoResults';
		}
		if (settings.messages && !settings.messages.searchboxPlaceholder) {
			settings.messages.searchboxPlaceholder = 'Search';
		}
		if (settings.messages && !settings.messages.selectedMessage) {
			settings.messages.selectedMessage = 'Selected';
		}
		if (settings.messages && !settings.messages.totalMessage) {
			settings.messages.totalMessage = 'TotalItems';
		}
		if (!settings.messages) {
			settings.messages = {
				titleMessage: settings.basePath,
				emptyMessage: 'NoResults',
				searchboxPlaceholder: 'Search',
				selectedMessage: 'Selected',
				totalMessage: 'TotalItems',
			};
		}

		let loadingSubject = new BehaviorSubject<boolean>(true);
		let rowsSubject = new BehaviorSubject<TableListRow[]>([]);
		let totalItemsSubject = new BehaviorSubject<number>(0);
		let filtersSubject = new BehaviorSubject<TableListFilters>(filters);
		let settingsSubject = new BehaviorSubject<DatatableSettings>(settings);

		var table = new AppDatatable();
		table.settingsSubject = settingsSubject;
		table.settingsObservable = settingsSubject.asObservable();
		table.loadingSubject = loadingSubject;
		table.loadingObservable = loadingSubject.asObservable();
		table.filtersSubject = filtersSubject;
		table.filtersObservable = filtersSubject.asObservable();
		table.rowsSubject = rowsSubject;
		table.rowsObservable = rowsSubject.asObservable();
		table.totalItemsSubject = totalItemsSubject;
		table.totalItemsObservable = totalItemsSubject.asObservable();
		return table;
	}

	// create(type: DataTableMode, details?: any, form?: any): AppDatatable {
	// 	switch (type) {
	// 		case DataTableMode.short:
	// 			return this.createShortDatatable(details, form);
	// 		case DataTableMode.list:
	// 			return this.createListGridDatatable(details, form);
	// 		case DataTableMode.roundList:
	// 			return this.createRoundListDatatable(details, form);
	// 			return this.createListGridDatatable(details, form);
	// 		case DataTableMode.cardList:
	// 			return this.createCardListDatatable();
	// 		case DataTableMode.fullscreen:
	// 			return this.createFullscreenDatatable(details, form);
	// 		case DataTableMode.paging:
	// 			return this.createPagingDatatable(DataTableMode.paging, details, form);
	// 		case DataTableMode.filter:
	// 			return this.createPagingDatatable(DataTableMode.filter, details, form);
	// 		default:
	// 			return this.createPagingDatatable(DataTableMode.paging, details, form);
	// 	}
	// }

	// private createListGridDatatable(details?: any, form?: any): AppDatatable {
	// 	var table: AppDatatable = new AppDatatable();
	// 	table.externalPaging = true;
	// 	table.form = form;
	// 	table.details = details;
	// 	table.addButton = false;
	// 	table.detailsButton = false;
	// 	table.editButton = false;
	// 	table.deleteButton = false;
	// 	table.acceptButton = false;
	// 	table.rescheduleButton = false;
	// 	table.rejectButton = false;
	// 	table.filters = {
	// 		pageSize: 6,
	// 		totalItems: 0,
	// 		totalPages: 0,
	// 		currentPage: 0,
	// 	};
	// 	table.listviewMode = 'grid';
	// 	table.renderActionsInModal = false;
	// 	table.mode = DataTableMode.list;

	// 	table.messages = {
	// 		// Message to show when array is presented
	// 		// but contains no values
	// 		emptyMessage: this.translateService.instant('Empty'),

	// 		// Footer total message
	// 		totalMessage: this.translateService.instant('Total'),

	// 		// Footer selected message
	// 		selectedMessage: this.translateService.instant('Selected'),
	// 		searchboxPlaceholder: this.translateService.instant(
	// 			'searchboxPlaceholder'
	// 		),
	// 	};

	// 	return table;
	// }
	// private createRoundListDatatable(details?: any, form?: any): AppDatatable {
	// 	var table: AppDatatable = new AppDatatable();
	// 	table.externalPaging = true;
	// 	table.form = form;
	// 	table.details = details;
	// 	table.addButton = false;
	// 	table.detailsButton = false;
	// 	table.editButton = false;
	// 	table.deleteButton = false;
	// 	table.acceptButton = false;
	// 	table.rescheduleButton = false;
	// 	table.rejectButton = false;
	// 	table.filters = {
	// 		pageSize: 9,
	// 		totalItems: 0,
	// 		totalPages: 0,
	// 		currentPage: 0,
	// 	};
	// 	table.renderActionsInModal = false;
	// 	table.mode = DataTableMode.roundList;

	// 	table.messages = {
	// 		// Message to show when array is presented
	// 		// but contains no values
	// 		emptyMessage: this.translateService.instant('Empty'),

	// 		// Footer total message
	// 		totalMessage: this.translateService.instant('Total'),

	// 		// Footer selected message
	// 		selectedMessage: this.translateService.instant('Selected'),
	// 		searchboxPlaceholder: this.translateService.instant(
	// 			'searchboxPlaceholder'
	// 		),
	// 	};

	// 	return table;
	// }
	// private createCardListDatatable(): AppDatatable {
	// 	var table: AppDatatable = new AppDatatable();
	// 	table.externalPaging = true;
	// 	table.addButton = false;
	// 	table.detailsButton = false;
	// 	table.editButton = false;
	// 	table.deleteButton = false;
	// 	table.acceptButton = false;
	// 	table.rescheduleButton = false;
	// 	table.rejectButton = false;
	// 	table.filters = {
	// 		pageSize: 8,
	// 		totalItems: 0,
	// 		totalPages: 0,
	// 		currentPage: 0,
	// 	};
	// 	table.listviewMode = 'grid';
	// 	table.renderActionsInModal = false;
	// 	table.mode = DataTableMode.cardList;

	// 	table.messages = {
	// 		// Message to show when array is presented
	// 		// but contains no values
	// 		emptyMessage: this.translateService.instant('Empty'),

	// 		// Footer total message
	// 		totalMessage: this.translateService.instant('Total'),

	// 		// Footer selected message
	// 		selectedMessage: this.translateService.instant('Selected'),
	// 		searchboxPlaceholder: this.translateService.instant(
	// 			'searchboxPlaceholder'
	// 		),
	// 	};

	// 	return table;
	// }
	// private createFullscreenDatatable(details?: any, form?: any): AppDatatable {
	// 	var table: AppDatatable = new AppDatatable();
	// 	table.form = form;
	// 	table.details = details;
	// 	table.addButton = false;
	// 	table.detailsButton = false;
	// 	table.editButton = false;
	// 	table.deleteButton = false;
	// 	table.renderActionsInModal = false;
	// 	table.mode = DataTableMode.fullscreen;

	// 	table.messages = {
	// 		// Message to show when array is presented
	// 		// but contains no values
	// 		emptyMessage: this.translateService.instant('Empty'),

	// 		// Footer total message
	// 		totalMessage: this.translateService.instant('Total'),

	// 		// Footer selected message
	// 		selectedMessage: this.translateService.instant('Selected'),
	// 		searchboxPlaceholder: this.translateService.instant(
	// 			'searchboxPlaceholder'
	// 		),
	// 	};

	// 	table.filters = {
	// 		pageSize: 50,
	// 		totalItems: 0,
	// 		totalPages: 0,
	// 		currentPage: 0,
	// 	};

	// 	return table;
	// }
	// private createPagingDatatable(mode: DataTableMode, details?: any, form?: any): AppDatatable {
	// 	var table: AppDatatable = new AppDatatable();
	// 	table.externalPaging = true;
	// 	table.form = form;
	// 	table.details = details;
	// 	table.addButton = false;
	// 	table.detailsButton = false;
	// 	table.editButton = false;
	// 	table.deleteButton = false;
	// 	table.acceptButton = false;
	// 	table.rescheduleButton = false;
	// 	table.rejectButton = false;
	// 	table.renderActionsInModal = false;
	// 	table.mode = mode;

	// 	table.messages = {
	// 		// Message to show when array is presented
	// 		// but contains no values
	// 		emptyMessage: this.translateService.instant('Empty'),

	// 		// Footer total message
	// 		totalMessage: this.translateService.instant('Total'),

	// 		// Footer selected message
	// 		selectedMessage: this.translateService.instant('Selected'),

	// 		// Searchbox placeholder
	// 		searchboxPlaceholder: this.translateService.instant(
	// 			'searchboxPlaceholder'
	// 		),
	// 	};

	// 	table.filters = {
	// 		pageSize: 10,
	// 		totalItems: 0,
	// 		totalPages: 0,
	// 		currentPage: 0,
	// 	};

	// 	return table;
	// }
	// private createShortDatatable(details?: any, form?: any): AppDatatable {
	// 	var table: AppDatatable = new AppDatatable();
	// 	table.form = form;
	// 	table.details = details;
	// 	table.addButton = false;
	// 	table.detailsButton = false;
	// 	table.editButton = false;
	// 	table.deleteButton = false;
	// 	table.acceptButton = false;
	// 	table.rescheduleButton = false;
	// 	table.rejectButton = false;
	// 	table.renderActionsInModal = true;
	// 	table.mode = DataTableMode.short;
	// 	table.headerHeight = 0;
	// 	table.footerHeight = 0;
	// 	table.messages = {
	// 		emptyMessage: '',
	// 		totalMessage: '',
	// 		selectedMessage: '',
	// 		searchboxPlaceholder: '',
	// 	};

	// 	table.filters = {
	// 		pageSize: 10,
	// 		totalItems: 0,
	// 		totalPages: 0,
	// 		currentPage: 0,
	// 	};

	// 	return table;
	// }
}
