<div class="sticky d-flex bg-secondary box-shadow-2 text-white badge-top-container" (click)="onClick()">
	<div class="profile-picture profileImgContainer" [ngStyle]="{
		'background-image':
			'url(' +
			(channel?.logo?.url ?? './assets/images/default-chat-bubbles-group.png') +
			')'
	}"></div>
	<span class="badge badge-dark p-0">
		<i *ngIf="type == AttendanceWindowType.Notes" class="i-Pen-5 text-16 p-0"></i>
		<i *ngIf="type == AttendanceWindowType.Documents" class="i-Files text-16 p-0"></i>
		<i *ngIf="type == AttendanceWindowType.Booking" class="i-Calendar-4 text-16 p-0"></i>
		<i *ngIf="type == AttendanceWindowType.Chat && channel?.unReadMessagesCount == 0"
			class="i-Speach-Bubbles text-16 p-0"></i>
		<span *ngIf="type == AttendanceWindowType.Chat && channel?.unReadMessagesCount > 0" class="text-16">
			{{channel?.unReadMessagesCount}}
		</span>
		<!-- TODO rever se o dto traz isto -->
	</span>
	<span class="sticky-name text-14">{{title}}</span>
</div>