import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SharedModule } from '@app/00.shared/shared.module';
import { StickyBallComponent } from './sticky-balls/sticky-ball.component';
import { ShoutBoxComponent } from './shout-box/shout-box.component';
import { MessengerComponent } from './messenger/messenger.component';
import { ConsultationModule } from '@app/consultation/consultation.module';

var components = [StickyBallComponent, ShoutBoxComponent, MessengerComponent];

@NgModule({
	imports: [CommonModule, SharedModule, ConsultationModule],
	declarations: components,
	exports: components,
})
export class MessengerModule {}
