import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { AttendanceWindowType } from '@app/01.global/helpers/attendance-window-type.enum';
import { RouteList } from '@app/01.global/helpers/route-enum';
import { IAppointment } from '@app/01.global/interfaces/IAppointment';
import { IChannel } from '@app/01.global/interfaces/iChannel';
import { AttendanceStatusService } from '@app/01.global/status-services/attendance-status.service';
import { NgbModal, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { Observable } from 'rxjs';

@Component({
	selector: 'app-doctor-sidebar',
	templateUrl: './doctor-sidebar.component.html',
	styleUrls: ['./doctor-sidebar.component.scss'],
})
export class DoctorSidebarComponent implements OnInit {
	channels$: Observable<IChannel[]>;

	RouteList = RouteList;

	@ViewChild('devicesModal') devicesModal: NgbModalRef;

	constructor(
		private statusService: AttendanceStatusService,
		public modalService: NgbModal
	) {}

	ngOnInit(): void {
		this.channels$ = this.statusService.doctorChannels$;
	}

	openDevicesModal() {
		var modalRef = this.modalService.open(this.devicesModal, {
			ariaLabelledBy: 'modal-basic-title',
			size: 'lg',
			backdrop: 'static',
			modalDialogClass: 'devices-modal',
		});

		modalRef.dismissed.subscribe((reason) => {
			var doctorCameraChecked = reason.cameraStatus;
			var doctorMicChecked = reason.micStatus;
		});
	}

	leave() {
		this.statusService.reset();
	}
}
