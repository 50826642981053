import { Injectable } from '@angular/core';
import { RouteList } from '@app/01.global/helpers/route-enum';
import { DatatableFilters } from '@app/01.global/models/DatatableFilters';
import { catchError, filter, forkJoin, map, Observable, of, tap } from 'rxjs';
import { BehaviorSubject } from 'rxjs';
import { TableList, TableListMode, TableListSettings } from '../models/table-list';
import { TableListActionButtonClicked, TableListActionButton } from '../models/table-list-action-button';
import { TableListFilters } from '../models/table-list-filters';
import { TableListRow } from '../models/table-list-row';

@Injectable({
	providedIn: 'root'
})
export class TableListStatusService {
	constructor(
	) { }

	private activeTables = new Map<string, TableList>();
	private activeFilters = new Map<string, TableListFilters>();
	private activeFiltersKey = "table-active-filters";

	private tableListButtonClickedSubject = new BehaviorSubject<TableListActionButtonClicked>(null);

	tableListButtonClickedObs = this.tableListButtonClickedSubject.asObservable();

	addToPage(table: TableList) {

		var identifier = table.settingsSubject.getValue().identifier;

		var updatedFilters: TableListFilters = table.filtersSubject?.getValue();

		var activeFilter: TableListFilters = this.activeFilters.get(identifier);
		if (activeFilter) {
			updatedFilters = activeFilter;
		}
		else {
			var storageItem = localStorage.getItem(this.activeFiltersKey + '-' + identifier);
			if (storageItem) {
				updatedFilters = JSON.parse(storageItem);
			}
		}

		this.setFilters(identifier, updatedFilters);
		this.activeTables.set(identifier, table);

		forkJoin([this.read(identifier), this.readCount(identifier)])
			.subscribe(([endedRead, endedReadCount]: [boolean, boolean]) => {
				this.activeTables.get(identifier).loadingSubject.next(false);
			});
	}

	removeFromPage(identifier: string): boolean {
		this.activeFilters.delete(identifier);
		this.setFilters(identifier, null);
		return this.activeTables.delete(identifier);
	}

	updateSettings(identifier: string, settings: TableListSettings) {
		this.findInPage(identifier).settingsSubject.next(settings);
	}

	getSettingsObservable(identifier: string): Observable<TableListSettings> {
		return this.findInPage(identifier)?.settingsObservable;
	}

	getFiltersObservable(identifier: string): Observable<TableListFilters> {
		return this.findInPage(identifier)?.filtersObservable;
	}

	getLoadingObservable(identifier: string): Observable<boolean> {
		return this.findInPage(identifier)?.loadingObservable;
	}

	getRowsObservable(identifier: string): Observable<TableListRow[]> {
		return this.findInPage(identifier).rowsObservable;
	}

	search(identifier: string, term: string) {
		let table = this.findInPage(identifier);
		table.loadingSubject.next(true);
		table.rowsSubject.next([]);

		let filters = table.filtersSubject.getValue();
		filters.searchTerm = term;
		filters.currentPage = 0;

		this.setFilters(identifier, filters);
		forkJoin([this.read(identifier), this.readCount(identifier)])
			.subscribe(([endedRead, endedReadCount]: [boolean, boolean]) => {
				table.loadingSubject.next(false);
			});
	}

	filterAndReset(identifier: string, filters: DatatableFilters) {
		let table = this.findInPage(identifier);
		table.loadingSubject.next(true);
		table.rowsSubject.next([]);

		filters.currentPage = 0;
		filters.searchTerm = '';
		this.setFilters(identifier, filters);

		forkJoin([this.read(identifier), this.readCount(identifier)])
			.subscribe(([endedRead, endedReadCount]: [boolean, boolean]) => {
				table.loadingSubject.next(false);
			});
	}

	readPage(identifier: string, pageNumber: number) {
		this.readPageInternal(identifier, pageNumber, false);
	}

	readPageAndUpdateCount(identifier: string, pageNumber: number) {
		this.readPageInternal(identifier, pageNumber, true);
	}

	private readPageInternal(identifier: string, pageNumber: number, withCount: boolean) {
		let table = this.findInPage(identifier);
		if (table) {
			table.loadingSubject.next(true);

			let filters = table.filtersSubject.getValue();
			filters.currentPage = pageNumber;

			this.setFilters(identifier, filters);

			if (withCount) {
				forkJoin([this.read(identifier), this.readCount(identifier)])
					.subscribe(([endedRead, endedReadCount]: [boolean, boolean]) => {
						table.loadingSubject.next(false);
					});
			} else {
				this.read(identifier)
					.subscribe((endedRead: boolean) => {
						table.loadingSubject.next(false);
					});
			}
		}

	}

	readDetails(identifier: string, id: number): Observable<any> {
		let table = this.findInPage(identifier);
		table.loadingSubject.next(true);

		var activeTableList = this.findInPage(identifier);

		return activeTableList.service.read(id)
			.pipe(tap((entity: any) => {
				table.loadingSubject.next(false);
			}));
	}

	readCount(identifier: string): Observable<boolean> {
		var activeTableList = this.findInPage(identifier);
		let filters = activeTableList.filtersSubject.getValue();

		return activeTableList.service.readCount(filters)
			.pipe(map((response: number) => {
				filters.totalItems = response;
				filters.totalPages = response / filters.pageSize
				activeTableList.filtersSubject.next(filters);
				activeTableList.totalItemsSubject.next(response);

				return true;
			}),
				catchError(error => {
					console.error(error);
					return of(true);
				}));
	}

	setTableListButtonClicked(btn?: TableListActionButton, tableIdentifier?: string, rowId?: number) {
		if (btn) {
			var activeTableList = this.findInPage(tableIdentifier);
			var settings = activeTableList.settingsSubject.getValue();
			var renderInModal = false;
			// var passThrough = false; // TBD gerir as ações default no modulo e passar as restantes para o projecto.
			switch (btn.route) {
				case RouteList._routeCrudEnum.Details:
					if (settings.showDetailsInModal) {
						renderInModal = true;
					}
					else {
						renderInModal = false;
					}
					break;
				case RouteList._routeCrudEnum.Add:
					if (settings.showAddInModal) {
						renderInModal = true;
					}
					else {
						renderInModal = false;
					}
					break;
				case RouteList._routeCrudEnum.Edit:
					if (settings.showUpdateInModal) {
						renderInModal = true;
					}
					else {
						renderInModal = false;
					}
					break;
				case RouteList._routeCrudEnum.Delete:
					if (settings.showDeleteInModal) {
						renderInModal = true;
					}
					else {
						renderInModal = false;
					}
					break;

				default:
					// passThrough = true; // TBD gerir as ações default no modulo e passar as restantes para o projecto.
					break;
			}

			var route = RouteList.joinRoutes([settings.basePath, btn.route]);
			var titleMessage = settings.messages?.titleMessage;
			if (titleMessage) {
				var title = titleMessage?.charAt(0).toUpperCase() + titleMessage?.slice(1);
			} else {
				var title = (settings.basePath ? settings.basePath?.charAt(0).toUpperCase() + settings.basePath?.slice(1) : '') + btn.route?.charAt(0).toUpperCase() + btn.route?.slice(1);
			}

			this.tableListButtonClickedSubject.next({
				row: this.activeTables.get(tableIdentifier).rowsSubject.getValue().find(r => r.id == rowId),
				identifier: tableIdentifier,
				btnRoute: btn.route,
				joinedRoute: route,
				modalTitle: title,
				renderInModal: renderInModal,
				// passThrough: passThrough // TBD gerir as ações default no modulo e passar as restantes para o projecto.
			});
		}
		else {
			this.tableListButtonClickedSubject.next(null);
		}
	}

	private read(identifier: string): Observable<boolean> {

		var activeTableList = this.findInPage(identifier);
		let filters = activeTableList.filtersSubject.getValue();

		return activeTableList.service.readAll(filters)
			.pipe(map((response: any[]) => {
				let rows = activeTableList.mapper.doMapList(
					response, activeTableList.pageRoute
				);

				var settings = activeTableList.settingsSubject.getValue();
				if (settings.mode == TableListMode.DatatableCardContact
					|| settings.mode == TableListMode.DatatableTileCard
					|| settings.mode == TableListMode.DatatableCardImage
					|| settings.mode == TableListMode.DatatableCircleCard
					|| settings.mode == TableListMode.DatatableEventCard
					|| settings.mode == TableListMode.DatatableMiniCard) {

					var currentRows: TableListRow[] = activeTableList.rowsSubject.getValue();

					if (filters.currentPage == 0) {
						activeTableList.rowsSubject.next(rows);
					}
					else {
						currentRows.push(...rows);
					}
				}
				else {
					activeTableList.rowsSubject.next(rows);
				}
				return true
			}),
				catchError(error => {
					console.error(error);
					return of(true);
				}));
	}

	private findInPage(identifier: string): TableList {
		return this.activeTables.get(identifier);
	}

	private setFilters(identifier: string, filters: TableListFilters) {
		this.activeFilters.set(identifier, filters);
		if (filters) {
			localStorage.setItem(this.activeFiltersKey + '-' + identifier, JSON.stringify(filters));
		}
		else {
			localStorage.removeItem(this.activeFiltersKey + '-' + identifier);
		}
		let table = this.findInPage(identifier);
		if (table) {
			table.filtersSubject.next(filters);
		}
	}
}

