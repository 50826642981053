<ng-container>
	<div class="d-flex flex-column h-100">
		<div class="sidebar-title-container w-100 d-flex flex-column justify-content-center p-2 my-2 shadow">
			<h4 class="text-primary mx-auto mb-0">{{ 'PatientQueue' | translate }}</h4>
		</div>
		<div class="patients-waiting-container" [perfectScrollbar]="{ suppressScrollX: true }">
			<ng-container *ngFor="let channel of channels$ | async">
				<app-doctor-sidebar-card [channelId]="channel.id">
				</app-doctor-sidebar-card>
			</ng-container>
		</div>
		<div class="flex-grow-1"></div>
		<div class="sidebar-footer w-100 d-flex flex-column">
			<button class="btn btn-secondary w-80 mx-auto mb-3" (click)="openDevicesModal()">
				{{ 'CheckDevices' | translate }}
			</button>
			<button class="btn btn-danger w-80 mx-auto" [routerLink]="RouteList.dashboard" (mousedown)="leave()">
				{{ 'Back' | translate }}
			</button>
		</div>
	</div>
</ng-container>
<ng-template #devicesModal let-modal>
	<app-attendance-devices-check [modalIsOpen]="openedDevicesModal"></app-attendance-devices-check>
</ng-template>