import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { DatatableFilters } from '@app/01.global/models/DatatableFilters';
import { IApiResponse } from '../interfaces/helpers/iResponseWrapper';
import '@app/01.global/helpers/observable-extensions';
import { ITableListService } from '../../table-list/services/iservice';
import { Observable } from 'rxjs';
import { AppointmentStatusEnum } from '../helpers/appointment-satus-enum';
import { AuthenticationService } from './authentication.service';
import { RoleEnum } from '../helpers/role-enum';
import { IAppointment } from '../interfaces/IAppointment';
import { BaseService } from './base.service';
import { IUser } from '../interfaces/IUser';

@Injectable({
	providedIn: 'root',
})
export class AppointmentService
	extends BaseService
	implements ITableListService
{
	ApiBaseURL = environment.ApiBaseURL;

	constructor(
		private http: HttpClient,
		private authenticationService: AuthenticationService
	) {
		super();
	}

	readAll(page: DatatableFilters): Observable<IAppointment[]> {
		let params = this.paginationToHttpParams(page);
		return this.http
			.get<IApiResponse>(`${this.ApiBaseURL}/Appointments`, {
				params,
			})
			.ToApiResult();
	}

	readCount(page: DatatableFilters): Observable<number> {
		let params = this.paginationToHttpParams(page);
		return this.http
			.get<IApiResponse>(`${this.ApiBaseURL}/Appointments/count`, {
				params,
			})
			.ToApiResult();
	}

	read(id: number): Observable<IAppointment> {
		return this.http
			.get<IApiResponse>(`${this.ApiBaseURL}/Appointments/${id}`)
			.ToApiResult();
	}

	readLastAppointmentsAsDoctor(
		page: DatatableFilters
	): Observable<IAppointment[]> {
		let params = this.paginationToHttpParams(page);
		return this.http
			.get<IApiResponse>(
				`${this.ApiBaseURL}/Appointments/lastAppointmentsAsDoctor`,
				{
					params,
				}
			)
			.ToApiResult();
	}

	readNextAppointmentsAsDoctor(
		page: DatatableFilters
	): Observable<IAppointment[]> {
		let params = this.paginationToHttpParams(page);
		return this.http
			.get<IApiResponse>(
				`${this.ApiBaseURL}/Appointments/nextAppointmentsAsDoctor`,
				{
					params,
				}
			)
			.ToApiResult();
	}
	readLastAppointmentsAsUser(
		page: DatatableFilters
	): Observable<IAppointment[]> {
		let params = this.paginationToHttpParams(page);
		return this.http
			.get<IApiResponse>(
				`${this.ApiBaseURL}/Appointments/lastAppointmentsAsUser`,
				{
					params,
				}
			)
			.ToApiResult();
	}
	readNextAppointmentsAsUser(
		page: DatatableFilters
	): Observable<IAppointment[]> {
		let params = this.paginationToHttpParams(page);
		return this.http
			.get<IApiResponse>(
				`${this.ApiBaseURL}/Appointments/nextAppointmentsAsUser`,
				{
					params,
				}
			)
			.ToApiResult();
	}

	create(appointment: IAppointment) {
		return this.http
			.post<IApiResponse>(`${this.ApiBaseURL}/Appointments/`, appointment)
			.ToApiResult();
	}

	update(previousAppointmentId: number, appointment: IAppointment) {
		return this.http
			.put<IApiResponse>(
				`${this.ApiBaseURL}/Appointments/` + previousAppointmentId,
				appointment
			)
			.ToApiResult();
	}

	delete(id: number) {
		throw new Error('Method not implemented.');
	}

	start(id: number) {
		return this.http
			.post<IApiResponse>(
				`${this.ApiBaseURL}/Appointments/start/${id}`,
				{}
			)
			.ToApiResult();
	}

	finish(id: number) {
		return this.http
			.post<IApiResponse>(
				`${this.ApiBaseURL}/Appointments/finish/${id}`,
				{}
			)
			.ToApiResult();
	}
}
