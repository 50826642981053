import { Component, Input, OnInit } from '@angular/core';
import { AttendanceWindowType } from '@app/01.global/helpers/attendance-window-type.enum';
import { IChannel } from '@app/01.global/interfaces/iChannel';
import { AttendanceStatusService } from '@app/01.global/status-services/attendance-status.service';
import { take } from 'rxjs';

@Component({
	selector: 'app-attendance-ball',
	templateUrl: './attendance-ball.component.html',
	styleUrls: ['./attendance-ball.component.scss'],
})
export class AttendanceBallComponent implements OnInit {

	channel: IChannel;

	AttendanceWindowType = AttendanceWindowType;

	@Input() id: string;
	@Input() type: AttendanceWindowType;
	@Input() channelId: number;
	@Input() title: string;

	constructor(private statusService: AttendanceStatusService) { }

	ngOnInit() {
		this.statusService
			.getChannel(this.channelId)
			.pipe(take(1))
			.subscribe((c: IChannel) => {
				this.channel = c;
			});
	}

	onClick() {
		this.statusService.reopenWindow(this.id);
	}
}
