import { Component, Input, OnInit } from '@angular/core';
import { RouteList } from '@app/01.global/helpers/route-enum';
import { TableListColumnMode } from '@app/table-list/models/table-list-column';
import { TableListRow } from '@app/table-list/models/table-list-row';
import { TableListStatusService } from '@app/table-list/services/table-list-status.service';

@Component({
	selector: 'table-list-card-profile-contact-info',
	templateUrl: './table-list-card-profile-contact-info.component.html',
	styleUrls: ['./table-list-card-profile-contact-info.component.scss']
})
export class TableListCardProfileContactInfoComponent implements OnInit {
	constructor(private statusService: TableListStatusService) { }

	@Input('row') row: TableListRow;
	TableListColumnMode = TableListColumnMode;
	@Input('tableIdentifier') tableIdentifier: string;

	ngOnInit(): void {
	}

	setTableListButtonClicked() {
		this.statusService.setTableListButtonClicked({
			order: 0,
			loading: false,
			showText: false,
			text: '',
			route: RouteList._routeCrudEnum.Details,
			btnClass: ''
		}, this.tableIdentifier, this.row.id);
	}
}
