import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { SharedAnimations } from '@app/01.global/animations/shared-animations';
import { DatatableFactory } from '@app/01.global/factories/datatable-factory';
import { ControlTypeEnum } from '@app/01.global/helpers/control-enum';
import { IUser } from '@app/01.global/interfaces/IUser';
import { PatientsMapperService } from '@app/01.global/mappers/IUser-mapper.service';
import { IChannel } from '@app/01.global/interfaces/iChannel';
import { ChannelUsersService } from '@app/01.global/services/channel.service';
import { AuthenticationService } from '@app/01.global/services/authentication.service';
import { Subscription } from 'rxjs';
import { ConsultationStatusService } from '@app/01.global/status-services/consultation-status.service';
import { LoaderService } from '@app/01.global/services/loader.service';
import { PerfectScrollbarDirective } from 'ngx-perfect-scrollbar';
import { RouteList } from '@app/01.global/helpers/route-enum';
import {
	AppDatatable,
	DatatableSettings,
} from '@app/01.global/models/data-table';
import { ITableListService } from '@app/table-list/services/iservice';
import { ITableListMapperService } from '@app/table-list/services/mapper.service';
import { TableListMode } from '@app/table-list/models/table-list';
import { DatatableFilters } from '@app/01.global/models/DatatableFilters';
import { TableListStatusService } from '@app/table-list/services/table-list-status.service';
import { RecordStates } from '@app/01.global/helpers/record-states-enum';

@Component({
	selector: 'app-add-user-to-channel',
	templateUrl: './add-user-to-channel.component.html',
	styleUrls: ['./add-user-to-channel.component.scss'],
	animations: [SharedAnimations],
	providers: [
		{
			provide: ITableListService,
			useClass: ChannelUsersService,
		},
		{
			provide: ITableListMapperService,
			useClass: PatientsMapperService,
		},
	],
})
export class AddUserToChannelComponent implements OnInit, OnDestroy {
	ControlTypeEnum = ControlTypeEnum;
	channel: IChannel;
	table: AppDatatable;
	tableIdentifier: string;
	subs: Subscription[] = [];
	currentUser: IUser;
	participants: IUser[];

	@ViewChild(PerfectScrollbarDirective)
	perfectScrollbar: PerfectScrollbarDirective;

	constructor(
		private authenticationService: AuthenticationService,
		private factory: DatatableFactory,
		private statusService: ConsultationStatusService,
		private tableListStatusService: TableListStatusService,
		private loaderService: LoaderService
	) { }

	ngOnInit(): void {


		this.setupTable();

		this.subs.push(
			this.authenticationService.user.subscribe((u) => {
				this.currentUser = u;
			})
		);

		this.subs.push(
			this.statusService.activeChannelObs.subscribe((response) => {
				this.channel = response?.channel;

				if (!this.channel) {
					return;
				}

				var activeParticipants = this.channel.participantStatus
					.filter((c) => c.recordState == RecordStates.active)
					.flatMap((p) => p.userId);

				this.participants = this.channel.doctors
					.concat(this.channel.users as IUser[])
					.filter((p) => activeParticipants.includes(p.id));

				this.table.filtersObservable.subscribe((f) => {
					if (f && f.readFromWhoId != this.channel.id) {
						var oldId = f.readFromWhoId;
						f.readFromWhoId = this.channel.id;
						this.table.filtersSubject.next(f);
						if (oldId > 0) {
							this.tableListStatusService.readPage(
								this.tableIdentifier,
								0
							);
						}
					}
				});
			})
		);
	}

	ngOnDestroy(): void {
		this.subs.forEach((s) => s?.unsubscribe());
	}

	setupTable() {
		var settings = new DatatableSettings();
		settings.mode = TableListMode.DatatableCircleCard;
		settings.basePath = RouteList.patients;
		settings.messages.titleMessage = 'Users';
		settings.showSearch = true;
		settings.icon = 'i-Add-User';
		settings.showAdd = false;

		var filters: DatatableFilters = {
			pageSize: 16,
			currentPage: 0,
		};

		this.table = this.factory.build(settings, filters);

		this.tableIdentifier = this.table.settingsSubject.getValue().identifier;
	}

	removeFromChannel(participant: IUser) {
		this.loaderService.enable();
		this.statusService
			.removeUserFromChannel(participant.id)
			.subscribe(() => {
				this.tableListStatusService.readPage(this.tableIdentifier, 0);
				this.perfectScrollbar.update();
				this.perfectScrollbar.scrollToRight(0, 250);
				this.loaderService.disable();
			});
	}
}
