import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import {
	UntypedFormBuilder,
	UntypedFormControl,
	UntypedFormGroup,
	Validators,
} from '@angular/forms';
import { DatatableFilters } from '@app/01.global/models/DatatableFilters';
import { IUser } from '@app/01.global/interfaces/IUser';
import { IChannel } from '@app/01.global/interfaces/iChannel';
import { AuthenticationService } from '@app/01.global/services/authentication.service';
import {
	debounceTime,
	distinctUntilChanged,
	interval,
	Observable,
	Subscription,
	switchMap,
	take,
} from 'rxjs';
import { ConsultationStatusService } from '@app/01.global/status-services/consultation-status.service';
import { ActiveChannel } from '@app/01.global/models/active-channel';
import { ConsultationSettings } from '@app/01.global/models/consultation-settings';
import { NgbModal, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { RouteList } from '@app/01.global/helpers/route-enum';
import { ControlTypeEnum } from '@app/01.global/helpers/control-enum';
import { DocTypesEnum } from '@app/01.global/helpers/doc-types-enum';

@Component({
	selector: 'app-consultation-sidebar',
	templateUrl: './consultation-sidebar.component.html',
	styleUrls: ['./consultation-sidebar.component.scss'],
})
export class ConsultationSidebarComponent implements OnInit, OnDestroy {
	channels: IChannel[] = [];
	activeChannel: IChannel;
	channelsTyping: {
		channelId: number;
		userName: string;
		isStillTyping: Observable<number>;
		isStillTypingSub: Subscription;
	}[] = [];
	pagination: DatatableFilters = {
		pageSize: 12,
		totalItems: 0,
		totalPages: 0,
		currentPage: 0,
	};
	throttle = 0;
	distance = 0.2;
	searchSub: Subscription;
	subs: Subscription[] = [];
	currentUser: IUser;
	searchTerm: UntypedFormControl = new UntypedFormControl('');
	showVideo: boolean = true;
	hasPermissionsToStartVideoCall: boolean = false;
	canJoinOnGoingVideoCall: boolean = false;
	readOnly: boolean = false;
	archiveMode: boolean;
	hasPermissionsToCreateAChannel: boolean = false;
	RouteList = RouteList;

	@ViewChild('launcherModal') launcherModal;
	@ViewChild('createChannelModal') createChannelModal;
	launcherModalResult: NgbModalRef;
	createChannelModalResult: NgbModalRef;

	ControlTypeEnum = ControlTypeEnum;
	DocTypesEnum = DocTypesEnum;

	form: UntypedFormGroup;
	loading = false;
	submitted = false;

	constructor(
		private formBuilder: UntypedFormBuilder,
		private authenticationService: AuthenticationService,
		private modalService: NgbModal,
		private statusService: ConsultationStatusService
	) { }

	ngOnInit(): void {
		this.subs.push(
			this.authenticationService.user.subscribe((u) => {
				this.currentUser = u;
			})
		);

		this.subs.push(
			this.statusService.activeChannelObs.subscribe(
				(c: ActiveChannel) => {
					this.activeChannel = c?.channel;
					if (this.activeChannel) {
						this.canJoinOnGoingVideoCall =
							c.canJoinOnGoingVideoCall;
						this.hasPermissionsToStartVideoCall =
							c.hasPermissionsToStartVideoCall;
						this.readOnly = c.readOnly;
					} else {
						this.canJoinOnGoingVideoCall = false;
						this.hasPermissionsToStartVideoCall = false;
						this.readOnly = false;
					}
				}
			)
		);

		this.subs.push(
			this.statusService.channelsObs.subscribe((response: IChannel[]) => {
				this.channels = [...response];
			})
		);

		this.subs.push(
			this.statusService.settingsObs.subscribe(
				(response: ConsultationSettings) => {
					this.showVideo = !!response?.showVideo;
					this.hasPermissionsToCreateAChannel =
						!!response?.hasPermissionsToCreateAChannel;
					var newArchiveMode = !!response?.archiveMode;
					if (
						this.archiveMode == undefined ||
						newArchiveMode != this.archiveMode
					) {
						this.archiveMode = newArchiveMode;
						this.pagination.currentPage = 0;
						this.readChannels();
					}
				}
			)
		);

		this.subs.push(
			this.statusService.IsTypingObs.subscribe(
				(response: { channelId: number; userName: string }) => {
					if (response != null) {
						var channelTyping = this.channelsTyping.find(
							(c) => c.channelId == response.channelId
						);

						var isStillTyping = interval(2500);
						var isStillTypingSub = isStillTyping
							.pipe(take(1))
							.subscribe(
								() =>
								(this.channelsTyping =
									this.channelsTyping.filter(
										(c) =>
											c.channelId !=
											response.channelId
									))
							);

						if (channelTyping) {
							channelTyping.isStillTypingSub.unsubscribe();
							channelTyping.isStillTyping = isStillTyping;
							channelTyping.isStillTypingSub = isStillTypingSub;
							return;
						}

						this.channelsTyping.push({
							channelId: response.channelId,
							userName: response.userName,
							isStillTyping: isStillTyping,
							isStillTypingSub: isStillTypingSub,
						});
					}
				}
			)
		);

		this.searchSub = this.searchTerm.valueChanges
			.pipe(
				debounceTime(500),
				distinctUntilChanged(),
				switchMap((value: string) => {
					this.pagination.searchTerm = value;
					this.readChannels();
					if (value == '') {
						return 'Ok';
					}
					return value;
				})
			)
			.subscribe(() => { });

		this.buildForm();
	}

	ngOnDestroy(): void {
		this.subs.forEach((s) => s?.unsubscribe());
		this.searchSub?.unsubscribe();
	}

	onScroll(): void {
		++this.pagination.currentPage;
		this.readChannels();
	}

	getIsTyping(channel: IChannel) {
		return this.channelsTyping.some((c) => c.channelId == channel.id);
	}

	getDisplayName(channel: IChannel) {
		var participants: IUser[] = channel.doctors.concat(
			channel.users as IUser[]
		);

		this.statusService.hideParticipantsDisplayName(participants);

		return participants.find(
			(p) =>
				p.userName ==
				this.channelsTyping.find((c) => c.channelId == channel.id)
					.userName
		).displayName;
	}

	readChannels() {
		if (this.archiveMode) {
			this.pagination.onlyArchived = true;
			this.pagination.onlyWithMessages = false;
			this.pagination.onlyActive = false;
		} else {
			this.pagination.onlyArchived = false;
			this.pagination.onlyWithMessages = true;
			this.pagination.onlyActive = true;
		}

		this.statusService.setChannels(this.pagination);
	}

	selectChannel(item: IChannel) {
		this.statusService.setActiveChannel(item.id).pipe(take(1)).subscribe();
	}

	resetSearchAndPagination() {
		this.pagination.currentPage = 0;
		this.pagination.searchTerm = '';
		this.searchTerm.setValue('');
	}

	joinVideoCall(value: boolean) {
		this.statusService.toggleVideoCall(value);
	}

	startVideoCall(value: boolean) {
		if (value) {
			this.launcherModalResult = this.modalService.open(
				this.launcherModal,
				{ size: 'xl' }
			);
		} else {
			this.statusService.toggleVideoCall(false);
		}
	}

	onTypeChosen(type: 'startApp' | 'newApp' | 'followUpCall') {
		this.launcherModalResult.close();
	}

	openCreateChannelModal() {
		this.modalService
			.open(this.createChannelModal, { size: 'md', backdrop: false })
			.result.then(() => {
				this.statusService.createChannel(this.form.value as IChannel);
				this.form.reset();
			});
	}

	buildForm() {
		this.form = this.formBuilder.group({
			name: ['', [Validators.required]],
			logo: [],
		});
	}
}
