import { BehaviorSubject, Observable } from "rxjs";
import { TableListRow } from "./table-list-row";
import { TableListFilters } from "./table-list-filters";
import { ITableListMapperService } from "../services/mapper.service";
import { ITableListService } from "../services/iservice";
import { TableListActionButton } from "./table-list-action-button";
import { ActivatedRoute } from "@angular/router";

export abstract class TableList {
	settingsSubject: BehaviorSubject<TableListSettings>;
	settingsObservable: Observable<TableListSettings>;
	rowsSubject: BehaviorSubject<TableListRow[]>;
	rowsObservable: Observable<TableListRow[]>;
	totalItemsSubject: BehaviorSubject<number>;
	totalItemsObservable: Observable<number>;
	filtersSubject: BehaviorSubject<TableListFilters>;
	filtersObservable: Observable<TableListFilters>;
	loadingSubject: BehaviorSubject<boolean>;
	loadingObservable: Observable<boolean>;
	service: ITableListService;
	mapper: ITableListMapperService;
	pageRoute?: ActivatedRoute;
}


export abstract class TableListSettings {
	private _showSearch?: boolean;
	public get showSearch(): boolean {
		return this._showSearch;
	}
	public set showSearch(value: boolean) {
		if (this.mode == TableListMode.Datatable && !value) {
			throw 'Mode AppDatatable requires search';
		}
		this._showSearch = value;
	}
	constructor() {
		if (this.mode == TableListMode.Datatable) {
			this.showSearch = true;
			this.actionButtons = [];
			this.showAddInModal = true;
			this.showDetailsInModal = false;
			this.showUpdateInModal = true;
			this.showDeleteInModal = true;
		}

		this.identifier = this.basePath;
	}

	mode: TableListMode;
	identifier: string;
	basePath: string;
	icon?: string;
	messages?: {
		titleMessage: string;
		// Message to show when array is presented
		// but contains no values
		emptyMessage: string;

		// Footer total message
		totalMessage: string;

		// Footer selected message
		selectedMessage: string;

		searchboxPlaceholder: string;
	};
	actionButtons?: TableListActionButton[];
	showAdd?: boolean;
	showAddInModal: boolean;
	showDetailsInModal: boolean;
	showUpdateInModal: boolean;
	showDeleteInModal: boolean;
}

export enum TableListMode {
	Datatable = 0, // Using ngx-datatable,
	DatatableCardContact = 1,
	DatatableMiniCard = 2,
	DatatableCardImage = 3,
	DatatableCircleCard = 4,
	DatatableTileCard = 5,
	DatatableEventCard = 6,
}