<ng-container *ngIf="hasPermissionsToMeetingNotes">
	<div class="chat-content rtl-ps-none">
		<div class="timeline-wrapper" [ngStyle]="{
	'height': 'calc(100vh - 21.2rem)',
	'max-height': 'calc(100vh - 21.2rem)',
	'overflow-y': 'auto',
	'padding-top': '6rem',
	'margin-top': '4px'
	}" [perfectScrollbar]="{ suppressScrollX: true }" ngxInfiniteScroller strategy="scrollingToTop"
			(onScrollUp)="onScroll()">
			<span *ngIf="loadingUp" class="loader-bubble loader-bubble-info ml-4 mt-n2"></span>
			<ul class="timeline clearfix">
				<li class="timeline-line"></li>
				<li class="timeline-item" *ngFor="let item of meetingNotes" style="margin-top: -5rem; width: 51%">
					<div class="timeline-badge">
						<i class="badge-icon i-Pen-5 bg-warning"></i>
					</div>
					<div class="timeline-card card mx-lg-5 mx-md-0">
						<div class="card-body">
							<div class="card-body text-center" (click)="edit(item.id)">
								<div *ngIf="!item.form">
									<h5 class="mb-1" [ngClass]="item.id == activeNoteId ? ' text-white' : ''">
										{{ item.title }}
									</h5>
									<small>{{ item.createdDate | localizedDate: 'full' }}</small>
								</div>
								<p *ngIf="!item.form" class="mb-1">{{ item.description }}</p>
								<ng-container *ngIf="item.form">
									<form [formGroup]="item.form" (ngSubmit)="updateNote(item.id, item.form)">
										<div class="row">
											<div class="col">
												<app-form-control [type]="ControlTypeEnum.Text" name="title"
													[control]="item.form.get('title')">
												</app-form-control>
												<app-form-control [type]="ControlTypeEnum.Textarea" name="description"
													[control]="item.form.get('description')">
												</app-form-control>
											</div>
										</div>
										<div class="row mt-2">
											<div class="col text-right">
												<button type="button" [disabled]="item.saving"
													class="btn btn-icon btn-rounded btn-danger mr-2"
													(click)="deleteNote(item.id)" title="{{ 'Delete' | translate }}">
													<span class="text-20 font-weight-bold">-</span>
												</button>
												<button type="button" [disabled]="item.saving"
													class="btn btn-icon btn-rounded btn-outline-warning mr-2"
													(click)="stopEditing(item.id);$event.stopPropagation()" title="{{ 'StopEditing' | translate }}">
													<span><i class="i-Left"></i></span>
												</button>
												<btn-loading btnClass="btn btn-icon btn-rounded btn-info"
													[loading]="item.saving" title="{{ 'Save' | translate }}">
													<span>
														<span><i class="i-Disk"></i></span>
													</span>
												</btn-loading>
											</div>
										</div>
									</form>
								</ng-container>
							</div>
						</div>
					</div>
				</li>
			</ul>
			<ul class="timeline clearfix">
				<li class="timeline-line"></li>
				<li class="timeline-group text-center">
					<span *ngIf="loading" class="loader-bubble loader-bubble-info ml-4 mt-n2"></span>
				</li>
			</ul>
			<ul class="timeline clearfix">
				<li class="timeline-line"></li>
				<li class="timeline-group text-center">
					<button class="btn btn-icon-text btn-warning">
						<i class="i-Calendar-4"></i> {{ userJoinDate | localizedDate: 'yyyy' }}
					</button>
				</li>
			</ul>
		</div>
	</div>
</ng-container>
<ng-container *ngIf="hasPermissionsToMeetingNotes">
	<div class="p-3 box-shadow-1 chat-input-area bg-white">
		<form [formGroup]="form" class="inputForm" (ngSubmit)="createNote()">
			<div class="row">
				<div class="col pr-2">
					<app-form-control [type]="ControlTypeEnum.Textarea" [rows]="3" name="title"
						[control]="form.get('title')" displayMode="horizontal" [showErrorMessages]="false"
						[showLabel]="false" placeholder="{{ 'Title' |translate}}">
					</app-form-control>
				</div>
				<div class="col pl-2">
					<app-form-control [type]="ControlTypeEnum.Textarea" [rows]="3" name="description"
						[showErrorMessages]="false" [showLabel]="false" displayMode="horizontal"
						[control]="form.get('description')" displayMode="horizontal"
						placeholder="{{ 'Description' |translate}}">
					</app-form-control>
				</div>
			</div>
			<div class="row">
				<div class="col text-right">
					<button type="button" class="btn btn-icon btn-rounded btn-outline-danger mr-2"
						(click)="form.reset()" title="{{ 'ResetForm' | translate }}">
						<span><i class="i-Repeat-2"></i></span>
					</button>
					<btn-loading btnClass="btn btn-icon btn-rounded btn-info" [loading]="saving" title="{{ 'Save' | translate }}">
						<span>
							<span><i class="i-Disk"></i></span>
						</span>
					</btn-loading>
				</div>
			</div>
		</form>
	</div>
</ng-container>
<div *ngIf="!hasPermissionsToMeetingNotes">
	<div class="align-items-center">
		<p class="alert-card mb-3 mx-auto p-2 px-5 d-flex alert alert-danger" style="width: fit-content;">
			{{ 'UnauthorizedOperation' | translate }}
		</p>
	</div>
</div>