<div class="container-fluid p-0">
	<div class="row mb-4" *ngIf="!bookingFromAttendance">
		<div class="col-12 col-xl-6 col-xxl-3 mb-3 mb-xxl-0" *ngIf="!isGuest">
			<div class="p-1 p-xl-3 p-xxl-4 rounded d-flex align-items-center bg-secondary text-white box-shadow-2 cursor-pointer"
				(click)="navigate(5)" (mouseenter)="hover(5, true)" (mouseleave)="hover(5, false)" [ngClass]="
					active == 5 || hoverActive == 5
						? 'bg-secondary text-white border border-transparent'
						: 'bg-white text-secondary border border-secondary'
				">
				<i class="i-MaleFemale text-20 text-xxl-32 mr-3"></i>
				<span class="font-weight-bold text-12 text-xxl-16 mb-0">
					{{ "SelectAPatient" | translate }}
				</span>
			</div>
		</div>
		<div class="col-12 col-xl-6 col-xxl-3 mb-3 mb-xxl-0" *ngIf="isGuest">
			<div class="p-1 p-xl-3 p-xxl-4 rounded d-flex align-items-center bg-danger text-white box-shadow-2 cursor-pointer"
				(click)="navigate(1)" (mouseenter)="hover(1, true)" (mouseleave)="hover(1, false)" [ngClass]="
					active == 1 || hoverActive == 1
						? 'bg-danger text-white border border-transparent'
						: 'bg-white text-danger border border-danger'
				">
				<i class="i-Danger text-20 text-xxl-32 mr-3"></i>
				<span class="font-weight-bold text-12 text-xxl-16 mb-0">
					{{ "Emergency" | translate }}
				</span>
			</div>
		</div>
		<div class="col-12 col-xl-6 col-xxl-3 mb-3 mb-xxl-0" *ngIf="isGuest || hasPatientSelected">
			<div class="p-1 p-xl-3 p-xxl-4 rounded d-flex align-items-center box-shadow-2 cursor-pointer" [ngClass]="
					active == 2 || hoverActive == 2
						? 'bg-info text-white border border-transparent'
						: 'bg-white text-info border border-info'
				" (click)="navigate(2)" (mouseenter)="hover(2, true)" (mouseleave)="hover(2, false)">
				<i class="i-Receipt-4 text-20 text-xxl-32 mr-3"></i>
				<span class="text-12 text-xxl-16 mb-0">
					{{ "Booking" | translate }}
				</span>
			</div>
		</div>
		<div class="col-12 col-xl-6 col-xxl-3 mb-3 mb-xxl-0" *ngIf="!isGuest && !isEmployeeCantHandleAppointments">
			<div class="p-1 p-xl-3 p-xxl-4  rounded d-flex align-items-center box-shadow-2 cursor-pointer" [ngClass]="
					active == 6 || hoverActive == 6
						? 'bg-primary text-white border border-transparent'
						: 'bg-white text-primary border border-primary'
				" (click)="navigate(6)" (mouseenter)="hover(6, true)" (mouseleave)="hover(6, false)">
				<i class="i-Information text-20 text-xxl-32 mr-3"></i>
				<span class="text-12 text-xxl-16 mb-0">
					{{ "BookingRequests" | translate }}
				</span>
			</div>
		</div>
		<div class="col-12 col-xl-6 col-xxl-3 mb-3 mb-xxl-0" *ngIf="isGuest">
			<div class="p-1 p-xl-3 p-xxl-4 rounded d-flex align-items-center box-shadow-2 cursor-pointer" [ngClass]="
					active == 3 || hoverActive == 3
						? 'bg-primary text-white border border-transparent'
						: 'bg-white text-primary border border-primary'
				" (click)="navigate(3)" (mouseenter)="hover(3, true)" (mouseleave)="hover(3, false)">
				<i class="i-Building text-20 text-xxl-32 mr-3"></i>
				<span class="text-12 text-xxl-16 mb-0">
					{{ "FindAProvider" | translate }}
				</span>
			</div>
		</div>
		<div class="col-12 col-xl-6 col-xxl-3 mb-3 mb-xxl-0" *ngIf="isGuest">
			<div class="p-1 p-xl-3 p-xxl-4 rounded d-flex align-items-center box-shadow-2 cursor-pointer" [ngClass]="
					active == 4 || hoverActive == 4
						? 'bg-primary text-white border border-transparent'
						: 'bg-white text-primary border border-primary'
				" (click)="navigate(4)" (mouseenter)="hover(4, true)" (mouseleave)="hover(4, false)">
				<i class="i-Doctor text-20 text-xxl-32 mr-3"></i>
				<span class="text-12 text-xxl-16 mb-0">
					{{ "FindADoctor" | translate }}
				</span>
			</div>
		</div>
	</div>
	<div class="row">
		<div class="col">
			<div [ngClass]="{'container': active == 3}">
				<div [ngClass]="{'row': active == 3}">
					<div [ngClass]="{'col': active == 3}">
						<router-outlet></router-outlet>
					</div>
				</div>
			</div>
		</div>
	</div>
</div>