import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { AttendanceWindowType } from '@app/01.global/helpers/attendance-window-type.enum';
import { RouteList } from '@app/01.global/helpers/route-enum';
import { IChannel } from '@app/01.global/interfaces/iChannel';
import { IPatientWaiting } from '@app/01.global/interfaces/iPatientWaiting';
import { IUser } from '@app/01.global/interfaces/IUser';
import { AttendanceService } from '@app/01.global/services/attendance.service';
import { AuthenticationService } from '@app/01.global/services/authentication.service';
import { AttendanceStatusService } from '@app/01.global/status-services/attendance-status.service';
import { BookingStatusService } from '@app/01.global/status-services/booking-status.service';
import { BookingDashboardComponent } from '@app/booking/booking-dashboard/booking-dashboard.component';
import { NgbModal, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { Observable } from 'rxjs';

@Component({
	selector: 'app-guest-sidebar',
	templateUrl: './guest-sidebar.component.html',
	styleUrls: ['./guest-sidebar.component.scss'],
})
export class GuestSidebarComponent implements OnInit {
	consultationChannel: IChannel;
	generalChannels: {
		channel: IChannel;
		unreadMessageCount$: Observable<number>;
	}[] = [];
	doctor: IUser;
	hasAppointment: boolean = false;
	unreadMessageCount$: Observable<number>;
	isOnline$: Observable<boolean>;
	RouteList = RouteList;
	AttendanceWindowType = AttendanceWindowType;
	@Input() consultationChannelId: number;
	@ViewChild('devicesModal') devicesModal: NgbModalRef;
	devicesAlreadyChecked: string;
	private guestEntering: IPatientWaiting;

	constructor(
		private statusService: AttendanceStatusService,
		private bookingStatusService: BookingStatusService,
		private router: Router,
		private route: ActivatedRoute,
		public modalService: NgbModal,
		private authService: AuthenticationService
	) { }

	ngOnInit() {
		this.unreadMessageCount$ =
			this.statusService.getChannelUnreadMessageCount$(
				this.consultationChannelId
			);
		this.isOnline$ =
			this.statusService.getChannelOnline$(
				this.consultationChannelId
			);
		this.statusService
			.getChannel(this.consultationChannelId)
			.subscribe((c) => {
				this.consultationChannel = c;
				this.generalChannels =
					this.statusService.getGeneralChannelsAsPatient();
				this.hasAppointment =
					c.appointments && c.appointments.length > 0;
				this.doctor = c.doctors[0];

				this.guestEntering = {
					doctorCameraChecked: false,
					doctorMicChecked: false,
					doctorPublicId: this.route.snapshot.params.doctorPublicId,
					doctorId: this.doctor.id,
					doctorUsername: this.doctor.userName,
					userCameraChecked: false,
					userMicChecked: false,
					channelId: c.id,
					userId: this.authService.userValue.id,
				};

				if (
					this.route.snapshot.firstChild?.firstChild?.component ==
					BookingDashboardComponent
				) {
					setTimeout(() => {
						this.openWindow(AttendanceWindowType.Booking, c.id);
					}, 200);
				}
			});

		this.devicesAlreadyChecked = localStorage.getItem(
			'devicesAlreadyChecked'
		);
	}

	openWindow(type: AttendanceWindowType, channelId: number) {
		var windowId = this.statusService.openWindow(channelId, type);
		if (type == AttendanceWindowType.Booking) {
			this.bookingStatusService.bookFromDoctorAttendance(
				this.doctor,
				windowId
			);
			this.router.navigate([
				RouteList.attendance,
				this.route.snapshot.params.providerPublicId,
				this.route.snapshot.params.doctorPublicId,
				RouteList._route0Enum.Booking,
				RouteList._route1Enum.Appointment,
				RouteList._route2Enum.BookingAgreement,
			]);
		}
	}

	openDevicesModal() {
		var modalRef = this.modalService.open(this.devicesModal, {
			ariaLabelledBy: 'modal-basic-title',
			size: 'lg',
			backdrop: 'static',
			modalDialogClass: 'devices-modal',
		});

		modalRef.dismissed.subscribe((reason) => {
			this.guestEntering.userCameraChecked = reason.cameraStatus;
			this.guestEntering.userMicChecked = reason.micStatus;
		});
	}

	leave() {
		this.statusService.reset();
		this.router.navigate([RouteList.dashboard]);
	}
}
