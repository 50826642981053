<div class="d-flex flex-row p-0 shadow" style="min-height: 7rem;">
	<div class="bg-primary text-center align-items-stretch p-2" style="width: 7rem;">
		<h1 class="text-center text-white mb-1" style="font-size: 3rem;">{{eventStartDay}}</h1>
		<h3 class="text-center text-white font-weight-bold">{{eventStartMonth}}</h3>
	</div>
	<div class="bg-light text-center align-items-stretch" style="width: 7rem;">
		<table-list-thumbnail *ngIf="eventThumbnail.value" [source]="eventThumbnail.value">
		</table-list-thumbnail>
	</div>
	<div class="py-2 px-3 font-weight-bold text-start align-items-stretch">
		<h2>{{eventTitle.value}}</h2>
		<div *ngFor="let item of eventDetails">
			<div *ngIf="item.displayMode == TableListColumnMode.html" [innerHtml]="item.value"></div>
			<div *ngIf="item.displayMode == TableListColumnMode.text">{{item.value}}</div>
		</div>
	</div>
	<div class="p-2 ml-auto my-auto">
		<ng-container *ngFor="let btn of row.actionButtons">
			<table-list-btn [btn]="btn" [rowId]="row.id" [tableIdentifier]="tableIdentifier">
			</table-list-btn>
		</ng-container>
	</div>
</div>