import { Component, OnInit } from '@angular/core';
import { RouteList } from '@app/01.global/helpers/route-enum';
import { AuthenticationService } from '@app/01.global/services/authentication.service';

@Component({
	selector: 'app-footer',
	templateUrl: './footer.component.html',
	styleUrls: ['./footer.component.scss'],
})
export class FooterComponent implements OnInit {
	routeList = RouteList;

	constructor(private authService: AuthenticationService) { }

	ngOnInit() { }

	isProviderRolePresent = (): boolean => this.authService?.isProvider;

	getCurrentYear(): number {
		const currentDate = new Date();
		return currentDate.getFullYear();
	}
}
