<div class="video-container h-100" *ngIf="session">
	<div class="row m-0 p-0 h-100 w-100">
		<ng-container *ngIf="streams.length == 0">
			<div class="app-inro-circle">
				<div class="border rounded-circle big-bubble bg-secondary"
					[@animate]="{value:'*',params:{scale:'.2',delay:'400ms', duration: '400ms'}}">
					<img src="../../../assets/images/hourglass.gif" />
				</div>
				<p class="text-16" [@animate]="{value:'*',params:{y:'120px',delay:'600ms', duration: '400ms'}}">{{
					"WaitingForPeopleToJoin" | translate }}</p>
				<button class="btn btn-lg btn-danger text-20" placement="bottom" [ngbTooltip]="'EndCall' | translate"
					(click)="endCall()">
					<i class="i-Telephone mr-2" style="transform: rotate(90deg);"></i><span>{{'EndCall' |
						translate}}</span>
				</button>
			</div>
		</ng-container>
		<div [ngClass]="getCSSClass()" *ngFor="let stream of streams">
			<app-subscriber [stream]="stream" [session]="session"></app-subscriber>
		</div>
	</div>
	<app-publisher [session]="session"></app-publisher>
	<ng-container *ngIf="streams.length > 0">
		<div class="call-options-container">
			<div class="spacing-for-default-video-top-bar"></div>
			<div class="video-top-bar">
				<div class="d-flex flex-row justify-content-center h-100">
					<button class="btn btn-icon btn-rounded btn-danger my-auto mx-1 text-20"
						style="transform: rotate(90deg);" placement="bottom" [ngbTooltip]="'EndCall' | translate"
						(click)="endCall()">
						<i class="i-Telephone"></i>
					</button>
					<button class="btn btn-icon btn-rounded btn-outline-secondary my-auto mx-1 text-20"
						placement="bottom" [ngbTooltip]="'ShareScreen' | translate" (click)="shareScreen()">
						<i class="i-Upload-Window"></i>
					</button>
				</div>
			</div>
		</div>
	</ng-container>
</div>