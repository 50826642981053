import { TableListActionButton } from "@app/table-list/models/table-list-action-button";
import { TableListColumn } from "@app/table-list/models/table-list-column";
import { TableListRow } from "@app/table-list/models/table-list-row";

export class DatatableRow implements TableListRow {
	constructor(source: any) {
		this.id = source.id;
		this.source = source;
		this.columns = [];
		this.actionButtons = [];
		if (!this.targetId || this.targetId == 0) {
			this.targetId = source.id;
		}
	}
	id: number;
	columns: TableListColumn[];
	source: any;
	targetId?: number;
	actionButtons?: TableListActionButton[];
}