import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { TableListFilters } from '@app/table-list/models/table-list-filters';
import { ITableListService } from '@app/table-list/services/iservice';
import { environment } from '@environments/environment';
import { Observable } from 'rxjs';
import { IChannel } from '../interfaces/iChannel';
import { IApiResponse } from '../interfaces/helpers/iResponseWrapper';
import { BaseService } from './base.service';
import { IPatientWaiting } from '../interfaces/iPatientWaiting';

@Injectable({
	providedIn: 'root',
})
export class AttendanceService
	extends BaseService
	implements ITableListService {
	ApiBaseURL = environment.ApiBaseURL;

	constructor(private http: HttpClient) {
		super();
	}

	readCount(page: TableListFilters): Observable<number> {
		throw new Error('Method not implemented.');
	}
	readAll(page: TableListFilters): Observable<any[]> {
		throw new Error('Method not implemented.');
	}
	read(id: number): Observable<any> {
		throw new Error('Method not implemented.');
	}

	getChannelsAsPatient(doctorPublicId: string): Observable<IChannel[]> {
		var params = new HttpParams();

		params = params.append('doctorPublicId', doctorPublicId);

		return this.http
			.get<IApiResponse>(
				`${this.ApiBaseURL}/Attendance/ChannelsAsPatient`,
				{ params }
			)
			.ToApiResult();
	}

	setGuestEntering(model: IPatientWaiting): Observable<IPatientWaiting> {
		return this.http
			.post<IApiResponse>(
				`${this.ApiBaseURL}/Attendance/SetGuestEntering`,
				model
			)
			.ToApiResult();
	}

	setGuestLeaving(model: IPatientWaiting): Observable<IPatientWaiting> {
		return this.http
			.put<IApiResponse>(
				`${this.ApiBaseURL}/Attendance/SetGuestLeaving`,
				model
			)
			.ToApiResult();
	}

	setDoctorEntering(): Observable<IPatientWaiting> {
		return this.http
			.post<IApiResponse>(
				`${this.ApiBaseURL}/Attendance/SetDoctorEntering`,
				{}
			)
			.ToApiResult();
	}

	setDoctorLeaving(): Observable<IPatientWaiting> {
		return this.http
			.put<IApiResponse>(
				`${this.ApiBaseURL}/Attendance/SetDoctorLeaving`,
				{}
			)
			.ToApiResult();
	}

	setGuestLeaveAllWaitingRooms(): Observable<void> {
		return this.http
			.put<IApiResponse>(
				`${this.ApiBaseURL}/Attendance/SetGuestLeaveAllWaitingRooms`,
				{}
			)
			.ToApiResult();
	}

	getChannelsAsDoctor(): Observable<IChannel[]> {
		return this.http
			.get<IApiResponse>(`${this.ApiBaseURL}/Attendance/ChannelsAsDoctor`)
			.ToApiResult();
	}

	sendEmailInvite(email: string): Observable<void> {
		return this.http
			.post<IApiResponse>(`${this.ApiBaseURL}/Attendance/SendEmailInvite/${email}`, {})
			.ToApiResult();
	}

	sendSMSInvite(sms: string): Observable<void> {
		return this.http
			.post<IApiResponse>(`${this.ApiBaseURL}/Messaging/SendSMSInvite/${sms}`, {})
			.ToApiResult();
	}
}
