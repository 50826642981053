<div class="container-fluid doNotPassContainerYsize" infinite-scroll
	[infiniteScrollDistance]="tableSettings.mode == TableListMode.DatatableCircleCard ? 5 : 2"
	[infiniteScrollThrottle]="0" (scroll)="onScroll()">
	<div class="row">
		<div class="col-12 p-2 p-md-3 p-lg-4">
			<div class="row" style="background-color: whitesmoke;">
				<div class="col-12 text-center">
					<div class="card card-icon-bg card-icon-bg-info o-hidden py-3 py-lg-4 py-xxl-5 rounded-3 search-hero-section"
						style="box-shadow: none;background-color: whitesmoke;">
						<div class="card-body">
							<i class="text-info text-64 {{tableSettings.icon}}" style="opacity: 0.75;"></i>
							<div class="container-fluid">
								<div class="row mb-3">
									<div class="col">
										<h1 class="display-5 fw-bold" style="margin-left: -65px;">
											{{tableSettings.messages.titleMessage}}
										</h1>
									</div>
								</div>
								<div class="row ml-5" *ngIf="tableSettings.showSearch">
									<div class="col">
										<div class="form-group search-container">
											<input type="text" class="form-control form-control-lg search-input"
												[formControl]="searchTerm"
												placeholder="{{tableSettings.messages.searchboxPlaceholder}}" />
											<span class="search-icon">
												<img src="https://cdns.iconmonstr.com/wp-content/releases/preview/2012/240/iconmonstr-magnifier-2.png"
													alt="Search">
											</span>
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
			<div class="row" *ngIf="tableSettings.actionButtons.length > 0">
				<div class="col-12 py-3 text-center">
					<ng-container *ngFor="let btn of tableSettings.actionButtons">
						<table-list-btn [btn]="btn" [tableIdentifier]="tableSettings.identifier"></table-list-btn>
					</ng-container>
				</div>
			</div>
			<div class="row" style="background-color: whitesmoke;" *ngIf="tableFilters.totalItems">
				<div class="col-12 py-3 text-center">
					<h3>{{ tableSettings.messages.totalMessage + ': ' + tableFilters.totalItems }}</h3>
				</div>
			</div>
		</div>
	</div>
	<div *ngIf="statusService.getRowsObservable(tableIdentifier) | async as rows">
		<div *ngIf="statusService.getFiltersObservable(tableIdentifier) | async as filters">
			<div *ngIf="rows.length > 0">
				<div class="row">
					<ng-container *ngIf="tableSettings.mode == TableListMode.DatatableCardContact">
						<div class="col-12 col-md-6 col-xl-4 p-2 p-md-3 p-lg-4" *ngFor="let row of rows; let i = index">
							<table-list-card-profile-contact-info [row]="row" [tableIdentifier]="tableIdentifier">
							</table-list-card-profile-contact-info>
						</div>
					</ng-container>
					<ng-container *ngIf="tableSettings.mode == TableListMode.DatatableCardImage">
						<div class="col-12 col-md-6 col-xl-4 p-2 p-md-3 p-lg-4" *ngFor="let row of rows; let i = index">
							<table-list-card-profile-image [row]="row" [tableIdentifier]="tableIdentifier">
							</table-list-card-profile-image>
						</div>
					</ng-container>
					<ng-container *ngIf="tableSettings.mode == TableListMode.DatatableCircleCard">
						<div class="col-12 col-md-6 col-lg-4 col-xl-3 p-2 p-md-3 p-lg-4" *ngFor="let row of rows">
							<table-list-circle-card [row]="row" [tableIdentifier]="tableIdentifier">
							</table-list-circle-card>
						</div>
					</ng-container>
					<ng-container *ngIf="tableSettings.mode == TableListMode.DatatableMiniCard">
						<div class="col-12 col-md-6 col-lg-4 col-xl-3 p-2 p-md-3 p-lg-4" *ngFor="let row of rows">
							<table-list-mini-card [row]="row" [tableIdentifier]="tableIdentifier">
							</table-list-mini-card>
						</div>
					</ng-container>
					<ng-container *ngIf="tableSettings.mode == TableListMode.DatatableEventCard">
						<div class="col-12 my-2 my-md-3 my-lg-4" *ngFor="let row of rows">
							<table-list-event-card [row]="row" [tableIdentifier]="tableIdentifier">
							</table-list-event-card>
						</div>
					</ng-container>
				</div>
			</div>
		</div>
		<div class="row" *ngIf="loading">
			<div class="col">
				<div class="d-flex justify-content-center mt-5">
					<div class="spinner-grow text-info mx-2" role="status">
						<span class="visually-hidden">Loading...</span>
					</div>
					<div class="spinner-grow text-info mx-2" role="status">
						<span class="visually-hidden">Loading...</span>
					</div>
					<div class="spinner-grow text-info mx-2" role="status">
						<span class="visually-hidden">Loading...</span>
					</div>
				</div>
			</div>
		</div>
	</div>
</div>