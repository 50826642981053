import { Component, HostListener, Input, OnDestroy, OnInit } from '@angular/core';
import { SharedAnimations } from '@app/01.global/animations/shared-animations';
import { AttendanceStatusService } from '@app/01.global/status-services/attendance-status.service';

@Component({
	selector: 'app-attendance-video',
	templateUrl: './attendance-video.component.html',
	styleUrls: ['./attendance-video.component.scss'],
	animations: [SharedAnimations],
})
export class AttendanceVideoComponent implements OnInit, OnDestroy {
	session: OT.Session;
	streams: Array<OT.Stream> = [];

	@Input() windowId: string;


	@HostListener("window:beforeunload", ["$event"]) unloadHandler() {
		this.endCall();
	}


	constructor(private statusService: AttendanceStatusService) { }

	ngOnInit(): void {
		this.session = this.statusService.getVideoSession(this.windowId);
		this.session.on('streamCreated', (event) => {
			const idx = this.streams.findIndex(
				(s) => (s.streamId = event.stream.streamId)
			);
			if (idx == -1) {
				this.streams.push(event.stream);
			}
		});
		this.session.on('streamDestroyed', (event) => {
			const idx = this.streams.findIndex(
				(s) => (s.streamId = event.stream.streamId)
			);
			if (idx > -1) {
				this.streams.splice(idx, 1);
			}
		});
	}

	ngOnDestroy(): void { }

	endCall() {
		if (this.session) {
			this.session.off('streamCreated');
			this.session.off('streamDestroyed');
			if (this.streams.length > 0) {
				//TODO / IDEIA - Se for o médico responsável terminar logo a chamada em vez de "Sair"
				this.statusService.leaveVideoCall(this.windowId);
			} else {
				this.statusService.endVideoCall(this.windowId);
			}
			this.session.disconnect();
			this.session = null;
			this.streams = [];
		}
	}

	shareScreen() { }

	getCSSClass(): string {
		var length = this.streams.length;

		switch (length) {
			case 1:
			case 2:
				return 'col px-0 h-100';
			case 3:
				return 'col-4 px-0 h-100';
			case 4:
				return 'col-6 px-0 h-50';
			default:
				return 'col-3 px-0';
		}
	}
}
