import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { IUser } from '@app/01.global/interfaces/IUser';
import { environment } from '@environments/environment';
import '@app/01.global/helpers/observable-extensions';
import { map, Observable, tap } from 'rxjs';
import { DatatableFilters } from '../models/DatatableFilters';
import { ITableListService } from '@app/table-list/services/iservice';
import { BaseService } from './base.service';
import { IApiResponse } from '../interfaces/helpers/iResponseWrapper';

@Injectable({
	providedIn: 'root',
})
export class UsersService extends BaseService implements ITableListService {
	apiBaseUrl = environment.ApiBaseURL;

	constructor(private http: HttpClient) {
		super();
	}

	create(model: IUser) {
		return this.http
			.post<IApiResponse>(`${this.apiBaseUrl}/users/create`, model)
			.ToApiResult();
	}

	delete(id: number) {
		return this.http
			.delete<IApiResponse>(`${this.apiBaseUrl}/users/${id}`)
			.ToApiResult();
	}

	update(id: number, model: IUser) {
		return this.http
			.put<IApiResponse>(`${this.apiBaseUrl}/users/${id}`, model)
			.ToApiResult();
	}

	updateDisplayName(model: IUser) {
		return this.http
			.put<IApiResponse>(`${this.apiBaseUrl}/users/updateDisplayName`, {
				displayName: model.displayName,
			})
			.ToApiResult();
	}

	updateDisplayLanguage(id: number) {
		return this.http
			.put<IApiResponse>(
				`${this.apiBaseUrl}/users/updateDisplayLanguage`,
				{
					languageId: id,
				}
			)
			.ToApiResult();
	}

	uploadPhoto(photo: File) {
		const uploadData = new FormData();
		uploadData.append('myFile', photo, photo.name);
		return this.http
			.post<IApiResponse>(
				`${this.apiBaseUrl}/users/uploadPhoto`,
				uploadData
			)
			.ToApiResult();
	}

	read(id: number): Observable<IUser> {
		return this.http
			.get<IApiResponse>(`${this.apiBaseUrl}/users/${id}`)
			.ToApiResult();
	}

	readCount(page: DatatableFilters): Observable<number> {
		let params = this.paginationToHttpParams(page);
		return this.http
			.get<IApiResponse>(`${this.apiBaseUrl}/users/count`, {
				params,
			})
			.ToApiResult();
	}

	readAll(page: DatatableFilters): Observable<IUser[]> {
		let params = this.paginationToHttpParams(page);

		return this.http
			.get<IApiResponse>(`${this.apiBaseUrl}/users`, {
				params,
			})
			.ToApiResult();
	}

	changeAppearOfflineStatus() {
		return this.http
			.put<IApiResponse>(
				`${this.apiBaseUrl}/users/changeAppearOfflineStatus`,
				{}
			)
			.ToApiResult();
	}
}
