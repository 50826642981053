import { Component, Input, OnInit } from "@angular/core";

@Component({
	selector: "app-label-with-popover",
	templateUrl: "./label-with-popover.component.html",
	styleUrls: ["./label-with-popover.component.scss"],
})
export class LabelWithPopoverComponent {
	@Input("triggers") triggers: string;
	@Input("placement") placement: string;
	@Input("animation") animation: string;
	@Input("container") container: string;
	@Input("targetLink") targetLink: string;
	@Input("cursorPointer") cursorPointer: boolean = true;

	constructor() {}
}
