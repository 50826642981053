import { Injectable } from '@angular/core';
import { SwUpdate, VersionEvent } from '@angular/service-worker';
import { Observable } from 'rxjs';
@Injectable({ providedIn: 'root' })
export class PwaService {
	promptEvent: any;
	constructor(private swUpdate: SwUpdate) {
		window.addEventListener('beforeinstallprompt', (event: Event) => {
			this.promptEvent = event;
		});
	}

	versionUpdates(): Observable<VersionEvent> {
		return this.swUpdate.versionUpdates;
	}

	activateUpdate() {
		return this.swUpdate.activateUpdate().then(() => document.location.reload())
	}
}
