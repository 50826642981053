import { Component, OnInit, ViewChild } from '@angular/core';
import { PwaService } from '@app/01.global/services/pwa.service';
import { NgbModal, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { TranslateService } from '@ngx-translate/core';
import { ToastrService } from 'ngx-toastr';

@Component({
	selector: 'app-auth-layout',
	templateUrl: './auth-layout.component.html',
	styleUrls: ['./auth-layout.component.scss'],
})
export class AuthLayoutComponent implements OnInit {
	@ViewChild('ConfirmUpdateModal') ConfirmUpdateModal: NgbModalRef;
	constructor(
		private translateService: TranslateService,
		private toastrService: ToastrService,
		private modalService: NgbModal,
		private pwaService: PwaService
	) { }

	ngOnInit() {
		this.pwaService.versionUpdates().subscribe((evt) => {
			if (evt.type == 'VERSION_READY') {
				this.askUserToUpdate();
			}
		});
	}

	askUserToUpdate() {
		this.modalService.dismissAll();
		const modalRef = this.modalService.open(this.ConfirmUpdateModal);

		modalRef.result
			.then(
				(result) => {
					this.pwaService.activateUpdate();
				},
				(reason) => {
				}
			)
			.finally(() => { });
	}
}
