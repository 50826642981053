import {
	Component,
	EventEmitter,
	Input,
	OnDestroy,
	OnInit,
	Output,
	ViewChildren,
} from '@angular/core';
import {
	ActivatedRoute,
	ResolveStart,
	Router,
} from '@angular/router';
import { RouteList } from '@app/01.global/helpers/route-enum';
import { IChannel } from '@app/01.global/interfaces/iChannel';
import { IChatMessage } from '@app/01.global/interfaces/iChatMessage';
import { ActiveChannel } from '@app/01.global/models/active-channel';
import { AuthenticationService } from '@app/01.global/services/authentication.service';
import { ChannelService } from '@app/01.global/services/channel.service';
import { SignalrService } from '@app/01.global/services/signalr.service';
import { ConsultationStatusService } from '@app/01.global/status-services/consultation-status.service';
import { ConsultationComponent } from '@app/consultation/consultation/consultation.component';
import { Subscription, take } from 'rxjs';
import { StickyBallComponent } from '../sticky-balls/sticky-ball.component';

@Component({
	selector: 'app-messenger',
	templateUrl: './messenger.component.html',
	styleUrls: ['./messenger.component.scss'],
})
export class MessengerComponent implements OnInit, OnDestroy {
	private subs: Subscription[] = [];
	latestChannels: IChannel[] = [];
	@Output() onShowRightPane: EventEmitter<boolean> =
		new EventEmitter<boolean>();
	@ViewChildren(StickyBallComponent) balls: StickyBallComponent[];

	private _show: boolean = false;
	get show(): boolean {
		return this._show;
	}
	set show(v) {
		if (this._show && !v) {
			this.closeBalls(this.balls);
		}
		this._show = v;
	}

	whoWantsToBounce: number = 0;

	constructor(
		private router: Router,
		private route: ActivatedRoute,
		private channelService: ChannelService,
		private signalRService: SignalrService,
		private statusService: ConsultationStatusService,
		private authService: AuthenticationService
	) { }

	ngOnInit() {
		this.getLatestChannels();

		this.show =
			this.route.snapshot?.firstChild?.firstChild?.component !=
			ConsultationComponent;

		this.router.events.subscribe((event) => {
			if (event instanceof ResolveStart) {
				this.show = !event.url.includes(RouteList.consultation);
			}
		});

		this.subs.push(this.statusService.activeChannelIsTypingObs.subscribe((userName: string) => {
			if (userName != null) {
				this.statusService.activeChannelObs.pipe(take(1)).subscribe((ac: ActiveChannel) => {
					if (ac?.channel != null) {
						this.latestChannels.unshift(this.latestChannels.splice(this.latestChannels.findIndex(c => c.id == ac.channel.id), 1)[0])
					}
				})
			}
		}))

		this.subs.push(this.signalRService.newChatMessage.subscribe(
			(message: IChatMessage) => {
				if (message == null) {
					return;
				}

				var existantChannel = this.latestChannels.find(
					(c) => c.id == message.channelId
				);

				if (existantChannel) {
					// only increment badge if it's not open
					if (
						message.senderId != this.authService.userValue.id
					) {
						existantChannel.unReadMessagesCount++;
						this.whoWantsToBounce = existantChannel.id;

						setTimeout(() => {
							this.whoWantsToBounce = 0;
						}, 2000);
					}
					existantChannel.messages.unshift(message);
					this.latestChannels.sort(
						(a, b) =>
							new Date(b.messages[0]?.sentDate).getTime() -
							new Date(a.messages[0]?.sentDate).getTime()
					);

				} else {
					this.channelService
						.read(message.channelId)
						.subscribe((result: IChannel) => {
							this.latestChannels.sort(
								(a, b) =>
									new Date(b.messages[0]?.sentDate).getTime() -
									new Date(a.messages[0]?.sentDate).getTime()
							);
							if (this.latestChannels.length > 3) {
								this.latestChannels.pop()
							}
							this.latestChannels.unshift(result);
						});
				}
			}
		));
	}

	ngOnDestroy(): void {
		this.subs.forEach((s) => s?.unsubscribe());
	}

	getLatestChannels() {
		this.channelService
			.readAll({
				pageSize: 4,
				totalItems: 0,
				totalPages: 0,
				currentPage: 0,
				onlyActive: true,
			})
			.subscribe((result: IChannel[]) => {
				this.latestChannels = result;
			});
	}

	onShowRightPaneHandle(event, id: number) {
		if (event) {
			this.closeBalls(this.balls.filter((b) => b.id != id));
		}
		this.onShowRightPane.emit(event);
	}

	closeBalls(list: StickyBallComponent[]) {
		list.forEach((b: StickyBallComponent) => {
			b.onClose();
		});
	}
}
