import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { IApiResponse } from '../interfaces/helpers/iResponseWrapper';

declare module 'rxjs' {
	interface Observable<T> {
		ToApiResult<TOutput>(
			this: Observable<IApiResponse>
		): Observable<TOutput>;
	}
}

function ToApiResult<T, TOutput>(this: Observable<IApiResponse>) {
	return this.pipe(
		map((response) => {
			return response.result as TOutput;
		})
	);
}

Observable.prototype.ToApiResult = ToApiResult;
