import { Injectable } from '@angular/core';
import { BookingFlows } from '../helpers/booking-flows.enum';
import { BookingStep } from '../helpers/booking-step';
import { RoleEnum } from '../helpers/role-enum';
import { RouteList } from '../helpers/route-enum';
import { AuthenticationService } from '../services/authentication.service';

/*
Guest:

new: type - agreement - cons settings - schedule/request - finish
rebook: type - rebook list - agreement - cons settings - schedule/request - finish
urgency: urgency - agreement - finish

Professional:

patient: select patient - type:new - agreement - cons settings - schedule/request - finish
	enquanto medico ou rapist seleccionas-te como professional na dropdown, sem disable
patient: select patient - type:rebook - rebook list - agreement - cons settings - schedule/request - finish

request: select request - agreement - cons settings - schedule/request - finish
	metes type a new, vais directo para agreement
	disable das dropdowns que já estão seleccionadas
	enquanto medico ou rapist seleccionas-te como professional na dropdown, sem disable
*/

@Injectable({
	providedIn: 'root',
})
export class BookingFlowFactory {
	constructor(private authService: AuthenticationService) {}

	private guestNew: string[] = [
		'appointment-type',
		'agreement',
		'consultation-settings',
		'schedule',
	];
	private guestRebook: string[] = [
		'appointment-type',
		'appointment-rebook',
		'agreement',
		'consultation-settings',
		'schedule',
	];
	private guestUrgency: string[] = [
		'urgency',
		'agreement',
		'consultation-settings',
	];
	private professionalSelect: string[] = [
		'select-patient',
		'appointment-type',
	];
	private professionalSelectNew: string[] = [
		'select-patient',
		'appointment-type',
		'agreement',
		'consultation-settings',
		'schedule',
	];
	private professionalSelectRebook: string[] = [
		'select-patient',
		'appointment-type',
		'appointment-rebook',
		'agreement',
		'consultation-settings',
		'schedule',
	];
	private professionalRequest: string[] = [
		'select-request',
		'agreement',
		'consultation-settings',
		'schedule',
	];

	private steps: BookingStep[] = [
		{
			route: RouteList.bookingAppointment,
			isActive: false,
			isValid: false,
			friendlyName: 'appointment-type',
		},
		{
			route: RouteList.bookingAgreement,
			isActive: false,
			isValid: false,
			friendlyName: 'agreement',
		},
		{
			route: RouteList.bookingConsultationType,
			isActive: false,
			isValid: false,
			friendlyName: 'consultation-settings',
		},
		{
			route: RouteList.bookingSchedule,
			isActive: false,
			isValid: false,
			friendlyName: 'schedule',
		},
		{
			route: RouteList.bookingRebookAppointment,
			isActive: false,
			isValid: false,
			friendlyName: 'appointment-rebook',
		},
		{
			route: RouteList.bookingUrgency,
			isActive: false,
			isValid: false,
			friendlyName: 'urgency',
		},
		{
			route: RouteList.bookingPatients,
			isActive: false,
			isValid: false,
			friendlyName: 'select-patient',
		},
		{
			route: RouteList.bookingRequests,
			isActive: false,
			isValid: false,
			friendlyName: 'select-request',
		},
	];

	build(flow: BookingFlows): BookingStep[] {
		var isGuest = this.authService.isGuest;
		var isProfessional = this.authService.hasSomeRole(
			RoleEnum.Doctor,
			RoleEnum.Employee,
			RoleEnum.Therapist
		);

		if (!isGuest && !isProfessional) {
			throw new Error('UnauthorizedOperation');
		}

		switch (flow) {
			case BookingFlows.new:
				if (isGuest) {
					return this.getSteps(this.guestNew);
				}
				if (isProfessional) {
					return this.getSteps(this.professionalSelectNew);
				}
				break;
			case BookingFlows.rebook:
				if (isGuest) {
					return this.getSteps(this.guestRebook);
				}
				if (isProfessional) {
					return this.getSteps(this.professionalSelectRebook);
				}
				break;
			case BookingFlows.urgency:
				if (isGuest) {
					return this.getSteps(this.guestUrgency);
				} else {
					throw new Error('UnauthorizedOperation');
				}
			case BookingFlows.select:
				if (isProfessional) {
					return this.getSteps(this.professionalSelect);
				} else {
					throw new Error('UnauthorizedOperation');
				}
			case BookingFlows.request:
				if (isProfessional) {
					return this.getSteps(this.professionalRequest);
				} else {
					throw new Error('UnauthorizedOperation');
				}
			default:
				throw new Error('UnhandledError');
		}
	}

	getSteps(list: string[]): BookingStep[] {
		var flowSteps: BookingStep[] = [];
		list.forEach((name) => {
			flowSteps.push(this.steps.find((bs) => bs.friendlyName == name));
		});
		return flowSteps;
	}
}
