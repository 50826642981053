<div class="card card-profile">
	<div class="card-body text-center">
		<div *ngFor="let col of row.columns">
			<div style="cursor:pointer" *ngIf="col.displayMode == TableListColumnMode.thumbnail"
				class="avatar box-shadow-2 mb-4" (click)="setTableListButtonClicked()">
				<table-list-thumbnail *ngIf="col.value" [source]="col.value">
				</table-list-thumbnail>
			</div>
			<h5 *ngIf="col.displayMode == TableListColumnMode.title">
				{{col.value}}
			</h5>
			<p class="text-muted" *ngIf="col.displayMode == TableListColumnMode.text">
				{{col.value}}
			</p>
			<p class="text-muted" *ngIf="col.displayMode == TableListColumnMode.date">
				{{col.text}}
			</p>
			<div *ngIf="col.displayMode == TableListColumnMode.checkbox">
				<div class="form-check">
					<input class="form-check-input" type="checkbox" checked="{{col.value}}" disabled>
					<label>{{col.name}}</label>
				</div>
			</div>
			<div *ngIf="col.displayMode == TableListColumnMode.html">
				<span [innerHtml]="col.value"></span>
			</div>
			<div *ngIf="col.displayMode == TableListColumnMode.list">
				<ul class="list-group list-group-flush">
					<li class="list-group-item" *ngFor="let item of col.value">
						{{item.text}}
					</li>
				</ul>
			</div>
			<div *ngIf="col.displayMode == TableListColumnMode.checkboxSwitch">
				<div class="form-check form-switch">
					<input class="form-check-input" type="checkbox" role="switch" disabled checked="{{col.value}}">
					<label>{{col.name}}</label>
				</div>
			</div>
		</div>
		<div class="mt-4">
			<ng-container *ngFor="let btn of row.actionButtons">
				<table-list-btn [btn]="btn" [rowId]="row.id" [tableIdentifier]="tableIdentifier">
				</table-list-btn>
			</ng-container>
		</div>
	</div>
</div>