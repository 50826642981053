import { Component, OnDestroy, OnInit } from '@angular/core';
import {
	UntypedFormBuilder,
	UntypedFormGroup,
	Validators,
} from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { IChatbotActivity } from '@app/01.global/interfaces/iChatbot';
import { IUser } from '@app/01.global/interfaces/IUser';
import { AuthenticationService } from '@app/01.global/services/authentication.service';
import { ChatbotService } from '@app/01.global/services/chatbot.service';
import { Subscription } from 'rxjs';

@Component({
	selector: 'app-shout-box',
	templateUrl: './shout-box.component.html',
	styleUrls: ['./shout-box.component.scss'],
})
export class ShoutBoxComponent implements OnInit, OnDestroy {
	form: UntypedFormGroup;
	messages: IChatbotActivity[] = [];
	subs: Subscription[] = [];
	currentUser: IUser;

	constructor(
		private route: ActivatedRoute,
		private router: Router,
		private formBuilder: UntypedFormBuilder,
		private service: ChatbotService,
		private auth: AuthenticationService
	) {}

	ngOnInit() {
		this.subs.push(
			this.auth.user.subscribe((u: IUser) => {
				this.currentUser = u;
			})
		);
		this.form = this.formBuilder.group({
			content: ['', Validators.required],
		});

		this.subs.push(
			this.service.newMessage.subscribe((m: IChatbotActivity) => {
				if (!!m) {
					if (m.from.id == this.currentUser.userName) {
						m.sender = 'user';
					} else {
						m.sender = 'bot';
					}
					this.messages.push(m);
				}
			})
		);
	}

	ngOnDestroy(): void {
		this.subs.forEach((s) => s?.unsubscribe());
	}

	sendMessage() {
		if (this.form.invalid) {
			return;
		}
		var question = this.form.value.content;

		this.service.askQuestion(question).subscribe();

		this.form.reset();
	}
}
