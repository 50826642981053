import { Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { addHours, subHours } from 'date-fns';
import {
	BehaviorSubject,
	finalize,
	interval,
	map,
	Observable,
	of,
	share,
	Subscription,
	take,
	tap,
} from 'rxjs';
import { AttendanceWindowType } from '../helpers/attendance-window-type.enum';
import { ChannelType } from '../helpers/channel-type';
import { MessageTypes } from '../helpers/message-types';
import { Utils } from '../helpers/utils';
import { IAppointment } from '../interfaces/IAppointment';
import { IChannel } from '../interfaces/iChannel';
import { IChatMessage } from '../interfaces/IChatMessage';
import { IDocument } from '../interfaces/IDocument';
import { INote } from '../interfaces/iNote';
import { IPatientWaiting } from '../interfaces/iPatientWaiting';
import { ITriageFormUser } from '../interfaces/iTriageFormUser';
import { IVideoRoom } from '../interfaces/IVideoRoom';
import { CalendarMapperService } from '../mappers/calendar-mapper.service';
import { AttendanceChannel } from '../models/attendance-channel';
import { AttendanceWindow } from '../models/attendance-window';
import { DatatableFilters } from '../models/DatatableFilters';
import { AppointmentService } from '../services/appointment.service';
import { AttendanceService } from '../services/attendance.service';
import { AuthenticationService } from '../services/authentication.service';
import { ChannelService } from '../services/channel.service';
import { ChatMessageService } from '../services/chat-message.service';
import { SignalrService } from '../services/signalr.service';

@Injectable({
	providedIn: 'root',
})
export class AttendanceStatusService {
	private roomsSubject = new BehaviorSubject<IAppointment[]>([]);
	private patientSubject = new BehaviorSubject<IAppointment[]>([]);
	private windowsSubject = new BehaviorSubject<AttendanceWindow[]>([]);
	public rooms$ = this.roomsSubject.asObservable();
	public patients$ = this.patientSubject.asObservable();
	public windows$ = this.windowsSubject.asObservable();
	private doctorChannelsSubject = new BehaviorSubject<IChannel[]>([]);
	public doctorChannels$ = this.doctorChannelsSubject.asObservable();

	public hasOnGoingCall: boolean = false;

	private loadedChannels: AttendanceChannel[] = [];
	private onGoingMessageRequests: {
		channelId: number;
		messageRequest$: Observable<IChatMessage[]>;
	}[] = [];
	private onGoingDocumentRequests: {
		channelId: number;
		documentRequest$: Observable<IDocument[]>;
	}[] = [];
	private onGoingNoteRequests: {
		channelId: number;
		noteRequest$: Observable<INote[]>;
	}[] = [];

	private subs: Subscription[] = [];

	private readonly windowsStorageKey = 'attendance-windows';
	private firstLoadCompleted = false;

	constructor(
		private authService: AuthenticationService,
		private appointmentService: AppointmentService,
		private calendarMapperService: CalendarMapperService,
		private attendanceService: AttendanceService,
		private channelService: ChannelService,
		private chatMessageService: ChatMessageService,
		private translate: TranslateService,
		private signalRService: SignalrService
	) { }

	reset() {
		this.subs.forEach((s) => s?.unsubscribe());
		this.onGoingMessageRequests = [];
		this.onGoingDocumentRequests = [];
		this.onGoingNoteRequests = [];
		this.loadedChannels = [];
		this.roomsSubject.next([]);
		this.doctorChannelsSubject.next([]);
		this.patientSubject.next([]);
		this.pushNewWindows([]);
	}

	updateSidebarMainLayout() {
		var filters: DatatableFilters = {
			pageSize: 0,
			currentPage: 0,
			startMinimumDate: subHours(new Date(), 4),
			startMaximumDate: addHours(new Date(), 4),
		};

		this.appointmentService
			.readAll(filters)
			.subscribe((response: IAppointment[]) => {
				response.forEach(
					(a) =>
					(a.appointmentLogo = {
						url: this.calendarMapperService.getLogo(a),
					})
				);

				if (this.authService.isGuest) {
					response.sort(
						(a, b) =>
							new Date(a.startDate).getTime() -
							new Date(b.startDate).getTime()
					);
					this.updateRooms(response);
				}
				if (this.authService.isDoctor || this.authService.isTherapist) {
					this.updatePatients(response);
				}
			});
	}

	getVideoSession(windowId: string): OT.Session {
		return this.windowsSubject.getValue().find((w) => w.id == windowId)
			.session;
	}

	leaveVideoCall(windowId: string) {
		var window = this.windowsSubject
			.getValue()
			.find((w) => w.id == windowId);

		// testar se faz sentido
		// if (window.appointmentId) {
		// 	this.appointmentService.finish(window.appointmentId).subscribe();
		// }
		this.channelService.leftVideoRoom(window.channelId).subscribe();

		this.hasOnGoingCall = false;

		this.closeWindow(windowId);
	}

	setGuestLeaveAllWaitingRooms() {
		this.attendanceService.setGuestLeaveAllWaitingRooms().subscribe();
	}

	endVideoCall(windowId: string) {
		var window = this.windowsSubject
			.getValue()
			.find((w) => w.id == windowId);

		this.channelService.end(window.channelId).subscribe();
		if (window.appointmentId) {
			this.appointmentService.finish(window.appointmentId).subscribe();
		}

		var attendanceChannel = this.loadedChannels.find(
			(ac) => ac.channel.id == window.channelId
		);
		attendanceChannel.channel.videoRoom = null;

		this.hasOnGoingCall = false;

		this.closeWindow(windowId);
	}

	answerVideoCall(channelId: number) {
		// desligar outra chamada antes de atender
		var currentWindows = this.windowsSubject.getValue();
		var windowsExceptVideoCall = currentWindows.filter(
			(w) => w.type != AttendanceWindowType.VideoCall
		);
		if (currentWindows.length != windowsExceptVideoCall.length) {
			this.pushNewWindows(windowsExceptVideoCall);
		}

		this.openWindow(channelId, AttendanceWindowType.VideoCall);
	}

	openWindow(
		channelId: number,
		type: AttendanceWindowType,
		appointmentId?: number
	): string {
		var currentWindows = this.windowsSubject.getValue();

		var openWindow = currentWindows.find(
			(w) => w.channelId == channelId && w.type == type
		);
		if (openWindow) {
			currentWindows.forEach(w => {
				w.isActive = false;
			});
			openWindow.isActive = true
			if (openWindow.isMinimized) {
				openWindow.isMinimized = false;
			}
			this.pushNewWindows(currentWindows);
			return;
		}

		var id = crypto.randomUUID();

		this.getChannel(channelId).subscribe((c) => {
			this.addWindow({
				id: id,
				type: type,
				channelId: channelId,
				title: c.name,
				isActive: true,
				isMinimized: false,
				isMaximized: false,
			}),
				appointmentId;
		});

		return id;
	}

	closeWindow(id: string) {
		var windows = this.windowsSubject.getValue();

		windows = windows.filter((w) => w.id != id);

		this.pushNewWindows(windows);
	}

	minimizeWindow(id: string) {
		var windows = this.windowsSubject.getValue();

		windows.find((w) => w.id == id).isMinimized = true;

		this.pushNewWindows(windows);
	}

	maximizeWindow(id: string) {
		var windows = this.windowsSubject.getValue();

		windows.find((w) => w.id == id).isMaximized = true;

		this.pushNewWindows(windows);
	}

	setWindowAsActive(id: string) {
		var windows = this.windowsSubject.getValue();
		var window = windows.find((w) => w.id == id);
		window.isMinimized = false;
		windows.forEach(w => {
			w.isActive = false;
		});
		window.isActive = true;

		this.pushNewWindows(windows);
	}

	reopenWindow(id: string) {
		var windows = this.windowsSubject.getValue();
		var window = windows.find((w) => w.id == id);
		window.isMinimized = false;
		windows.forEach(w => {
			w.isActive = false;
		});
		window.isActive = true;

		if (window.type == AttendanceWindowType.Chat) {
			this.loadedChannels
				.find((ac) => ac.channel.id == window.channelId)
				.unreadMessageCountSubject.next(0);
		}

		this.pushNewWindows(windows);
	}

	getChannelsAsDoctor() {
		this.attendanceService
			.getChannelsAsDoctor()
			.subscribe((channels: IChannel[]) => {
				channels.forEach((element) => {
					this.loadChannel(element);
				});
				this.doctorChannelsSubject.next(channels);
				if (!this.firstLoadCompleted) {
					this.readLocalStorage();
					this.firstLoadCompleted = true;
				}
			});
	}

	getChannelsAsPatient(doctorPublicId: string): Observable<IChannel> {
		if (this.loadedChannels.length > 0) {
			return of(
				this.loadedChannels.find(
					(element) =>
						element.channel.channelType == ChannelType.Consultation
				).channel
			);
		}
		return this.attendanceService.getChannelsAsPatient(doctorPublicId).pipe(
			tap((channels: IChannel[]) => {
				channels.forEach((element) => {
					this.loadChannel(element);
					if (element.videoRoom) {
						this.answerVideoCall(element.id);
					}
				});
				if (!this.firstLoadCompleted) {
					this.readLocalStorage();
					this.firstLoadCompleted = true;
				}
			}),
			map((channels: IChannel[]) => {
				return channels.find(
					(element) => element.channelType == ChannelType.Consultation
				);
			})
		);
	}

	getChannelAppointments(channelId: number): IAppointment[] {
		return this.loadedChannels.find((c) => c.channel.id == channelId)
			.channel.appointments;
	}

	getChannelTriageAnswer(channelId: number): ITriageFormUser[] {
		return this.loadedChannels.find((c) => c.channel.id == channelId)
			.channel.appointments[0].triageFormUsers;
	}

	getChannel(channelId: number): Observable<IChannel> {
		var attendanceChannel = this.loadedChannels.find(
			(c) => c.channel.id == channelId
		);
		if (attendanceChannel != null) {
			return of(attendanceChannel.channel);
		}

		if (this.authService.isDoctor || this.authService.isTherapist) {
			return this.attendanceService
				.getChannelsAsDoctor()
				.pipe(map((channels: IChannel[]) => {
					var newChannels = channels.filter(c => !this.loadedChannels.map(ac => ac.channel.id).includes(c.id));
					newChannels.forEach(element => {
						this.loadChannel(element);
					});
					var currentDoctorChannels = this.doctorChannelsSubject.getValue();
					this.doctorChannelsSubject.next(currentDoctorChannels.concat(newChannels).sort((a, b) => {
						var aHasApps = a.appointments?.length > 0;
						var bHasApps = b.appointments?.length > 0;
						if (!aHasApps && !bHasApps) {
							return 0;
						}
						if (!aHasApps) {
							return 1
						}
						if (!bHasApps) {
							return -1
						}
						return a.appointments[0].startDate > b.appointments[0].startDate ? 1 : -1
					}));
					return channels.find(c => c.id);
				}));
		}
	}

	getGeneralChannelsAsPatient(): {
		channel: IChannel;
		unreadMessageCount$: Observable<number>;
	}[] {
		return this.loadedChannels
			.filter((ac) => ac.channel.channelType != ChannelType.Consultation)
			.map((ac) => {
				return {
					channel: ac.channel,
					unreadMessageCount$: ac.unreadMessageCount$,
				};
			});
	}

	getChannelMessages$(channelId: number): Observable<IChatMessage[]> {
		return this.loadedChannels.find((c) => c.channel.id == channelId)
			.messages$;
	}

	getChannelDocuments$(channelId: number): Observable<IDocument[]> {
		return this.loadedChannels.find((c) => c.channel.id == channelId)
			.documents$;
	}

	getChannelNotes$(channelId: number): Observable<INote[]> {
		return this.loadedChannels.find((c) => c.channel.id == channelId)
			.notes$;
	}

	getChannelOnline$(channelId: number): Observable<boolean> {
		return this.loadedChannels.find((c) => c.channel.id == channelId)
			.isOnline$;
	}

	getWhoIsTyping$(channelId: number): Observable<string> {
		return this.loadedChannels.find((c) => c.channel.id == channelId)
			.whoIsTyping$;
	}

	getPatientsWaiting$(channelId: number): Observable<IPatientWaiting[]> {
		return this.loadedChannels.find((c) => c.channel.id == channelId)
			.patientsWaiting$;
	}

	getChannelUnreadMessageCount$(channelId: number): Observable<number> {
		return this.loadedChannels.find((c) => c.channel.id == channelId)
			.unreadMessageCount$;
	}

	loadMoreMessages(channelId: number): void {
		this.readMessages(channelId, true).subscribe((results) => {
			var attendanceChannel = this.loadedChannels.find(
				(c) => c.channel.id == channelId
			);
			if (results.length == 0) {
				attendanceChannel.messageFilters.currentPage--;
			}
			var messagesSubject = attendanceChannel.messagesSubject;
			messagesSubject.next(results.concat(messagesSubject.getValue()));
		});
	}

	loadMoreDocuments(channelId: number): void {
		this.readDocuments(channelId, true).subscribe((results) => {
			var attendanceChannel = this.loadedChannels.find(
				(c) => c.channel.id == channelId
			);
			if (results.length == 0) {
				attendanceChannel.documentFilters.currentPage--;
			}
			var documentsSubject = attendanceChannel.documentsSubject;
			documentsSubject.next(results.concat(documentsSubject.getValue()));
		});
	}

	loadMoreNotes(channelId: number): void {
		this.readNotes(channelId, true).subscribe((results) => {
			var attendanceChannel = this.loadedChannels.find(
				(c) => c.channel.id == channelId
			);
			if (results.length == 0) {
				attendanceChannel.noteFilters.currentPage--;
			}
			var notesSubject = attendanceChannel.notesSubject;
			notesSubject.next(results.concat(notesSubject.getValue()));
		});
	}

	afterSendingMessage(channelId: number): void {
		var filters = this.loadedChannels.find(
			(lc) => lc.channel.id == channelId
		).messageFilters;
		var latestMessagesFilters = { ...filters };
		latestMessagesFilters.currentPage = 0;

		this.chatMessageService
			.readAll(latestMessagesFilters)
			.subscribe((results) => {
				var messagesSubject = this.loadedChannels.find(
					(c) => c.channel.id == channelId
				).messagesSubject;
				messagesSubject.next(
					Utils.arrayUnique(
						[...messagesSubject.getValue(), ...results],
						'id'
					)
				);
			});
	}

	afterCreatingNote(channelId: number): void {
		var filters = this.loadedChannels.find(
			(lc) => lc.channel.id == channelId
		).noteFilters;
		var latestNotesFilters = { ...filters };
		latestNotesFilters.currentPage = 0;

		this.channelService
			.readMeetingNotes(latestNotesFilters)
			.subscribe((results) => {
				var notesSubject = this.loadedChannels.find(
					(c) => c.channel.id == channelId
				).notesSubject;
				notesSubject.next(
					Utils.arrayUnique(
						[...notesSubject.getValue(), ...results],
						'id'
					)
				);
			});
	}

	private readMessages(
		channelId: number,
		addingMore: boolean = false
	): Observable<IChatMessage[]> {
		// Prevent duplicate requests on scroll.
		// More: https://stackoverflow.com/a/50865911/6441494

		var onGoingRequest = this.onGoingMessageRequests.find(
			(or) => or.channelId == channelId
		);

		if (onGoingRequest) {
			return onGoingRequest.messageRequest$;
		} else {
			var filters = this.loadedChannels.find(
				(lc) => lc.channel.id == channelId
			).messageFilters;
			if (addingMore) {
				filters.currentPage++;
			} else {
				filters.currentPage = 0;
			}

			var messageRequest$ = this.chatMessageService.readAll(filters).pipe(
				share(),
				finalize(() => {
					this.onGoingMessageRequests =
						this.onGoingMessageRequests.filter(
							(or) => or.channelId !== channelId
						);
				})
			);
			this.onGoingMessageRequests.push({
				channelId: channelId,
				messageRequest$: messageRequest$,
			});
			return messageRequest$;
		}
	}

	private readDocuments(
		channelId: number,
		addingMore: boolean = false
	): Observable<IDocument[]> {
		// Prevent duplicate requests on scroll.
		// More: https://stackoverflow.com/a/50865911/6441494

		var onGoingRequest = this.onGoingDocumentRequests.find(
			(or) => or.channelId == channelId
		);

		if (onGoingRequest) {
			return onGoingRequest.documentRequest$;
		} else {
			var filters = this.loadedChannels.find(
				(lc) => lc.channel.id == channelId
			).documentFilters;
			if (addingMore) {
				filters.currentPage++;
			}
			else {
				filters.currentPage = 0;
			}

			var documentRequest$ = this.channelService
				.readSharedFiles(filters)
				.pipe(
					share(),
					finalize(() => {
						this.onGoingDocumentRequests =
							this.onGoingDocumentRequests.filter(
								(or) => or.channelId !== channelId
							);
					})
				);
			this.onGoingDocumentRequests.push({
				channelId: channelId,
				documentRequest$: documentRequest$,
			});
			return documentRequest$;
		}
	}

	private readNotes(
		channelId: number,
		addingMore: boolean = false
	): Observable<INote[]> {
		// Prevent duplicate requests on scroll.
		// More: https://stackoverflow.com/a/50865911/6441494

		var onGoingRequest = this.onGoingNoteRequests.find(
			(or) => or.channelId == channelId
		);

		if (onGoingRequest) {
			return onGoingRequest.noteRequest$;
		} else {
			var filters = this.loadedChannels.find(
				(lc) => lc.channel.id == channelId
			).noteFilters;
			if (addingMore) {
				filters.currentPage++;
			}
			else {
				filters.currentPage = 0;
			}

			var noteRequest$ = this.channelService
				.readMeetingNotes(filters)
				.pipe(
					share(),
					finalize(() => {
						this.onGoingNoteRequests =
							this.onGoingNoteRequests.filter(
								(or) => or.channelId !== channelId
							);
					})
				);
			this.onGoingNoteRequests.push({
				channelId: channelId,
				noteRequest$: noteRequest$,
			});
			return noteRequest$;
		}
	}

	private addWindow(newWindow: AttendanceWindow, appointmentId?: number) {
		var windows = this.windowsSubject.getValue();
		windows.forEach((w) => {
			w.isActive = false;
		});

		var attendanceChannel: AttendanceChannel = this.loadedChannels.find(
			(ac) => ac.channel.id == newWindow.channelId
		);

		switch (newWindow.type) {
			case AttendanceWindowType.TriageAnswer:
				newWindow.width = 800;
				newWindow.height = 500;
				newWindow.left = 50;
				newWindow.top = 50;
				break;
			case AttendanceWindowType.Notes:
				this.onGoingNoteRequests = this.onGoingNoteRequests.filter(
					(or) => or.channelId != newWindow.channelId
				);
				this.readNotes(newWindow.channelId, false).subscribe(
					(results: INote[]) => {
						attendanceChannel.notesSubject.next(results);
					}
				);
				newWindow.width = 500;
				newWindow.height = 300;
				newWindow.left = 200;
				newWindow.top = 200;
				break;
			case AttendanceWindowType.Documents:
				this.onGoingDocumentRequests =
					this.onGoingDocumentRequests.filter(
						(or) => or.channelId != newWindow.channelId
					);
				this.readDocuments(newWindow.channelId, false).subscribe(
					(results: IDocument[]) => {
						attendanceChannel.documentsSubject.next(results);
					}
				);
				newWindow.width = 800;
				newWindow.height = 600;
				newWindow.left = 200;
				newWindow.top = 200;
				if (this.authService.isGuest) {
					newWindow.title =
						this.translate.instant('ConsultDocuments');
				}
				break;
			case AttendanceWindowType.Chat:
				this.onGoingMessageRequests =
					this.onGoingMessageRequests.filter(
						(or) => or.channelId != newWindow.channelId
					);
				this.readMessages(newWindow.channelId, false).subscribe(
					(results: IChatMessage[]) => {
						attendanceChannel.unreadMessageCountSubject.next(0);
						attendanceChannel.messagesSubject.next(results);
					}
				);
				newWindow.width = 500;
				newWindow.height = 600;
				newWindow.left = 200;
				newWindow.top = 200;
				break;
			case AttendanceWindowType.Booking:
				newWindow.width = window.innerWidth - 240;
				newWindow.height = window.innerHeight;
				newWindow.left = 0;
				newWindow.top = 0;
				newWindow.isMaximized = true;
				break;
			case AttendanceWindowType.VideoCall:
				windows.forEach((w) => {
					w.isMinimized = true;
					w.isMaximized = false;
				});
				newWindow.width = window.innerWidth - 240;
				newWindow.height = window.innerHeight;
				newWindow.left = 0;
				newWindow.top = 0;
				newWindow.isMaximized = true;
				this.hasOnGoingCall = true;
				if (appointmentId) {
					newWindow.appointmentId = appointmentId;
					this.appointmentService.start(appointmentId).subscribe();
				}
				if (attendanceChannel.channel.videoRoom) {
					newWindow.session = OT.initSession(
						attendanceChannel.channel.videoRoom.apiKey,
						attendanceChannel.channel.videoRoom.sessionId
					);
					newWindow.session.connect(
						attendanceChannel.channel.videoRoom.token,
						(err) => {
							if (err) {
								this.getNewVideoSession(
									newWindow,
									attendanceChannel,
									windows
								);
								return;
							} else {
								this.channelService.notifyJoinedVideoRoom(attendanceChannel.channel.id).subscribe();
							}
						}
					);
				} else if (this.firstLoadCompleted) {
					this.getNewVideoSession(
						newWindow,
						attendanceChannel,
						windows
					);
					return;
				} else {
					return
				}
			default:
				break;
		}

		windows.push(newWindow);

		this.pushNewWindows(windows);
	}

	private updateRooms(response: IAppointment[]) {
		this.roomsSubject.next(response);
	}

	private updatePatients(response: IAppointment[]) {
		this.patientSubject.next(response);
	}

	private loadChannel(c: IChannel) {
		var messageSubject = new BehaviorSubject<IChatMessage[]>([]);
		var documentSubject = new BehaviorSubject<IDocument[]>([]);
		var noteSubject = new BehaviorSubject<INote[]>([]);
		var unreadMessageCountSubject = new BehaviorSubject<number>(
			c.unReadMessagesCount
		);
		var whoIsTypingSubject = new BehaviorSubject<string>(null);
		var patientsWaitingSubject = new BehaviorSubject<IPatientWaiting[]>(c.patientsWaiting ?? []);

		var isOnlineSubject = new BehaviorSubject<boolean>(
			c.doctors
				.concat(c.users)
				.some(u => u.id != this.authService.userValue.id && u.isOnline));

		var newAttendanceChannel: AttendanceChannel = {
			channel: c,
			messagesSubject: messageSubject,
			messages$: messageSubject.asObservable(),
			messageFilters: {
				pageSize: 10,
				totalItems: 0,
				totalPages: 0,
				currentPage: 0,
				readFromWhoId: c.id,
			},
			unreadMessageCountSubject: unreadMessageCountSubject,
			unreadMessageCount$: unreadMessageCountSubject.asObservable(),
			whoIsTypingSubject: whoIsTypingSubject,
			whoIsTyping$: whoIsTypingSubject.asObservable(),
			documentsSubject: documentSubject,
			documents$: documentSubject.asObservable(),
			documentFilters: {
				pageSize: 10,
				totalItems: 0,
				totalPages: 0,
				currentPage: 0,
				readFromWhoId: c.id,
			},
			notesSubject: noteSubject,
			notes$: noteSubject.asObservable(),
			isOnlineSubject: isOnlineSubject,
			isOnline$: isOnlineSubject.asObservable(),
			noteFilters: {
				pageSize: 10,
				totalItems: 0,
				totalPages: 0,
				currentPage: 0,
				readFromWhoId: c.id,
			},
			patientsWaitingSubject: patientsWaitingSubject,
			patientsWaiting$: patientsWaitingSubject.asObservable()
		};

		this.loadedChannels.push(newAttendanceChannel);
	}

	subscribeToSignalR() {
		// new message
		this.subs.push(
			this.signalRService.newChatMessage.subscribe(
				(message: IChatMessage) => {
					if (!message) {
						return;
					}

					var attendanceChannel = this.loadedChannels.find(
						(ac) => ac.channel.id == message.channelId
					);

					if (attendanceChannel) {
						if (
							message.sender.displayName ==
							attendanceChannel?.whoIsTypingSubject.getValue()
						) {
							attendanceChannel.whoIsTypingSubject.next(
								message.sender.displayName
							);
						}

						attendanceChannel.messagesSubject.next([
							...attendanceChannel.messagesSubject.getValue(),
							message,
						]);

						var currentWindows = this.windowsSubject.getValue();
						var chatWindow = currentWindows.find(
							(w) =>
								w.channelId == message.channelId &&
								w.type == AttendanceWindowType.Chat
						);

						if (!chatWindow || chatWindow.isMinimized) {
							attendanceChannel.unreadMessageCountSubject.next(
								attendanceChannel.unreadMessageCountSubject.getValue() +
								1
							);
						}

						if (message.typeId == MessageTypes.UploadDocumentMessage) {
							attendanceChannel.documentsSubject.next([
								...attendanceChannel.documentsSubject.getValue(),
								message.attachment,
							]);
						}

						if (message.typeId == MessageTypes.VideoCallEnded) {
							attendanceChannel.channel.videoRoom = null;
							var videoCallWindow = currentWindows.find(
								(w) =>
									w.channelId == message.channelId &&
									w.type == AttendanceWindowType.VideoCall
							);

							if (videoCallWindow) {
								this.hasOnGoingCall = false;
								this.closeWindow(videoCallWindow.id);
							}
						}
					}

					if (message.typeId == MessageTypes.NewAppointment) {
						if (this.authService.isDoctor || this.authService.isTherapist) {
							this.getChannel(message.channelId).subscribe()
						}
					}
				}
			)
		);

		// is typing
		this.subs.push(
			this.signalRService.iAmTyping.subscribe(
				(model: { channelId: number; userName: string }) => {
					if (
						model == null ||
						model.userName == this.authService.userValue.userName
					) {
						return;
					}

					var attendanceChannel = this.loadedChannels.find(
						(ac) => ac.channel.id == model.channelId
					);

					if (attendanceChannel == null) {
						return;
					}

					attendanceChannel.whoIsTypingSubject.next(model.userName);

					attendanceChannel.isStillTypingSubscription?.unsubscribe();
					attendanceChannel.isStillTypingSubscription = interval(2500)
						.pipe(take(1))
						.subscribe(() =>
							attendanceChannel.whoIsTypingSubject.next(null)
						);
				}
			)
		);

		this.subs.push(
			this.signalRService.iAmOnline.subscribe((userName: string) => {
				if (
					userName?.length > 0
				) {
					this.loadedChannels.forEach(ac => {
						if (ac.channel.doctors.concat(ac.channel.users).some(u => u.userName == userName)) {
							ac.isOnlineSubject.next(true);
						}
					});
				}
			})
		);

		this.subs.push(
			this.signalRService.iAmOffline.subscribe((userName: string) => {
				if (
					userName?.length > 0
				) {
					this.loadedChannels.forEach(ac => {
						if (ac.channel.doctors.concat(ac.channel.users)
							.some(u => u.userName == userName)
							&& ac.channel.doctors.concat(ac.channel.users)
								.every(u => u.id == this.authService.userValue.id || u.userName == userName || u.isOnline == false)) {
							if (this.authService.isGuest || ac.channel.appointments?.length > 0) {
								ac.isOnlineSubject.next(false);
							} else {
								this.loadedChannels = this.loadedChannels.filter(lc => lc.channel.id != ac.channel.id);
								this.doctorChannelsSubject.next(this.doctorChannelsSubject.getValue().filter(c => c.id != ac.channel.id))
							}
						}
					});
				}
			})
		);

		this.subs.push(
			this.signalRService.guestEntering.subscribe(
				(model: IPatientWaiting) => {
					if (model) {
						if (this.loadedChannels.some((lc) => lc.channel.id == model.channelId)) {
							this.loadedChannels
								.find((lc) => lc.channel.id == model.channelId)
								.patientsWaitingSubject.next([model]);
						} else {
							this.getChannel(model.channelId).subscribe(c => {
								// this.loadedChannels
								// 	.find((lc) => lc.channel.id == c.id)
								// 	.patientsWaitingSubject.next([model]);
							})
						}
						this.loadedChannels
							.filter(ac => ac.channel.channelType != ChannelType.Consultation
								&& ac.channel.users.map(u => u.id).includes(model.userId))
							.forEach(ac => {
								var subject = ac.patientsWaitingSubject;
								subject.next([...subject.getValue().filter(pw => pw.userId != model.userId), model])
							});
					}
				}
			)
		);

		this.subs.push(
			this.signalRService.guestLeaving.subscribe(
				(model: IPatientWaiting) => {
					if (model) {

						var acConsultation = this.loadedChannels.find((lc) => lc.channel.id == model.channelId);

						if (acConsultation.channel.appointments?.length > 0) {
							acConsultation.patientsWaitingSubject.next([]);
						} else {
							let index = this.loadedChannels.findIndex(
								(lc) => lc.channel.id === model.channelId
							);
							this.loadedChannels.splice(index, 1);
							this.doctorChannelsSubject.next(
								this.loadedChannels.map((lc) => lc.channel)
							);
						}

						this.loadedChannels
							.filter(ac => ac.channel.channelType != ChannelType.Consultation
								&& ac.channel.users.map(u => u.id).includes(model.userId))
							.forEach(ac => {
								var subject = ac.patientsWaitingSubject;
								subject.next([...subject.getValue().filter(pw => pw.userId != model.userId)])
							});
					}
				}
			)
		);
	}

	setGuestEntering(model: IPatientWaiting) {
		this.attendanceService
			.setGuestEntering(model)
			.subscribe((record: IPatientWaiting) => {
				model.lastEnterDate = record.lastEnterDate;
				this.signalRService.sendGuestEntering(model);
			});
	}

	setGuestLeaving(model: IPatientWaiting) {
		this.attendanceService
			.setGuestLeaving(model)
			.subscribe((record: IPatientWaiting) => {
				this.signalRService.sendGuestLeaving(model);
			});
	}

	private getNewVideoSession(
		window: AttendanceWindow,
		aChannel: AttendanceChannel,
		otherWindows: AttendanceWindow[]
	) {
		this.channelService
			.readVideoRoom(window.channelId)
			.subscribe((room: IVideoRoom) => {
				window.session = OT.initSession(room.apiKey, room.sessionId);
				aChannel.channel.videoRoom = room;
				window.session.connect(
					aChannel.channel.videoRoom.token,
					(err) => {
						if (err) {
							throw err;
						}
					}
				);
				otherWindows.push(window);

				this.pushNewWindows(otherWindows);
			});
		return;
	}

	private pushNewWindows(windows: AttendanceWindow[]) {
		localStorage.setItem(this.windowsStorageKey, JSON.stringify(windows));
		this.windowsSubject.next(windows);
	}

	private readLocalStorage() {
		var windowsString = localStorage.getItem(this.windowsStorageKey);
		var windows = JSON.parse(windowsString);
		windows?.forEach((window) => {
			this.openWindow(
				window.channelId,
				window.type,
				window.appointmentId
			);
		});
	}
}
