import { Directive, ElementRef, Input } from "@angular/core";
@Directive({
	selector: "[appHover]",
	host: {
		"(mouseenter)": "onMouseEnter()",
		"(mouseleave)": "onMouseLeave()",
	},
})
export class HoverDirective {
	el: HTMLElement;

	constructor(el: ElementRef) {
		this.el = el.nativeElement;
	}

	@Input("triggerClass") triggerClass: string;

	onMouseEnter() {
		this.el.classList.add(this.triggerClass ?? "");
	}
	onMouseLeave() {
		this.el.classList.remove(this.triggerClass ?? "");
	}
}
