import { Component, Input, OnInit } from '@angular/core';
import { SharedAnimations } from '@app/01.global/animations/shared-animations';
import { AuthenticationService } from '@app/01.global/services/authentication.service';
import { AttendanceStatusService } from '@app/01.global/status-services/attendance-status.service';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { MediaPermissionsError, MediaPermissionsErrorType } from 'mic-check';
import { requestAudioPermissions } from 'mic-check/lib/requestMediaPermissions';
import { WebcamInitError, WebcamUtil } from 'ngx-webcam';

@Component({
	selector: 'app-attendance-devices-check',
	templateUrl: './attendance-devices-check.component.html',
	styleUrls: ['./attendance-devices-check.component.scss'],
	animations: [SharedAnimations],
})
export class AttendanceDevicesCheckComponent implements OnInit {
	private _modalIsOpen: boolean;
	@Input('modalIsOpen') get modalIsOpen(): boolean {
		return this._modalIsOpen;
	}
	public set modalIsOpen(value: boolean) {
		this._modalIsOpen = value;
		this.checkCamera();
	}
	step: 'webcam' | 'mic' = 'webcam';
	cameraIsActive: boolean;
	cameraWasDenied: boolean;
	cameraNotFound: boolean;
	micIsActive: boolean;
	micWasDenied: boolean;
	micNotFound: boolean;
	currentUserFirstName: string = '';
	constructor(
		private authService: AuthenticationService,
		private statusService: AttendanceStatusService,
		public modalService: NgbModal
	) { }

	ngOnInit(): void {
		this.currentUserFirstName = this.authService.userValue.firstName;
	}

	checkCamera() {
		WebcamUtil.getAvailableVideoInputs().then(
			(mediaDevices: MediaDeviceInfo[]) => {
				//MediaDeviceKind = "audioinput" | "audiooutput" | "videoinput";
				if (
					mediaDevices.some(
						(md) => md.deviceId != '' && md.kind == 'videoinput'
					)
				) {
					this.cameraIsActive = true;
				} else {
					setTimeout(() => {
						this.checkCamera();
					}, 1000);
				}
			}
		);

	}

	handleWebcamInitError(error: WebcamInitError): void {
		this.cameraIsActive = false;
		if (
			error.mediaStreamError &&
			error.mediaStreamError.name === 'NotAllowedError'
		) {
			this.cameraWasDenied = true;
		}
		if (
			error.mediaStreamError &&
			error.mediaStreamError.name === 'NotFoundError'
		) {
			this.cameraNotFound = true;
		}
	}

	handleMicRequestPermission() {
		requestAudioPermissions()
			.then(() => {
				this.micIsActive = true;
			})
			.catch((err: MediaPermissionsError) => {
				const { type, name, message } = err;
				if (type === MediaPermissionsErrorType.SystemPermissionDenied) {
					// browser does not have permission to access camera or microphone
					this.micWasDenied = true;
				} else if (
					type === MediaPermissionsErrorType.UserPermissionDenied
				) {
					// user didn't allow app to access camera or microphone
					this.micWasDenied = true;
				} else if (
					type === MediaPermissionsErrorType.CouldNotStartVideoSource
				) {
					// camera is in use by another application (Zoom, Skype) or browser tab (Google Meet, Messenger Video)
					// (mostly Windows specific problem)
					this.micNotFound = true;
				} else {
					// not all error types are handled by this library
				}
			});
	}

	handleDevicesStep(step: 'webcam' | 'mic') {
		if (step == 'webcam') {
			if (this.cameraIsActive) {
				this.step = 'mic';
				this.handleMicRequestPermission();
			} else if (this.cameraWasDenied) {
				if (localStorage.getItem('devicesAlreadyChecked')) {
					localStorage.removeItem('devicesAlreadyChecked');
				}
			} else if (this.cameraNotFound) {
				this.step = 'mic';
				this.handleMicRequestPermission();
			}
		}
		if (step == 'mic') {
			if (this.micIsActive) {
				localStorage.setItem(
					'devicesAlreadyChecked',
					JSON.stringify(true)
				);
			} else if (this.micWasDenied) {
				if (localStorage.getItem('devicesAlreadyChecked')) {
					localStorage.removeItem('devicesAlreadyChecked');
				}
			} else if (this.micNotFound) {
				if (localStorage.getItem('devicesAlreadyChecked')) {
					localStorage.removeItem('devicesAlreadyChecked');
				}
			}

			this.modalService.dismissAll({
				cameraStatus: this.cameraIsActive,
				micStatus: this.micIsActive,
			});
		}
	}
}
