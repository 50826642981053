import { Component, Input, OnInit } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { DocTypesEnum } from '@app/01.global/helpers/doc-types-enum';
import { IDocument } from '@app/01.global/interfaces/iDocument';
import { NgxDropzonePreviewComponent } from 'ngx-dropzone';

@Component({
	selector: 'app-dropzone-image-preview',
	templateUrl: './dropzone-image-preview.component.html',
	styleUrls: ['./dropzone-image-preview.component.scss'],
	providers: [
		{
			provide: NgxDropzonePreviewComponent,
			useExisting: DropzoneImagePreviewComponent,
		},
	],
})
export class DropzoneImagePreviewComponent extends NgxDropzonePreviewComponent {
	DocTypesEnum = DocTypesEnum;

	constructor(sanitizer: DomSanitizer) {
		super(sanitizer);
	}

	show(enumId: number) {
		switch (enumId) {
			case DocTypesEnum.ProfilePhoto:
			case DocTypesEnum.MedicalOfficeLogo:
			case DocTypesEnum.AgreementCardImage:
			case DocTypesEnum.ChannelLogo:
			case DocTypesEnum.AgreementLogo:
				return 'image';
			default:
				return 'icon';
		}
	}
}
