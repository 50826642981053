import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ExcerptPipe } from './excerpt.pipe';
import { GetValueByKeyPipe } from './get-value-by-key.pipe';
import { RelativeTimePipe } from './relative-time.pipe';
import { SafeHtmlPipe } from './safe-html.pipe';
import { WordExcerptPipe } from './word-excerpt.pipe';
import { TimeMagnitudePipe } from './time-magnitude.pipe';
import { LocalizedDatePipe } from './localized-date.pipe';
import { RemoveStringPipe } from './remove-string.pipe';
import { RemoveHtmlTagsPipe } from './remove-HtmlTags.pipe';

const pipes = [ExcerptPipe, GetValueByKeyPipe, RelativeTimePipe, SafeHtmlPipe, WordExcerptPipe, TimeMagnitudePipe, LocalizedDatePipe, RemoveStringPipe, RemoveHtmlTagsPipe];

@NgModule({
	imports: [CommonModule],
	declarations: pipes,
	exports: pipes,
})
export class SharedPipesModule { }
