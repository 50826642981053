import { NgModule } from "@angular/core";
import { Routes, RouterModule } from "@angular/router";
import { AuthGuard } from "./01.global/helpers/auth.guard";
import { RoleEnum } from "./01.global/helpers/role-enum";
import { RouteList } from "./01.global/helpers/route-enum";
import { AttendanceLayoutComponent } from "./layouts/attendance-layout/attendance-layout.component";
import { AuthLayoutComponent } from "./layouts/auth-layout/auth-layout.component";
import { BlankLayoutComponent } from "./layouts/blank-layout/blank-layout.component";
import { MainLayoutComponent } from "./layouts/main-layout/main-layout.component";

const routes: Routes = [
	{
		path: "",
		component: AuthLayoutComponent,
		children: [
			{
				path: "",
				loadChildren: () =>
					import("@app/authentication/authentication.module").then(
						(m) => m.AuthenticationModule
					),
			},
		],
	},
	{
		path: "",
		component: MainLayoutComponent,
		canActivate: [AuthGuard],
		data: {
			allowedRoles: [
				RoleEnum.Admin,
				RoleEnum.Employee,
				RoleEnum.Doctor,
				RoleEnum.Therapist,
				RoleEnum.Guest,
				RoleEnum.Provider,
			],
		},
		runGuardsAndResolvers: "paramsOrQueryParamsChange",
		children: [
			{
				path: RouteList._route0Enum.AgreementGroup,
				loadChildren: () =>
					import(
						"@app/agreement-groups/agreement-groups.module"
					).then((m) => m.AgreementGroupsModule),
				canActivate: [AuthGuard],
				data: {
					allowedRoles: [RoleEnum.Admin],
				},
			},
			{
				path: RouteList._route0Enum.Logs,
				loadChildren: () =>
					import("@app/app-logs/app-logs.module").then(
						(m) => m.AppLogsModule
					),
				canActivate: [AuthGuard],
				data: {
					allowedRoles: [RoleEnum.Admin],
				},
			},
			{
				path: RouteList._route0Enum.Booking,
				loadChildren: () =>
					import("@app/booking/booking.module").then(
						(m) => m.BookingModule
					),
				canActivate: [AuthGuard],
				data: {
					allowedRoles: [RoleEnum.Guest, RoleEnum.Employee, RoleEnum.Doctor],
				},
			},
			{
				path: RouteList._route0Enum.Calendar,
				loadChildren: () =>
					import("@app/calendar/calendar.module").then(
						(m) => m.CalendarAppModule
					),
				canActivate: [AuthGuard],
				data: {
					allowedRoles: [RoleEnum.Guest, RoleEnum.Doctor, RoleEnum.Therapist, RoleEnum.Employee],
				},
			},
			{
				path: RouteList._route0Enum.Consultation,
				loadChildren: () =>
					import("@app/consultation/consultation.module").then(
						(m) => m.ConsultationModule
					),
				canActivate: [AuthGuard],
				data: {
					allowedRoles: [RoleEnum.Guest, RoleEnum.Doctor, RoleEnum.Therapist],
				},
			},
			{
				path: RouteList._route0Enum.ContactInfo,
				loadChildren: () =>
					import("@app/contact-info/contact-info.module").then(
						(m) => m.ContactInfoModule
					),
				canActivate: [AuthGuard],
				data: {
					allowedRoles: [RoleEnum.Admin],
				},
			},
			{
				path: RouteList._route0Enum.ConsultationTypeStandard,
				loadChildren: () =>
					import(
						"@app/consultation-type-standard/consultation-type-standard.module"
					).then((m) => m.ConsultationTypeStandardModule),
				canActivate: [AuthGuard],
				data: {
					allowedRoles: [RoleEnum.Admin],
				},
			},
			{
				path: RouteList._route0Enum.SpecialtiesStandard,
				loadChildren: () =>
					import(
						"@app/specialty-standard/specialty-standard.module"
					).then((m) => m.SpecialtyStandardModule),
				canActivate: [AuthGuard],
				data: {
					allowedRoles: [RoleEnum.Admin],
				},
			},

			{
				path: RouteList._route0Enum.MedicalProcedure,
				loadChildren: () =>
					import('@app/medical-procedure/medical-procedure.module').then(
						(m) => m.MedicalProcedureModule
					),
				canActivate: [AuthGuard],
				data: {
					allowedRoles: [RoleEnum.Admin],
				},
			},
			{
				path: RouteList._route0Enum.Dashboard,
				loadChildren: () =>
					import("@app/02.dashboard/dashboard.module").then(
						(m) => m.DashboardModule
					),
			},
			{
				path: RouteList._route0Enum.Files,
				loadChildren: () =>
					import("@app/files/files.module").then(
						(m) => m.FilesModule
					),
			},
			{
				path: RouteList._route0Enum.Inbox,
				loadChildren: () =>
					import("@app/inbox/inbox.module").then(
						(m) => m.InboxModule
					),
				data: {
					allowedRoles: [RoleEnum.Guest, RoleEnum.Doctor, RoleEnum.Therapist, RoleEnum.Employee],
				}
			},
			{
				path: RouteList._route0Enum.Profile,
				loadChildren: () =>
					import("@app/profile/profile.module").then(
						(m) => m.ProfileModule
					),
			},
			{
				path: RouteList._route0Enum.Providers,
				loadChildren: () =>
					import("@app/providers/providers.module").then(
						(m) => m.ProvidersModule
					),
			},
		],
	},
	{
		path: "",
		component: AttendanceLayoutComponent,
		// runGuardsAndResolvers: "paramsOrQueryParamsChange",
		children: [
			{
				path: RouteList._route0Enum.Attendance,
				loadChildren: () =>
					import(
						"@app/attendance/attendance.module"
					).then((m) => m.AttendanceModule),
			},
		]
	},
	{
		path: "**",
		redirectTo: RouteList.notFound,
	},
];

@NgModule({
	imports: [RouterModule.forRoot(routes, { onSameUrlNavigation: "reload", scrollPositionRestoration: 'enabled' })],
	exports: [RouterModule],
})
export class AppRoutingModule { }
