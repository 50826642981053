import { Component, Input, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { AttendanceWindowType } from '@app/01.global/helpers/attendance-window-type.enum';
import { RouteList } from '@app/01.global/helpers/route-enum';
import { IChannel } from '@app/01.global/interfaces/iChannel';
import { IUser } from '@app/01.global/interfaces/IUser';
import { AttendanceStatusService } from '@app/01.global/status-services/attendance-status.service';
import { BookingStatusService } from '@app/01.global/status-services/booking-status.service';
import { WebcamInitError } from 'ngx-webcam';

@Component({
	selector: 'app-guest-background-instructions',
	templateUrl: './guest-background-instructions.component.html',
	styleUrls: ['./guest-background-instructions.component.scss'],
})
export class GuestBackgroundInstructionsComponent implements OnInit {
	devicesAlreadyChecked: string;
	cameraIsActive: boolean;
	cameraWasDenied: boolean;
	cameraNotFound: boolean;
	micIsActive: boolean;
	micWasDenied: boolean;
	micNotFound: boolean;
	hasAppointment: boolean = false;
	doctor: IUser;
	channel: IChannel;

	@Input() channelId: number;

	constructor(private statusService: AttendanceStatusService,
		private bookingStatusService: BookingStatusService,
		private router: Router,
		private route: ActivatedRoute) { }

	ngOnInit(): void {
		this.statusService.getChannel(this.channelId)
			.subscribe((c: IChannel) => {
				this.hasAppointment = c.appointments && c.appointments.length > 0;
				this.channel = c;
				this.doctor = c.doctors[0];
			})
		this.devicesAlreadyChecked = localStorage.getItem(
			'devicesAlreadyChecked'
		);
	}

	handleWebcamInitError(error: WebcamInitError): void {
		this.cameraIsActive = false;
		if (
			error.mediaStreamError &&
			error.mediaStreamError.name === 'NotAllowedError'
		) {
			this.cameraWasDenied = true;
		}
		if (
			error.mediaStreamError &&
			error.mediaStreamError.name === 'NotFoundError'
		) {
			this.cameraNotFound = true;
		}
	}


	openBookingWindow() {
		var windowId = this.statusService.openWindow(this.channelId, AttendanceWindowType.Booking);
		this.bookingStatusService.bookFromDoctorAttendance(
			this.doctor,
			windowId
		);
		this.router.navigate([
			RouteList.attendance,
			this.route.snapshot.params.providerPublicId,
			this.route.snapshot.params.doctorPublicId,
			RouteList._route0Enum.Booking,
			RouteList._route1Enum.Appointment,
			RouteList._route2Enum.BookingAgreement,
		]);
	}

}
