<ng-container *ngIf="consultationChannel">
	<div class="d-flex flex-column h-100">
		<div class="d-flex flex-column mx-auto w-80" style="height: 20.5rem;">
			<div class="sidebar-thumbnail rounded-circle mb-3 shadow mx-auto" [ngStyle]="{
			'background-image':
			'url(' +
			(consultationChannel.logo?.url) +
			')'
		}" [ngClass]="{online: (isOnline$ | async)}"></div>
			<h4 class="mx-auto">{{doctor.displayName}}</h4>
			<button class="btn btn-rounded btn-info open-window-button my-2 w-100" *ngIf="!hasAppointment"
				(click)="openWindow(AttendanceWindowType.Booking, consultationChannel.id)">
				{{ 'BookAppointmentWithDoctor' | translate }}
			</button>
			<button class="btn btn-rounded btn-info open-window-button my-2 w-100" *ngIf="hasAppointment"
				(click)="openWindow(AttendanceWindowType.Booking, consultationChannel.id)">
				{{ 'BookAnotherAppointment' | translate }}
			</button>
			<button class="btn btn-rounded btn-primary open-window-button my-2 w-100"
				(click)="openWindow(AttendanceWindowType.Chat, consultationChannel.id)">
				{{ 'ChatWithDoctor' | translate }}
				<span *ngIf="unreadMessageCount$ | async as unreadMessageCount" class="message-count-button">
					<span *ngIf="unreadMessageCount > 0">
						{{unreadMessageCount}}
					</span>
				</span>
			</button>
			<button class="btn btn-rounded btn-primary open-window-button my-2 w-100"
				(click)="openWindow(AttendanceWindowType.Documents, consultationChannel.id)">
				{{ 'ConsultDocuments' | translate }}
			</button>
		</div>

		<div class="my-3 w-85 general-channels-container" [perfectScrollbar]="{ suppressScrollX: true }">
			<div *ngFor="let gchannel of generalChannels; let i = index;" ngbDropdown [placement]="'bottom-end'">
				<div class="d-flex flex-row my-2 cursor-pointer" id="{{'channelOptionsDropdown' + i}}"
					ngbDropdownToggle>
					<div class="avatar-md profileImgContainer rounded-circle shadow-sm my-auto position-relative"
						[ngStyle]="{
						'background-image':
						'url(' +
						(gchannel.channel.logo?.url ?? './assets/images/default-chat-bubbles-group.png') +
						')'
					}" style="min-width: 54px;" [ngClass]="{online: (gchannel.isOnline$ | async)}">
						<span *ngIf="gchannel.unreadMessageCount$ | async as unreadMessageCount"
							class="message-count-thumb">
							<span *ngIf="unreadMessageCount > 0">
								{{unreadMessageCount}}
							</span>
						</span>
					</div>
					<div class="my-auto pl-2" style="white-space: break-spaces;">
						{{gchannel.channel.name}}
					</div>
				</div>
				<div ngbDropdownMenu aria-labelledby="{{'channelOptionsDropdown' + i}}" class="mr-1">
					<button ngbDropdownItem class="d-flex flex-row"
						(click)="openWindow(AttendanceWindowType.Chat, gchannel.channel.id)">
						<i class="i-Speach-Bubbles text-16 my-auto mr-2"></i>
						<span class="my-auto">
							{{ 'Conversation' | translate }}
						</span>
					</button>
					<button ngbDropdownItem (click)="openWindow(AttendanceWindowType.Documents, gchannel.channel.id)">
						<i class="i-Files mr-2"></i>
						{{ 'Files' | translate }}
					</button>
				</div>
			</div>
		</div>

		<div class="flex-grow-1"></div>
		<div class="sidebar-footer w-100 d-flex flex-column" style="height: 6.1875rem;">
			<button class="btn btn-secondary w-80 mx-auto mb-3" (click)="openDevicesModal()">
				{{ 'CheckDevices' | translate }}
			</button>
			<button class="btn btn-danger w-80 mx-auto" (click)="leave()">
				{{ 'Back' | translate }}
			</button>
		</div>
	</div>
</ng-container>
<ng-template #devicesModal let-modal>
	<app-attendance-devices-check [modalIsOpen]="openedDevicesModal"></app-attendance-devices-check>
</ng-template>