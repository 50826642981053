<table-list [table]="table" [serviceToken]="serviceToken" [mapperToken]="mapperToken">
</table-list>
<ng-template #actionModal let-modal>
	<div class="modal-header">
		<h4 class="modal-title" id="modal-title">
			{{modalTitle| translate }}
		</h4>
		<button type="button" class="close" aria-label="Close button" aria-describedby="modal-title"
			(click)="modal.dismiss($event)">
			<span aria-hidden="true">&times;</span>
		</button>
	</div>
	<div class="modal-body m-3">
		<div class="row">
			<div class="col">
				<router-outlet (deactivate)='onDeactivate($event)'></router-outlet>
			</div>
		</div>
	</div>
</ng-template>
<ng-template #mapModal let-modal>
	<div class="modal-header">
		<h4 class="modal-title" id="modal-title">
			{{modalTitle| translate }}
		</h4>
		<button type="button" class="close" aria-label="Close button" aria-describedby="modal-title"
			(click)="modal.dismiss($event)">
			<span aria-hidden="true">&times;</span>
		</button>
	</div>
	<div class="modal-body m-3" *ngIf="location">
		<div class="row">
			<div class="col w-100">
				<app-map [latitude]="location.latitude" [longitude]="location.longitude" [zoom]="15"
					[markers]="markers">
				</app-map>
			</div>
		</div>
	</div>
	<div class="modal-footer p-3" style="border-top: 1px solid #EEE;">
		<ng-container>
			<button type="button" class="btn btn-outline-secondary" (click)="modal.dismiss($event)"
				data-toggle="tooltip" data-placement="bottom" title="{{'Cancel' | translate}}">
				{{ 'Cancel' | translate }}
			</button>
			<a target="_blank"
				[href]="'https://www.google.com/maps/dir/?api=1&destination='+location.latitude+','+location.longitude"
				class="btn btn-primary" (click)="modal.close($event)" data-toggle="tooltip" data-placement="bottom"
				title="{{ 'ViewRoute' | translate }}">
				{{ 'ViewRoute' | translate }}
			</a>
		</ng-container>
	</div>
</ng-template>