<div class="card o-hidden m-4">
	<div class="card-body pt-0 px-0">
		<div class="row no-gutters">
			<div class="col-12">
				<div class="header-cover gradient-background" style="
				height: 6rem;"></div>
				<i class="text-white text-28 {{tableSettings.icon}}"
					style="position: absolute; top:0.75rem; right:0.75rem;"></i>
				<div class="profile-picture mb-2 profileImgContainer mx-auto" style="height: 7rem;width: 7rem;">
					<table-list-thumbnail *ngIf="thumb.value" [source]="thumb.value" [rounded]="true">
					</table-list-thumbnail>
				</div>
			</div>
		</div>
		<div class="row no-gutters my-3">
			<div class="col-12 text-center">
				<h5 class="" *ngIf="title.value">
					{{title.value}}
				</h5>
			</div>
		</div>
		<div class="row no-gutters border-top pt-3">
			<div class="col-12 d-flex justify-content-center">
				<ng-container *ngFor="let btn of row.actionButtons">
					<table-list-btn [btn]="btn" [rowId]="row.id" [tableIdentifier]="tableIdentifier">
					</table-list-btn>
				</ng-container>
			</div>
		</div>
	</div>
</div>