import { Injectable, Pipe, PipeTransform } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';

@Pipe({
	name: 'timeMagnitude'
})
@Injectable({
	providedIn: 'root'
})
export class TimeMagnitudePipe implements PipeTransform {
	constructor(private translate: TranslateService) { }

	transform(valueSeconds: number): any {
		if (valueSeconds > 24 * 3600) {
			var valueDays = Math.floor(valueSeconds / (24 * 3600));
			return this.translate.instant((valueDays == 1 ? '1day' : 'xdays'), { '0': valueDays })
		}
		if (valueSeconds > 3600) {
			var valueHours = Math.floor(valueSeconds / 3600);
			console.log(valueHours)
			return this.translate.instant((valueHours == 1 ? '1hour' : 'xhours'), { '0': valueHours })
		}
		if (valueSeconds > 60) {
			var valueMinutes = Math.floor(valueSeconds / 60)
			return this.translate.instant((valueMinutes == 1 ? '1minute' : 'xminutes'), { '0': valueMinutes })
		}
		return this.translate.instant((valueSeconds == 1 ? '1second' : 'xseconds'), { '0': valueSeconds })
	}
}