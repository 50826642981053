import { Component, ElementRef, HostListener, OnInit, ViewChild } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { NgbModal, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { TranslateService } from '@ngx-translate/core';
import { ToastrService } from 'ngx-toastr';
import { Subscription, take } from 'rxjs';
import { MessageTypes } from './01.global/helpers/message-types';
import { RouteList } from './01.global/helpers/route-enum';
import { IChatMessage } from './01.global/interfaces/IChatMessage';
import { IUser } from './01.global/interfaces/IUser';
import { AuthenticationService } from './01.global/services/authentication.service';
import { LoaderService } from './01.global/services/loader.service';
import { PwaService } from './01.global/services/pwa.service';
import { SearchService } from './01.global/services/search.service';
import { SignalrService } from './01.global/services/signalr.service';
import { UsersService } from './01.global/services/users.service';
import { AttendanceStatusService } from './01.global/status-services/attendance-status.service';
import { ConsultationStatusService } from './01.global/status-services/consultation-status.service';
import { AttendanceLayoutComponent } from './layouts/attendance-layout/attendance-layout.component';

@Component({
	selector: 'app-root',
	templateUrl: './app.component.html',
	styleUrls: ['./app.component.scss'],
})
export class AppComponent implements OnInit {

	subs: Subscription[] = [];
	showUpdateButton: boolean = false;
	incomingCallMessage: IChatMessage;
	callModalRef: NgbModalRef;

	@ViewChild('callModal') callModal: ElementRef;
	@ViewChild('ConfirmUpdateModal') ConfirmUpdateModal: ElementRef;

	@HostListener("window:beforeunload", ["$event"]) unloadHandler() {
		this.subs.forEach(s => s?.unsubscribe())
	}

	constructor(private signalRService: SignalrService,
		private authService: AuthenticationService,
		public searchService: SearchService,
		private toastrService: ToastrService,
		private translateService: TranslateService,
		private router: Router,
		private modalService: NgbModal,
		public pwaService: PwaService,
		public loaderService: LoaderService,
		private consultationStatusService: ConsultationStatusService,
		public userService: UsersService,
		private route: ActivatedRoute,
		private attendanceStatusService: AttendanceStatusService
	) { }

	ngOnInit(): void {
		this.toastrService.clear();

		this.pwaService.versionUpdates().subscribe((evt) => {
			if (evt.type == 'VERSION_READY') {
				this.askUserToUpdate();
			}
		});

		this.subs.push(this.authService.user.subscribe(u => {
			this.toastrService.clear();
			if (u) {
				this.signalRService.startIfNotConnected().subscribe();
			}
		}))

		this.subscribeToSignalR();
		this.consultationStatusService.subscribeToSignalR();
		this.consultationStatusService.readLocalStorage();
	}

	askUserToUpdate() {
		const modalRef = this.modalService.open(this.ConfirmUpdateModal);

		modalRef.result
			.then(
				() => {
					this.showUpdateButton = false;
					this.pwaService.activateUpdate();
				},
				() => {
					this.showUpdateButton = true;
				}
			)
			.finally(() => { });
	}

	private subscribeToSignalR() {
		this.subs.push(
			this.signalRService.invitationFinished.subscribe((user: IUser) => {
				//Pedido de adesão
				//"O utilizador {0} concluiu o seu registo na aplicação
				// TODO passar os dados da consulta como parametro
				if (user == null) {
					return;
				}
				this.toastrService.info(
					this.translateService.instant('UserYouInvitedSignedUp'),
					this.translateService.instant('InvitationRequest'),
					{ positionClass: 'toast-bottom-right' }
				);
			})
		);

		this.subs.push(
			this.signalRService.pendingEmployees.subscribe((v: boolean) => {
				if (v) {
					this.toastrService
						.warning(
							this.translateService.instant(
								'havePendingEmployees'
							),
							this.translateService.instant(
								'havePendingEmployees'
							),
							{
								positionClass: 'toast-bottom-right',
								disableTimeOut: true,
								closeButton: true,
							}
						)
						.onTap.pipe(take(1))
						.subscribe(() =>
							this.router.navigate([
								RouteList.employees,
								RouteList._routeCrudEnum.InProgress,
							])
						);
				}
			})
		);
		this.subs.push(
			this.signalRService.pendingTriageFormAnswers.subscribe(
				(v: boolean) => {
					if (v) {
						this.toastrService
							.warning(
								this.translateService.instant(
									'havePendingTriageFormAnswers'
								),
								this.translateService.instant(
									'havePendingTriageFormAnswers'
								),
								{
									positionClass: 'toast-bottom-right',
									disableTimeOut: true,
									closeButton: true,
								}
							)
							.onTap.pipe(take(1))
							.subscribe(() =>
								this.router.navigate([
									RouteList.triageAnswers,
									RouteList._routeCrudEnum.InProgress,
								])
							);
					}
				}
			)
		);

		this.subs.push(
			this.signalRService.pendingAgreementCards.subscribe(
				(v: boolean) => {
					if (v) {
						this.toastrService
							.warning(
								this.translateService.instant(
									'havePendingAgreementCards'
								),
								this.translateService.instant(
									'havePendingAgreementCards'
								),
								{
									positionClass: 'toast-bottom-right',
									disableTimeOut: true,
									closeButton: true,
								}
							)
							.onTap.pipe(take(1))
							.subscribe(() =>
								this.router.navigate([RouteList.profile])
							);
					}
				}
			)
		);
		this.subs.push(
			this.signalRService.acceptedAgreementCard.subscribe(
				(v: { cardNumber: string; userName: string }) => {
					if (v) {
						this.toastrService
							.info(
								this.translateService.instant(
									'CardWasAccepted',
									v
								),
								this.translateService.instant(
									'CardWasAccepted'
								),
								{
									positionClass: 'toast-bottom-right',
									disableTimeOut: true,
									closeButton: true,
								}
							)
							.onTap.pipe(take(1))
							.subscribe(() =>
								this.router.navigate([
									RouteList.triageAnswers,
									RouteList._routeCrudEnum.InProgress,
								])
							);
					}
				}
			)
		);

		this.subs.push(
			this.signalRService.newChatMessage.subscribe(
				(message: IChatMessage) => {
					if (message == null) {
						return;
					}
					switch (message.typeId) {
						case MessageTypes.VideoCallStarted:
							if (message.senderId != this.authService.userValue.id) {
								this.openAcceptCallModal(message);
							}
							break;
						case MessageTypes.JoinedTheGroup:
							if (
								message.contentTargetId == this.authService.userValue.id
							) {
								this.openAcceptCallModal(message);
							}
							break;
						case MessageTypes.VideoCallEnded:
							this.closeAcceptCallModal();
						default:
							break;
					}
				}
			)
		);
	}

	private openAcceptCallModal(message: IChatMessage) {
		this.incomingCallMessage = message;
		this.callModalRef = this.modalService.open(
			this.callModal,
			{
				ariaLabelledBy: 'modal-basic-title',
				centered: true,
				size: 'md',
				backdrop: 'static',
				keyboard: false,
			}
		);

		this.callModalRef.result.then(
			() => {
				this.modalService.dismissAll();
				if (this.route.snapshot.firstChild.component != AttendanceLayoutComponent) {
					this.router.navigate([RouteList.attendance,
					message.sender.specialty.providerAccountPublicIdentifier,
					message.sender.publicIdentifier
					]);
				}
				this.attendanceStatusService.answerVideoCall(message.channelId)
				this.incomingCallMessage = null;
			},
			() => {
				this.incomingCallMessage = null;
			}
		);
	}

	private closeAcceptCallModal() {
		this.callModalRef?.dismiss();
		this.callModalRef = null;
	}

}
