<div class="mb-4">
	<ng-container [ngSwitch]="message.typeId">
		<!-- the same view can be shown in more than one case -->
		<ng-container *ngSwitchCase="messageTypes.SystemNotificationMessage">
			<div class="align-items-center">
				<p class="alert-card mb-3 mx-auto p-2 px-5 d-flex alert alert-info" style="width: fit-content;">
					<i class="i-Information mr-3" style="font-size: 1rem;"></i>{{message.content | translate}}
				</p>
			</div>
		</ng-container>
		<ng-container *ngSwitchCase="messageTypes.UserMessage">
			<div *ngIf="message.sender.id === currentUser.id" class="d-flex">
				<div class="message flex-grow-1" style="margin-left: 20%;">
					<div class="d-flex">
						<p class="mb-1 text-title text-16 flex-grow-1">{{message?.sender.displayName}}</p>
						<ng-template #popContent>
							<p class="m-0">
								<span>
									<i class="text-20 i-Mail-Send"></i>
									{{ 'SentAt' | translate }}
								</span>
							</p>
							<p class="m-0">{{ message.sentDate | localizedDate: 'EEEE, MMMM d, y, HH:mm:ss zzzz' }}</p>
							<div *ngFor="let receiver of message.recipients">
								<p class="m-0" *ngIf="receiver.isRead && receiver.readDate">
									<i class="i-Checked-User"></i>
									{{ 'ReadAt' | translate }}
								</p>
								<p class="m-0" *ngIf="receiver.isRead && receiver.readDate">
									{{receiver.recipient.displayName}}:{{
									receiver.readDate | localizedDate:'EEEE, MMMM d, y, HH:mm:ss zzzz'}}
								</p>
							</div>
						</ng-template>
						<span class="text-small text-muted" [animation]="false" triggers="mouseenter:mouseleave"
							[ngbPopover]="popContent" [popoverClass]="'bg-white text-14 text-muted'">
							{{message.sentDate | localizedDate:'MMM d, y, HH:mm:ss'}}
							<span class="text-info" *ngIf="readByEveryone(message)">
								<i class="i-Pulse text-24"></i>
							</span>
							<span *ngIf="!readByEveryone(message)">
								<i class="i-Sand-watch-2 text-20"></i>
							</span>
						</span>
					</div>
					<p class="m-0">{{message.content}}</p>
				</div>
				<img [src]="message.sender.photo?.url ?? './assets/images/default-user.png'"
					[alt]="message.sender.displayName" class="avatar-sm rounded-circle ml-3">
			</div>
			<div *ngIf="message.sender.id !== currentUser.id" class="d-flex user">
				<app-label-with-popover [targetLink]="routeList.joinRoutes([isCurrentUserDoctor() ? 
					routeList.patients : 
					routeList.employees, 
				routeList._routeCrudEnum.Details,message.sender.id ])">
					<span slot="label">
						<img [src]="message.sender.photo?.url ?? './assets/images/default-user.png'"
							[alt]="message.sender.displayName" class="avatar-sm rounded-circle mr-3">
					</span>
				</app-label-with-popover>
				<div class="message flex-grow-1 bg-primary text-white" style="margin-right: 20%;">
					<div class="d-flex">
						<p class="mb-1 text-title text-16 flex-grow-1 text-white">{{message.sender.displayName}}</p>
						<ng-template #popContent>
							<p class="m-0 text-white">
								<span>
									<i class="text-20 i-Mail-Send"></i>
									{{ 'SentAt' | translate }}
								</span>
							</p>
							<p class="m-0 text-white">
								{{ message.sentDate | localizedDate: 'EEEE, MMMM d, y, HH:mm:ss zzzz' }}
							</p>
						</ng-template>
						<span class="text-small text-white" [animation]="false" triggers="mouseenter:mouseleave"
							[ngbPopover]="popContent" [popoverClass]="'bg-info text-14 text-white'">
							{{message.sentDate | localizedDate:'MMM d, y, HH:mm:ss'}}
						</span>
					</div>
					<p class="m-0">{{message.content}}</p>
				</div>
			</div>
		</ng-container>
		<ng-container *ngSwitchCase="messageTypes.UploadDocumentMessage">
			<div class="align-items-center">
				<div class="alert-card mb-3 p-0 alert align-items-center"
					[ngClass]="message.sender.id === currentUser.id ? 'ml-auto alert-light' : 'mr-auto alert-primary'"
					style="width: fit-content;padding-top: 2px !important">
					<p class="m-0" *ngIf="isUrlImage(message.attachment?.url)">
						<a href="{{ message.attachment?.url }}" target="_blank" class="m-0">
							<img [src]="message.attachment?.url" alt="{{ message.content }}"
								style="height: 15rem;border-radius: 10px;" />
						</a>
					</p>
					<video width="300" *ngIf="isUrlVideo(message.attachment?.url)" [src]="message.attachment?.url"
						controls class="m-2"></video>
					<ng-container *ngIf="!isUrlImage(message.attachment?.url) && !isUrlVideo(message.attachment?.url)">
						<p class="d-flex mx-auto px-5 mt-2" style="width: fit-content;">
							{{ message.content }}
						</p>
						<p class="d-flex mb-0 mt-2 mx-auto" style="width: fit-content;">
							<a href="{{ message.attachment?.url }}" target="_blank" class="m-0 text-24"
								data-toggle="tooltip" data-placement="bottom" title="{{url}}">
								<i class="i-File-Download"></i>
							</a>
						</p>
					</ng-container>
					<ng-template #popContent>
						<p class="m-0">
							<span>
								<i class="text-20 i-Mail-Send"></i>
								{{ 'SentAt' | translate }}
							</span>
						</p>
						<p class="m-0">{{ message.sentDate | localizedDate: 'EEEE, MMMM d, y, HH:mm:ss zzzz' }}</p>
						<div *ngFor="let receiver of message.recipients">
							<p class="m-0" *ngIf="receiver.isRead && receiver.readDate">
								<i class="i-Checked-User"></i>
								{{ 'ReadAt' | translate }}
							</p>
							<p class="m-0" *ngIf="receiver.isRead && receiver.readDate">
								{{receiver.recipient.displayName}}:{{
								receiver.readDate | localizedDate:'EEEE, MMMM d, y, HH:mm:ss zzzz'}}
							</p>
						</div>
					</ng-template>
				</div>
			</div>
		</ng-container>
		<ng-container
			*ngSwitchCase="message.typeId == messageTypes.CreatedTheGroup || message.typeId == messageTypes.JoinedTheGroup ? message.typeId : -1 ">
			<div class="align-items-center">
				<p class="alert-card mb-3 mx-auto p-2 px-5 d-flex alert alert-success" style="width: fit-content;">
					<i class="i-Add-User mr-3" style="font-size: 1rem;"></i>{{message.content | translate : {'0':
					message.contentDisplay1} }}
				</p>
			</div>
		</ng-container>
		<ng-container *ngSwitchCase="messageTypes.LeftTheGroup">
			<div class="align-items-center">
				<p class="alert-card mb-3 mx-auto p-2 px-5 d-flex alert alert-danger" style="width: fit-content;">
					<i class="i-Remove-User mr-3" style="font-size: 1rem;"></i>
					{{message.content | translate: {'0': message.contentDisplay1} }}
				</p>
			</div>
		</ng-container>
		<ng-container *ngSwitchCase="messageTypes.RemovedFromTheGroup">
			<div class="align-items-center">
				<p class="alert-card mb-3 mx-auto p-2 px-5 d-flex alert alert-danger" style="width: fit-content;">
					<i class="i-Remove-User mr-3" style="font-size: 1rem;"></i>
					{{message.content | translate: {'0': message.contentDisplay1, '0': message.contentDisplay2} }}
				</p>
			</div>
		</ng-container>
		<ng-container *ngSwitchCase="messageTypes.LeftVideoCall">
			<div class="align-items-center">
				<p class="alert-card mb-3 mx-auto p-2 px-5 d-flex alert alert-info" style="width: fit-content;">
					<i class="i-Information mr-3" style="font-size: 1rem;"></i>
					{{message.content | translate: {'0': message.contentDisplay1} }}
				</p>
			</div>
		</ng-container>
		<ng-container *ngSwitchCase="messageTypes.CreatedTheGroup">
			<div class="align-items-center">
				<p class="alert-card mb-3 mx-auto p-2 px-5 d-flex alert alert-info" style="width: fit-content;">
					<i class="i-Information mr-3" style="font-size: 1rem;"></i>
					{{message.content | translate: {'0': message.contentDisplay1} }}
				</p>
			</div>
		</ng-container>
		<ng-container *ngSwitchCase="messageTypes.JoinedTheGroup">
			<div class="align-items-center">
				<p class="alert-card mb-3 mx-auto p-2 px-5 d-flex alert alert-info" style="width: fit-content;">
					<i class="i-Information mr-3" style="font-size: 1rem;"></i>
					{{message.content | translate: {'0': message.contentDisplay1} }}
				</p>
			</div>
		</ng-container>
		<ng-container *ngSwitchCase="messageTypes.JoinedVideoCall">
			<div class="align-items-center">
				<p class="alert-card mb-3 mx-auto p-2 px-5 d-flex alert alert-info" style="width: fit-content;">
					<i class="i-Information mr-3" style="font-size: 1rem;"></i>
					{{message.content | translate: {'0': message.contentDisplay1} }}
				</p>
			</div>
		</ng-container>
		<ng-container *ngSwitchCase="messageTypes.NewAppointment">
			<div class="align-items-center">
				<p class="alert-card mb-3 mx-auto p-2 px-5 d-flex alert alert-info" style="width: fit-content;"
					*ngIf="message.contentDisplay2 == null">
					<i class="i-Information mr-3" style="font-size: 1rem;"></i>
					{{'Api.AppointmentWasBookedBy' | translate: {'0': message.contentDisplay1} }}
				</p>
				<p class="alert-card mb-3 mx-auto p-2 px-5 d-flex alert alert-info" style="width: fit-content;"
					*ngIf="message.contentDisplay2 != null">
					<i class="i-Information mr-3" style="font-size: 1rem;"></i>
					{{'Api.AppointmentWasBookedByEmployee' | translate: {'0': message.contentDisplay1, '1':
					message.contentDisplay2, '2':
					message.contentDisplay3} }}
				</p>
			</div>
		</ng-container>
		<!--default case when there are no matches -->
		<ng-container *ngSwitchDefault>
			<div class="align-items-center">
				<p class="alert-card mb-3 mx-auto p-2 px-5 d-flex alert alert-info" style="width: fit-content;">
					<i class="i-Information mr-3" style="font-size: 1rem;"></i>{{message.content | translate}}
				</p>
			</div>
		</ng-container>
	</ng-container>
</div>