import { Component, Input, OnInit } from '@angular/core';
import {
	UntypedFormBuilder,
	UntypedFormGroup,
	Validators,
} from '@angular/forms';
import { ControlTypeEnum } from '@app/01.global/helpers/control-enum';
import { INote } from '@app/01.global/interfaces/iNote';
import { AuthenticationService } from '@app/01.global/services/authentication.service';
import { ChannelService } from '@app/01.global/services/channel.service';
import { AttendanceStatusService } from '@app/01.global/status-services/attendance-status.service';
import { Observable } from 'rxjs';

@Component({
	selector: 'app-attendance-notes',
	templateUrl: './attendance-notes.component.html',
	styleUrls: ['./attendance-notes.component.scss'],
})
export class AttendanceNotesComponent implements OnInit {
	@Input() channelId: number;

	loading: boolean = false;
	loadingUp: boolean = false;
	saving: boolean = false;
	notes$: Observable<INote[]>;
	userJoinDate: Date;

	ControlTypeEnum = ControlTypeEnum;
	form: UntypedFormGroup;

	constructor(
		private service: ChannelService,
		private statusService: AttendanceStatusService,
		private authService: AuthenticationService,
		private fb: UntypedFormBuilder
	) {}

	ngOnInit() {
		this.buildForm();
		this.notes$ = this.statusService.getChannelNotes$(this.channelId);
		this.userJoinDate = this.authService.userValue?.createdDate;
	}

	onScroll() {
		this.loadingUp = true;
		this.statusService.loadMoreNotes(this.channelId);
	}

	buildForm() {
		this.form = this.fb.group({
			title: ['', Validators.required],
			description: ['', Validators.required],
		});
	}

	createNote() {
		if (this.form.invalid) {
			return;
		}
		this.saving = true;
		var note = this.form.value;
		this.form.disable();
		this.service
			.createNote(this.channelId, note)
			.subscribe((response: INote) => {
				this.saving = false;
				this.form.reset();
				this.form.enable();
				this.statusService.afterCreatingNote(this.channelId);
			});
	}
}
