import { HttpInterceptor, HttpHandler, HttpEvent, HttpRequest } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Observable, of, takeUntil } from "rxjs";
import { HttpCancelService } from "../services/http-cancel.service";

@Injectable()
export class HttpCancelInterceptor implements HttpInterceptor {
	constructor(
		private httpCancelService: HttpCancelService
	) { }

	intercept<T>(req: HttpRequest<T>, next: HttpHandler): Observable<HttpEvent<T>> {
		return next.handle(req).pipe(takeUntil(
			this.httpCancelService.cancelPendingRequests$
		))
	}
}
