import { TableListActionButton } from "@app/table-list/models/table-list-action-button";
import { TableListColumn, TableListColumnMode } from "@app/table-list/models/table-list-column";
import { TableListRow } from "@app/table-list/models/table-list-row";

export class DatatableColumn implements TableListColumn {
	constructor() {
		this.value = '';
		this.tooltip = this.name ?? this.value;
		this.displayMode = TableListColumnMode.text;
	}
	value?: any;
	translate?: boolean;
	tooltip?: string;
	name: string;
	sortable: boolean;
	displayMode: TableListColumnMode;
}