<div #box class="resizable-draggable" [style.width.px]="width" [style.height.px]="height"
	[style.transform]="'translate3d('+ left + 'px,' + top + 'px,' + '0px)'"
	[class.active]="status === 1 || status === 2" (click)="focusWindow()" (mousedown)="setStatus($event, 2)"
	(window:mouseup)="setStatus($event, 0)" [ngClass]="{'video-window': type == AttendanceWindowType.VideoCall,
	'active-window shadow' : isActive && type != AttendanceWindowType.VideoCall,
	'background-window': !isActive && type != AttendanceWindowType.VideoCall}">
	<div class="resize-action" (mousedown)="setStatus($event, 1)" *ngIf="canResize">
	</div>
	<div class="w-100 h-100 p-2 pt-4">
		<div class="window-header w-100">
			<div class="d-flex flex-row justify-content-between">
				<div>
					<h6 class="p-2 mb-0">
						{{title}}
					</h6>
				</div>
				<div class="ml-auto pt-1 pr-1">
					<span class="header-actions cursor-pointer mx-1 text-20 text-info" (click)="minimize()"
						*ngIf="canMinimize">
						-
					</span>
					<span class="header-actions cursor-pointer mx-1 text-14 text-info" (click)="maximize()">
						<i class="i-One-Window"></i>
					</span>
					<span class="header-actions cursor-pointer mx-1 text-20 text-danger" (click)="close()">
						x
					</span>
				</div>
			</div>
		</div>
		<div class="window-content mt-2" [ngSwitch]="type" (mousedown)="$event.stopPropagation()" [perfectScrollbar]="">
			<app-attendance-chat *ngSwitchCase="AttendanceWindowType.Chat" [channelId]="channelId">
			</app-attendance-chat>
			<app-attendance-notes *ngSwitchCase="AttendanceWindowType.Notes" [channelId]="channelId">
			</app-attendance-notes>
			<app-attendance-documents *ngSwitchCase="AttendanceWindowType.Documents" [channelId]="channelId">
			</app-attendance-documents>
			<app-attendance-triage-answer *ngSwitchCase="AttendanceWindowType.TriageAnswer" [channelId]="channelId">
			</app-attendance-triage-answer>
			<app-attendance-video *ngSwitchCase="AttendanceWindowType.VideoCall" [channelId]="channelId"
				[windowId]="id">
			</app-attendance-video>
			<ng-container *ngSwitchCase="AttendanceWindowType.Booking">
				<router-outlet></router-outlet>
			</ng-container>
		</div>
	</div>
</div>