<div class="row" *ngIf="showHasNoSpecialtiesWarning || showHasNoMedicalOfficesWarning ">
	<div class="col">
		<div class="alert alert-card alert-danger text-center" role="alert">
			{{ showHasNoSpecialtiesWarning ? 'ProviderHasNoSpecialties' :
			'HasNoMedicalOffices' |
			translate
			}}
			<br>
			<button class="btn btn-lg btn-rounded btn-info ml-3 my-5" *ngIf="showHasNoSpecialtiesWarning"
				(click)="dismiss([RouteList.specialties, RouteList._routeCrudEnum.Add], 'showHasNoSpecialtiesWarning')"
				data-toggle="tooltip" data-placement="bottom" title="{{ 'CreateSpecialty' | translate }}">{{
				'CreateSpecialty' | translate }}</button>
			<button class="btn btn-lg btn-rounded btn-info ml-3 my-5" *ngIf="showHasNoMedicalOfficesWarning"
				(click)="dismiss([RouteList.medicalOffices, RouteList._routeCrudEnum.Add],'showHasNoMedicalOfficesWarning')"
				data-toggle="tooltip" data-placement="bottom" title="{{ 'CreateMedicalOffice' | translate }}">{{
				'CreateMedicalOffice' | translate }}</button>
		</div>
	</div>
</div>
<div *ngIf="!showHasNoSpecialtiesWarning && !showHasNoMedicalOfficesWarning && !!form">
	<form [formGroup]="form" (ngSubmit)="submitForm()">
		<div class="row" *ngIf="isEditconsultationTypesMode">
			<div class="col">
				<ng-template *ngTemplateOutlet="consultationTypes">
				</ng-template>
			</div>
		</div>
		<ng-container *ngIf="!isEditconsultationTypesMode">
			<div class="row mb-3" *ngIf="isEmployee">
				<div class="col-md-6">
					<div class="row">
						<div class="col-md-12">
							<app-form-control [submitted]="submitted" [type]="ControlTypeEnum.Text" name="firstName"
								[control]="form.get('firstName')"></app-form-control>
						</div>
						<div class="col-md-12">
							<app-form-control [submitted]="submitted" [type]="ControlTypeEnum.Text" name="lastName"
								[control]="form.get('lastName')"></app-form-control>
						</div>
						<div class="col-md-12">
							<app-form-control [submitted]="submitted" [type]="ControlTypeEnum.Text" name="displayName"
								[control]="form.get('displayName')"></app-form-control>
						</div>
					</div>
				</div>
				<div class="col-md-6">
					<app-form-control [submitted]="submitted" [type]="ControlTypeEnum.Document"
						[documentType]="DocTypesEnum.ProfilePhoto" name="photo" [control]="form.get('photo')">
					</app-form-control>
				</div>
			</div>
			<div class="row mb-3" *ngIf="!isEmployee">
				<div class="col-md-4">
					<app-form-control [submitted]="submitted" [type]="ControlTypeEnum.Text" name="firstName"
						[control]="form.get('firstName')"></app-form-control>
				</div>
				<div class="col-md-4">
					<app-form-control [submitted]="submitted" [type]="ControlTypeEnum.Text" name="lastName"
						[control]="form.get('lastName')"></app-form-control>
				</div>
				<div class="col-md-4">
					<app-form-control [submitted]="submitted" [type]="ControlTypeEnum.Text" name="displayName"
						[control]="form.get('displayName')"></app-form-control>
				</div>
			</div>
			<div class="row mb-3">
				<div class="col-md-6">
					<app-form-control [submitted]="submitted" [type]="ControlTypeEnum.Email" name="email"
						[control]="form.get('email')">
					</app-form-control>
				</div>
				<div class="col-md-2">
					<app-form-control [type]="ControlTypeEnum.Typeahead"
						[placeholder]="'SelectAPhoneNumber' | translate" [control]="form.get('phoneNumberCountryCode')"
						name="phoneNumberCountryCode" (typeaheadSearchChange)="updateSearchPhoneNumber($event)"
						[serviceReadMethod]="searchPhoneNumber">
					</app-form-control>
				</div>
				<div class="col-md-4">
					<app-form-control [submitted]="submitted" [type]="ControlTypeEnum.Text" name="phoneNumber"
						[control]="form.get('phoneNumber')">
					</app-form-control>
				</div>
			</div>
			<div class="row mb-3">
				<div class="col-md-6">
					<app-form-control [submitted]="submitted" [type]="ControlTypeEnum.Password" name="password"
						[control]="form.get('password')" [isEdit]="!isAddMode">
					</app-form-control>
				</div>
				<div class="col-md-6">
					<app-form-control [submitted]="submitted" [type]="ControlTypeEnum.Password" name="confirmPassword"
						[control]="form.get('confirmPassword')" [isEdit]="!isAddMode">
					</app-form-control>
				</div>
			</div>
			<div class="row mb-3">
				<div class="col-md-4">
					<app-form-control [type]="ControlTypeEnum.Typeahead" [placeholder]="'SelectATitle' | translate"
						[formatterTypeahead]="formatterTitle" [control]="form.get('titleId')" name="titleId"
						(typeaheadSearchChange)="updateSearchTitle($event)" [serviceReadMethod]="searchTitle">
					</app-form-control>
				</div>
				<div class="col-md-4">
					<app-form-control [type]="ControlTypeEnum.Typeahead" [placeholder]="'SelectALanguage' | translate"
						[formatterTypeahead]="formatterLanguage" [control]="form.get('languageId')" name="languageId"
						(typeaheadSearchChange)="updateSearchLanguage($event)" [serviceReadMethod]="searchLanguage">
					</app-form-control>
				</div>
				<div class="col-md-4">
					<app-form-control [type]="ControlTypeEnum.Typeahead" [placeholder]="'SelectAGender' | translate"
						[formatterTypeahead]="formatterGender" [control]="form.get('genderId')" name="genderId"
						(typeaheadSearchChange)="updateSearchGender($event)" [serviceReadMethod]="searchGender">
					</app-form-control>
				</div>
				<div class="col-md-4" *ngIf="isPatient">
					<app-form-control [type]="ControlTypeEnum.Typeahead"
						[placeholder]="'SelectAMedicalOffice' | translate" [formatterTypeahead]="formatterMedicalOffice"
						[control]="form.get('medicalOfficeId')" name="medicalOfficeId"
						(typeaheadSearchChange)="updateSearchMedicalOffice($event)"
						[serviceReadMethod]="searchMedicalOffice">
					</app-form-control>
				</div>
			</div>
			<div class="row mb-3" *ngIf="isEmployee">
				<div class="col-md-6">
					<app-form-control [type]="ControlTypeEnum.Typeahead" [placeholder]="'SelectAPosition' | translate"
						[formatterTypeahead]="formatterPosition" [control]="form.get('positionId')" name="positionId"
						(typeaheadSearchChange)="updateSearchPosition($event)" [serviceReadMethod]="searchPosition">
					</app-form-control>
				</div>
				<div class="col-md-6" *ngIf="isDoctor.value == true">
					<app-form-control [type]="ControlTypeEnum.Typeahead" [placeholder]="'SelectASpecialty' | translate"
						[formatterTypeahead]="formatterSpecialty" [control]="form.get('specialtyId')" name="specialtyId"
						(typeaheadSearchChange)="updateSearchSpecialty($event)" [serviceReadMethod]="searchSpecialty">
					</app-form-control>
				</div>
				<div class="col-md-6" *ngIf="positionName === positionEnum.Employee">
					<app-form-control [type]="ControlTypeEnum.Typeahead" [placeholder]="'SelectABranch' | translate"
						[formatterTypeahead]="formatterBranch" [control]="form.get('branchAccountId')"
						name="branchAccountId" (typeaheadSearchChange)="updateSearchBranch($event)"
						[serviceReadMethod]="searchBranch">
					</app-form-control>
				</div>
			</div>
			<div class="row mb-3" *ngIf="isEmployee">
				<div class="col-md-3">
					<app-form-control [submitted]="submitted" [type]="ControlTypeEnum.CheckboxSwitch" name="isDoctor"
						[control]="isDoctor">
					</app-form-control>
				</div>
				<div class="col" *ngIf="isDoctor.value == true">
					<ng-template *ngTemplateOutlet="consultationTypes">
					</ng-template>
				</div>
			</div>
			<ng-container formGroupName="address" *ngIf="isEmployee">
				<div class="row">
					<div class="col">
						<app-address-picker (onPlaceSelected)="placeSelected($event)"></app-address-picker>
					</div>
					<div class="col"></div>
				</div>
				<div class=" row">
					<div class="col-md-4">
						<app-form-control [submitted]="submitted" [type]="ControlTypeEnum.Text" name="line1"
							[control]="form.get('address.line1')">
						</app-form-control>
					</div>
					<div class="col-md-4">
						<app-form-control [submitted]="submitted" [type]="ControlTypeEnum.Text" name="line2"
							[control]="form.get('address.line2')">
						</app-form-control>
					</div>
					<div class="col-md-4">
						<app-form-control [submitted]="submitted" [type]="ControlTypeEnum.Text" name="postalCode"
							[control]="form.get('address.postalCode')">
						</app-form-control>
					</div>
				</div>
				<div class="row">
					<div class="col-md-4">
						<app-form-control [submitted]="submitted" [type]="ControlTypeEnum.Text" name="city"
							[control]="form.get('address.city')">
						</app-form-control>
					</div>
					<div class="col-md-4">
						<app-form-control [submitted]="submitted" [type]="ControlTypeEnum.Text" name="district"
							[control]="form.get('address.district')">
						</app-form-control>
					</div>
					<div class="col-md-4">
						<app-form-control [submitted]="submitted" [type]="ControlTypeEnum.Text" name="country"
							[control]="form.get('address.country')">
						</app-form-control>
					</div>
				</div>
			</ng-container>
		</ng-container>
		<div class="row" *ngIf="isPatient || isEditconsultationTypesMode">
			<div class="col-sm-12" style="text-align: end;">
				<button class="btn btn-icon btn-rounded btn-outline-warning mr-3" type="button"
					[routerLink]="RouteList.joinRoutes([RouteList.patients, RouteList._routeCrudEnum.List])"
					data-toggle="tooltip" data-placement="bottom" title="{{ 'Back' | translate }}">
					<i class="i-Back1"></i>
				</button>
				<btn-loading *ngIf="!isDeleteMode" btnClass="btn-primary" [loading]="loading" data-toggle="tooltip"
					data-placement="bottom" title="{{isAddMode ? ('Create'| translate)  : 
				         isEditMode ? ('SaveChanges'| translate):''}}">

					<span *ngIf="isAddMode">
						{{ 'Create' | translate }}
					</span>
					<span *ngIf="isEditMode">
						{{ 'SaveChanges' | translate }}
					</span>
				</btn-loading>
				<btn-loading *ngIf="isDeleteMode" btnClass="btn-danger" [loading]="loading" data-toggle="tooltip"
					data-placement="bottom" title="{{'Delete' | translate}}">
					<span>{{ 'Delete' | translate }}</span>
				</btn-loading>
			</div>
		</div>
		<div class="row" *ngIf="!isEditconsultationTypesMode && (isProvider || isEmployee) ">
			<div class="col-sm-12" style="text-align: end;">
				<btn-loading btnClass="btn-primary" [loading]="loading" data-toggle="tooltip" data-placement="bottom"
					title="{{ 'Next' | translate }}">
					<span>{{ 'Next' | translate }}</span>
				</btn-loading>
			</div>
		</div>
	</form>
</div>

<ng-template #consultationTypes>
	<app-form-control [submitted]="submitted" [type]="ControlTypeEnum.Autocomplete" name="consultationTypes"
		[control]="form.get('consultationTypes')" [options]="availableConsultationTypes" [displayBy]="'name'"
		[identifyBy]="'id'">
	</app-form-control>
</ng-template>