import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { MessageTypes } from '@app/01.global/helpers/message-types';
import { RouteList } from '@app/01.global/helpers/route-enum';
import { IChatMessage } from '@app/01.global/interfaces/iChatMessage';
import { IUser } from '@app/01.global/interfaces/IUser';
import { IChannel } from '@app/01.global/interfaces/iChannel';
import { AuthenticationService } from '@app/01.global/services/authentication.service';
import { Subscription } from 'rxjs';
import { ConsultationStatusService } from '@app/01.global/status-services/consultation-status.service';
import { ActiveChannel } from '@app/01.global/models/active-channel';

@Component({
	selector: 'app-chat-message',
	templateUrl: './chat-message.component.html',
	styleUrls: ['./chat-message.component.scss'],
})
export class ChatMessageComponent implements OnInit, OnDestroy {
	@Input('message') message: IChatMessage;
	messageTypes = MessageTypes;
	routeList = RouteList;
	subs: Subscription[] = [];
	currentUser: IUser;
	activeChannel: IChannel;

	constructor(
		private authenticationService: AuthenticationService,
		private statusService: ConsultationStatusService
	) { }

	ngOnInit(): void {
		this.subs.push(
			this.authenticationService.user.subscribe((u: IUser) => {
				this.currentUser = u;
			})
		);

		this.subs.push(
			this.statusService.activeChannelObs.subscribe(
				(c: ActiveChannel) => {
					this.activeChannel = c?.channel;
				}
			)
		);
	}

	ngOnDestroy(): void {
		this.subs.forEach((s) => s?.unsubscribe());
	}

	readByEveryone(msg: IChatMessage): boolean {
		if (msg.recipients.every((r) => r.isRead)) {
			return true;
		}

		return false;
	}

	isUrlImage(url: string): boolean {
		if (
			url &&
			(url.endsWith('.gif') ||
				url.endsWith('.jpeg') ||
				url.endsWith('.png') ||
				url.endsWith('.jpg'))
		) {
			return true;
		}
		return false;
	}

	isUrlVideo(url: string): boolean {
		return url && url.endsWith('.mp4')
	}

	isCurrentUserDoctor(): boolean {
		return this.activeChannel?.doctors.some(
			(ap) => ap.id == this.currentUser.id
		);
	}
}
