import { Injectable } from '@angular/core';
import {
	HttpRequest,
	HttpHandler,
	HttpEvent,
	HttpInterceptor,
} from '@angular/common/http';
import { Observable } from 'rxjs';

@Injectable()
export class TimeZoneInterceptor implements HttpInterceptor {
	public intercept(
		req: HttpRequest<any>,
		next: HttpHandler
	): Observable<HttpEvent<any>> {
		const modifiedReq = req.clone({
			headers: req.headers.set(
				'mHealth-Local-Time-Zone',
				Intl.DateTimeFormat().resolvedOptions().timeZone
			),
		});
		return next.handle(modifiedReq);
	}
}
