import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { AuthGuard } from '@app/01.global/helpers/auth.guard';
import { RoleEnum } from '@app/01.global/helpers/role-enum';
import { RouteList } from '@app/01.global/helpers/route-enum';
import { AttendanceHomeComponent } from './attendance-home/attendance-home.component';

const routes: Routes = [
	{
		path: RouteList._route1Enum.PatientQueue,
		component: AttendanceHomeComponent,
		canActivate: [AuthGuard],
		data: {
			allowedRoles: [RoleEnum.Doctor, RoleEnum.Therapist]
		}
	},
	{
		path: ':providerPublicId/:doctorPublicId',
		component: AttendanceHomeComponent,
		data: { bookingFromAttendance: true },
		children: [
			{
				path: "auth",
				loadChildren: () =>
					import("@app/authentication/authentication.module").then(
						(m) => m.AuthenticationModule
					),
			},
			{
				path: RouteList._route0Enum.Booking,
				loadChildren: () =>
					import("@app/booking/booking.module").then(
						(m) => m.BookingModule
					),
			},
		],
	}
];

@NgModule({
	imports: [RouterModule.forChild(routes)],
	exports: [RouterModule]
})
export class AttendanceRoutingModule { }
