import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';

@Injectable({
	providedIn: 'root'
})
export class LoaderService {

	loaderSubject: Subject<boolean> = new Subject<boolean>();

	constructor() { }

	enable() {
		this.emit(true);
	}

	disable() {
		this.emit(false);
	}

	private emit(value: boolean) {
		this.loaderSubject.next(value);
	}
}
