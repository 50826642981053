import { Injectable } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { IUser } from '@app/01.global/interfaces/IUser';
import { TableListColumnMode } from '@app/table-list/models/table-list-column';
import { TranslateService } from '@ngx-translate/core';
import { PositionEnum } from '../helpers/position-enum';
import { RoleEnum } from '../helpers/role-enum';
import { DatatableColumn } from '../models/datatable-column';
import { DatatableRow } from '../models/datatable-row';
import { AuthenticationService } from '../services/authentication.service';
import { BaseMapperService } from './base-mapper.service';

@Injectable({
	providedIn: 'root',
})
export class ProvidersMapperService extends BaseMapperService {
	constructor(
		translateService: TranslateService,
		private authenticationService: AuthenticationService
	) {
		super(translateService);
	}

	doMap(source: IUser, route: ActivatedRoute): DatatableRow {
		var inProgress: boolean = route.snapshot.data.inProgress;
		var isBranchMode: boolean = route.snapshot.data.isBranchMode;

		var row = new DatatableRow(source);

		row.columns = [
			{
				displayMode: TableListColumnMode.title,
				value:
					source.providerInfo?.name ??
					source.providerInfo?.commercialName ??
					// source.providerInfo?.description ??
					source.displayName,
				name: this.translateService.instant('Name'),
				sortable: true,
			},
			{
				name: this.translateService.instant('providerInfo?.address'),
				sortable: true,
				displayMode: TableListColumnMode.html,
				value:
					source.providerInfo?.address?.line1 +
					'<br>' +
					source.providerInfo?.address?.line2 +
					'<br>' +
					source.providerInfo?.address?.postalCode +
					'<br>' +
					source.providerInfo?.address?.city +
					' - ' +
					source.providerInfo?.address?.country,
			},
		];

		var ThumbnailColumn = new DatatableColumn();

		ThumbnailColumn.name = '';
		ThumbnailColumn.sortable = false;
		ThumbnailColumn.displayMode = TableListColumnMode.thumbnail;
		ThumbnailColumn.value = source.photo?.url;
		ThumbnailColumn.tooltip =
			source.providerInfo?.name ??
			source.providerInfo?.commercialName ??
			// source.providerInfo?.description ??
			source.displayName;

		row.columns.push(ThumbnailColumn);

		if (!inProgress) {
			row.actionButtons.push(this.createDetailsButton(false));

			if (
				isBranchMode ||
				row.source.providerInfo?.mainProviderAccountId > 0 ||
				row.source.providerInfo?.showMainBranch
			) {
				row.actionButtons.push(this.createMapButton(false));
			}
		}

		if (
			this.authenticationService.isAdmin ||
			(this.authenticationService.isProvider && isBranchMode)
		) {
			row.actionButtons.push(this.createUpdateButton(false));
			row.actionButtons.push(this.createDeleteButton(false));
		}

		return row;
	}
}

@Injectable({
	providedIn: 'root',
})
export class PatientsMapperService extends BaseMapperService {
	constructor(
		translateService: TranslateService,
		private authenticationService: AuthenticationService
	) {
		super(translateService);
	}

	doMap(source: IUser, route: ActivatedRoute): DatatableRow {
		var row = new DatatableRow(source);
		row.columns = [
			{
				displayMode: TableListColumnMode.title,
				value: source.displayName,
				name: this.translateService.instant('DisplayName'),
				sortable: true,
			},
		];

		var ThumbnailColumn = new DatatableColumn();

		ThumbnailColumn.displayMode = TableListColumnMode.thumbnail;
		ThumbnailColumn.value = source.photo?.url;
		ThumbnailColumn.tooltip = source.displayName;
		ThumbnailColumn.name = 'thumbnail';
		ThumbnailColumn.sortable = false;

		row.columns.push(ThumbnailColumn);

		if (route.snapshot.data.isForBooking) {
			row.actionButtons.push(this.createSelectButton());
		} else {
			row.actionButtons.push(this.createDetailsButton());
		}

		return row;
	}
}

@Injectable({
	providedIn: 'root',
})
export class EmployeesMapperService extends BaseMapperService {
	constructor(
		translateService: TranslateService,
		private authenticationService: AuthenticationService
	) {
		super(translateService);
	}

	doMap(source: IUser, route: ActivatedRoute): DatatableRow {
		var inProgress: boolean = route.snapshot.data.inProgress;
		var row = new DatatableRow(source);
		row.columns = [];
		row.columns = row.columns.concat([
			{
				name: this.translateService.instant('DisplayName'),
				sortable: true,
				displayMode: TableListColumnMode.title,
				value: source.displayName,
				tooltip: source.displayName,
			},
		]);

		if (source.specialty?.name) {
			row.columns.push({
				name: this.translateService.instant('Specialty'),
				sortable: true,
				displayMode: TableListColumnMode.text,
				value:
					source.specialty?.name
				// + (source.specialty?.description
				// ? ' - ' + source.specialty?.description
				// : ''),
			});
		}

		if (source.position.name != PositionEnum.Employee) {
			var consultationTypesColumn: DatatableColumn = {
				name: this.translateService.instant('ConsultationTypes'),
				sortable: true,
				value: '',
				displayMode: TableListColumnMode.html,
			};

			source.consultationTypes.forEach((consultationType, index) => {
				consultationTypesColumn.value =
					consultationTypesColumn.value +
					(index > 0 ? '<br>' : '') +
					this.translateService.instant(consultationType.name);
			});

			row.columns.push(consultationTypesColumn);
		}
		if (source.employeeInfo) {
			let spokenLanguages: DatatableColumn = {
				name: this.translateService.instant('SpokenLanguages'),
				sortable: true,
				displayMode: TableListColumnMode.html,
				value: ''
			};
			source.employeeInfo.spokenLanguages.forEach((spokenLanguage, index) => {
				spokenLanguages.value =
					spokenLanguages.value +
					(index > 0 ? '<br>' : '') +
					this.translateService.instant(spokenLanguage.nativeName);
			});

			row.columns.push(spokenLanguages);

			if (source.position.name != PositionEnum.Employee) {
				row.columns.push(
					{
						name: this.translateService.instant('PraticeAreas'),
						sortable: true,
						displayMode: TableListColumnMode.text,
						value: source.employeeInfo.praticeAreas,
					},
					{
						name: this.translateService.instant('Diseases'),
						sortable: true,
						displayMode: TableListColumnMode.text,
						value: source.employeeInfo?.diseases,
					},
					{
						name: this.translateService.instant(
							'AcceptVideoConsultations'
						),
						sortable: false,
						displayMode: TableListColumnMode.checkboxSwitch,
						value: source.employeeInfo?.acceptVideoConsultations,
					},
					{
						name: this.translateService.instant('AcceptOnlineBooking'),
						sortable: false,
						displayMode: TableListColumnMode.checkboxSwitch,
						value: source.employeeInfo?.acceptOnlineBooking,
					}
				);
			}
		}

		var ThumbnailColumn = new DatatableColumn();

		ThumbnailColumn.name = 'photo';
		ThumbnailColumn.sortable = false;
		ThumbnailColumn.displayMode = TableListColumnMode.thumbnail;
		ThumbnailColumn.value = source.photo?.url;
		ThumbnailColumn.tooltip = source.displayName;

		row.columns.push(ThumbnailColumn);

		if (!inProgress) {
			row.actionButtons.push(this.createDetailsButton());
			if (this.authenticationService.isGuest) {
				row.actionButtons.push(this.createGoToWaitingRoomButton());
			}
		}

		if (this.authenticationService.isAdmin || this.authenticationService.isProvider) {
			row.actionButtons.push(this.createUpdateButton());
			row.actionButtons.push(this.createDeleteButton());
		}

		return row;
	}
}
