<div class="card user-profile o-hidden mb-4 w-100">
	<div class="header-cover"></div>
	<div class="user-info card-body py-0" style="position: relative">
		<div class="profile-picture avatar-xl mb-2 profileImgContainer shadow" [ngStyle]="{
		'margin-top': '-10.25rem',
		'background-image':
			'url(' +
			photoUrl +
			')'}"></div>
		<h2 class="m-3">{{ title }}</h2>
		<div class="d-flex cursor-pointer" *ngIf="subTitle">
			<div class="profile-picture avatar-md mb-2 profileImgContainer shadow" [ngStyle]="{
				'background-image':
					'url(' +
					subTitlePhotoUrl +
					')'}"></div>
			<h4 class="text-center my-auto ml-3">{{subTitle}}</h4>
		</div>
		<div class="row my-1" *ngIf="showConnectionOptions">
			<div class="col text-left my-1">
				<label class="switch switch-primary mr-3">
					<input type="checkbox" [checked]="source?.employeeInfo?.acceptVideoConsultations" disabled />
					<span class="slider"></span>
					<span>{{ 'VideoConsultation' | translate }}</span>
				</label>
			</div>
			<div class="col text-left my-1">
				<label class="switch switch-primary mr-3">
					<input type="checkbox" [checked]="source?.employeeInfo?.acceptOnlineBooking" disabled />
					<span class="slider"></span>
					<span>{{ 'AcceptOnlineBooking' | translate }}</span>
				</label>
			</div>
		</div>
		<div class="row my-1" *ngIf="showBooking">
			<div class="col text-left my-1 mb-3">
				<button *ngIf="showAppointmentCalendar()" class="btn btn-secondary px-3" (click)="bookFromDoctorDetails()" data-toggle="tooltip"
					data-placement="bottom" title="{{'Booking' | translate}}">
					<i class="i-Calendar-4 text-20 mr-2"></i>
					<span style="vertical-align: text-bottom;">{{ 'Booking' | translate }}</span>
				</button>
			</div>
		</div>
	</div>
	<div class="card-body pt-0" *ngIf="showCardBody">
		<hr class="my-5" />
		<div [ngSwitch]="type">
			<ng-container *ngSwitchCase="DetailsHeroSectionType.patient">
				<div class="row mb-4">
					<div class="col">
						<p class="m-0">
							<span class="text-info text-20 mr-2" style="vertical-align: sub;"><i
									class="i-Email"></i></span>
							<a href="mailto:{{source.email}}">{{source.email}}</a>
						</p>
						<p class="m-0" *ngIf="source?.phoneNumber && source?.phoneNumberCountryCode">
							<span class="text-info text-20 mr-2" style="vertical-align: sub;"><i
									class="i-Telephone"></i></span>
							+{{source.phoneNumberCountryCode}} {{source.phoneNumber}}
						</p>
						<p class="m-0" *ngIf="source?.address.line1">
							<span class="text-info text-20 mr-2" style="vertical-align: sub;"><i
									class="i-Mail-3"></i></span>
							<app-display-template-address [address]="source.address">
							</app-display-template-address>
						</p>
					</div>
				</div>
			</ng-container>
			<ng-container *ngSwitchCase="DetailsHeroSectionType.provider">
				<div class="row mb-4">
					<div class="col">
						<p class="m-0 cursor-pointer" *ngIf="source.providerInfo.mainProvider"
							[routerLink]="RouteList.joinRoutes([RouteList.providers, RouteList._routeCrudEnum.Details, source.providerInfo.mainProviderAccountId])">
							<span class="text-info text-20 mr-2" style="vertical-align: sub;"><i
									class="i-University1"></i></span>
							<span>
								{{source.providerInfo.mainProvider.commercialName}}
							</span>
						</p>
						<p class="m-0">
							<span class="text-info text-20 mr-2" style="vertical-align: sub;"><i
									class="i-Email"></i></span>
							<a href="mailto:{{source.email}}">{{source.email}}</a>
						</p>
						<p class="m-0" *ngIf="source.providerInfo.website">
							<span class="text-info text-20 mr-2" style="vertical-align: sub;"><i
									class="i-Sidebar-Window"></i></span>
							{{source.providerInfo.website}}
						</p>
						<p class="m-0" *ngIf="source.providerInfo.nif || source.providerInfo.mainProvider?.nif">
							<span class="text-info text-20 mr-2" style="vertical-align: sub;"><i
									class="i-Receipt-3"></i></span>
							{{source.providerInfo.nif ?? source.providerInfo.mainProvider?.nif}}
						</p>
						<p class="m-0" *ngIf="isAdmin && source.providerInfo.contractStartDateIgnoringTimeZone">
							<span class="text-info text-20 mr-2" style="vertical-align: sub;"><i
									class="i-Clock-Back"></i></span>
							{{source.providerInfo.contractStartDateIgnoringTimeZone | localizedDate: 'fullDate'}}
						</p>
						<p class="m-0" *ngIf="isAdmin && source.providerInfo.contractEndDateIgnoringTimeZone">
							<span class="text-info text-20 mr-2" style="vertical-align: sub;"><i
									class="i-Clock-Forward"></i></span>
							{{source.providerInfo.contractEndDateIgnoringTimeZone | localizedDate: 'fullDate'}}
						</p>
						<p class="m-0" *ngIf="source.providerInfo.observations">
							<span class="text-info text-20 mr-2" style="vertical-align: sub;"><i
									class="i-Information"></i></span>
							{{source.providerInfo.observations}}
						</p>
						<p class="m-0" *ngIf="isAdmin">
							<app-form-control [type]="ControlTypeEnum.CheckboxSwitch"
								[control]="paymentsSuspendedControl" name="providerInfoPaymentsSuspended">
							</app-form-control>
						</p>
					</div>
					<div class="col">
						<p class="m-0" *ngIf="source.providerInfo.description">
							<span class="text-info text-20 mr-2" style="vertical-align: sub;"><i
									class="i-Letter-Open"></i>
							</span>
							<span>{{source.providerInfo.description | removeHtmlTags}}</span>
						</p>
					</div>
				</div>
			</ng-container>
			<ng-container *ngSwitchCase="DetailsHeroSectionType.medicalOffice">
				<div class="row mb-4">
					<div class="col text-left">
						<p class="m-0 cursor-pointer"
							[routerLink]="RouteList.joinRoutes([RouteList.providers, RouteList._routeCrudEnum.Details, source.branchAccountId])">
							<span class="text-info text-20 mr-2" style="vertical-align: sub;"><i
									class="i-University1"></i></span>
							<span>
								{{source.branch.commercialName}}
							</span>
						</p>
						<p class="m-0" *ngIf="source.branch.account.email">
							<span class="text-info text-20 mr-2" style="vertical-align: sub;"><i
									class="i-Email"></i></span>
							<a href="mailto:{{source.branch.account.email}}">{{source.branch.account.email}}</a>
						</p>
						<p class="m-0" *ngIf="source.branch.website">
							<span class="text-info text-20 mr-2" style="vertical-align: sub;"><i
									class="i-Sidebar-Window"></i></span>
							{{source.branch.website}}
						</p>
						<p class="m-0" *ngIf="source.branch.nif || source.branch.mainProvider.nif">
							<span class="text-info text-20 mr-2" style="vertical-align: sub;"><i
									class="i-Receipt-3"></i></span>
							{{source.branch.nif ?? source.branch.mainProvider.nif}}
						</p>
						<p class="m-0" *ngIf="isAdmin && source.branch.contractStartDateIgnoringTimeZone">
							<span class="text-info text-20 mr-2" style="vertical-align: sub;"><i
									class="i-Clock-Back"></i></span>
							{{source.branch.contractStartDateIgnoringTimeZone | localizedDate: 'fullDate'}}
						</p>
						<p class="m-0" *ngIf="isAdmin && source.branch.contractEndDateIgnoringTimeZone">
							<span class="text-info text-20 mr-2" style="vertical-align: sub;"><i
									class="i-Clock-Forward"></i></span>
							{{source.branch.contractEndDateIgnoringTimeZone | localizedDate: 'fullDate'}}
						</p>
						<p class="m-0" *ngIf="source.branch.observations">
							<span class="text-info text-20 mr-2" style="vertical-align: sub;"><i
									class="i-Information"></i></span>
							{{source.branch.observations}}
						</p>
						<p class="m-0" *ngIf="isAdmin">
							<app-form-control [type]="ControlTypeEnum.CheckboxSwitch"
								[control]="paymentsSuspendedControl" name="providerInfoPaymentsSuspended">
							</app-form-control>
						</p>
					</div>
					<div class="col text-left">
						<p class="m-0" *ngIf="source.branch.description">
							<span class="text-info text-20 mr-2" style="vertical-align: sub;"><i
									class="i-Letter-Open"></i>
							</span>
							<span [innerHTML]="source.branch.description"></span>
						</p>
					</div>
					<div class="col text-left" *ngIf="source.appointments">
						<p class="m-0" *ngFor="let slot of source.appointments"
							(click)="bookFromMedicalOfficeEmptySlot(slot)">
							<span class="text-info text-20 mr-2" style="vertical-align: sub;"><i
									class="i-Letter-Open"></i></span>
							{{slot.startDate}}
							{{slot.endDate}}
						</p>
					</div>
				</div>
			</ng-container>
		</div>
	</div>
</div>