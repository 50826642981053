import { Component, Input, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { RouteList } from '@app/01.global/helpers/route-enum';
import { IUser } from '@app/01.global/interfaces/IUser';
import { AuthenticationService } from '@app/01.global/services/authentication.service';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
	selector: 'app-users-widget',
	templateUrl: './users-widget.component.html',
	styleUrls: ['./users-widget.component.scss'],
})
export class UsersWidgetComponent implements OnInit {
	@Input('title') title: string = '';
	@Input('iconColor') iconColor: string = 'primary';
	@Input('multiple') multiple: boolean = false;
	@Input('placement') placement:
		| 'top'
		| 'top-start'
		| 'top-left'
		| 'top-end'
		| 'top-right'
		| 'bottom'
		| 'bottom-start'
		| 'bottom-left'
		| 'bottom-end'
		| 'bottom-right'
		| 'start'
		| 'left'
		| 'start-top'
		| 'left-top'
		| 'start-bottom'
		| 'left-bottom'
		| 'end'
		| 'right'
		| 'end-top'
		| 'right-top'
		| 'end-bottom'
		| 'right-bottom' = 'right';

	_users!: IUser[];
	@Input() get users(): IUser[] {
		return this._users;
	}

	set users(v: IUser[]) {
		this._users = v;
	}

	routeList = RouteList;
	@Input() loading: boolean = false;
	constructor(
		private router: Router,
		private authenticationService: AuthenticationService,
		private modalService: NgbModal
	) { }

	ngOnInit(): void { }

	navigateToDetails(target: IUser) {
		if (target.employeeInfoAccountId) {
			this.router.navigate([
				RouteList.joinRoutes([
					RouteList.employees,
					RouteList._routeCrudEnum.Details + '/' + target.id,
				]),
			]);
		} else if (target.providerInfoAccountId) {
			this.router.navigate([
				RouteList.joinRoutes([
					RouteList.providers,
					RouteList._routeCrudEnum.Details + '/' + target.id,
				]),
			]);
		} else {
			if (
				this.authenticationService.isAdmin ||
				this.authenticationService.isProvider ||
				this.authenticationService.isTherapist
			) {
				this.router.navigate([
					RouteList.joinRoutes([
						RouteList.patients,
						RouteList._routeCrudEnum.Details + '/' + target.id,
					]),
				]);
			}
		}
		this.modalService.dismissAll();
	}
}
