<div class="timeline-wrapper" [ngStyle]="{
				position: 'relative',
				'max-height': maxHeight,
				'overflow-y': verticalOverflow,
				'padding-top': '6rem'
			}" [perfectScrollbar]="{ suppressScrollX: true }" infinite-scroll [infiniteScrollDistance]="distance"
	[infiniteScrollThrottle]="throttle" [scrollWindow]="false" (scrolled)="onScroll()">
	<app-local-loader [enabled]="loading"></app-local-loader>
	<ng-container *ngIf="appointments && appointments.length > 0">
		<ul class="timeline clearfix">
			<li class="timeline-line"></li>
			<li class="timeline-item" *ngFor="let item of appointments; let i = index"
				style="margin-top: -5rem; width: 51%">
				<div class="timeline-badge">
					<i class="badge-icon text-white i-Library" [ngStyle]="{
										'background-color':getAppointmentColor(item)
									}" style="font-size: 1rem;"></i>
				</div>
				<ng-template #popContentDoctor>
				</ng-template>
				<div class="timeline-card card mx-2">
					<div class="card-body p-2">
						<div class="mb-1" style="width: 100%;">
							<div *ngIf="isCurrentUserParticipant(item) &&
												!parentSentData" style="cursor: pointer" [ngbPopover]="popContentDoctor" triggers="mouseenter:mouseleave"
								[placement]="['right', 'auto']" animation="false" container="body">
								<div class="d-flex flex-row">
									<div class="profile-picture avatar-lg mr-2 profileImgContainer" [ngStyle]="{
											'background-image':
											'url(' +
											(item.medicalOffice?.doctor?.photo?.url ??
											'./assets/images/default-user.png') +
											')'
										}"></div>
									<div class="flex-grow-1 my-auto">
										<h5 class="m-0">{{item.medicalOffice?.doctor?.displayName}}</h5>
										<p class="m-0 text-small text-muted"><a
												href="mailto:{{item.medicalOffice.branch.commercialName}}">{{item.medicalOffice.branch.commercialName}}</a>
										</p>
										<p class="m-0 text-small text-muted">
											{{item.medicalOffice.doctor.specialty?.name}}
										</p>
									</div>
									<div class="flex-grow-1 my-auto ml-auto text-right">
										<button class="btn btn-rounded btn-icon btn-info my-1 mr-0"
											[routerLink]="routeList.joinRoutes([routeList.employees, routeList._routeCrudEnum.Details, item.medicalOffice.doctor?.id])"><i
												class="i-Find-User text-white" style="font-size: 1rem;"></i></button>
									</div>
								</div>
							</div>
							<div *ngIf="currentUser?.id ==
													item.medicalOffice.doctor?.id &&
												!parentSentData
											" style="cursor: pointer" [ngbPopover]="popContentDoctor" triggers="mouseenter:mouseleave"
								[placement]="['right', 'auto']" animation="false" container="body">
								<div class="d-flex flex-row" *ngFor="let participant of item.participants">
									<div class="profile-picture avatar-lg mr-2 profileImgContainer" [ngStyle]="{
											'background-image':
											'url(' +
											(participant.photo?.url ??
											'./assets/images/default-user.png') +
											')'
										}"></div>
									<div class="flex-grow-1 my-auto">
										<h5 class="m-0">{{participant.displayName}}</h5>
										<p class="m-0 text-small text-muted"><a
												href="mailto:{{participant.email}}">{{participant.email}}</a>
										</p>
									</div>
									<div class="flex-grow-1 my-auto ml-auto text-right">
										<button class="btn btn-rounded btn-icon btn-info my-1 mr-0"
											[routerLink]="routeList.joinRoutes([routeList.patients, routeList._routeCrudEnum.Details, participant.id])"><i
												class="i-Find-User text-white" style="font-size: 1rem;"></i></button>
									</div>
								</div>
							</div>
							<div *ngIf="(!isCurrentUserParticipant(item) &&
													currentUser?.id !==
														item.medicalOffice.doctor?.id) ||
												parentSentData
											" style="cursor: pointer" [ngbPopover]="popContentDoctor" triggers="mouseenter:mouseleave"
								[placement]="['right', 'auto']" animation="false" container="body">
								<div class="d-flex flex-row">
									<div class="profile-picture avatar-lg mr-2 profileImgContainer" [ngStyle]="{
											'background-image':
											'url(' +
											(item.medicalOffice?.doctor?.photo?.url ??
											'./assets/images/default-user.png') +
											')'
										}"></div>
									<div class="flex-grow-1 my-auto">
										<h5 class="m-0">{{item.medicalOffice?.doctor?.displayName}}</h5>
										<p class="m-0 text-small text-muted"><a
												href="mailto:{{item.medicalOffice?.branch?.commercialName}}">{{item.medicalOffice?.branch?.commercialName}}</a>
										</p>
										<p class="m-0 text-small text-muted">
											{{item.medicalOffice?.doctor?.Specialty?.name}}
										</p>
									</div>
									<div class="flex-grow-1 my-auto ml-auto text-right">
										<button class="btn btn-rounded btn-icon btn-info my-1 mr-0"
											[routerLink]="routeList.joinRoutes([routeList.employees, routeList._routeCrudEnum.Details, item.medicalOffice?.doctor?.id])"><i
												class="i-Find-User text-white" style="font-size: 1rem;"></i></button>
									</div>
								</div>
							</div>
							<p class="mb-1" (click)="navigateToCalendar(item)" style="cursor: pointer">
								<i class="i-Calendar-4 text-primary" style="font-size: 1rem;"></i>
								<span class="text-12">
									{{ item.startDate | localizedDate: 'fullDate' }}
								</span>
							</p>
							<p class="mb-1" (click)="navigateToCalendar(item)" style="cursor: pointer">
								<i class="i-Clock text-primary" style="font-size: 1rem;"></i>
								<span class="text-12">
									{{ item.startDate | localizedDate: 'fullTime' }}
								</span>
							</p>
							<div class="mb-1" *ngIf="item.appointmentStatusesMachine.length > 0">
								<div class="appointment-actions d-flex justify-content-end">
									<div class="status-logo d-none d-xl-flex d-xxl-flex" [ngStyle]="{
										'background-color':
											getAppointmentColor(item)
									}">
										<i class="i-Loading-3 text-white" style="font-size: 1.3rem;"></i>
										<span class="status-name text-12 text-white">
											{{
											item.appointmentStatusesMachine[0].machineStatus.description
											| translate
											}}
										</span>
									</div>
								</div>
								<span class="d-block d-xl-none d-xxl-none" [ngStyle]="{
													color: getAppointmentColor(item)
												}">
									<i class="i-Loading-3 text-white" style="font-size: 1.3rem;"></i>
									{{
									item.appointmentStatusesMachine[0].machineStatus.description
									| translate
									}}
								</span>
							</div>
							<p class="mb-1" *ngFor="let ms of item.consultationTypes">
								<i class="i-Cube-Molecule-2 text-primary" style="font-size: 1rem;"></i>
								<span class="text-12">

									{{ ms.name | translate }}
								</span>
							</p>
						</div>
					</div>
				</div>
			</li>
		</ul>
		<ul class="timeline clearfix">
			<li class="timeline-line"></li>
			<li class="timeline-group text-center">
				<button class="btn btn-icon-text btn-secondary" data-toggle="tooltip" data-placement="bottom"
					title="{{ userJoinDate | localizedDate: 'yyyy' }}">
					<i class="i-Calendar-4 text-white" style="font-size: 1rem;"></i>
					{{ userJoinDate | localizedDate: 'yyyy' }}
				</button>
			</li>
		</ul>
	</ng-container>
	<ng-container *ngIf="appointments && appointments.length === 0">
		<h5 class="m-0 text-primary text-center">
			{{'NoData' | translate}}
		</h5>
	</ng-container>
</div>