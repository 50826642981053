import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { DatatableFilters } from '@app/01.global/models/DatatableFilters';
import { IApiResponse } from '../interfaces/helpers/iResponseWrapper';
import { environment } from '@environments/environment';
import '@app/01.global/helpers/observable-extensions';
import { ITableListService } from '../../table-list/services/iservice';
import { Observable } from 'rxjs';
import { IAgreementCard } from '../interfaces/iAgreementCard';
import { BaseService } from './base.service';

@Injectable({ providedIn: 'root' })
export class AgreementCardsService
	extends BaseService
	implements ITableListService
{
	ApiBaseURL = environment.ApiBaseURL;

	constructor(private http: HttpClient) {
		super();
	}

	create(model: IAgreementCard) {
		return this.http
			.post<IApiResponse>(`${this.ApiBaseURL}/AgreementCards/`, model)
			.ToApiResult();
	}

	update(id: number, model: IAgreementCard) {
		return this.http
			.put<IApiResponse>(`${this.ApiBaseURL}/AgreementCards/${id}`, model)
			.ToApiResult();
	}

	delete(id: number) {
		return this.http
			.delete<IApiResponse>(`${this.ApiBaseURL}/AgreementCards/${id}`)
			.ToApiResult();
	}

	accept(id: number) {
		return this.http
			.post<IApiResponse>(
				`${this.ApiBaseURL}/AgreementCards/Accept/${id}`,
				{}
			)
			.ToApiResult();
	}

	readCount(page: DatatableFilters): Observable<number> {
		let params = this.paginationToHttpParams(page);
		return this.http
			.get<IApiResponse>(`${this.ApiBaseURL}/AgreementCards/count`, {
				params,
			})
			.ToApiResult();
	}

	readAll(page: DatatableFilters): Observable<IAgreementCard[]> {
		let params = this.paginationToHttpParams(page);

		return this.http
			.get<IApiResponse>(`${this.ApiBaseURL}/AgreementCards`, {
				params,
			})
			.ToApiResult();
	}

	readUserCards(userId: number = 0): Observable<IAgreementCard[]> {
		return this.http
			.get<IApiResponse>(
				`${this.ApiBaseURL}/AgreementCards/user/${userId}`
			)
			.ToApiResult();
	}

	read(id: number): Observable<IAgreementCard> {
		return this.http
			.get<IApiResponse>(`${this.ApiBaseURL}/AgreementCards/${id}`)
			.ToApiResult();
	}

	setAsDefault(id: number) {
		return this.http
			.post<IApiResponse>(`${this.ApiBaseURL}/AgreementCards/${id}`, {})
			.ToApiResult();
	}
}
