import { Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { RouteList } from '@app/01.global/helpers/route-enum';
import { IAppointment } from '@app/01.global/interfaces/IAppointment';
import { ActiveChannel } from '@app/01.global/models/active-channel';
import { ConsultationSettings } from '@app/01.global/models/consultation-settings';
import { DatatableFilters } from '@app/01.global/models/DatatableFilters';
import { AppointmentService } from '@app/01.global/services/appointment.service';
import { AuthenticationService } from '@app/01.global/services/authentication.service';
import { ConsultationStatusService } from '@app/01.global/status-services/consultation-status.service';
import { Subscription } from 'rxjs';

@Component({
	selector: 'app-consultation',
	templateUrl: './consultation.component.html',
	styleUrls: ['./consultation.component.scss'],
})
export class ConsultationComponent implements OnInit, OnDestroy {
	constructor(
		private route: ActivatedRoute,
		private statusService: ConsultationStatusService,
		private appointmentService: AppointmentService,
		public authenticationService: AuthenticationService
	) { }

	routeList = RouteList;
	subs: Subscription[] = [];
	showVideo: boolean;
	showSharedFiles: boolean;
	showMeetingNotes: boolean;
	showPatientHistory: boolean;
	showAddUser: boolean;
	archiveMode: boolean;
	appointments: IAppointment[];
	userJoinDate: Date;
	page: DatatableFilters = {
		totalItems: 0,
		pageSize: 10,
		currentPage: 0,
		totalPages: 0,
	};

	ngOnInit(): void {
		this.route.data.subscribe((data) => {
			this.statusService.toggleArchiveMode(!!data.archiveMode);
		});

		this.subs.push(
			this.statusService.activeChannelObs.subscribe(
				(response: ActiveChannel) => {
					if (
						response &&
						response.channel &&
						response.channel.users &&
						response.channel.users.length > 0
					) {
						this.page.readFromWhoId = response.channel.users[0].id;
						this.userJoinDate =
							response.channel.users[0].birthDateIgnoringTimeZone;
						if (this.authenticationService.isDoctor || this.authenticationService.isTherapist) {
							this.readAppointments();
						}
					}
				}
			)
		);

		this.subs.push(
			this.statusService.settingsObs.subscribe(
				(response: ConsultationSettings) => {
					this.showAddUser = response.showAddUser;
					this.showMeetingNotes = response.showMeetingNotes;
					this.showPatientHistory = response.showPatientHistory;
					this.showVideo = response.showVideo;
					this.showSharedFiles = response.showSharedFiles;
					this.archiveMode = response.archiveMode;
				}
			)
		);
	}

	ngOnDestroy(): void {
		this.subs.forEach((s) => s?.unsubscribe());
		this.statusService.reset();
	}

	private readAppointments() {
		this.appointmentService.readAll(this.page).subscribe({
			next: (response: IAppointment[]) => {
				this.appointments = response;
			},
			error: () => { },
		});
	}
}
