import { BrowserModule } from '@angular/platform-browser';
import { APP_INITIALIZER, NgModule } from '@angular/core';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { SharedModule } from './00.shared/shared.module';
import {
	HttpClient,
	HttpClientModule,
	HTTP_INTERCEPTORS,
} from '@angular/common/http';
import { ErrorInterceptor } from './01.global/helpers/error.interceptor';
import { JwtInterceptor } from './01.global/helpers/jwt.interceptor';
import { TranslateModule, TranslateLoader } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { appInitializer } from './01.global/helpers/app.initializer';
import { JwtHelperService, JWT_OPTIONS } from '@auth0/angular-jwt';
//https://stackoverflow.com/questions/62698023/refreshing-same-url-gives-404-not-found-in-angular
import { HashLocationStrategy, LocationStrategy, registerLocaleData } from '@angular/common';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { AuthenticationService } from './01.global/services/authentication.service';
import {
	GoogleLoginProvider,
	SocialAuthServiceConfig,
} from '@abacritt/angularx-social-login';
import { ServiceWorkerModule } from '@angular/service-worker';
import { LayoutsModule } from './layouts/layouts.module';
import { TimeZoneInterceptor } from './01.global/helpers/time-zone.interceptor';
import { LogInterceptor } from './01.global/helpers/logging.interceptor';
import { SubmittedFormInterceptor } from './01.global/helpers/submitted-form.interceptor';
import { environment } from '@environments/environment';
import { HttpCancelInterceptor } from './01.global/helpers/http-cancel.interceptor';
import localePt from '@angular/common/locales/pt';
import localeEn from '@angular/common/locales/en';
import localeFr from '@angular/common/locales/fr';
import localeDe from '@angular/common/locales/de';
import localeEs from '@angular/common/locales/es';


// required for AOT compilation
export function HttpLoaderFactory(http: HttpClient): TranslateHttpLoader {
	return new TranslateHttpLoader(http);
}

registerLocaleData(localePt);
registerLocaleData(localeEn);
registerLocaleData(localeDe);
registerLocaleData(localeFr);
registerLocaleData(localeEs);



@NgModule({
	declarations: [AppComponent],
	imports: [
		BrowserModule,
		SharedModule,
		HttpClientModule,
		TranslateModule.forRoot({
			loader: {
				provide: TranslateLoader,
				useFactory: HttpLoaderFactory,
				deps: [HttpClient],
			},
		}),
		BrowserAnimationsModule,
		AppRoutingModule,
		NgbModule,
		LayoutsModule,
		ServiceWorkerModule.register('ngsw-worker.js', {
			enabled: environment.production || environment.staging,
			// Register the ServiceWorker as soon as the application is stable
			// or after 30 seconds (whichever comes first).
			registrationStrategy: 'registerWhenStable:30000',
		}),
	],
	providers: [
		{
			provide: APP_INITIALIZER,
			useFactory: appInitializer,
			multi: true,
			deps: [AuthenticationService],
		},
		{ provide: HTTP_INTERCEPTORS, useClass: HttpCancelInterceptor, multi: true },
		{ provide: HTTP_INTERCEPTORS, useClass: JwtInterceptor, multi: true },
		{ provide: HTTP_INTERCEPTORS, useClass: LogInterceptor, multi: true },
		{ provide: HTTP_INTERCEPTORS, useClass: ErrorInterceptor, multi: true },
		{
			provide: HTTP_INTERCEPTORS,
			useClass: SubmittedFormInterceptor,
			multi: true,
		},
		{
			provide: HTTP_INTERCEPTORS,
			useClass: TimeZoneInterceptor,
			multi: true,
		},
		{ provide: LocationStrategy, useClass: HashLocationStrategy },
		{ provide: JWT_OPTIONS, useValue: JWT_OPTIONS },
		JwtHelperService,
		{
			provide: 'SocialAuthServiceConfig',
			useValue: {
				autoLogin: false,
				providers: [
					{
						id: GoogleLoginProvider.PROVIDER_ID,
						provider: new GoogleLoginProvider(
							'768816325366-4jv7f5knlimb3jce1j21moteb7ih4fse.apps.googleusercontent.com'
						),
					},
				],
			} as SocialAuthServiceConfig,
		},
	],
	bootstrap: [AppComponent],
})
export class AppModule { }
