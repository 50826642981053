import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { DatatableFilters } from '@app/01.global/models/DatatableFilters';
import { IApiResponse } from '../interfaces/helpers/iResponseWrapper';
import { environment } from '@environments/environment';
import '@app/01.global/helpers/observable-extensions';
import { ITableListService } from '../../table-list/services/iservice';
import { DocTypesEnum } from '../helpers/doc-types-enum';
import { Observable } from 'rxjs';
import { IDocument } from '../interfaces/iDocument';
import { BaseService } from './base.service';

@Injectable({
	providedIn: 'root',
})
export class DocumentsService extends BaseService implements ITableListService {
	ApiBaseURL = environment.ApiBaseURL;

	constructor(private http: HttpClient) {
		super();
	}

	readCount(page: DatatableFilters): Observable<number> {
		let params = this.paginationToHttpParams(page);
		return this.http
			.get<IApiResponse>(`${this.ApiBaseURL}/documents/count`, {
				params,
			})
			.ToApiResult();
	}

	readAll(page: DatatableFilters): Observable<IDocument[]> {
		let params = this.paginationToHttpParams(page);

		return this.http
			.get<IApiResponse>(`${this.ApiBaseURL}/documents`, {
				params,
			})
			.ToApiResult();
	}

	create(file: File, docType: DocTypesEnum): Observable<IDocument> {
		const uploadData = new FormData();
		uploadData.append('docType', docType.toString());
		uploadData.append('myFile', file, file.name);
		return this.http
			.post<IApiResponse>(`${this.ApiBaseURL}/documents`, uploadData)
			.ToApiResult();
	}

	delete(id: number): Observable<void> {
		return this.http
			.delete<IApiResponse>(`${this.ApiBaseURL}/documents/${id}`)
			.ToApiResult();
	}

	exportCSV(): Observable<IDocument> {
		return this.http
			.get<IApiResponse>(`${this.ApiBaseURL}/documents/exportcsv`)
			.ToApiResult();
	}

	read(id: number): Observable<any> {
		throw new Error('Method not implemented.');
	}
}
