import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { INote } from '@app/01.global/interfaces/iNote';
import { DatatableFilters } from '@app/01.global/models/DatatableFilters';
import { IApiResponse } from '../interfaces/helpers/iResponseWrapper';
import { environment } from '@environments/environment';
import '@app/01.global/helpers/observable-extensions';
import { ITableListService } from '../../table-list/services/iservice';
import { Observable, of } from 'rxjs';
import { IChannel } from '../interfaces/iChannel';
import { IDocument } from '../interfaces/iDocument';
import { IUser } from '../interfaces/IUser';
import { BaseService } from './base.service';
import { IVideoRoom } from '../interfaces/IVideoRoom';

@Injectable({
	providedIn: 'root',
})
export class ChannelService extends BaseService implements ITableListService {
	ApiBaseURL = environment.ApiBaseURL;

	constructor(private http: HttpClient) {
		super();
	}

	create(model: IChannel): Observable<IChannel> {
		return this.http
			.post<IApiResponse>(`${this.ApiBaseURL}/channels/`, model)
			.ToApiResult();
	}

	readVideoRoom(channelId: number): Observable<IVideoRoom> {
		return this.http
			.post<IApiResponse>(
				`${this.ApiBaseURL}/channels/readVideoRoom/${channelId}`,
				{}
			)
			.ToApiResult();
	}

	update(id: number, model: IChannel): Observable<IChannel> {
		return this.http
			.put<IApiResponse>(`${this.ApiBaseURL}/channels/${id}`, model)
			.ToApiResult();
	}

	delete(id: number): Observable<void> {
		return this.http
			.delete<IApiResponse>(`${this.ApiBaseURL}/channels/${id}`)
			.ToApiResult();
	}

	deleteNote(id: number): Observable<void> {
		return this.http
			.delete<IApiResponse>(`${this.ApiBaseURL}/channels/note/${id}`)
			.ToApiResult();
	}

	read(id: number): Observable<IChannel> {
		return this.http
			.get<IApiResponse>(`${this.ApiBaseURL}/channels/${id}`)
			.ToApiResult();
	}

	readCount(filters: DatatableFilters): Observable<number> {
		let params = this.paginationToHttpParams(filters);
		return this.http
			.get<IApiResponse>(`${this.ApiBaseURL}/channels/count`, {
				params,
			})
			.ToApiResult();
	}

	readAll(filters: DatatableFilters): Observable<IChannel[]> {
		let params = this.paginationToHttpParams(filters);

		return this.http
			.get<IApiResponse>(`${this.ApiBaseURL}/channels`, {
				params,
			})
			.ToApiResult();
	}

	readIds(): Observable<number[]> {
		return this.http
			.get<IApiResponse>(`${this.ApiBaseURL}/channels/readIds`)
			.ToApiResult();
	}

	end(channelId: number) {
		return this.http
			.post<IApiResponse>(
				`${this.ApiBaseURL}/channels/end/${channelId}`,
				{}
			)
			.ToApiResult();
	}

	leftVideoRoom(channelId: number) {
		return this.http
			.post<IApiResponse>(
				`${this.ApiBaseURL}/channels/leftVideoRoom/${channelId}`,
				{}
			)
			.ToApiResult();
	}

	createNote(channelId: number, note: INote): Observable<INote> {
		return this.http
			.post<IApiResponse>(
				`${this.ApiBaseURL}/channels/createNote/${channelId}`,
				note
			)
			.ToApiResult();
	}

	updateNote(noteId: number, note: INote): Observable<INote> {
		return this.http
			.put<IApiResponse>(
				`${this.ApiBaseURL}/channels/updateNote/${noteId}`,
				note
			)
			.ToApiResult();
	}

	readMeetingNotes(filters: DatatableFilters): Observable<INote[]> {
		let params = this.paginationToHttpParams(filters);
		return this.http
			.get<IApiResponse>(`${this.ApiBaseURL}/channels/readMeetingNotes`, {
				params,
			})
			.ToApiResult();
	}

	readNotesFromPatient(patientId): Observable<INote[]> {
		return this.http
			.get<IApiResponse>(
				`${this.ApiBaseURL}/channels/readPatientNotes/${patientId}`
			)
			.ToApiResult();
	}

	readSharedFiles(filters: DatatableFilters): Observable<IDocument[]> {
		let params = this.paginationToHttpParams(filters);
		return this.http
			.get<IApiResponse>(`${this.ApiBaseURL}/channels/readSharedFiles`, {
				params,
			})
			.ToApiResult();
	}

	readConsultationChannelId(doctorId: number): Observable<IChannel> {
		return this.http
			.get<IApiResponse>(`${this.ApiBaseURL}/channels/createchannelifnotexists/${doctorId}`)
			.ToApiResult();
	}

	notifyJoinedVideoRoom(channelId: number): Observable<void> {
		return this.http.post<IApiResponse>(`${this.ApiBaseURL}/channels/leftVideoRoom/${channelId}`, {})
			.ToApiResult();
	}
}

@Injectable({
	providedIn: 'root',
})
export class ChannelUsersService
	extends BaseService
	implements ITableListService {
	ApiBaseURL = environment.ApiBaseURL;

	constructor(private http: HttpClient) {
		super();
	}

	add(channelId: number, userToAddId: number): Observable<IChannel> {
		return this.http
			.put<IApiResponse>(
				`${this.ApiBaseURL}/channels/add/${channelId}/${userToAddId}`,
				{}
			)
			.ToApiResult();
	}
	remove(channelId: number, userToRemoveId: number): Observable<IChannel> {
		return this.http
			.put<IApiResponse>(
				`${this.ApiBaseURL}/channels/remove/${channelId}/${userToRemoveId}`,
				{}
			)
			.ToApiResult();
	}

	readAll(filters: DatatableFilters): Observable<IUser[]> {
		if (filters.readFromWhoId <= 0) {
			return of([]);
		}
		let params = this.paginationToHttpParams(filters);
		return this.http
			.get<IApiResponse>(`${this.ApiBaseURL}/channels/readUsersToAdd`, {
				params,
			})
			.ToApiResult();
	}

	readActiveParticipants(filters: DatatableFilters): Observable<IUser[]> {
		if (filters.readFromWhoId <= 0) {
			return of([]);
		}
		let params = this.paginationToHttpParams(filters);
		return this.http
			.get<IApiResponse>(
				`${this.ApiBaseURL}/channels/readActiveParticipants`,
				{
					params,
				}
			)
			.ToApiResult();
	}

	readCount(filters: DatatableFilters): Observable<number> {
		if (filters.readFromWhoId <= 0) {
			return of(0);
		}
		let params = this.paginationToHttpParams(filters);
		return this.http
			.get<IApiResponse>(
				`${this.ApiBaseURL}/channels/readUsersToAdd/count`,
				{
					params,
				}
			)
			.ToApiResult();
	}

	delete(id: number) {
		throw new Error('Method not implemented.');
	}

	archive(id: number): Observable<null> {
		return this.http
			.put<IApiResponse>(`${this.ApiBaseURL}/channels/archive/${id}`, {})
			.ToApiResult();
	}

	unarchive(id: number): Observable<null> {
		return this.http
			.put<IApiResponse>(
				`${this.ApiBaseURL}/channels/unarchive/${id}`,
				{}
			)
			.ToApiResult();
	}

	read(id: number): Observable<any> {
		throw new Error('Method not implemented.');
	}
}
