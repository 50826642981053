<div class="border-right">
	<div class="pt-2 pb-2 pl-3 pr-3 d-flex align-items-center o-hidden box-shadow-1 chat-topbar">
		<a appSidebarToggler="chat-sidebar" class="link-icon d-md-none">
			<i class="icon-regular ml-0 mr-3 i-Left"></i>
		</a>
		<div *ngIf="archiveMode" class="profile-picture
			avatar-md
			mr-2
			profileImgContainer text-center" style="line-height: 60px;">
			<i class="i-Folder-Zip text-26"></i>
		</div>
		<div *ngIf="archiveMode" class="mr-2">
			{{ 'ArchivedChat' | translate }}
		</div>
		<div *ngIf="!archiveMode" class="profile-picture
		avatar-md
		mr-2
		profileImgContainer" [ngStyle]="{
			'background-image':
				'url(' +
				(currentUser?.photo?.url ??
				'./assets/images/default-user.png') +
				')'
		}"></div>
		<div class="flex-grow-1"></div>
		<ng-container *ngIf="!showVideo">
			<button class="btn btn-icon btn-rounded btn-outline-info" (click)="openCreateChannelModal()"
				*ngIf="!archiveMode && hasPermissionsToCreateAChannel" title="{{ 'CreateChannel' | translate }}">
				<span class="text-20">+</span>
			</button>
			<button class="btn btn-icon btn-rounded btn-light ml-2" data-toggle="tooltip" data-placement="bottom"
				title="{{ 'ArchivedChat' | translate }}" [routerLink]="RouteList.joinRoutes([
			RouteList.consultation,
			RouteList._routeCrudEnum.Archived])" *ngIf="!archiveMode">
				<span><i class="i-Folder-Zip"></i></span>
			</button>
			<button class="btn btn-icon btn-rounded btn-secondary" [routerLink]="RouteList.joinRoutes([
			RouteList.consultation])" *ngIf="archiveMode" data-toggle="tooltip" data-placement="bottom"
				title="{{ 'Back' | translate }}">
				<span><i class="i-Back1"></i></span>
			</button>
		</ng-container>
	</div>
	<div class="p-3 d-flex align-items-center o-hidden">
		<div class="form-group m-0 flex-grow-1">
			<input type="text" class="form-control form-control-rounded" id="search"
				placeholder="{{ 'SearchContacts' | translate }}" [formControl]="searchTerm">
		</div>
	</div>
	<div [perfectScrollbar]="{suppressScrollX: true}" class="contacts-scrollable rtl-ps-none" infinite-scroll
		[infiniteScrollDistance]="distance" [infiniteScrollThrottle]="throttle" [scrollWindow]="false"
		(scrolled)="onScroll()">
		<div *ngFor="let channel of channels" class="border-bottom align-items-start contact o-hidden"
			[ngClass]="{online: channel?.status === 'online'}">
			<!-- , active:channel?.id === activeChannel?.id}"> -->
			<ng-container *ngIf="channel">
				<div class="row p-2 pl-3 mx-0" (click)="selectChannel(channel)">
					<div class="col px-0">
						<div class="profileImgContainer avatar-sm border-status rounded-circle mr-3 d-flex" [ngStyle]="{
							'background-image':
							'url(' +
							(channel.logo?.url ?? './assets/images/default-chat-bubbles-group.png') +
							')'
						}" [ngClass]="{online: channel.status === 'online'}"></div>
						<h6 class="text-primary">{{channel.name | wordExcerpt : 30}}</h6>
						<div class="channel-info text-small">
							<div *ngIf="getIsTyping(channel); else messageP" class="text-success">
								<strong>
									{{getDisplayName(channel)}} {{'IsTyping' | translate}}
								</strong>
							</div>
							<ng-template #messageP>
								<div class="text-muted"> {{ channel.messages[0]?.content  | removeString: '.' | translate | removeHtmlTags}}
								</div>
							</ng-template>
						</div>
					</div>
				</div>
				<!-- <div class="row p-2 pl-3 mx-0 bg-success call-button"
					*ngIf="!archiveMode && activeChannel?.id == channel.id && !showVideo && hasPermissionsToStartVideoCall && !canJoinOnGoingVideoCall && !readOnly"
					(click)="startVideoCall(true)">
					<div class="col px-0">
						<div class="avatar-sm rounded-circle mr-3 d-flex">
							<i class="rounded-circle text-white i-Telephone"></i>
						</div>
						<h6 class="text-white">
							{{ 'Call' | translate }}
						</h6>
					</div>
				</div>
				<div class="row p-2 pl-3 mx-0 bg-secondary call-button"
					*ngIf="activeChannel?.id == channel.id && !showVideo && canJoinOnGoingVideoCall && !readOnly"
					(click)="joinVideoCall(true)">
					<div class="col px-0">
						<div class="avatar-sm rounded-circle mr-3 d-flex">
							<i class="rounded-circle text-white i-Add-User"></i>
						</div>
						<h6 class="text-white">
							{{ 'JoinCall' | translate }}
						</h6>
					</div>
				</div>
				<div class="row p-2 pl-3 mx-0 bg-danger call-button"
					*ngIf="activeChannel?.id == channel.id && showVideo && !readOnly" (click)="startVideoCall(false)">
					<div class="col px-0">
						<div class="avatar-sm rounded-circle mr-3 d-flex">
							<i class="rounded-circle text-white i-Telephone" style="transform: rotate(90deg);"></i>
						</div>
						<h6 class="text-white">
							{{ 'EndCall' | translate }}
						</h6>
					</div>
				</div> -->
			</ng-container>
		</div>
	</div>
</div>

<ng-template #launcherModal let-modal>
	<app-modal (onClose)="modal.dismiss($event)" (onConfirmClose)="modal.close($event)" [showDefaultFooter]="false">
		<div modal-header>{{ 'LaunchCall' | translate }}</div>
		<div modal-body>
			<app-call-launcher (onTypeChosen)="onTypeChosen($event)"></app-call-launcher>
		</div>
	</app-modal>
</ng-template>

<ng-template #createChannelModal let-modal>
	<app-modal (onClose)="modal.dismiss($event)" (onConfirmClose)="modal.close($event)" [showDefaultFooter]="true"
		[disableConfirmButton]="form.invalid">
		<div modal-header>{{ 'CreateChannel' | translate }}</div>
		<div modal-body>
			<!-- logo -->
			<div class="row">
				<div class="col">
					<app-form-control [submitted]="submitted" [displayMode]="'horizontal'" [type]="ControlTypeEnum.Text"
						name="name" [control]="form.get('name')">
					</app-form-control>
				</div>
			</div>
			<!-- logo -->
			<div class="row">
				<div class="col">
					<app-form-control [submitted]="submitted" [displayMode]="'horizontal'"
						[type]="ControlTypeEnum.Document" [documentType]="DocTypesEnum.ChannelLogo" name="logo"
						[control]="form.get('logo')"></app-form-control>
				</div>
			</div>
		</div>
	</app-modal>
</ng-template>