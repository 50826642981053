import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

@Component({
	selector: 'app-modal',
	templateUrl: './modal.component.html',
	styleUrls: ['./modal.component.scss'],
})
export class ModalComponent implements OnInit {
	@Input() showDefaultFooter: boolean = true;
	@Input() confirmButtonText: string = 'Confirm';
	@Input() disableConfirmButton: boolean = false;
	@Input() confirmButtonClass:
		| 'primary'
		| 'secondary'
		| 'info'
		| 'warning'
		| 'success'
		| 'danger'
		| 'dark'
		| 'light'
		| 'outline-primary'
		| 'outline-secondary'
		| 'outline-info'
		| 'outline-warning'
		| 'outline-success'
		| 'outline-danger'
		| 'outline-dark'
		| 'outline-light' = 'primary';
	@Output() onClose: EventEmitter<void> = new EventEmitter();
	@Output() onConfirmClose: EventEmitter<void> = new EventEmitter();
	constructor() { }

	ngOnInit(): void { }

	dismiss(event) {
		this.onClose.emit(event);
	}

	confirm(event) {
		this.onConfirmClose.emit(event);
	}
}
