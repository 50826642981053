import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { RouteList } from '@app/01.global/helpers/route-enum';
import { IAppointment } from '@app/01.global/interfaces/IAppointment';
import { AttendanceStatusService } from '@app/01.global/status-services/attendance-status.service';
import { TranslateService } from '@ngx-translate/core';
import { Observable } from 'rxjs';

@Component({
	selector: 'app-patient-queue-main-sidebar',
	templateUrl: './patient-queue-main-sidebar.component.html',
	styleUrls: ['./patient-queue-main-sidebar.component.scss'],
})
export class PatientQueueMainSidebarComponent implements OnInit {
	appointments$: Observable<IAppointment[]>;
	constructor(
		private attendanceStatusService: AttendanceStatusService,
		private router: Router,
		private route: ActivatedRoute,
		private translateService: TranslateService
	) {}

	ngOnInit(): void {
		this.appointments$ = this.attendanceStatusService.patients$;
	}

	onWaitingRoomClick() {
		this.router.navigate([RouteList.patientQueue], {
			relativeTo: this.route,
		});
	}
	getTranslatableTitle(title: string) {
		var splitter = title.split(' - ');
		return (
			this.translateService.instant(splitter[0].trim()) +
			' - ' +
			splitter[1]
		);
	}
}
