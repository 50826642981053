<h5 class="my-3 mx-2 text-15" placement="top" ngbTooltip="{{ 'InfoPacientAttendances' | translate }}">
	{{'PatientAttendances' | translate}}
	<i class="i-Information"></i>
</h5>

<div *ngFor="let item of appointments$ | async" class="d-flex flex-row p-0 shadow sidebar-row mb-3 cursor-pointer"
	(click)="goToWaitingRoom(item)">
	<div class="bg-transparent text-center align-items-stretch" style="width: 3.5rem;">
		<div class="sidebar-thumbnail" [ngStyle]="{
			'background-image':
			'url(' +
			(item.appointmentLogo?.url) +
			')'
		}"></div>
	</div>
	<div class="py-1 px-2 text-start align-items-stretch o-hidden" placement="top"
		ngbTooltip="{{getTranslatableTitle(item.title)}}">
		{{getTranslatableTitle(item.title)}}
	</div>
	<div class="bg-transparent text-center align-items-stretch p-0"
		style="border: 2px solid #25acfd; line-height: 1.25;">
		<span class="bg-info text-white d-block px-2 w-100 py-1">{{item.startDate | localizedDate: 'EEEE'}}</span>
		<span class="text-info d-block">{{item.startDate | localizedDate: 'MMM'}}</span>
		<span class="text-info d-block" style="font-size: 1.3rem;">{{item.startDate | localizedDate: 'dd'}}</span>
		<span class="text-info d-block">{{item.startDate | localizedDate: 'yyyy'}}</span>
		<span class="bg-info d-block text-white text-center font-weight-bold px-2 w-100 py-1"
			style="font-size: 1.1rem;">{{item.startDate | localizedDate:
			'HH:mm'}}</span>
	</div>
</div>

<ng-template #nearAppointmentModal let-modal>
	<app-modal (onClose)="modal.dismiss($event)" (onConfirmClose)="modal.close($event)" [showDefaultFooter]="false">
		<div modal-header>
			{{ (patientIsDelayed ? 'YouAreRetardedForYourConsultation' : 'AppointmentStartingSoon') |
			translate }}
		</div>
		<div modal-body>
			<div class="row">
				<div class="col">
					<ng-container *ngIf="patientIsDelayed">
						<div class="alert-card mb-4 alert alert-danger text-16 text-center">
							<h3 class="text-bold">{{
								'YouAreRetardedForYourConsultation' | translate }}</h3>
							<cd-timer class="text-24" [startTime]="secondsUntilStart" format="hms">
							</cd-timer>
						</div>
					</ng-container>
					<ng-container *ngIf="!patientIsDelayed">
						<div class="alert-card mb-4 alert alert-success text-16 text-center">
							<h3>{{ 'AppointmentStartingSoon' |
								translate }}
							</h3>
							<cd-timer class="text-24" [startTime]="secondsUntilStart" format="hms" countdown="true"
								(onComplete)="setPatientAsRetarded()">
							</cd-timer>
						</div>
					</ng-container>
				</div>
			</div>
			<div class="row">
				<div class="col text-center">
					<button class="btn btn-lg btn-info"
						(click)="goToWaitingRoom();modal.close($event);">{{'GoToWaitingRoom' |
						translate }}</button>
				</div>
			</div>
			<div class="row">
				<div class="col text-center">
					<!-- <div class="wrapper">
						<div class="ring">
							<div class="coccoc-alo-phone coccoc-alo-green coccoc-alo-show mx-auto"
								(click)="modal.close($event)">
								<div class="coccoc-alo-ph-circle"></div>
								<div class="coccoc-alo-ph-circle-fill"></div>
								<div class="coccoc-alo-ph-img-circle"></div>
							</div>
						</div>
					</div> -->
				</div>
			</div>
		</div>
	</app-modal>
</ng-template>