<div class="auth-layout-wrap" style="background-image: url(./assets/images/hero-bg.jpg)">
	<div class="auth-content">
		<router-outlet></router-outlet>
	</div>
</div>
<ng-template #ConfirmUpdateModal let-modal>
	<div class="modal-header">
		<h4 class="modal-title" id="modal-title">
			{{ 'ApplicationUpdateAvailable' | translate }}
		</h4>
		<button type="button" class="close" aria-label="Close button" aria-describedby="modal-title"
			(click)="modal.dismiss($event)">
			<span aria-hidden="true">&times;</span>
		</button>
	</div>
	<div class="modal-body m-3">
		<span class="text-warning">{{
			'ApplicationUpdateAvailable' | translate
			}}</span>
	</div>
	<div class="modal-footer" style="padding: 1rem; border-top: 1px solid #eee;">
		<button type="button" class="btn btn-outline-secondary" (click)="modal.dismiss($event)">
			{{ 'Cancel' | translate }}
		</button>
		<button type="button" class="'btn btn-primary" (click)="modal.close($event)">
			{{ 'Update' | translate }}
		</button>
	</div>
</ng-template>