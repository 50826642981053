import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { TableListSettings } from '@app/table-list/models/table-list';
import { TableListColumn, TableListColumnMode } from '@app/table-list/models/table-list-column';
import { TableListRow } from '@app/table-list/models/table-list-row';
import { TableListStatusService } from '@app/table-list/services/table-list-status.service';
import { Subscription } from 'rxjs';

@Component({
	selector: 'table-list-card-profile-image',
	templateUrl: './table-list-card-profile-image.component.html',
	styleUrls: ['./table-list-card-profile-image.component.scss']
})
export class TableListCardProfileImageComponent implements OnInit, OnDestroy {
	constructor(private statusService: TableListStatusService) { }
	@Input('row') row: TableListRow;
	TableListColumnMode = TableListColumnMode;
	public get thumb(): TableListColumn {
		return this.row.columns.find(c => c.displayMode == TableListColumnMode.thumbnail);
	}
	public get title(): TableListColumn {
		return this.row.columns.find(c => c.displayMode == TableListColumnMode.title);
	}
	public get firstTabInfoColumns(): TableListColumn[] {
		return this.row.columns.filter(c => c.displayMode != TableListColumnMode.thumbnail
			&& c.displayMode != TableListColumnMode.title
			&& c.displayMode != TableListColumnMode.html);
	}
	public get htmlColumns(): TableListColumn[] {
		return this.row.columns.filter(c => c.displayMode == TableListColumnMode.html);
	}
	@Input('tableIdentifier') tableIdentifier: string;
	tableSettings: TableListSettings;

	subs: Subscription[] = [];

	ngOnInit(): void {
		this.subs.push(this.statusService.getSettingsObservable(this.tableIdentifier)
			.subscribe(settings => this.tableSettings = settings))
	}

	ngOnDestroy(): void {
		this.subs.forEach(s => s?.unsubscribe());
	}

}
