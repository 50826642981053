<div class="notes-container h-100 o-hidden" [perfectScrollbar]="{ suppressScrollX: true }" ngxInfiniteScroller
	strategy="scrollingToTop" (onScrollUp)="onScroll()">
	<div class="timeline-wrapper">
		<!-- <span *ngIf="loadingUp" class="loader-bubble loader-bubble-info ml-4 mt-n2"></span> -->
		<ul class="timeline clearfix">
			<li class="timeline-line"></li>
			<li class="timeline-item" *ngFor="let item of notes$ | async" style="margin-top: -5rem; width: 51%">
				<div class="timeline-badge">
					<i class="badge-icon i-Pen-5 bg-warning"></i>
				</div>
				<div class="timeline-card card mx-lg-5 mx-md-0">
					<div class="card-body">
						<div class="card-body text-center">
							<h5 class="mb-1">
								{{ item.title }}
							</h5>
							<small>{{ item.createdDate | localizedDate: 'full' }}</small>
							<p class="mb-1">{{ item.description }}</p>
						</div>
					</div>
				</div>
			</li>
		</ul>
		<ul class="timeline clearfix">
			<li class="timeline-line"></li>
			<li class="timeline-group text-center">
				<button class="btn btn-icon-text btn-warning">
					<i class="i-Calendar-4"></i> {{ userJoinDate | localizedDate: 'yyyy' }}
				</button>
			</li>
		</ul>
	</div>
</div>
<div class="p-3 box-shadow-1 notes-input-area bg-white">
	<form [formGroup]="form" class="inputForm" (ngSubmit)="createNote()">
		<div class="row">
			<div class="col-12 col-lg-6">
				<app-form-control [type]="ControlTypeEnum.Textarea" [rows]="3" name="title"
					[control]="form.get('title')" displayMode="horizontal" [showErrorMessages]="false"
					[showLabel]="false" placeholder="{{ 'Title' |translate}}">
				</app-form-control>
			</div>
			<div class="col-12 col-lg-6">
				<app-form-control [type]="ControlTypeEnum.Textarea" [rows]="3" name="description"
					[showErrorMessages]="false" [showLabel]="false" displayMode="horizontal"
					[control]="form.get('description')" displayMode="horizontal"
					placeholder="{{ 'Description' |translate}}">
				</app-form-control>
			</div>
		</div>
		<div class="row">
			<div class="col text-right">
				<button type="button" class="btn btn-icon btn-rounded btn-outline-danger mr-2" (click)="form.reset()">
					<span><i class="i-Repeat-2"></i></span>
				</button>
				<btn-loading btnClass="btn btn-icon btn-rounded btn-info" [loading]="saving">
					<span>
						<span><i class="i-Disk"></i></span>
					</span>
				</btn-loading>
			</div>
		</div>
	</form>
</div>