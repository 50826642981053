import { Injectable } from '@angular/core';
import { BehaviorSubject, forkJoin, map, Observable, of } from 'rxjs';
import { BookingStep } from '../helpers/booking-step';
import { RoleEnum } from '../helpers/role-enum';
import { Utils } from '../helpers/utils';
import { IAgreementCard } from '../interfaces/iAgreementCard';
import { IAppointment } from '../interfaces/IAppointment';
import { IBookingRequest } from '../interfaces/iBookingRequest';
import { IChannel } from '../interfaces/iChannel';
import { IConsultationType } from '../interfaces/iConsultation-type';
import { IUser } from '../interfaces/IUser';
import { BookingRequestService } from '../services/booking-request.service';
import { AuthenticationService } from '../services/authentication.service';
import { BookingService } from '../services/booking.service';
import { ConsultationTypeService } from '../services/consultation-type.service';
import { UsersService } from '../services/users.service';
import { BookingFlowFactory } from '../factories/booking-flow-factory';
import { BookingFlows } from '../helpers/booking-flows.enum';
import { AgreementCardsService } from '../services/agreement-cards.service';

@Injectable({
	providedIn: 'root',
})
export class BookingStatusService {
	private finishedAppointment: IAppointment;

	private patientSubject = new BehaviorSubject<IUser>(null);
	patientObs = this.patientSubject.asObservable();

	navigationSubject = new BehaviorSubject<number>(null);
	navigationObs = this.navigationSubject.asObservable();

	private agreementSubject = new BehaviorSubject<IAgreementCard>(null);
	agreementObs = this.agreementSubject.asObservable();

	private consultationSettingsSubject = new BehaviorSubject<{
		provider?: IUser;
		consultationType?: IConsultationType;
		employee?: IUser;
	}>({});

	consultationSettingsObs = this.consultationSettingsSubject.asObservable();

	private scheduleSubject = new BehaviorSubject<IAppointment>(null);

	scheduleObs = this.scheduleSubject.asObservable();

	private requestSubject = new BehaviorSubject<IBookingRequest>(null);

	requestObs = this.requestSubject.asObservable();

	private progressSubject = new BehaviorSubject<number>(0);

	progressObs = this.progressSubject.asObservable();

	private showPreviousSubject = new BehaviorSubject<boolean>(false);

	showPreviousObs = this.showPreviousSubject.asObservable();

	private showNextSubject = new BehaviorSubject<boolean>(false);

	showNextObs = this.showNextSubject.asObservable();

	private steps: BookingStep[] = [];

	private currentFlow: BookingFlows;

	public windowId: string;

	constructor(
		private ctService: ConsultationTypeService,
		private userService: UsersService,
		private bookingService: BookingService,
		private appointmentRequest: BookingRequestService,
		private authService: AuthenticationService,
		private bookingFlowFactory: BookingFlowFactory,
		private agreementCardsService: AgreementCardsService
	) {
		if (
			!!localStorage.getItem('booking-steps') &&
			localStorage.getItem('booking-steps') != 'null'
		) {
			if (JSON.parse(localStorage.getItem('booking-steps')).length > 0) {
				this.setSteps(
					null,
					JSON.parse(localStorage.getItem('booking-steps'))
				);
			}
		}
		if (
			!!localStorage.getItem('booking-finished') &&
			localStorage.getItem('booking-finished') != 'null'
		) {
			this.setFinished(
				JSON.parse(localStorage.getItem('booking-finished'))
			);
		}
		if (
			!!localStorage.getItem('booking-agreement') &&
			localStorage.getItem('booking-agreement') != 'null'
		) {
			this.setAgreement(
				JSON.parse(localStorage.getItem('booking-agreement'))
			);
		}
		if (
			!!localStorage.getItem('booking-consultation-settings') &&
			localStorage.getItem('booking-consultation-settings') != 'null' &&
			localStorage.getItem('booking-consultation-settings') != '{}'
		) {
			this.setConsultationSettings(
				JSON.parse(
					localStorage.getItem('booking-consultation-settings')
				)
			);
		}
		if (
			!!localStorage.getItem('booking-request') &&
			localStorage.getItem('booking-request') != 'null'
		) {
			this.setRequest(
				JSON.parse(localStorage.getItem('booking-request'))
			);
		}
		if (
			!!localStorage.getItem('booking-schedule') &&
			localStorage.getItem('booking-schedule') != 'null'
		) {
			this.setSchedule(
				JSON.parse(localStorage.getItem('booking-schedule'))
			);
		}
		if (
			!!localStorage.getItem('appointment-rebook') &&
			localStorage.getItem('appointment-rebook') != 'null'
		) {
			this.setRebook(
				JSON.parse(localStorage.getItem('appointment-rebook'))
			);
		}
		if (
			!!localStorage.getItem('booking-patient') &&
			localStorage.getItem('booking-patient') != 'null'
		) {
			this.setPatient(
				JSON.parse(localStorage.getItem('booking-patient'))
			);
		}
		if (
			!!localStorage.getItem('booking-navigation') &&
			localStorage.getItem('booking-navigation') != 'null'
		) {
			this.setNavigation(
				+JSON.parse(localStorage.getItem('booking-navigation'))
			);
		} else {
			if (this.authService.isGuest) {
				this.setNavigation(0);
			} else {
				this.setNavigation(5);
			}
		}
	}

	setAppointmentType(type: 'new' | 'rebook') {
		if (this.authService.isGuest) {
			this.setConsultationSettings(null);
			this.setAgreement(null);
			this.setSchedule(null);
		}
		this.setSteps(type == 'new' ? BookingFlows.new : BookingFlows.rebook);
		var step = this.steps.find((s) => s.friendlyName == 'appointment-type');
		this.goToStep(
			this.steps.findIndex((s) => s.friendlyName == 'appointment-type')
		);
		if (step != null) {
			step.isValid = type != null;
			step.isActive = true;
		}
		this.handleProgress();
	}

	setAgreement(agreement: IAgreementCard) {
		var step = this.steps.find((s) => s.friendlyName == 'agreement');
		if (step != null) {
			step.isValid = agreement != null;
		}
		if (agreement) {
			localStorage.setItem(
				'booking-agreement',
				JSON.stringify(agreement)
			);
		} else {
			localStorage.removeItem('booking-agreement');
		}
		this.agreementSubject.next(agreement);
		this.handleProgress();
	}

	setConsultationSettings(settings: {
		provider?: IUser;
		consultationType?: IConsultationType;
		employee?: IUser;
	}) {
		var step = this.steps.find(
			(s) => s.friendlyName == 'consultation-settings'
		);
		if (step != null) {
			step.isValid = settings?.consultationType != null;
		}

		if (
			settings?.consultationType != null ||
			settings?.provider != null ||
			settings?.employee != null
		) {
			localStorage.setItem(
				'booking-consultation-settings',
				JSON.stringify(settings)
			);
		} else {
			localStorage.removeItem('booking-consultation-settings');
		}
		this.consultationSettingsSubject.next(settings);
		this.handleProgress();
	}

	selectSchedule(schedule: IAppointment) {
		this.setSchedule(schedule);
		var settings = this.consultationSettingsSubject.getValue();
		if (settings.employee == null) {
			this.userService
				.read(schedule.medicalOffice.doctorId)
				.subscribe((u) => this.setEmployee(u));
		}
		if (settings.provider == null) {
			this.setProvider(schedule.medicalOffice?.branch?.account);
		}
	}

	setRequest(request: IBookingRequest) {
		var step = this.steps.find((s) => s.friendlyName == 'schedule');
		if (step != null) {
			step.isValid = request != null;
		}
		if (request) {
			this.setNavigation(2);
			localStorage.setItem('booking-request', JSON.stringify(request));
		} else {
			localStorage.removeItem('booking-request');
		}
		this.requestSubject.next(request);
		this.handleProgress();
	}

	selectRebook(previous: IAppointment) {
		this.setRebook(previous);

		forkJoin({
			consultationType: this.ctService.read(
				+previous.consultationType.id
			),
			employee: this.userService.read(previous.medicalOffice.doctorId),
			provider: this.userService.read(
				previous.medicalOffice.branch?.account.id
			),
		}).subscribe((settings) => this.setConsultationSettings(settings));

		// setagreement
	}

	selectUrgency(schedule: IAppointment) {
		this.reset();
		this.setSteps(BookingFlows.urgency);
		this.steps.find((s) => s.friendlyName == 'urgency').isActive = true;
		this.goToStep(
			this.steps.findIndex((s) => s.friendlyName == 'agreement')
		);
		this.setUrgency(schedule);
		this.userService
			.read(schedule.medicalOffice?.doctorId)
			.subscribe((u) => this.setEmployee(u));
	}

	bookFromMedicalOfficeEmptySlot(schedule: IAppointment) {
		this.reset();
		this.setNavigation(2);
		this.setSteps(BookingFlows.new);
		this.steps.find((s) => s.friendlyName == 'agreement').isActive = true;
		this.setAppointmentType('new');
		this.userService
			.read(schedule.medicalOffice.doctorId)
			.subscribe((u) => this.setEmployee(u));
		this.userService
			.read(schedule.medicalOffice.branchAccountId)
			.subscribe((p) => this.setProvider(p));
		this.setSchedule(schedule);
	}

	selectPatient(patient: IUser) {
		this.reset();
		this.setSteps(BookingFlows.select);
		this.setPatient(patient);
		var step = this.steps.find((s) => s.friendlyName == 'select-patient');
		step.isValid = true;
		step.isActive = true;

		if (this.authService.hasSomeRole(RoleEnum.Doctor, RoleEnum.Therapist)) {
			this.setEmployee(this.authService.userValue);
		}
		this.handleProgress();
	}

	selectRequest(request: IBookingRequest) {
		this.reset();
		this.setSteps(BookingFlows.request);
		this.steps.find((s) => s.friendlyName == 'select-request').isActive =
			true;
		this.userService
			.read(request.patientId)
			.subscribe((u) => this.setPatient(u));

		var cu = this.authService.userValue;

		if (this.authService.hasSomeRole(RoleEnum.Doctor, RoleEnum.Therapist)) {
			this.setEmployee(cu);
			if (request.providerId > 0) {
				this.userService
					.read(request.providerId)
					.subscribe((u) => this.setProvider(u));
			}
		} else {
			if (request.employeeId > 0) {
				this.userService
					.read(request.employeeId)
					.subscribe((u) => this.setEmployee(u));
			}
			this.userService
				.read(cu.branchAccountId)
				.subscribe((u) => this.setProvider(u));
		}

		this.steps.find((s) => s.friendlyName == 'select-patient').isValid =
			true;
		this.handleProgress();
	}

	bookFromDoctorDetails(doctor: IUser) {
		this.reset();
		this.setNavigation(2);
		this.setSteps(BookingFlows.new);
		this.steps.find((s) => s.friendlyName == 'agreement').isActive = true;
		this.setAppointmentType('new');
		this.setEmployee(doctor);
	}

	createRequestFromDoctorDetails(doctor: IUser, provider?: IUser) {
		this.reset();
		this.setNavigation(2);
		this.setSteps(BookingFlows.new);
		this.steps.find((s) => s.friendlyName == 'agreement').isActive = true;
		this.setAppointmentType('new');
		this.setEmployee(doctor);
		if (provider) {
			this.setProvider(provider);
		}
	}

	bookFromDoctorAttendance(doctor: IUser, windowId: string) {
		this.reset();
		this.setNavigation(2);
		this.setSteps(BookingFlows.new);
		this.steps.find((s) => s.friendlyName == 'agreement').isActive = true;
		this.setAppointmentType('new');
		this.setEmployee(doctor);
		this.windowId = windowId;
	}

	bookFromChannel(channel: IChannel) {
		this.reset();
		this.setPatient(channel.users[0]);
		this.setNavigation(2);
		this.setAppointmentType('new');
		this.steps.find((s) => s.friendlyName == 'agreement').isActive = true;
		this.goToStep(
			this.steps.findIndex((s) => s.friendlyName == 'agreement')
		);

		var patientAgreementCards: IAgreementCard[] = [];

		this.agreementCardsService
			.readUserCards(channel.users[0]?.id)
			.subscribe((agreementCards: IAgreementCard[]) => {
				patientAgreementCards = agreementCards;
				if (
					patientAgreementCards &&
					patientAgreementCards.some(
						(ac) => ac.id == channel.users[0].defaultCardId
					)
				) {
					this.setAgreement(
						patientAgreementCards.find(
							(ac) => ac.id == channel.users[0].defaultCardId
						)
					);
				} else if (
					patientAgreementCards &&
					patientAgreementCards.length > 0
				) {
					this.setAgreement(patientAgreementCards[0]);
				}
			});

		this.setEmployee(this.authService.userValue);
	}

	bookFromLastAppointmentDashboard(appointment: IAppointment) {
		this.reset();
		this.setAppointmentType('rebook');
		this.selectRebook(appointment);
	}

	setNavigation(index: number) {
		if (index) {
			localStorage.setItem('booking-navigation', index.toString());
		} else {
			localStorage.removeItem('booking-navigation');
		}

		this.navigationSubject.next(index);
	}

	setFinished(app: IAppointment) {
		this.finishedAppointment = app;
		if (app) {
			localStorage.setItem('booking-finished', JSON.stringify(app));
		} else {
			localStorage.removeItem('booking-finished');
		}
	}

	setCanceled() {
		localStorage.removeItem('booking-finished');
	}

	goToPreviousStep() {
		const stepIndex = this.steps.findIndex((s) => s.isActive);
		if (stepIndex > 0) {
			this.steps[stepIndex].isActive = false;
			var step = this.steps[stepIndex - 1];
			step.isActive = true;
			this.showNextSubject.next(true);
			if (stepIndex > 1) {
				this.showPreviousSubject.next(true);
			} else {
				this.showPreviousSubject.next(false);
			}
			return step.route;
		}
	}

	goToNextStep() {
		const stepIndex = this.steps.findIndex((s) => s.isActive);
		if (stepIndex > -1 && stepIndex < this.steps.length - 1) {
			var currentStep = this.steps[stepIndex];
			if (!currentStep.isActive) {
				throw new Error('UnhandledError');
			}
			currentStep.isActive = false;
			var step = this.steps[stepIndex + 1];
			step.isActive = true;
			this.showPreviousSubject.next(true);
			if (step.isValid && stepIndex < this.steps.length - 2) {
				this.showNextSubject.next(true);
			} else {
				this.showNextSubject.next(false);
			}
			return step.route;
		}
	}

	reset() {
		this.setPatient(null);
		this.setAgreement(null);
		this.setRebook(null);
		this.setConsultationSettings(null);
		this.setRequest(null);
		this.setSchedule(null);
		this.setFinished(null);
		this.setNavigation(null);
		this.windowId = null;
	}

	finish(): Observable<IAppointment> {
		if (this.finishedAppointment) {
			return of(this.finishedAppointment);
		}

		var schedule = this.scheduleSubject.getValue();
		var ctValue =
			this.consultationSettingsSubject.getValue().consultationType;
		schedule.consultationTypeId = ctValue?.id;

		var patientValue = this.patientSubject.getValue();
		schedule.patientId = patientValue?.id ?? 0;

		schedule.isUrgency = this.currentFlow == BookingFlows.urgency;

		return this.bookingService.finish(schedule).pipe(
			map((a) => {
				this.setFinished(a);
				return a;
			})
		);
	}

	cancel(): Observable<IAppointment> {
		if (this.finishedAppointment) {
			return of(this.finishedAppointment);
		}

		var schedule = this.scheduleSubject.getValue();
		var ctValue =
			this.consultationSettingsSubject.getValue().consultationType;
		schedule.consultationTypeId = ctValue?.id;

		var patientValue = this.patientSubject.getValue();
		schedule.patientId = patientValue?.id ?? 0;

		schedule.isUrgency = this.currentFlow == BookingFlows.urgency;

		return this.bookingService.cancel(schedule).pipe(
			map((a) => {
				this.setCanceled();
				return a;
			})
		);
	}

	finishRequest(): Observable<IBookingRequest> {
		var request = this.requestSubject.getValue();
		if (request) {
			if (!request.requestFromDateIgnoringTimeZone) {
				delete request.requestFromDateIgnoringTimeZone;
			}
			var ctValue =
				this.consultationSettingsSubject.getValue().consultationType;
			request.consultationTypeId = ctValue?.id;

			return this.appointmentRequest.create(request).pipe(
				map((a) => {
					return a;
				})
			);
		}

		return of(null);
	}

	private goToStep(index: number = 0) {
		var step = this.steps[index];
		step.isActive = true;

		this.steps.map((s) => {
			if (s.friendlyName != step.friendlyName) {
				s.isActive = false;
			}
		});

		this.showPreviousSubject.next(index > 0);
		this.showNextSubject.next(step.isValid);
	}

	private handleProgress() {
		localStorage.setItem('booking-steps', JSON.stringify(this.steps));
		this.progressSubject.next(
			((1 +
				Utils.findLastIndex(this.steps, (s) => {
					return s.isValid;
				})) /
				this.steps.length) *
			100
		);
	}

	private setSteps(flow: BookingFlows = null, steps: BookingStep[] = null) {
		this.currentFlow = flow;
		if (steps) {
			this.steps = steps;
		} else if (flow) {
			this.steps = this.bookingFlowFactory.build(flow);
		} else {
			this.steps = [];
		}
		this.handleProgress();
	}

	private setProvider(provider: IUser) {
		var settings = this.consultationSettingsSubject.getValue();
		if (settings == null) {
			settings = {};
		}
		settings.provider = provider;
		this.setConsultationSettings(settings);
	}

	private setEmployee(employee: IUser) {
		var settings = this.consultationSettingsSubject.getValue();
		if (settings == null) {
			settings = {};
		}
		settings.employee = employee;
		this.setConsultationSettings(settings);
	}

	private setConsultationType(ct: IConsultationType) {
		var settings = this.consultationSettingsSubject.getValue();
		if (settings == null) {
			settings = {};
		}
		settings.consultationType = ct;
		this.setConsultationSettings(settings);
	}

	private setSchedule(schedule: IAppointment) {
		var step = this.steps.find((s) => s.friendlyName == 'schedule');
		if (schedule) {
			if (step != null) {
				step.isValid = true;
			}
			localStorage.setItem('booking-schedule', JSON.stringify(schedule));
		} else {
			if (step != null) {
				step.isValid = false;
			}
			localStorage.removeItem('booking-schedule');
		}
		this.scheduleSubject.next(schedule);
		this.handleProgress();
	}

	private setRebook(schedule: IAppointment) {
		var step = this.steps.find(
			(s) => s.friendlyName == 'appointment-rebook'
		);
		if (step != null) {
			step.isValid = schedule != null;
		}
		this.handleProgress();
	}

	private setUrgency(schedule: IAppointment) {
		this.steps.find((s) => s.friendlyName == 'urgency').isValid =
			schedule != null;
		this.handleProgress();
		this.setSchedule(schedule);
	}

	private setPatient(patient) {
		if (patient) {
			localStorage.setItem('booking-patient', JSON.stringify(patient));
		} else {
			localStorage.removeItem('booking-patient');
		}
		this.patientSubject.next(patient);
	}
}
