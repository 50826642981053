import { Injectable } from '@angular/core';
import { TableListActionButton } from '@app/table-list/models/table-list-action-button';
import { TableListMapperService } from '@app/table-list/services/mapper.service';
import { TranslateService } from '@ngx-translate/core';
import { RouteList } from '../helpers/route-enum';

@Injectable({
	providedIn: 'root',
})
export abstract class BaseMapperService extends TableListMapperService {
	constructor(protected translateService: TranslateService) {
		super(translateService);
	}

	createRescheduleButton(): TableListActionButton {
		return {
			order: 11,
			loading: false,
			icon: 'i-Reload',
			text: this.translateService.instant('Reschedule'),
			showText: false,
			route: RouteList._routeCrudEnum.ReSchedule,
			btnClass: 'btn btn-rounded btn-icon btn-warning',
		};
	}

	createSelectButton(): TableListActionButton {
		return {
			order: 20,
			loading: false,
			text: this.translateService.instant('Select'),
			showText: true,
			route: RouteList._routeCrudEnum.Select,
			btnClass: 'btn btn-rounded btn-info',
		};
	}

	createUrgencyButton(): TableListActionButton {
		return {
			order: 0,
			loading: false,
			icon: 'i-Consulting',
			text: this.translateService.instant('SelectDoctor'),
			showText: false,
			route: RouteList._routeCrudEnum.Urgency,
			btnClass: 'btn btn-rounded btn-icon btn-danger',
		};
	}

	createOneClickDoctorButton(): TableListActionButton {
		return {
			order: 0,
			loading: false,
			icon: 'i-Split-Horizontal-2-Window',
			text: this.translateService.instant('OneClickDoctor'),
			showText: false,
			route: RouteList.attendance,
			btnClass: 'btn btn-rounded btn-icon btn-outline-primary',
		};
	}

	createMapButton(outline = true): TableListActionButton {
		return {
			order: 0,
			loading: true,
			icon: 'i-Map2',
			text: this.translateService.instant('ViewOnMap'),
			showText: false,
			route: RouteList._routeCrudEnum.Map,
			loadingText: this.translateService.instant('LoadingMap'),
			btnClass: outline
				? 'btn btn-rounded btn-icon btn-outline-danger'
				: 'btn btn-rounded btn-icon btn-danger',
		};
	}
}
