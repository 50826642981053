import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { DatatableFilters } from '@app/01.global/models/DatatableFilters';
import { IApiResponse } from '../interfaces/helpers/iResponseWrapper';
import { environment } from 'src/environments/environment';
import '@app/01.global/helpers/observable-extensions';
import { ITableListService } from '../../table-list/services/iservice';
import { Observable } from 'rxjs';
import { IConsultationType } from '../interfaces/iConsultation-type';
import { ISpecialty } from '../interfaces/ISpecialty';
import { BaseService } from './base.service';

@Injectable({
	providedIn: 'root',
})
export class ConsultationTypeService
	extends BaseService
	implements ITableListService
{
	apiBaseUrl = environment.ApiBaseURL;

	constructor(private http: HttpClient) {
		super();
	}

	create(model: IConsultationType) {
		return this.http
			.post<IApiResponse>(`${this.apiBaseUrl}/ConsultationTypes`, model)
			.ToApiResult();
	}

	readCount(page: DatatableFilters): Observable<number> {
		let params = this.paginationToHttpParams(page);
		return this.http
			.get<IApiResponse>(`${this.apiBaseUrl}/ConsultationTypes/count`, {
				params,
			})
			.ToApiResult();
	}

	delete(id: number) {
		return this.http
			.delete<IApiResponse>(`${this.apiBaseUrl}/ConsultationTypes/${id}`)
			.ToApiResult();
	}

	readAll(page: DatatableFilters): Observable<IConsultationType[]> {
		let params = this.paginationToHttpParams(page);
		return this.http
			.get<IApiResponse>(`${this.apiBaseUrl}/ConsultationTypes`, {
				params,
			})
			.ToApiResult();
	}

	read(id: number): Observable<IConsultationType> {
		return this.http
			.get<IApiResponse>(`${this.apiBaseUrl}/ConsultationTypes/${id}`)
			.ToApiResult();
	}

	readUserConsultationTypes(
		userId: number = 0
	): Observable<IConsultationType[]> {
		return this.http
			.get<IApiResponse>(
				`${this.apiBaseUrl}/ConsultationTypes/user/${userId}`
			)
			.ToApiResult();
	}

	update(id: number, model: IConsultationType) {
		return this.http
			.put<IApiResponse>(
				`${this.apiBaseUrl}/ConsultationTypes/${id}`,
				model
			)
			.ToApiResult();
	}

	UpdateUserConsultationTypes(model: ISpecialty) {
		return this.http
			.post<IApiResponse>(
				`${this.apiBaseUrl}/ConsultationTypes/user`,
				model
			)
			.ToApiResult();
	}
}

@Injectable({
	providedIn: 'root',
})
export class ConsultationTypeStandardService
	extends BaseService
	implements ITableListService
{
	apiBaseUrl = environment.ApiBaseURL;

	constructor(private http: HttpClient) {
		super();
	}

	create(model: IConsultationType) {
		return this.http
			.post<IApiResponse>(
				`${this.apiBaseUrl}/ConsultationTypesStandard`,
				model
			)
			.ToApiResult();
	}

	readCount(page: DatatableFilters): Observable<number> {
		let params = this.paginationToHttpParams(page);
		return this.http
			.get<IApiResponse>(
				`${this.apiBaseUrl}/ConsultationTypesStandard/count`,
				{
					params,
				}
			)
			.ToApiResult();
	}

	delete(id: number) {
		return this.http.delete<IApiResponse>(
			`${this.apiBaseUrl}/ConsultationTypesStandard/${id}`
		);
	}

	readAll(page: DatatableFilters): Observable<IConsultationType[]> {
		let params = this.paginationToHttpParams(page);
		return this.http
			.get<IApiResponse>(`${this.apiBaseUrl}/ConsultationTypesStandard`, {
				params,
			})
			.ToApiResult();
	}

	read(id: number): Observable<IConsultationType> {
		return this.http
			.get<IApiResponse>(
				`${this.apiBaseUrl}/ConsultationTypesStandard/${id}`
			)
			.ToApiResult();
	}

	update(id: number, model: IConsultationType) {
		return this.http
			.put<IApiResponse>(
				`${this.apiBaseUrl}/ConsultationTypesStandard/${id}`,
				model
			)
			.ToApiResult();
	}
}
