import { Injectable } from '@angular/core';
import {
	HttpRequest,
	HttpHandler,
	HttpEvent,
	HttpInterceptor,
	HttpResponse,
	HttpStatusCode,
} from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { catchError, tap } from 'rxjs/operators';
import { TranslateService } from '@ngx-translate/core';
import { ToastrService } from 'ngx-toastr';
import { AuthenticationService } from '@app/01.global/services/authentication.service';
import { LoaderService } from '../services/loader.service';
import { RouteList } from './route-enum';

@Injectable()
export class ErrorInterceptor implements HttpInterceptor {
	constructor(
		private toastrService: ToastrService,
		private translateService: TranslateService,
		private authenticationService: AuthenticationService,
		private loader: LoaderService
	) { }

	toastSuccessIgnoreList(request: HttpRequest<any>) {
		var routes = [
			'notifications/markallasread',
			'users/authenticate',
			'users/refresh-token',
			'users/logout',
			'chatmessages',
			'faq/deploy',
			'chatbot/ask',
			'channel',
			'attendance',
		];
		return (
			request.method != 'GET' &&
			!routes.some((route) => request.url.toLowerCase().includes(route))
		);
	}

	toastErrorIgnoreList(request: HttpRequest<any>) {
		var routes = [
			'users/refresh-token',
			'channel'
		];
		return routes.some((route) => request.url.includes(route));
	}

	intercept(
		request: HttpRequest<any>,
		next: HttpHandler
	): Observable<HttpEvent<any>> {
		return next.handle(request).pipe(
			tap((evt) => {
				if (evt instanceof HttpResponse) {
					if (
						evt.status == HttpStatusCode.Ok &&
						evt.body.isError == false &&
						this.toastSuccessIgnoreList(request)
					) {
						this.toastrService.success(
							this.translateService.instant(evt.body.message),
							this.translateService.instant(
								'OperationSuccessfulyTitle'
							),
							{ positionClass: 'toast-bottom-right' }
						);
					}
				}
			}),
			catchError((err) => {
				console.error(err);
				this.loader.disable();
				if (
					[401, 403].includes(err.status) &&
					this.authenticationService.userValue
				) {
					// auto logout if 401 or 403 response returned from api
					this.authenticationService.logout();
				}

				if (this.toastErrorIgnoreList(request)) {
					return throwError(
						() =>
							new Error(
								err.error.responseException.exceptionMessage
							)
					);
				}

				const title =
					this.translateService.instant('Error') +
					' ' +
					this.translateService.instant('Code') +
					':';
				if (err.error) {
					this.toastrService.error(
						this.translateService.instant(
							err.error.exceptionMessage ??
								err.error.title ??
								'UnhandledError'
						),
						title + err.error.referenceErrorCode,
						{
							positionClass: 'toast-bottom-right',
							timeOut: 10000,
							progressBar: true,
							progressAnimation: 'decreasing',
						}
					);
					return throwError(
						() =>
							new Error(
								err?.error?.responseException?.error
									?.lockedOutSecondsLeft ??
								err?.error?.responseException
									?.exceptionMessage ??
								err.error.title
							)
					);
				} else {
					this.toastrService.error(
						this.translateService.instant(
							'ErrorOccurredContactSupport'
						),
						title,
						{
							positionClass: 'toast-bottom-right',
							timeOut: 10000,
							progressBar: true,
							progressAnimation: 'decreasing',
						}
					);
					return throwError(() => new Error(err.error.title));
				}
			})
		);
	}
}
