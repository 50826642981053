<span (click)="$event.stopPropagation()" *ngIf="file?.type?.enumId" [ngSwitch]="show(file?.type?.enumId)">
	<div *ngSwitchCase="'icon'" class="card card-icon-big">
		<div class="card-body p-3 text-center">
			<i class="i-File"></i>
			<div class="text-13">{{file?.name}}</div>
		</div>
		<ng-container *ngTemplateOutlet="removeBadge"></ng-container>
	</div>

	<div *ngSwitchCase="'image'" class="card text-white o-hidden">
		<img class="card-img" [src]="file?.url" [alt]="file?.name">
		<div class="card-img-overlay">
			<div class="card-title text-white text-center" style="font-size: 13px !important;">{{file?.name}}</div>
		</div>
		<ng-container *ngTemplateOutlet="removeBadge"></ng-container>
	</div>
</span>

<ng-template #removeBadge>
	<ngx-dropzone-remove-badge *ngIf="removable" (click)="_remove($event)" class="remove-badge">
	</ngx-dropzone-remove-badge>
</ng-template>