import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { AppointmentStatusEnum } from '@app/01.global/helpers/appointment-satus-enum';
import { ChannelType } from '@app/01.global/helpers/channel-type';
import { RouteList } from '@app/01.global/helpers/route-enum';
import { IAppointment } from '@app/01.global/interfaces/IAppointment';
import { IChannel } from '@app/01.global/interfaces/iChannel';
import { ActiveChannel } from '@app/01.global/models/active-channel';
import { AppointmentService } from '@app/01.global/services/appointment.service';
import { BookingStatusService } from '@app/01.global/status-services/booking-status.service';
import { ConsultationStatusService } from '@app/01.global/status-services/consultation-status.service';
import { take } from 'rxjs';

@Component({
	selector: 'app-call-launcher',
	templateUrl: './call-launcher.component.html',
	styleUrls: ['./call-launcher.component.scss'],
})
export class CallLauncherComponent implements OnInit {
	appointmentsToStart: IAppointment[] = [];
	activeChannel: IChannel;
	ChannelType = ChannelType;
	AppointmentStatusEnum = AppointmentStatusEnum;
	buttonBgClass: string = '';

	@Output()
	onTypeChosen = new EventEmitter<string>();

	constructor(
		private router: Router,
		private route: ActivatedRoute,
		private appointmentService: AppointmentService,
		private bookingStatusService: BookingStatusService,
		private consultationStatusService: ConsultationStatusService
	) {}

	ngOnInit() {
		this.consultationStatusService.activeChannelObs
			.pipe(take(1))
			.subscribe((ch: ActiveChannel) => {
				this.appointmentService
					.readAll({
						pageSize: 0,
						totalItems: 0,
						totalPages: 0,
						currentPage: 0,
						channelId: ch.channel.id,
					})
					.subscribe((result: IAppointment[]) => {
						this.appointmentsToStart = result;
					});

				this.activeChannel = ch.channel;
			});
	}

	close(type: 'startApp' | 'newApp' | 'followUpCall', id?: number) {
		switch (type) {
			case 'startApp':
				this.onTypeChosen.emit(type);
				this.consultationStatusService.toggleVideoCall(true, id);
				break;
			case 'newApp':
				this.bookingStatusService.bookFromChannel(this.activeChannel);
				this.router.navigate([RouteList.bookingAgreement]);
				this.onTypeChosen.emit(type);
				break;
			case 'followUpCall':
				this.onTypeChosen.emit(type);
				this.consultationStatusService.toggleVideoCall(true);
				break;
			default:
				break;
		}
	}
}
