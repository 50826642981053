<div class="reviews-list-container w-100 px-3" [perfectScrollbar]="{ suppressScrollX: true }" infinite-scroll
	[infiniteScrollDistance]="2" [infiniteScrollThrottle]="0" (scrolled)="onScroll()">
	<ng-container *ngFor="let review of statusService.reviews$ | async">
		<div class="card mb-4">
			<div class="card-body">
				<div class="d-flex flex-column">
					<div class="d-flex flex-row mb-2">
						<div class="profile-picture avatar-md profileImgContainer shadow" [ngStyle]="{
							'background-image':
								'url(' +
								review.user.photo?.url ??
								'./assets/images/default-user.png' +
								')'}"></div>
						<span class="my-auto ml-3 text-18">{{review.user.displayName}}</span>
						<div class="ml-auto">
							{{review.createdDate | localizedDate:'medium'}}
						</div>
					</div>
					<div class="d-flex flex-column">
						<ngb-rating max="5" [rate]="review.rating" [readonly]="true"
							class="rating-warning text-30"></ngb-rating>
						<h2 class="my-auto"><strong>{{review.title}}</strong></h2>
					</div>
					<div class="d-flex flex-row">
						<span [innerHTML]="review.content"></span>
					</div>
				</div>
				<div class="d-flex justify-content-end" *ngIf="review.user.id == currentUser.id">
					<button class="btn btn-icon btn-rounded btn-primary mr-2 text-20" placement="top"
						[ngbTooltip]="'Edit' | translate" (click)="editReview(review)">
						<i class="i-Pen-4"></i>
					</button>
					<button class="btn btn-icon btn-rounded btn-danger mr-2 text-20" placement="top"
						[ngbTooltip]="'Delete' | translate" (click)="deleteReview(review.id)">
						&times;
					</button>
				</div>
			</div>
		</div>
	</ng-container>
</div>

<ng-template #deleteModal let-modal>
	<app-modal (onClose)="modal.dismiss($event)" (onConfirmClose)="modal.close($event)" [confirmButtonClass]="'danger'">
		<div modal-header>{{ 'Delete' | translate }}</div>
		<div modal-body>
			<p>{{ 'DoYouReallyWantToDeleteThisReview' | translate }}</p>
			<div class="alert-card mb-4 alert alert-danger text-16 text-center">
				{{ 'OperationCannotBeUndone' | translate }}
			</div>
		</div>
	</app-modal>
</ng-template>

<ng-template #editModal let-modal>
	<app-modal (onClose)="modal.dismiss($event)" (onConfirmClose)="modal.close($event)" [showDefaultFooter]="false">
		<div modal-header>
			<i class="i-Medal-2 mr-2"></i>
			{{'EditReview' | translate}}
		</div>
		<div modal-body>
			<router-outlet></router-outlet>
		</div>
	</app-modal>
</ng-template>