import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { WebcamModule } from 'ngx-webcam';
import { AttendanceRoutingModule } from './attendance-routing.module';
import { PatientQueueMainSidebarComponent } from './patient-queue-main-sidebar/patient-queue-main-sidebar.component';
import { SharedModule } from '@app/00.shared/shared.module';
import { AttendanceHomeComponent } from './attendance-home/attendance-home.component';
import { AttendanceModalComponent } from './attendance-modal/attendance-modal.component';
import { GuestSidebarComponent } from './guest-sidebar/guest-sidebar.component';
import { AttendanceNotesComponent } from './attendance-notes/attendance-notes.component';
import { AttendanceDocumentsComponent } from './attendance-documents/attendance-documents.component';
import { AttendanceChatComponent } from './attendance-chat/attendance-chat.component';
import { AttendanceBallComponent } from './attendance-ball/attendance-ball.component';
import { DoctorSidebarComponent } from './doctor-sidebar/doctor-sidebar.component';
import { MainSidebarAvailableAttendancesComponent } from './main-sidebar-available-attendances/main-sidebar-available-attendances';
import { ConsultationModule } from '@app/consultation/consultation.module';
import { AttendanceDevicesCheckComponent } from './attendance-devices-check/attendance-devices-check.component';
import { GuestBackgroundInstructionsComponent } from './guest-background-instructions/guest-background-instructions.component';
import { DoctorBackgroundInstructionsComponent } from './doctor-background-instructions/doctor-background-instructions.component';
import { DoctorSidebarCardComponent } from './doctor-sidebar-card/doctor-sidebar-card.component';
import { AttendanceCallLauncherComponent } from './attendance-call-launcher/attendance-call-launcher.component';
import { AttendanceVideoComponent } from './attendance-video/attendance-video.component';
import { AttendanceTriageAnswerComponent } from './attendance-triage-answer/attendance-triage-answer.component';

@NgModule({
	declarations: [
		MainSidebarAvailableAttendancesComponent,
		PatientQueueMainSidebarComponent,
		AttendanceHomeComponent,
		AttendanceModalComponent,
		GuestSidebarComponent,
		AttendanceNotesComponent,
		AttendanceDocumentsComponent,
		AttendanceChatComponent,
		AttendanceBallComponent,
		DoctorSidebarComponent,
		AttendanceDevicesCheckComponent,
		GuestBackgroundInstructionsComponent,
		DoctorBackgroundInstructionsComponent,
		DoctorSidebarCardComponent,
		AttendanceCallLauncherComponent,
		AttendanceVideoComponent,
  AttendanceTriageAnswerComponent,
	],
	exports: [
		MainSidebarAvailableAttendancesComponent,
		PatientQueueMainSidebarComponent,
		GuestSidebarComponent,
		DoctorSidebarComponent,
	],
	imports: [
		CommonModule,
		AttendanceRoutingModule,
		SharedModule,
		ConsultationModule,
		WebcamModule,
	],
})
export class AttendanceModule { }
