export enum RoleEnum {
	Admin = 'Admin',
	PowerUser = 'PowerUser',
	Guest = 'Guest',
	Employee = 'Employee',
	Beneficiary = 'Beneficiary',
	Doctor = 'Doctor',
	Therapist = 'Therapist',
	Provider = 'Provider',
	Developer = 'Developer',
}
