import { Injectable } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { RouteList } from '@app/01.global/helpers/route-enum';
import { TranslateService } from '@ngx-translate/core';
import { TableListActionButton } from '../models/table-list-action-button';
import { TableListRow } from '../models/table-list-row';

@Injectable({
	providedIn: 'root',
})
export abstract class ITableListMapperService {
	abstract doMap(source: any, route: ActivatedRoute): TableListRow;
	abstract doMapList(source: any[], route: ActivatedRoute): TableListRow[];
}

@Injectable({
	providedIn: 'root',
})
export abstract class TableListMapperService extends ITableListMapperService {
	constructor(protected translateService: TranslateService) {
		super();
	}
	doMapList(source: any[], route: ActivatedRoute): TableListRow[] {
		var rows = source.map((u) => this.doMap(u, route));

		rows.forEach((row) => {
			row.columns = row.columns.sort(
				(a, b) => a.displayMode - b.displayMode
			);
		});

		return rows;
	}

	createDetailsButton(outline = true): TableListActionButton {
		return {
			order: 0,
			loading: false,
			icon: 'i-File-Search',
			text: this.translateService.instant('Details'),
			showText: false,
			route: RouteList._routeCrudEnum.Details,
			btnClass: outline
				? 'btn btn-rounded btn-icon btn-outline-secondary'
				: 'btn btn-rounded btn-icon btn-secondary',
		};
	}

	createGoToWaitingRoomButton(outline = true): TableListActionButton {
		return {
			order: 0,
			loading: false,
			icon: 'i-Split-Horizontal-2-Window',
			text: this.translateService.instant('OneClickDoctor'),
			showText: false,
			route: RouteList.attendance,
			btnClass: outline
				? 'btn btn-rounded btn-icon btn-outline-info'
				: 'btn btn-rounded btn-icon btn-info',
		};
	}

	createUpdateButton(outline = true): TableListActionButton {
		return {
			order: 1,
			loading: false,
			icon: 'i-File-Edit',
			text: this.translateService.instant('Edit'),
			showText: false,
			route: RouteList._routeCrudEnum.Edit,
			btnClass: outline
				? 'btn btn-rounded btn-icon btn-outline-secondary'
				: 'btn btn-rounded btn-icon btn-secondary',
		};
	}

	createDeleteButton(outline = true): TableListActionButton {
		return {
			order: 2,
			loading: false,
			icon: 'i-Remove-File',
			text: this.translateService.instant('Delete'),
			showText: false,
			route: RouteList._routeCrudEnum.Delete,
			btnClass: outline
				? 'btn btn-rounded btn-icon btn-outline-danger'
				: 'btn btn-rounded btn-icon btn-danger',
		};
	}

	createBookingByRequestButton(): TableListActionButton {
		return {
			order: 0,
			loading: false,
			icon: 'i-Find-User',
			text: this.translateService.instant('BookingByRequest'),
			showText: false,
			route: RouteList._routeCrudEnum.BookingByRequest,
			btnClass: 'btn btn-rounded btn-icon btn-secondary',
		};
	}
}
