import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TableListTableComponent } from './components/table-list-data/table-list-data.component';
import { TableListContainerComponent } from './components/table-list-container/table-list-container.component';
import { TableListBtnComponent } from './templates/table-list-btn/table-list-btn.component';
import { NgxDatatableModule } from '@swimlane/ngx-datatable';
import { TableListThumbnailComponent } from './templates/table-list-thumbnail/table-list-thumbnail.component';
import { RouterModule } from '@angular/router';
import { TableListCardComponent } from './components/table-list-card/table-list-card.component';
import { TableListCardProfileImageComponent } from './components/table-list-card-profile-image/table-list-card-profile-image.component';
import { TableListCardProfileContactInfoComponent } from './components/table-list-card-profile-contact-info/table-list-card-profile-contact-info.component';
import { NgbNavModule } from '@ng-bootstrap/ng-bootstrap';
import { PerfectScrollbarModule } from 'ngx-perfect-scrollbar';
import { ReactiveFormsModule } from '@angular/forms';
import { InfiniteScrollModule } from 'ngx-infinite-scroll';
import { TableListMiniCardComponent } from './components/table-list-mini-card/table-list-mini-card.component';
import { TableListCircleCardComponent } from './components/table-list-circle-card/table-list-circle-card.component';
import { TableListTilesComponent } from './components/table-list-tiles/table-list-tiles.component';
import { TableListEventCardComponent } from './components/table-list-event-card/table-list-event-card.component';

@NgModule({
	declarations: [
		TableListTableComponent,
		TableListContainerComponent,
		TableListBtnComponent,
		TableListThumbnailComponent,
		TableListCardComponent,
		TableListCardProfileImageComponent,
		TableListCardProfileContactInfoComponent,
		TableListMiniCardComponent,
		TableListCircleCardComponent,
		TableListTilesComponent,
		TableListEventCardComponent,
	],
	imports: [
		CommonModule,
		ReactiveFormsModule,
		RouterModule,
		NgxDatatableModule,
		NgbNavModule,
		PerfectScrollbarModule,
		InfiniteScrollModule
	],
	exports: [
		TableListContainerComponent,
		TableListThumbnailComponent
	],
})
export class TableListModule { }
