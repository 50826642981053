import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { PerfectScrollbarModule } from 'ngx-perfect-scrollbar';
import { ToastrModule } from 'ngx-toastr';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { SearchModule } from './components/search/search.module';
import { SharedComponentsModule } from './components/shared-components.module';
import { SharedDirectivesModule } from './directives/shared-directives.module';
import { SharedPipesModule } from './pipes/shared-pipes.module';
import { TranslateModule } from '@ngx-translate/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { NgxDatatableModule } from '@swimlane/ngx-datatable';
import { NgxPaginationModule } from 'ngx-pagination';
import { NgxDropzoneModule } from 'ngx-dropzone';
import { InfiniteScrollModule } from 'ngx-infinite-scroll';
import { FormWizardModule } from './components/form-wizard/form-wizard.module';
import { TagInputModule } from 'ngx-chips';
import { QuillModule } from 'ngx-quill';
import { NgxInfiniteScrollerModule } from 'ngx-infinite-scroller';
import { ColorPickerModule } from 'ngx-color-picker';
import { TableListModule } from '@app/table-list/table-list.module';
import { CdTimerModule } from 'angular-cd-timer';

@NgModule({
	imports: [
		CommonModule,
		PerfectScrollbarModule,
		SearchModule,
		FormsModule,
		ReactiveFormsModule,
		NgxPaginationModule,
		NgxDatatableModule,
		NgxDropzoneModule,
		ToastrModule.forRoot(),
		NgbModule,
		SharedComponentsModule,
		SharedDirectivesModule,
		SharedPipesModule,
		RouterModule,
		InfiniteScrollModule,
		NgxInfiniteScrollerModule,
		TranslateModule,
		FormWizardModule,
		TagInputModule,
		QuillModule.forRoot(),
		ColorPickerModule,
		TableListModule,
		CdTimerModule,
	],
	exports: [
		CommonModule,
		FormsModule,
		ReactiveFormsModule,
		NgxPaginationModule,
		NgxDatatableModule,
		NgxDropzoneModule,
		NgbModule,
		PerfectScrollbarModule,
		TranslateModule,
		SharedComponentsModule,
		SharedDirectivesModule,
		SharedPipesModule,
		InfiniteScrollModule,
		NgxInfiniteScrollerModule,
		FormWizardModule,
		TagInputModule,
		QuillModule,
		SearchModule,
		ColorPickerModule,
		TableListModule,
		CdTimerModule,
	],
})
export class SharedModule { }
