import { Injectable } from '@angular/core';
import {
	HttpInterceptor,
	HttpEvent,
	HttpHandler,
	HttpRequest,
} from '@angular/common/http';
import { Observable } from 'rxjs';

@Injectable()
export class SubmittedFormInterceptor implements HttpInterceptor {
	intercept(
		req: HttpRequest<any>,
		next: HttpHandler
	): Observable<HttpEvent<any>> {
		switch (req.method) {
			case 'POST':
			case 'PUT':
				var request = req.clone();
				for (const [k, v] of Object.entries(request.body)) {
					var value = v as any;
					if (value == null) {
						delete request.body[k];
						continue;
					}
					try {
						if (
							typeof value == 'object' &&
							'value' in value &&
							'text' in value
						) {
							// if starts with (+ then its a country code phone, so we dont convert to int
							request.body[k] = v['value'].startsWith('(+')
								? v['value']
								: +v['value'];
						}
					} catch (error) {
						console.error(error);
					}
				}
				return next.handle(request);
			default:
				break;
		}
		return next.handle(req);
	}
}
