import { Injectable } from '@angular/core';
import {
	catchError,
	from,
	map,
	Observable,
	of,
	Subject,
	Subscription,
} from 'rxjs';
import * as signalR from '@microsoft/signalr';
import { environment } from '@environments/environment';
import { AuthenticationService } from './authentication.service';
import { IUser } from '../interfaces/IUser';
import { IChatMessage } from '../interfaces/iChatMessage';
import { NotificationsService } from './notifications.service';
import { IPatientWaiting } from '../interfaces/iPatientWaiting';

@Injectable({
	providedIn: 'root',
})
export class SignalrService {
	private notificationsChangesSubject = new Subject<void>();
	notificationsChanges: Observable<void> =
		this.notificationsChangesSubject.asObservable();
	private invitationFinishedSubject: Subject<IUser> =
		new Subject<IUser>();
	public invitationFinished: Observable<IUser> =
		this.invitationFinishedSubject.asObservable();

	private newChatMessageSubject: Subject<IChatMessage> =
		new Subject<IChatMessage>();
	public newChatMessage: Observable<IChatMessage> =
		this.newChatMessageSubject.asObservable();

	private chatMessageReadSubject: Subject<IChatMessage> =
		new Subject<IChatMessage>();
	public chatMessageRead: Observable<IChatMessage> =
		this.chatMessageReadSubject.asObservable();

	private iAmOfflineSubject: Subject<string> =
		new Subject<string>();
	public iAmOffline: Observable<string> =
		this.iAmOfflineSubject.asObservable();

	private iAmOnlineSubject: Subject<string> =
		new Subject<string>();
	public iAmOnline: Observable<string> = this.iAmOnlineSubject.asObservable();

	private guestEnteringSubject: Subject<IPatientWaiting> =
		new Subject<IPatientWaiting>();
	public guestEntering: Observable<IPatientWaiting> =
		this.guestEnteringSubject.asObservable();

	private guestLeavingSubject: Subject<IPatientWaiting> =
		new Subject<IPatientWaiting>();
	public guestLeaving: Observable<IPatientWaiting> =
		this.guestLeavingSubject.asObservable();

	private iAmTypingSubject: Subject<{
		channelId: number;
		userName: string;
	}> = new Subject<{ channelId: number; userName: string }>();
	public iAmTyping: Observable<{ channelId: number; userName: string }> =
		this.iAmTypingSubject.asObservable();

	private pendingEmployeesSubject: Subject<boolean> =
		new Subject<boolean>();
	public pendingEmployees: Observable<boolean> =
		this.pendingEmployeesSubject.asObservable();

	private pendingTriageFormAnswersSubject: Subject<boolean> =
		new Subject<boolean>();
	public pendingTriageFormAnswers: Observable<boolean> =
		this.pendingTriageFormAnswersSubject.asObservable();

	private pendingAgreementCardsSubject: Subject<boolean> =
		new Subject<boolean>();
	public pendingAgreementCards: Observable<boolean> =
		this.pendingAgreementCardsSubject.asObservable();

	private acceptedAgreementCardSubject: Subject<{
		cardNumber: string;
		userName: string;
	}> = new Subject<{ cardNumber: string; userName: string }>();
	public acceptedAgreementCard: Observable<{
		cardNumber: string;
		userName: string;
	}> = this.acceptedAgreementCardSubject.asObservable();

	private appearAsOfflineSubject: Subject<boolean> =
		new Subject<boolean>();
	public appearAsOffline: Observable<boolean> =
		this.appearAsOfflineSubject.asObservable();

	private connection: signalR.HubConnection;

	private subs: Subscription[] = [];

	constructor(
		private authenticationService: AuthenticationService,
		private notificationsService: NotificationsService,
	) { }

	sendGuestLeaveAllWaitingRooms() { }
	sendDoctorLeaving() { }
	sendDoctorEntering() { }
	sendGuestLeaving(model: IPatientWaiting) {
		this.startIfNotConnected().subscribe((isConnected: boolean) => {
			if (isConnected) {
				this.connection.send('SetGuestLeaving', model);
			}
		});
	}
	sendGuestEntering(model: IPatientWaiting) {
		this.startIfNotConnected().subscribe((isConnected: boolean) => {
			if (isConnected) {
				this.connection.send('SetGuestEntering', model);
			}
		});
	}

	sendTypingInChannelToHub(channelId: number) {
		this.startIfNotConnected().subscribe((isConnected: boolean) => {
			if (isConnected) {
				this.connection.send('SetIsTypingInChannel', channelId);
			}
		});
	}

	askLoginNotifications() {
		this.notificationsService.loginNotifications().subscribe();
	}

	private connect(): Observable<boolean> {
		try {
			this.connection = new signalR.HubConnectionBuilder()
				.configureLogging(signalR.LogLevel.Warning)
				.withUrl(environment.ApiBaseURL + '/hubs/hub', {
					accessTokenFactory: () =>
						this.authenticationService.tokenValue,
					skipNegotiation: true,
					transport: signalR.HttpTransportType.WebSockets,
				})
				.build();

			return from(this.connection.start()).pipe(
				map(() => {
					console.info('SignalR Connected!');

					this.askLoginNotifications();

					this.listenerSetup();
					return true;
				}),
				catchError((err) => {
					console.warn(err);
					return of(false);
				})
			);
		} catch (error) {
			console.warn('Could not connect to SignalR', error);
			return of(false);
		}
	}

	disconnect() {
		if (
			this.connection?.state == signalR.HubConnectionState.Connecting ||
			this.connection?.state == signalR.HubConnectionState.Connected
		) {
			console.info('SignalR Disconnected!');
			this.connection.stop();
			this.connection = null;
		}
		this.notificationsChangesSubject.next(null);
		this.invitationFinishedSubject.next(null);
		this.newChatMessageSubject.next(null);
		this.chatMessageReadSubject.next(null);
		this.iAmOfflineSubject.next(null);
		this.iAmOnlineSubject.next(null);
		this.guestEnteringSubject.next(null);
		this.guestEnteringSubject.next(null);
		this.iAmTypingSubject.next(null);
		this.appearAsOfflineSubject.next(null);
		this.pendingEmployeesSubject.next(null);
		this.pendingTriageFormAnswersSubject.next(null);

		this.subs.forEach((s) => s?.unsubscribe());
	}

	startIfNotConnected(): Observable<boolean> {
		if (
			this.connection?.state == signalR.HubConnectionState.Connecting ||
			this.connection?.state == signalR.HubConnectionState.Reconnecting
		) {
			return of(false);
		}

		if (
			!this.connection ||
			this.connection?.state ==
			signalR.HubConnectionState.Disconnecting ||
			this.connection?.state == signalR.HubConnectionState.Disconnected
		) {
			return this.connect();
		}
		return of(true);
	}

	private listenerSetup() {
		this.connection.on('BroadcastNotificationsChanged', () => {
			this.notificationsChangesSubject.next();
		});
		this.connection.on('BroadcastInvitationFinished', (result: IUser) => {
			this.invitationFinishedSubject.next(result);
		});
		this.connection.on(
			'BroadcastNewChatMessage',
			(message: IChatMessage) => {
				this.newChatMessageSubject.next(message);
			}
		);
		this.connection.on(
			'BroadcastChatMessageRead',
			(message: IChatMessage) => {
				this.chatMessageReadSubject.next(message);
			}
		);
		this.connection.on('BroadcastAppearAsOffline', (isActive: boolean) => {
			this.appearAsOfflineSubject.next(isActive);
		});
		this.connection.on(
			'BroadcastGuestEntering',
			(model: IPatientWaiting) => {
				this.guestEnteringSubject.next(model);
			}
		);
		this.connection.on(
			'BroadcastGuestLeaving',
			(model: IPatientWaiting) => {
				this.guestLeavingSubject.next(model);
			}
		);
		this.connection.on('BroadcastIAmOnline', (userName: string) => {
			this.iAmOnlineSubject.next(userName);
		});
		this.connection.on('BroadcastIAmOffline', (userName: string) => {
			this.iAmOfflineSubject.next(userName);
		});
		this.connection.on(
			'BroadcastIAmTyping',
			(channelId: number, userName: string) => {
				if (userName == this.authenticationService.userValue.userName) {
					return;
				}
				this.iAmTypingSubject.next({ channelId, userName });
			}
		);
		this.connection.on('BroadcastPendingTriageFormAnswers', () => {
			this.pendingTriageFormAnswersSubject.next(true);
		});
		this.connection.on('BroadcastPendingEmployees', () => {
			this.pendingEmployeesSubject.next(true);
		});
		this.connection.on('BroadcastPendingAgreementCards', () => {
			this.pendingAgreementCardsSubject.next(true);
		});
		this.connection.on(
			'BroadcastAcceptedAgreementCard',
			(cardNumber: string, userName: string) => {
				this.acceptedAgreementCardSubject.next({
					cardNumber: cardNumber,
					userName: userName,
				});
			}
		);

		this.appearAsOfflineSubject.next(
			this.authenticationService.userValue.appearOffline
		);
	}
}
