<div class="d-flex pl-3 pr-3 pt-2 pb-2 o-hidden box-shadow-1 chat-topbar">
	<a appSidebarToggler="chat-sidebar" class="link-icon d-md-none">
		<i class="icon-regular i-Right ml-0 mr-3"></i>
	</a>
	<div class="d-flex align-items-center" *ngIf="activeChannel && !isGroup">
		<app-label-with-popover [triggers]="'mouseenter:mouseleave'" [placement]="'right'" [animation]="false"
			[container]="'body'" [targetLink]="routeList.joinRoutes([isCurrentUserDoctor() ? 
					routeList.patients : 
					routeList.employees, 
				routeList._routeCrudEnum.Details,
				isCurrentUserDoctor() ? 
					activeChannel.users[0].id : 
					activeChannel.doctors[0].id ])">
			<span slot="label">
				<div class="d-flex flex-row" style="margin-top: 20px;">
					<div class="profileImgContainer avatar-sm rounded-circle mr-2" [ngStyle]="{
							'border':'0px',
							'background-image':
							'url(' +
							(activeChannel.logo?.url ?? './assets/images/default-chat-bubbles-group.png') +
							')'
						}"></div>
					<span class="mx-0 my-auto text-title text-16 flex-grow-1">{{activeChannel.name}}</span>
				</div>
			</span>
		</app-label-with-popover>
	</div>
	<div class="d-flex align-items-center" *ngIf="activeChannel && isGroup">
		<app-label-with-popover [triggers]="'mouseenter:mouseleave'" [placement]="'right'" [animation]="false"
			[container]="'body'">
			<span slot="label">
				<img [src]="activeChannel.logo?.url ?? './assets/images/default-chat-bubbles-group.png'"
					[alt]="activeChannel.name" class="avatar-sm rounded-circle mr-2">
			</span>
		</app-label-with-popover>
		<span *ngIf="canEditName" class="m-0 text-title text-16 flex-grow-1 channel-name-input" role="textbox"
			contenteditable (keydown)="onKeydown($event)" (input)="editingName($event)">{{activeChannel.name}}</span>
		<span *ngIf="!canEditName" class="m-0 text-title text-16 flex-grow-1">{{activeChannel.name}}</span>
		<span style="cursor: pointer" class="ml-3" *ngIf="isEditingName" (click)="updateChannelName()">
			<i class="text-success text-20 i-Yes"></i>
		</span>
	</div>
	<div class="flex-grow-1"></div>
	<div class="d-flex align-items-center" *ngIf="activeChannel">
		<button class="btn btn-icon btn-rounded btn-outline-secondary mr-2" placement="bottom"
			*ngIf="hasPermissionsToBookSession" [ngbTooltip]="'BookSession' | translate" (click)="bookSession()">
			<i class="i-Calendar-4"></i>
		</button>
		<button class="btn btn-icon btn-rounded btn-outline-primary mr-2" placement="bottom" *ngIf="archiveMode"
			[ngbTooltip]="'UnarchiveChannel' | translate" (click)="unarchiveChannel()">
			<i class="i-Restore-Window"></i>
		</button>
		<button class="btn btn-icon btn-rounded btn-outline-danger mr-2" placement="bottom"
			*ngIf="!archiveMode && !showVideo" [ngbTooltip]="'ArchiveChannel' | translate"
			(click)="archiveChannel(modal)">
			<i class="i-Folders"></i>
		</button>
		<button *ngIf="!archiveMode && !showAddUser && !showVideo && hasPermissionsToAddUser"
			class="btn btn-icon btn-rounded btn-outline-dark mr-2" placement="bottom"
			[ngbTooltip]="'AddUserToChannel' | translate" (click)="AddUserToChannel(true)">
			<i class="i-Add-User"></i>
		</button>
		<button *ngIf="!archiveMode && showAddUser && !showVideo" class="btn btn-icon btn-rounded btn-dark mr-2"
			placement="bottom" [ngbTooltip]="'close' | translate" (click)="AddUserToChannel(false)">
			<span aria-hidden="true">&times;</span>
		</button>
		<button *ngIf="!showSharedFiles" class="btn btn-icon btn-rounded btn-outline-info mr-2" placement="bottom"
			[ngbTooltip]="'SharedFiles' | translate" (click)="SharedFiles(true)">
			<i class="i-Files"></i>
		</button>
		<button *ngIf="showSharedFiles" class="btn btn-icon btn-rounded btn-info mr-2 text-20" placement="bottom"
			[ngbTooltip]="'Close' | translate" (click)="SharedFiles(false)">
			<span aria-hidden="true">&times;</span>
		</button>
		<button *ngIf="!showMeetingNotes && hasPermissionsToMeetingNotes"
			class="btn btn-icon btn-rounded btn-outline-warning mr-2" placement="bottom"
			[ngbTooltip]="'MeetingNotes' | translate" (click)="MeetingNotes(true)">
			<i class="i-Pen-5"></i>
		</button>
		<button *ngIf="showMeetingNotes" class="btn btn-icon btn-rounded btn-warning mr-2 text-20" placement="bottom"
			[ngbTooltip]="'Close' | translate" (click)="MeetingNotes(false)">
			<span aria-hidden="true">&times;</span>
		</button>
		<button *ngIf="!showPatientHistory && hasPermissionsToPatientHistory"
			class="btn btn-icon btn-rounded btn-outline-primary mr-2" placement="bottom"
			[ngbTooltip]="'PatientHistory' | translate" (click)="PatientHistory(true)">
			<i class="i-Clock-Back"></i>
		</button>
		<button *ngIf="showPatientHistory" class="btn btn-icon btn-rounded btn-primary mr-2 text-20" placement="bottom"
			[ngbTooltip]="'Close' | translate" (click)="PatientHistory(false)">
			<span aria-hidden="true">&times;</span>
		</button>
		<button *ngIf="isGuest" class="btn btn-icon btn-rounded btn-primary text-20" data-toggle="tooltip"
			(click)="goToWaitingRoom()" data-placement="bottom" title="{{'GoToWaitingRoom' | translate }}">
			<i class="i-Support"></i>
		</button>
		<!-- <button *ngIf="!archiveMode && !showVideo && hasPermissionsToStartVideoCall && !canJoinOnGoingVideoCall"
			class="btn btn-icon btn-rounded btn-outline-success" placement="bottom" [ngbTooltip]="'Call' | translate"
			(click)="startVideoCall(true)">
			<i class="i-Telephone"></i>
		</button>
		<button *ngIf="!archiveMode && !showVideo && canJoinOnGoingVideoCall"
			class="btn btn-icon btn-rounded btn-outline-secondary" placement="left"
			[ngbTooltip]="'JoinCall' | translate" (click)="joinVideoCall(true)">
			<i class="i-Add-User"></i>
		</button>
		<button *ngIf="!archiveMode && showVideo" class="btn btn-icon btn-rounded btn-danger"
			style="transform: rotate(90deg);" placement="bottom" [ngbTooltip]="'EndCall' | translate"
			(click)="startVideoCall(false)">
			<i class="i-Telephone"></i>
		</button> -->
	</div>
</div>

<ng-template #modal let-modal>
	<app-modal (onClose)="modal.dismiss($event)" (onConfirmClose)="modal.close($event)">
		<div modal-header>{{ 'ConfirmArchiveChannel' | translate }}</div>
		<div modal-body>
			<div class="alert-card alert alert-danger">
				<p>{{'ConfirmArchiveChannel' | translate}}</p>
			</div>
		</div>
	</app-modal>
</ng-template>

<ng-template #launcherModal let-modal>
	<app-modal (onClose)="modal.dismiss($event)" (onConfirmClose)="modal.close($event)" [showDefaultFooter]="false">
		<div modal-header>{{ 'LaunchCall' | translate }}</div>
		<div modal-body>
			<app-call-launcher (onTypeChosen)="onTypeChosen($event)"></app-call-launcher>
		</div>
	</app-modal>
</ng-template>

<ng-template #bookSessionModal let-modal>
	<app-modal (onClose)="modal.dismiss($event)" (onConfirmClose)="modal.close($event)" [showDefaultFooter]="false">
		<div modal-header>{{ 'BookSession' | translate }}</div>
		<div modal-body>
			<app-booking-session [activeChannel]="activeChannel.id" (onSetSchedule)="onSetSchedule($event)">
			</app-booking-session>
		</div>
	</app-modal>
</ng-template>