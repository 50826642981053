<div class="row mt-3">
	<div class="col d-flex flex-row" [perfectScrollbar]="{ suppressScrollY: true }" style="margin-left: 15px;
    margin-right: 15px;
    padding-left: 0;
    padding-right: 0;">
		<div class="px-5" style="min-width: 25rem;width: 25rem;"
			*ngFor="let participant of participants; let i = index">
			<app-label-with-popover [triggers]="'mouseenter:mouseleave'" [placement]="'bottom'" [animation]="false"
				[container]="'body'" [targetLink]="">
				<div slot="label">
					<div class="d-flex flex-row">
						<div class="profile-picture avatar-lg mr-2 profileImgContainer" [ngStyle]="{
								'background-image':
									'url(' +
									(participant.photo?.url ??
									'./assets/images/default-user.png') +
									')'
							}"></div>
						<div class="flex-grow-1 my-auto">
							<h5 class="m-0 text-left">{{participant.displayName}}</h5>
							<p class="m-0 text-left text-small text-muted"><a
									href="mailto:{{participant.email}}">{{participant.email}}</a>
							</p>
							<p class="m-0 text-left text-small text-muted">{{participant.specialty?.name}}
							</p>
						</div>
						<div class="flex-grow-1 my-auto ml-auto text-right">
							<button class="btn btn-rounded btn-icon btn-danger m-1"
								(click)="removeFromChannel(participant)"><i class="i-Remove-User"
									style="font-size: 1rem;"></i></button>
						</div>
					</div>
				</div>
			</app-label-with-popover>
		</div>
	</div>
</div>
<div class="row" style="max-height: calc(100% - 100px);" [perfectScrollbar]="{ suppressScrollX: true }">
	<div class="col">
		<div class="px-xl-5 px-md-3 py-xl-3 py-lg-2 py-md-1 mb-4">
			<app-data-table-container *ngIf="table && channel?.id" [table]="table">
			</app-data-table-container>
		</div>
	</div>
</div>