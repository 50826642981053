import { HttpInterceptor, HttpHandler, HttpRequest, HttpEvent, HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { tap } from 'rxjs/operators';

@Injectable()
export class LogInterceptor implements HttpInterceptor {
	constructor() { }

	intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
		const started = Date.now();
		return next.handle(req).pipe(
			tap(event => {
				if (event instanceof HttpResponse) {
					const elapsed = Date.now() - started;
					console.info(`Request for ${req.urlWithParams} took ${elapsed} ms.`);
				}
			})
		);
	}
}