import { ChangeDetectorRef, Component, InjectionToken, Injector, Input, OnDestroy, OnInit } from '@angular/core';
import { FormControl } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { TableList, TableListMode, TableListSettings } from '@app/table-list/models/table-list';
import { TableListFilters } from '@app/table-list/models/table-list-filters';
import { ITableListService } from '@app/table-list/services/iservice';
import { ITableListMapperService } from '@app/table-list/services/mapper.service';
import { TableListStatusService } from '@app/table-list/services/table-list-status.service';
import { TranslateService } from '@ngx-translate/core';
import { debounceTime, distinctUntilChanged, Subscription, switchMap, take } from 'rxjs';

@Component({
	selector: 'table-list',
	templateUrl: './table-list-container.component.html',
	styleUrls: ['./table-list-container.component.scss']
})
export class TableListContainerComponent implements OnInit, OnDestroy {
	constructor(public route: ActivatedRoute,
		public statusService: TableListStatusService,
		private service: ITableListService,
		private mapper: ITableListMapperService,
		private cdr: ChangeDetectorRef,
		private injector: Injector,
		private translateService: TranslateService,) { }

	@Input('table') table: TableList;
	@Input('serviceToken') serviceToken: InjectionToken<ITableListService>;
	@Input('mapperToken') mapperToken: InjectionToken<ITableListMapperService>;

	identifier: string;
	private subs: Subscription[] = [];
	isLoading: boolean;
	totalItems: number = 0;
	TableListMode = TableListMode;
	searchTerm: FormControl = new FormControl('');
	settings: TableListSettings;

	ngOnInit(): void {
		if (this.table) {
			var initialSettings = this.table.settingsSubject.getValue();
			this.identifier = initialSettings.identifier;

			this.table.service = this.serviceToken ? this.injector.get(this.serviceToken) : this.service;
			this.table.mapper = this.mapperToken ? this.injector.get(this.mapperToken) : this.mapper;
			this.table.pageRoute = this.route;
			this.statusService.addToPage(this.table);

			this.subs.push(this.statusService.getSettingsObservable(this.identifier)
				.subscribe(settings => {
					settings.actionButtons = settings.actionButtons?.sort((a, b) => a.order - b.order);
					this.settings = settings;
				}))


			this.statusService.getFiltersObservable(this.identifier).pipe(take(1))
				.subscribe((filters: TableListFilters) => {
					this.searchTerm.setValue(filters?.searchTerm ?? '');
				})

			this.subs.push(this.searchTerm.valueChanges
				.pipe(
					debounceTime(500),
					distinctUntilChanged(),
					switchMap((value: string) => {
						this.statusService.search(this.identifier, value);
						return value;
					})
				)
				.subscribe(() => { }));

			this.subs.push(this.statusService.getLoadingObservable(this.identifier).subscribe((isLoading: boolean) => {
				this.isLoading = isLoading;
				this.cdr.detectChanges();
			}));

			if (this.settings?.messages?.titleMessage == "triageanswers") {
				this.settings.messages.titleMessage = this.translateService.instant('TriageAnswers'); 
			}
		}
	}

	ngOnDestroy(): void {
		this.subs.forEach(s => s?.unsubscribe())
		this.statusService.removeFromPage(this.identifier);
	}
}
