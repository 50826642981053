import { DatePipe } from '@angular/common';
import { Component, Input, OnInit } from '@angular/core';
import { DatatableColumn } from '@app/01.global/models/datatable-column';
import { TableListColumnMode } from '@app/table-list/models/table-list-column';
import { TableListRow } from '@app/table-list/models/table-list-row';
import { TranslateService } from '@ngx-translate/core';

@Component({
	selector: 'table-list-event-card',
	templateUrl: './table-list-event-card.component.html',
	styleUrls: ['./table-list-event-card.component.scss']
})
export class TableListEventCardComponent implements OnInit {
	@Input('row') row: TableListRow;
	TableListColumnMode = TableListColumnMode;
	@Input('tableIdentifier') tableIdentifier: string;
	dates: DatatableColumn[] = [];
	eventStartDay: string;
	eventStartMonth: string;
	eventThumbnail: DatatableColumn;
	eventTitle: DatatableColumn;
	eventDetails: DatatableColumn[];
	constructor(private datePipe: DatePipe, private translateService: TranslateService,) { }

	ngOnInit(): void {
		if (this.row) {
			this.dates = this.row.columns.filter(c => c.displayMode == TableListColumnMode.date);
			this.dates = this.sortData();
			if (this.dates.length > 0) {
				this.eventStartDay = this.datePipe.transform(this.dates[0].value, "dd");
				this.eventStartMonth = this.translateService.instant(this.datePipe.transform(this.dates[0].value, "MMM"));
				this.eventThumbnail = this.row.columns.find(c => c.displayMode == TableListColumnMode.thumbnail);
				this.eventTitle = this.row.columns.find(c => c.displayMode == TableListColumnMode.title);
				this.eventDetails = this.row.columns.filter(c => c.displayMode == TableListColumnMode.text || c.displayMode == TableListColumnMode.html);
			}
		}
	}

	sortData(): DatatableColumn[] {
		return this.dates.sort((a, b) => {
			return <any>new Date(b.value) - <any>new Date(a.value);
		});
	}

}
