import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { TableListFilters } from '../models/table-list-filters';

@Injectable({
	providedIn: 'root',
})
export abstract class ITableListService {
	abstract readCount(page: TableListFilters): Observable<number>;
	abstract readAll(page: TableListFilters): Observable<any[]>;
	abstract read(id: number): Observable<any>;
}
