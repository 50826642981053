<div class="modal-body p-0">
	<div class="row o-hidden m-0" style="min-height: 341px;">
		<div class="col-5 p-3">
			<div class="text-primary text-14 mb-3">
				<span>{{'Hello' | translate}}, {{currentUserFirstName}} {{'WelcomeToWaitingRoom' |
					translate}}</span>
			</div>
			<div [@animate]="{value:'*',params:{y:'170px',delay:'400ms', duration: '350ms'}}">
				<div class="alert-card alert alert-warning text-16 mb-3">
					{{"PleaseTurnOnYourCamera" |
					translate}}
				</div>
			</div>
			<div *ngIf="!cameraIsActive && cameraWasDenied"
				[@animate]="{value:'*',params:{y:'170px',delay:'500ms', duration: '400ms'}}">
				<div class="alert-card alert alert-danger text-16 mb-3">
					{{"YouDidNotAllowCameraAccess" |
					translate}}&nbsp;
					{{"TurnOnCameraTutorial" | translate}}
				</div>
			</div>
			<div *ngIf="!cameraIsActive && cameraNotFound"
				[@animate]="{value:'*',params:{y:'170px',delay:'500ms', duration: '400ms'}}">
				<div class="alert-card alert alert-danger text-16 mb-3">
					{{"CameraNotFound" |
					translate}}
				</div>
			</div>
			<div *ngIf="cameraIsActive" [@animate]="{value:'*',params:{y:'170px',delay:'500ms', duration: '400ms'}}">
				<div class="alert-card alert alert-success text-16 mb-3">{{"YourCameraIsOk" |
					translate}}
				</div>
			</div>
			<div *ngIf="step == 'mic'" [@animate]="{value:'*',params:{y:'170px',delay:'400ms', duration: '350ms'}}">
				<div class="alert-card alert alert-warning text-16 mb-3">
					{{"PleaseTurnOnYourMic" |
					translate}}
				</div>
			</div>
			<div *ngIf="!micIsActive && micWasDenied"
				[@animate]="{value:'*',params:{y:'170px',delay:'500ms', duration: '400ms'}}">
				<div class="alert-card alert alert-danger text-16 mb-3">
					{{"YouDidNotAllowMicAccess" |
					translate}}&nbsp;
					{{"TurnOnMicTutorial" | translate}}
				</div>
			</div>
			<div *ngIf="!micIsActive && micNotFound"
				[@animate]="{value:'*',params:{y:'170px',delay:'500ms', duration: '400ms'}}">
				<div class="alert-card alert alert-danger text-16 mb-3">
					{{"MicNotFound" |
					translate}}
				</div>
			</div>
			<div *ngIf="micIsActive" [@animate]="{value:'*',params:{y:'170px',delay:'500ms', duration: '400ms'}}">
				<div class="alert-card alert alert-success text-16 mb-3">{{"YourMicIsOk" |
					translate}}
				</div>
			</div>
			<div style="height: 3rem;"></div>
			<div class="buttons-container text-center">
				<button class="btn btn-rounded btn-primary text-18 py-1 px-4 d-flex mx-auto align-items-center mb-1"
					(click)="handleDevicesStep(step)">
					<i *ngIf="step == 'webcam' && cameraIsActive" class="i-Camera mr-3"></i>
					<i *ngIf="step == 'mic' && micIsActive" class="i-Microphone-3 mr-3"></i>
					<ng-container *ngIf="step == 'webcam'">
						{{"Advance" | translate}}
					</ng-container>
					<ng-container *ngIf="step == 'mic'">
						{{"Close" | translate}}
					</ng-container>
				</button>
			</div>
		</div>
		<div class="col-7 p-3 d-flex flex-column" #webcamContainer>
			<div class="webcam-container" *ngIf="step == 'webcam'">
				<webcam [width]="(webcamContainer.offsetWidth - 32)" [height]="340"
					(initError)="handleWebcamInitError($event)"></webcam>
				<!-- https://github.com/basst314/ngx-webcam -->
				<div class="app-inro-circle" *ngIf="!cameraIsActive && cameraWasDenied">
					<div class="border rounded-circle big-bubble bg-danger text-white"
						[@animate]="{value:'*',params:{scale:'.2',delay:'400ms', duration: '400ms'}}">
						<strong class="icon">&times;</strong>
					</div>
				</div>
			</div>
			<div class="mic-container" *ngIf="step == 'mic'">
				<div class="app-inro-circle">
					<div class="border rounded-circle big-bubble text-white" [ngClass]="{
						'bg-danger' : !micIsActive && (micWasDenied || micNotFound),
						'bg-success' : micIsActive}" [@animate]="{value:'*',params:{scale:'.2',delay:'400ms', duration: '400ms'}}">
						<i *ngIf="micIsActive" class="i-Microphone-3"></i>
						<strong *ngIf="!micIsActive && (micWasDenied || micNotFound)" class="icon">&times;</strong>
					</div>
				</div>
			</div>
		</div>
	</div>
</div>