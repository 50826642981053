<div class="search-ui rtl-ps-none" perfectScrollbar
	[@animate]="{value:'*',params:{y:'120px',opacity:'0',delay:'100ms', duration: '400ms'}}">
	<div class="search-header">
		<div class="main-color-logo logo"></div>
		<button class="btn btn-icon bg-transparent float-right mt-2" (click)="service.searchOpen = false"
			data-toggle="tooltip" data-placement="bottom" title="{{ 'Close' | translate }}">
			<i class="i-Close-Window text-22 text-muted"></i>
		</button>
	</div>

	<input type="text" [placeholder]="'TypeHere' | translate" class="search-input text-primary"
		[formControl]="searchTerm" autofocus />

	<div class="search-title">
		<span class="text-13 text-primary">{{"SearchResults" | translate}}:<b>{{filters.totalItems}}</b></span>
	</div>
	<div class="search-results list-horizontal">
		<div (click)="navigateToDetails(user)" *ngFor="let user of users | paginate: { 
			totalItems: filters.totalItems,
			itemsPerPage: filters.pageSize,
			currentPage: filters.currentPage + 1 }; let i = index;" class="list-item col-md-12 pl-1 cursor-pointer"
			[@animate]="{value:'*',params:{delay: (i*100)+'ms', y:'50px'}}" data-toggle="tooltip"
			data-placement="bottom" title="{{ 'GoToDetailsPage' | translate }}">
			<div class="card o-hidden flex-row mb-4 d-flex">
				<div class="list-thumb d-flex my-auto">
					<div class="profile-picture avatar-lg profileImgContainer" [ngStyle]="{
						'background-image':
							'url(' + (user.photo?.url  ?? './assets/images/default-user.png') +')'
						}"></div>
				</div>
				<div class="flex-grow-1 pl-2 d-flex">
					<div
						class="card-body align-self-center d-flex flex-column justify-content-between align-items-lg-center flex-lg-row">
						<h4 class="w-20 w-sm-100 item-title" *ngIf="!user.providerInfo">{{user.displayName}}</h4>
						<h4 class="w-20 w-sm-100 item-title" *ngIf="user.providerInfoAccountId > 0">
							{{user.providerInfo.commercialName}}</h4>
						<p class="m-0 text-muted text-small w-10 w-sm-100 text-right"
							*ngIf="user.providerInfoAccountId > 0">
							{{user.providerInfo?.address?.line1}}
							<br>
							{{user.providerInfo?.address?.line2}}
							<br>
							{{user.providerInfo?.address?.postalCode}}
							<br>
							{{user.providerInfo?.address?.city}} - {{user.providerInfo?.address?.country}}
						</p>
						<p class="m-0 text-muted text-small w-10 w-sm-100 text-center" *ngIf="user.employeeInfoId > 0">
							{{user.specialty?.name}}
						</p>
						<ul style="list-style: none;" *ngIf="user.employeeInfoId > 0"
							class="m-0 text-muted text-small  w-10 w-sm-100  text-center">
							<li class="m-0" *ngFor="let consultationType of user.consultationTypes">
								{{consultationType.text | translate}}
							</li>
						</ul>
						<ul style="list-style: none;" class="m-0 text-muted text-small w-10 w-sm-100  text-center"
							*ngIf="user.employeeInfoId > 0">
							<li class="m-0" *ngFor="let sp of user.employeeInfo.spokenLanguages">
								{{sp.text | translate}}
							</li>

						</ul>
						<p class="m-0 text-muted text-small  w-10 w-sm-100  text-center"
							*ngIf="user.employeeInfoId > 0">
							{{user.employeeInfo.praticeAreas}}
						</p>
						<p class="m-0 text-muted text-small  w-10 w-sm-100  text-center"
							*ngIf="user.employeeInfoId > 0">
							{{user.employeeInfo?.diseases}}
						</p>
						<p class="m-0 text-muted text-small w-20 w-sm-100  text-right" *ngIf="user.employeeInfoId > 0">
							<label class="switch switch-secondary m-0">
								<span>
									{{ 'AcceptVideoConsultations' | translate }}
								</span>
								<input type="checkbox" disabled
									checked="{{user.employeeInfo?.acceptVideoConsultations}}" />
								<span class="slider"></span>
							</label>
						</p>
						<p class="m-0 text-muted text-small w-20 w-sm-100  text-right" *ngIf="user.employeeInfoId > 0">
							<label class="switch switch-secondary m-0">
								<span>
									{{ 'AcceptOnlineBooking' | translate }}
								</span>
								<input type="checkbox" disabled checked="{{user.employeeInfo?.acceptOnlineBooking}}" />
								<span class="slider"></span>
							</label>
						</p>
					</div>
				</div>
			</div>
		</div>
	</div>
	<!-- PAGINATION CONTROL -->
	<div class="col-md-12 mt-3" *ngIf="filters.totalItems">
		<pagination-controls (pageChange)="pageChange($event)" previousLabel="" nextLabel="">
		</pagination-controls>
	</div>
</div>