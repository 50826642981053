import {
	Component,
	EventEmitter,
	Input,
	OnDestroy,
	OnInit,
	Output,
} from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { RouteList } from '@app/01.global/helpers/route-enum';
import { IChannel } from '@app/01.global/interfaces/iChannel';
import { IUser } from '@app/01.global/interfaces/IUser';
import { AuthenticationService } from '@app/01.global/services/authentication.service';
import { ChatbotService } from '@app/01.global/services/chatbot.service';
import { ConsultationStatusService } from '@app/01.global/status-services/consultation-status.service';
import { Subscription, take } from 'rxjs';

@Component({
	selector: 'app-sticky-ball',
	templateUrl: './sticky-ball.component.html',
	styleUrls: ['./sticky-ball.component.scss'],
})
export class StickyBallComponent implements OnInit, OnDestroy {
	showRightPane: boolean;
	hasClickedBefore = false;

	@Input() mode: 'bot' | 'channel';
	@Input() channel: IChannel;
	@Input() canShowRightPane: boolean;
	@Output() onShowRightPane: EventEmitter<boolean> =
		new EventEmitter<boolean>();
	routeList = RouteList;
	currentUser: IUser;
	userSub: Subscription;
	get inBotMode() {
		return this.mode == 'bot';
	}
	get inChannelMode() {
		return this.mode == 'channel';
	}
	get id() {
		return this.mode == 'bot' ? 0 : this.channel.id;
	}

	constructor(
		private router: Router,
		private route: ActivatedRoute,
		private authenticationService: AuthenticationService,
		private chatbotService: ChatbotService,
		private consultationStatusService: ConsultationStatusService
	) {}

	ngOnInit() {
		if (this.inBotMode) {
			this.chatbotService.connect();
		}

		this.userSub = this.authenticationService.user.subscribe((u) => {
			this.currentUser = u;
		});

		// if (this.inChannelMode && this.canShowRightPane) {
		// 	var activeChannelId = this.route.snapshot.queryParams?.channelId;
		// 	if (activeChannelId == this.channel.id) {
		// 		setTimeout(() => {
		// 			this.onIconClick();
		// 		}, 100);
		// 	}
		// }
	}

	ngOnDestroy(): void {
		if (this.inBotMode) {
			this.chatbotService.disconnect();
		}
		this.userSub?.unsubscribe();
	}

	onIconClick() {
		this.showRightPane = !this.showRightPane;

		if (this.inBotMode) {
			if (!this.hasClickedBefore) {
				this.chatbotService.startConversation();
				this.hasClickedBefore = true;
			}
		} else {
			if (this.showRightPane) {
				this.consultationStatusService
					.setActiveChannel(this.channel.id)
					.pipe(take(1))
					.subscribe();
				this.channel.unReadMessagesCount = 0;
			} else {
				this.consultationStatusService
					.setActiveChannel(0)
					.pipe(take(1))
					.subscribe();
			}
		}

		this.onShowRightPane.emit(this.showRightPane);
	}

	onClose() {
		this.showRightPane = false;
		this.onShowRightPane.emit(this.showRightPane);
	}

	onCloseClick() {
		this.onClose();
		if (this.inChannelMode) {
			this.router.navigate([], { relativeTo: this.route });
		}
	}

	navigate() {
		this.router.navigate([RouteList.consultation]);
		this.consultationStatusService
			.setActiveChannel(this.channel.id)
			.pipe(take(1))
			.subscribe();
	}
}
