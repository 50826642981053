<div class="chat-content rtl-ps-none" [perfectScrollbar]="{ suppressScrollX: true }">
	<div *ngFor="let message of messages" class="mb-4">
		<div *ngIf="message.sender === 'user'" class="d-flex">
			<div class="message flex-grow-1" style="margin-left: 20%;">
				<div class="d-flex">
					<p class="mb-1 text-title text-16 flex-grow-1">{{currentUser.displayName}}</p>
					<span class="text-small text-muted">
						{{message.timestamp | localizedDate:'MMM d, y, HH:mm:ss'}}
					</span>
				</div>
				<p class="m-0">{{message.text}}</p>
			</div>
			<img [src]="currentUser.photo.url" [alt]="currentUser.displayName" class="avatar-sm rounded-circle ml-3">
		</div>
		<div *ngIf="message.sender == 'bot'" class="d-flex user">
			<i class="i-Support avatar-sm rounded-circle mr-3" style="font-size: 2rem;"></i>
			<div class="message flex-grow-1 bg-primary text-white" style="margin-right: 20%;">
				<div class="d-flex">
					<p class="mb-1 text-title text-16 flex-grow-1 text-white">{{'LiveChat' | translate}}</p>
					<span class="text-small text-white">
						{{message.timestamp | localizedDate:'MMM d, y, HH:mm:ss'}}
					</span>
				</div>
				<p class="m-0" [innerHtml]="message.text"></p>
			</div>
		</div>
	</div>
</div>
<div class="pl-3 pr-1 pt-3 pb-3 box-shadow-1 shout-message-input">
	<form [formGroup]="form" class="inputForm" (ngSubmit)="sendMessage()"
		(keyup.enter)="sendMessage();$event.preventDefault()">
		<div class="form-group d-flex flex-row">
			<textarea class="form-control form-control-rounded" formControlName="content"
				placeholder="{{ 'TypeYourMessage' | translate }}" cols="20" rows="3"></textarea>
			<button class="btn btn-icon btn-rounded btn-secondary my-auto ml-auto">
				<i class="i-Paper-Plane"></i>
			</button>
		</div>
	</form>
</div>