import { Component, OnInit, ViewChild } from '@angular/core';
import { FormControl, Validators, AbstractControl, ValidatorFn, ValidationErrors } from '@angular/forms';
import { RouteList } from '@app/01.global/helpers/route-enum';
import { AuthenticationService } from '@app/01.global/services/authentication.service';
import { environment } from '@environments/environment';
import { WebcamInitError } from 'ngx-webcam';
import { Clipboard } from '@angular/cdk/clipboard';
import { NgbModal, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { ControlTypeEnum } from '@app/01.global/helpers/control-enum';
import { AttendanceService } from '@app/01.global/services/attendance.service';
import { TranslateService } from '@ngx-translate/core';


@Component({
	selector: 'app-doctor-background-instructions',
	templateUrl: './doctor-background-instructions.component.html',
	styleUrls: ['./doctor-background-instructions.component.scss']
})
export class DoctorBackgroundInstructionsComponent implements OnInit {
	devicesAlreadyChecked: string;
	cameraIsActive: boolean;
	cameraWasDenied: boolean;
	cameraNotFound: boolean;
	micIsActive: boolean;
	micWasDenied: boolean;
	micNotFound: boolean;
	copyLinkControl: FormControl;
	emailControl: FormControl;
	smsControl: FormControl;
	ControlTypeEnum = ControlTypeEnum;
	emailSubmitted = false;
	emailLoading = false;
	smsSubmitted = false;
	smsLoading = false;

	@ViewChild('emailModal') emailModal;
	@ViewChild('smsModal') smsModal;
	emailModalRef: NgbModalRef;
	smsModalRef: NgbModalRef;

	constructor(private service: AttendanceService,
		public authService: AuthenticationService,
		private clipboard: Clipboard,
		private translateService: TranslateService,
		private modalService: NgbModal
	) {
		this.emailControl = new FormControl('', [Validators.email]);
		this.smsControl = new FormControl('', [Validators.required, Validators.minLength(7),this.createPhoneCheckValidator()]);
}


	ngOnInit(): void {
		this.devicesAlreadyChecked = localStorage.getItem(
			'devicesAlreadyChecked'
		);

		var user = this.authService.userValue;
		this.copyLinkControl = new FormControl(
			environment.baseUrl
			+ '/#'
			+ RouteList.attendance
			+ '/' + user.specialty.providerAccountPublicIdentifier
			+ '/' + user.publicIdentifier)
	}

	handleWebcamInitError(error: WebcamInitError): void {
		this.cameraIsActive = false;
		if (
			error.mediaStreamError &&
			error.mediaStreamError.name === 'NotAllowedError'
		) {
			this.cameraWasDenied = true;
		}
		if (
			error.mediaStreamError &&
			error.mediaStreamError.name === 'NotFoundError'
		) {
			this.cameraNotFound = true;
		}
	}

	copyText() {
		this.clipboard.copy(this.copyLinkControl.getRawValue());
	}

	openEmailForm() {
		this.emailModalRef = this.modalService.open(this.emailModal, { backdrop: 'static' });
		this.emailModalRef.result.then(() => { })
	}

	openSMSForm() {
		this.smsModalRef = this.modalService.open(this.smsModal, { backdrop: 'static' });
		this.smsModalRef.result.then(() => { })
	}


	submitEmailForm() {
		this.emailLoading = true;
		this.emailSubmitted = true;
		if (this.emailControl.invalid) {
			return;
		}

		this.service.sendEmailInvite(this.emailControl.value)
			.subscribe({
				next: () => {
					this.emailLoading = false;
					this.emailModalRef.close();
				},
				error: () => {
					this.emailLoading = false;
				}
			})
	}

	submitSMSForm() {
		this.smsLoading = true;
		this.smsSubmitted = true;
		if (this.smsControl.invalid) {
			return;
		}

		this.service.sendSMSInvite(this.smsControl.value)
			.subscribe({
				next: () => {
					this.smsLoading = false;
					this.smsModalRef.close();
				},
				error: () => {
					this.smsLoading = false;
				}
			})
	}
	
	navigateToGoogleCalendar(): void {
		const startDate = new Date(); 
		const roundedMinutes = Math.ceil(startDate.getMinutes() / 5) * 5; // Round up to the nearest multiple of 5
		startDate.setMinutes(roundedMinutes);
		const endDate = new Date(startDate.getTime() + 45 * 60000); //Set the meeting end date after 45 minutes

		const startDateFormatted = startDate.toISOString().replace(/-|:|\.\d+/g, '');
		const endDateFormatted = endDate.toISOString().replace(/-|:|\.\d+/g, '');
		
		const appointmentTitle = encodeURIComponent(this.translateService.instant('WaitingRoom'));
		
		const googleCalendarUrl = `http://www.google.com/calendar/event?action=TEMPLATE&dates=${startDateFormatted}Z%2F${endDateFormatted}Z&text=${appointmentTitle}&location=Online&ctz=UTC`;
		
		window.open(googleCalendarUrl, '_blank');
	  }
	  
	
	  formatDate(date: Date): string {
		return `${date.getDate()}/${date.getMonth() + 1}/${date.getFullYear()} ${date.getHours()}:${date.getMinutes()}:${date.getSeconds()} GMT`;
	  }
	createPhoneCheckValidator(): ValidatorFn {
		return (control:AbstractControl) : ValidationErrors | null => {
			const phoneRegex = /^\d{10}$/;
			const value = control.value;

			if (!value) {
				return null;
			}
	
			return phoneRegex.test(value) ? {phoneValid:true}: null;
		}
	}

}
