export enum ControlTypeEnum {
	Text,
	Email,
	Number,
	Password,
	Radio,
	RadioOutline,
	Checkbox,
	CheckboxOutline,
	CheckboxSwitch,
	Dropdown,
	Autocomplete,
	Textarea,
	Date,
	Document,
	Typeahead,
	Review
}
