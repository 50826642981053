import {
	Component,
	ElementRef,
	Input,
	OnDestroy,
	OnInit,
	ViewChild,
} from '@angular/core';
import {
	UntypedFormBuilder,
	UntypedFormGroup,
	Validators,
} from '@angular/forms';
import { IChatMessage } from '@app/01.global/interfaces/IChatMessage';
import { IDocument } from '@app/01.global/interfaces/IDocument';
import { ChatMessageService } from '@app/01.global/services/chat-message.service';
import { SignalrService } from '@app/01.global/services/signalr.service';
import { AttendanceStatusService } from '@app/01.global/status-services/attendance-status.service';
import { TranslateService } from '@ngx-translate/core';
import { ToastrService } from 'ngx-toastr';
import {
	debounceTime,
	interval,
	Observable,
	startWith,
	Subscription,
} from 'rxjs';

@Component({
	selector: 'app-attendance-chat',
	templateUrl: './attendance-chat.component.html',
	styleUrls: ['./attendance-chat.component.scss'],
})
export class AttendanceChatComponent implements OnInit, OnDestroy {
	@Input() channelId: number;

	messages$: Observable<IChatMessage[]>;
	form: UntypedFormGroup;
	loading: boolean = false;
	loadingUp: boolean = false;
	whoIsTyping$: Observable<string>;
	isStillTypingSub: Subscription;
	iAmTyping: Observable<number>;
	iAmTypingSub: Subscription;
	contentSub: Subscription;

	@ViewChild('fileInput') fileInput: ElementRef;

	constructor(
		private statusService: AttendanceStatusService,
		private formBuilder: UntypedFormBuilder,
		private toastrService: ToastrService,
		private translateService: TranslateService,
		private chatMessageService: ChatMessageService,
		private signalRService: SignalrService
	) { }

	ngOnInit() {
		this.buildForm();
		this.messages$ = this.statusService.getChannelMessages$(this.channelId);
		this.whoIsTyping$ = this.statusService.getWhoIsTyping$(this.channelId);
	}

	ngOnDestroy(): void {
		this.iAmTypingSub?.unsubscribe();
		this.contentSub?.unsubscribe();
	}

	onScroll() {
		this.statusService.loadMoreMessages(this.channelId);
	}

	sendMessage() {
		if (this.form.invalid) {
			return;
		}
		this.form.controls.content.disable();
		this.chatMessageService
			.create(this.form.getRawValue())
			.subscribe((result) => {
				this.statusService.afterSendingMessage(this.channelId)
				this.form.controls.content.enable();
				this.form.controls.content.setValue('');
			});
	}

	onFileChanged(event) {
		const allowedTypes: Array<string> = [
			'image/gif',
			'image/jpeg',
			'image/png',
			'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
			'application/msword',
			'application/vnd.ms-excel',
			'application/vnd.ms-powerpoint',
			'text/plain',
			'application/pdf',
		];
		const file = event.target.files[0];

		if (allowedTypes.indexOf(file.type) === -1) {
			this.toastrService.error(
				this.translateService.instant('FileTypeIsNotAllowed'),
				'Error',
				{ positionClass: 'toast-bottom-right' }
			);

			return;
		}

		this.chatMessageService
			.upload(event.target.files[0], this.channelId)
			.subscribe((x: IDocument) => {
				this.statusService.afterSendingMessage(this.channelId)
				this.fileInput.nativeElement.value = '';
			});
	}

	private buildForm() {
		this.form = this.formBuilder.group({
			content: ['', Validators.required],
			channelId: [this.channelId, Validators.required],
		});

		this.contentSub?.unsubscribe();
		this.contentSub = this.form.controls.content.valueChanges
			.pipe(debounceTime(500))
			.subscribe((value) => {
				if (value == '') {
					if (this.iAmTyping) {
						this.iAmTypingSub.unsubscribe();
						this.iAmTyping = null;
					}
					return;
				}
				if (!this.iAmTyping) {
					this.iAmTyping = interval(1500).pipe(startWith(0));
					this.iAmTypingSub = this.iAmTyping.subscribe(() => {
						this.signalRService.sendTypingInChannelToHub(
							this.channelId
						);
					});
				}
			});
	}
}
