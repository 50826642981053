import { Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { CalendarEvent } from 'angular-calendar';
import { startOfDay } from 'date-fns';
import { Subscription } from 'rxjs';
import { IAppointment } from '../interfaces/IAppointment';
import { IUser } from '../interfaces/IUser';
import { AuthenticationService } from '../services/authentication.service';
import { ToastrService } from 'ngx-toastr';
import { BaseMapperService } from './base-mapper.service';
import { DatatableRow } from '../models/datatable-row';
import { TableListColumnMode } from '@app/table-list/models/table-list-column';
import { DatePipe } from '@angular/common';
import { ChannelType } from '../helpers/channel-type';
import { MedicalOfficesService } from '../services/medical-offices.service';

@Injectable({
	providedIn: 'root',
})
export class CalendarMapperService extends BaseMapperService {
	currentUser: IUser;
	userSub: Subscription;
	constructor(
		private authenticationService: AuthenticationService,
		private toastr: ToastrService,
		private datePipe: DatePipe,
		translateService: TranslateService,
		private medicalOfficeService: MedicalOfficesService
	) {
		super(translateService);
		this.userSub = this.authenticationService.user.subscribe((u: IUser) => {
			this.currentUser = u;
		});
	}

	doMapFromAppointment(
		source: IAppointment,
		emptySlot: boolean = false,
		readOnly: boolean = false
	): CalendarEvent {
		var ce: CalendarEvent = {
			id: source.id,
			start: new Date(source.startDate) || startOfDay(new Date()),
			end: new Date(source.endDate) || startOfDay(new Date()),
			title: source.title,
			color: {
				primary: '#25ACFD',
				secondary: '#EFEFEF',
				secondaryText: '#134379',
			},
			meta: {
				logo: this.getLogo(source),
				medicalofficeId: source.medicalOfficeId,
				employeeAccountId: source.medicalOffice.doctorId,
				emptySlot: emptySlot,
				tmpEvent: false,
			},
			resizable: {
				beforeStart: !readOnly,
				afterEnd: !readOnly,
			},
			draggable: !readOnly,
		};

		var splitter = source.title.split(' - ');
		if (splitter.length == 2) {
			ce.title =
				this.translateService.instant(splitter[0].trim()) +
				' - ' +
				splitter[1];
		}

		return ce;
	}

	doMapToAppointment(source: CalendarEvent): IAppointment {
		var ci: IAppointment = {
			id: source.id as number,
			startDate: source.start,
			endDate: source.end,
			title: source.title || this.currentUser.displayName,
			consultationType: source.meta.consultationType,
			appointmentStatusesMachine: [],
			channel: undefined,
			channelId: 0,
			consultationTypeId: 0,
			createdBy: 0,
			inProgress: false,
			isUrgency: false,
			medicalOffice: undefined,
			medicalOfficeId: 0,
			participants: [],
			patientId: 0,
		};
		return ci;
	}

	doMap(source: IAppointment): DatatableRow {
		var row = new DatatableRow(source);

		var splitter = source.title.split(' - ');

		//name, description, employees, econsultationTypes
		row.columns = [
			{
				name: 'thumbnail',
				sortable: false,
				displayMode: TableListColumnMode.thumbnail,
				value: this.getLogo(source),
				tooltip: source.title,
			},
			{
				name: this.translateService.instant('Name'),
				sortable: true,
				displayMode: TableListColumnMode.title,
				value:
					splitter.length == 2
						? this.translateService.instant(splitter[0].trim()) +
						  ' - ' +
						  splitter[1]
						: source.title,
				tooltip:
					splitter.length == 2
						? this.translateService.instant(splitter[0].trim()) +
						  ' - ' +
						  splitter[1]
						: source.title,
			},
			{
				name: this.translateService.instant('StartDate'),
				sortable: true,
				displayMode: TableListColumnMode.date,
				value: source.startDate,
				text: this.datePipe.transform(source.startDate, 'long'),
				tooltip: this.datePipe.transform(source.startDate, 'long'),
			},
			{
				name: this.translateService.instant('EndDate'),
				sortable: true,
				displayMode: TableListColumnMode.date,
				value: source.endDate,
				text: this.datePipe.transform(source.startDate, 'long'),
				tooltip: this.datePipe.transform(source.endDate, 'long'),
			},
			{
				name: this.translateService.instant('ConsultationTypes'),
				sortable: true,
				displayMode: TableListColumnMode.text,
				value: this.translateService.instant(
					source.consultationType?.name
				),
			},
			{
				name: this.translateService.instant('branch?.name'),
				sortable: true,
				displayMode: TableListColumnMode.html,
				value:
					'<div class="d-flex flex-row"><img class="avatar-sm rounded-circle mr-2" src="' +
					(source.medicalOffice.branch.account.photo?.url ??
						'./assets/images/default-chat-bubbles-group.png') +
					'"/><span class="mx-0 my-auto">' +
					source.medicalOffice.branch.commercialName +
					'</span></div>',
			},
		];

		row.actionButtons.push(this.createDetailsButton());

		return row;
	}

	doMapFromAppointments(
		source: IAppointment[],
		emptySlot: boolean = false,
		readOnly: boolean = false
	): CalendarEvent[] {
		return source.map((u) =>
			this.doMapFromAppointment(u, emptySlot, readOnly)
		);
	}

	doMapToAppointments(source: CalendarEvent[]): IAppointment[] {
		return source.map((u) => this.doMapToAppointment(u));
	}

	getLogo(source: IAppointment): string {
		if (
			source.medicalOffice?.doctorId == this.currentUser.id &&
			source.channel?.channelType == ChannelType.Consultation
		) {
			return (
				source.participants[0].photo?.url ??
				'/assets/images/default-user.png'
			);
		} else if (
			source.participants.some((ap) => ap.id == this.currentUser.id) &&
			source.channel.channelType == ChannelType.Consultation
		) {
			return (
				source.medicalOffice.doctor?.photo?.url ??
				'/assets/images/default-user.png'
			);
		} else {
			return '/assets/images/default-chat-bubbles-group.png';
		}
	}
}
