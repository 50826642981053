<div class="main-header" *ngIf="currentUser">
	<div class="logo-container d-none d-xl-inline">
		<a [routerLink]="routeList.dashboard">
			<div class="main-color-logo logo"></div>
		</a>
	</div>
	<div class="logo-container d-inline d-lg-none">
		<a [routerLink]="'./'">
			<div class="main-color-logo logo"></div>
		</a>
	</div>

	<div class="menu-toggle" style="width: 30px;" (click)="toggleSidebar()">
		<div></div>
		<div></div>
		<div></div>
	</div>

	<div class="d-flex align-items-center">
		<div>
			<span class="badge badge-pill badge-secondary text-14 py-2 px-lg-3 cursor-pointer mr-1 mr-lg-3"
				(click)="searchService.searchOpen = true">
				<i class="search-icon text-muted i-Magnifi-Glass1"></i>
				<span class="d-none d-xxl-inline ml-lg-1">{{'Search' | translate}}</span>
			</span>
			<span class="badge badge-pill badge-danger text-14 py-2 px-lg-3 cursor-pointer mr-1 mr-lg-3" *ngIf="isGuest"
				(click)="onUrgencyClick()">
				<i class="i-Danger"></i>
				<span class="d-none d-xl-inline ml-lg-1">{{'FindImidiateMedicalAssistance' | translate}}</span>
			</span>
			<span class="badge badge-pill badge-info text-14 py-2 px-lg-3 cursor-pointer mr-1 mr-lg-3" *ngIf="isGuest"
				(click)="onBookingClick()" data-toggle="tooltip" data-placement="bottom"
				title="{{ 'BookAnAppointment' | translate }}">
				<i class="i-Calendar"></i>
				<span class="d-none d-xl-inline ml-lg-1">
					{{ 'BookAnAppointment' | translate }}
				</span>
			</span>
			<span class="badge badge-pill badge-info text-14 py-2 px-lg-3 cursor-pointer mr-1 mr-lg-3" *ngIf="isGuest"
				(click)="onOneClickDoctorClick()" data-toggle="tooltip" data-placement="bottom"
				title="{{ 'OneClickDoctor' | translate }}">
				<i class="i-Split-Horizontal-2-Window"></i>
				<span class="d-none d-xl-inline ml-lg-1">
					{{ 'OneClickDoctor' | translate }}
				</span>
			</span>
			<span class="badge badge-pill badge-info text-14 py-2 px-lg-3 cursor-pointer mr-1 mr-lg-3"
				*ngIf="isDoctor || isTherapist" (click)="onWaitingRoomClick()" data-toggle="tooltip"
				data-placement="bottom" title="{{ 'SeeWaitingRoom' | translate }}">
				<i class="i-Align-Justify-All"></i>
				<span class="d-none d-xl-inline ml-lg-1">
					{{ 'SeeWaitingRoom' | translate }}
				</span>
			</span>
			<span class="badge badge-pill badge-info text-14 py-2 px-lg-3 cursor-pointer"
				*ngIf="isGuest || isDoctor || isTherapist" (click)="chat()" data-toggle="tooltip" data-placement="auto"
				title="{{'Chat'| translate}}">
				<i class="i-Speach-Bubble-11"></i>
				<span class="d-none d-xl-inline ml-lg-1">
					{{ 'Chat' | translate }}
				</span>
			</span>
		</div>
	</div>

	<div style="margin: auto"></div>

	<div class="header-part-right">
		<div ngbDropdown [placement]="'bottom-right'" class="d-none d-sm-inline-block">
			<i ngbDropdownToggle class="i-Globe text-muted header-icon" role="button" data-toggle="tooltip"
				data-placement="bottom" title="{{ 'Language' | translate }}" id="languagesDropdown"></i>
			<div ngbDropdownMenu aria-labelledby="languagesDropdown" class="header-account-dropdown">
				<button class="dropdown-item" *ngFor="let lang of availableLanguages" data-toggle="tooltip"
					data-placement="bottom" title="{{ lang.text | translate }}" (click)="setLanguage(lang.value)">
					{{ lang.text | translate }}
				</button>
			</div>
		</div>

		<div ngbDropdown [placement]="'bottom-right'" data-toggle="tooltip" data-placement="bottom"
			title="{{ 'Notifications' | translate }}">
			<div class="badge-top-container">
				<span class="badge badge-primary">
					{{ notifications.length > 99 ? '99+' : notifications.length }}
				</span>
				<i ngbDropdownToggle class="i-Bell text-muted header-icon" role="button"></i>
			</div>
			<div ngbDropdownMenu class="notification-dropdown rtl-ps-none" perfectScrollbar infinite-scroll
				[scrollWindow]="false" (scrolled)="onNotificationsScroll()">
				<div class="dropdown-item d-flex" *ngFor="let item of notifications"
					(click)="navigateNotification(item)" [queryParams]="{'id': item.entityId}">
					<div class="notification-icon">
						<i class="{{ item.icon }} text-{{ item.status }} mr-1"></i>
					</div>
					<div class="notification-details flex-grow-1">
						<p class="m-0 d-flex align-items-center">
							<span>{{ item.title }}</span>
							<span *ngIf="item.badge" class="badge badge-pill badge-{{
									item.status
								}} ml-1 mr-1">
								{{ item.badge }}
							</span>
							<span class="flex-grow-1"></span>
							<span class="text-small text-muted ml-auto">
								{{ item.time | relativeTime }}
							</span>
						</p>
						<p class="text-small text-muted m-0">
							{{ item.text | excerpt: 30 }}
						</p>
					</div>
				</div>
			</div>
		</div>

		<div ngbDropdown [placement]="'bottom-right'" class="user col align-self-end">
			<div class="avatar-sm img-bg-setup cursor-pointer border-status" style="border-radius: 50%;" [ngStyle]="{
				'background-image':
					'url(' +
					(currentUser?.photo?.url  ?? './assets/images/default-user.png') +
					')'
			}" [ngClass]="{online: isOnline === true }" id="userDropdown" ngbDropdownToggle alt="{{ currentUser?.displayName }}"
				data-toggle="tooltip" data-placement="bottom"></div>
			<div ngbDropdownMenu aria-labelledby="userDropdown" class="header-account-dropdown">
				<div class="dropdown-header text-16">
					<i class="i-Lock-User text-20 mr-1"></i>
					{{ currentUser?.displayName }}
				</div>
				<button *ngIf="isOnline && (isDoctor || isTherapist)" class="dropdown-item" (click)="changeStatus()"
					data-toggle="tooltip" data-placement="bottom" title="{{ 'AppearOffline' | translate }}">
					<i class="i-Eye text-danger mr-1"></i>
					{{ 'AppearOffline' | translate }}
				</button>
				<button *ngIf="!isOnline && (isDoctor || isTherapist)" class="dropdown-item" (click)="changeStatus()"
					data-toggle="tooltip" data-placement="bottom" title="{{ 'AppearOnline' | translate }}">
					<i class="i-Eye text-success mr-1"></i>
					{{ 'AppearOnline' | translate }}
				</button>
				<button class="dropdown-item" [routerLink]="routeList.profile" data-toggle="tooltip"
					data-placement="bottom" title="{{ 'AccountSettings' | translate }}">
					<i class="i-Gear mr-1"></i>
					{{ 'AccountSettings' | translate }}
				</button>
				<button *ngIf="pwaService.promptEvent" class="dropdown-item" data-toggle="tooltip"
					data-placement="bottom" title="{{ 'installPwa' | translate }}" (click)="installPwa()">
					<i class="i-Download mr-1"></i>
					{{ 'installPwa' | translate }}
				</button>
				<button *ngIf="updateButton" class="dropdown-item" data-toggle="tooltip" data-placement="bottom"
					title="{{ 'askUserToUpdate' | translate }}" (click)="askUserToUpdate()">
					<i class="i-Repeat-3 mr-1"></i>
					{{ 'askUserToUpdate' | translate }}
				</button>
				<!-- 				
					<button class="dropdown-item" [routerLink]="" data-toggle="tooltip" data-placement="bottom"
					title="{{ 'Support' | translate }}">
					<i class="i-Ambulance mr-1"></i>
					{{ 'Support' | translate }}
				</button> 
				-->
				<button fullScreenWindow class="dropdown-item" data-toggle="tooltip" data-placement="bottom"
					title="{{ 'fullScreenWindow' | translate }}">
					<i class="i-Full-Screen"></i>
					{{ 'fullScreenWindow' | translate }}
				</button>
				<button class="dropdown-item" (click)="signout()" data-toggle="tooltip" data-placement="bottom"
					title="{{ 'SignOut' | translate }}">
					<i class="i-Power-2 text-danger mr-1"></i>
					{{ 'SignOut' | translate }}
				</button>
			</div>
		</div>
	</div>
</div>