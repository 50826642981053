import { Injectable } from '@angular/core';
import { map, Observable, tap } from 'rxjs';
import { BehaviorSubject, Subject } from 'rxjs';
import { ReviewStatistic, ReviewSummary } from '../helpers/reviewSummary';
import { IReview } from '../interfaces/IReview';
import { DatatableFilters } from '../models/DatatableFilters';
import { ReviewsService } from '../services/reviews.service';

@Injectable({ providedIn: 'root' })
export class ReviewStatusService {
	pagination: DatatableFilters = {
		currentPage: 0,
		pageSize: 10,
		totalItems: 0,
		totalPages: 0,
	};

	private professionalId = 0;

	private reviewsSubject = new BehaviorSubject<IReview[]>([]);
	public reviews$ = this.reviewsSubject.asObservable();

	private reviewSummarySubject = new Subject<ReviewStatistic>();
	public reviewSummary$ = this.reviewSummarySubject.asObservable();

	constructor(private service: ReviewsService) { }

	readSummary(professionalId: number = this.professionalId) {
		this.professionalId = professionalId;
		if (this.professionalId == 0) {
			return;
		}
		this.service
			.readSummary(professionalId)
			.subscribe((reviewsSummary: ReviewSummary[]) => {
				var stat = new ReviewStatistic();
				var num = 0;
				stat.total = 0;
				reviewsSummary.forEach((summary) => {
					num = num + summary.rating * summary.count;
					stat.total += summary.count;
				});

				stat.average = num / stat.total;

				stat.reviewsSummary = reviewsSummary;

				this.reviewSummarySubject.next(stat)
			});
	}

	readReviews(professionalId: number) {
		this.pagination.currentPage = 0;
		this.pagination.readFromWhoId = professionalId;
		this.service
			.readAll(this.pagination)
			.subscribe((results: IReview[]) => {
				this.reviewsSubject.next(results);
			});
	}

	readMoreReviews() {
		this.pagination.currentPage++;
		this.service
			.readAll(this.pagination)
			.subscribe((results: IReview[]) => {
				this.reviewsSubject.next([...this.reviewsSubject.getValue(), ...results]);
			});
	}

	deleteReview(id: number) {
		this.service
			.delete(id)
			.subscribe(() => {
				this.readSummary();
				this.reviewsSubject.next(this.reviewsSubject.getValue().filter(r => r.id != id));
			});
	}

	editReview(id: number, review: IReview): Observable<IReview> {
		return this.service
			.update(id, review)
			.pipe(tap((result: IReview) => {
				this.readSummary();
				this.reviewsSubject.next(this.reviewsSubject.getValue().map(r => r.id == id ? result : r));
			}));
	}

	createReview(review: IReview): Observable<void> {
		return this.service
			.create(review)
			.pipe(map((result: IReview) => {
				this.readSummary();
				this.reviewsSubject.next([result, ...this.reviewsSubject.getValue()]);
			}));
	}
}