import { ActivatedRoute } from "@angular/router";
import { TableList, TableListMode, TableListSettings } from "@app/table-list/models/table-list";
import { TableListActionButton } from "@app/table-list/models/table-list-action-button";
import { TableListRow } from "@app/table-list/models/table-list-row";
import { ITableListService } from "@app/table-list/services/iservice";
import { ITableListMapperService } from "@app/table-list/services/mapper.service";
import { BehaviorSubject, Observable } from "rxjs";
import { DatatableFilters } from "./DatatableFilters";

export class AppDatatable implements TableList {
	settingsSubject: BehaviorSubject<DatatableSettings>;
	settingsObservable: Observable<DatatableSettings>;
	pageRoute?: ActivatedRoute;
	rowsSubject: BehaviorSubject<TableListRow[]>;
	rowsObservable: Observable<TableListRow[]>;
	totalItemsSubject: BehaviorSubject<number>;
	totalItemsObservable: Observable<number>;
	filtersSubject: BehaviorSubject<DatatableFilters>;
	filtersObservable: Observable<DatatableFilters>;
	loadingSubject: BehaviorSubject<boolean>;
	loadingObservable: Observable<boolean>;
	service: ITableListService;
	mapper: ITableListMapperService;
}

export class DatatableSettings implements TableListSettings {
	constructor() {
		this.showAdd = false;
		this.actionButtons = [];
		this.rolesAllowedToAdd = [];
		this.showInviteEmployeeButton = false;
		this.showSyncButton = false;
		this.rolesAllowedToInviteEmployee = [];
		this.rolesAllowedToSync = [];
		this.messages = {
			titleMessage: '',
			emptyMessage: '',
			totalMessage: '',
			selectedMessage: '',
			searchboxPlaceholder: ''
		}

		this.showAddInModal = true;
		this.showDetailsInModal = false;
		this.showUpdateInModal = true;
		this.showDeleteInModal = true;

		this.identifier = crypto.randomUUID();
	}
	showAddInModal: boolean;
	showDetailsInModal: boolean;
	showUpdateInModal: boolean;
	showDeleteInModal: boolean;
	showSearch: boolean;
	mode: TableListMode;
	basePath: string;
	identifier: string;
	icon?: string;
	messages?: {
		titleMessage: string;
		emptyMessage: string;
		totalMessage: string;
		selectedMessage: string;
		searchboxPlaceholder: string;
	};
	actionButtons?: TableListActionButton[];

	showAdd?: boolean;
	rolesAllowedToAdd?: string[];

	showInviteEmployeeButton?: boolean;
	showSyncButton?: boolean;
	rolesAllowedToInviteEmployee?: string[];
	rolesAllowedToSync?: string[];
}
