import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { HttpClient } from '@angular/common/http';
import { BehaviorSubject, Observable, of } from 'rxjs';
import { catchError, map, tap } from 'rxjs/operators';
import { environment } from '@environments/environment';
import { TranslateService } from '@ngx-translate/core';
import { ValidationErrors, AbstractControl } from '@angular/forms';
import { IUser } from '@app/01.global/interfaces/IUser';
import { IApiResponse } from '../interfaces/helpers/iResponseWrapper';
import { RoleEnum } from '@app/01.global/helpers/role-enum';
import { IResetPassword } from '@app/01.global/interfaces/helpers/iResetPassword';
import '@app/01.global/helpers/observable-extensions';
import { RouteList } from '../helpers/route-enum';
import {
	GoogleLoginProvider,
	SocialAuthService,
	SocialUser,
} from '@abacritt/angularx-social-login';

@Injectable({ providedIn: 'root' })
export class AuthenticationService {
	private userSubject: BehaviorSubject<IUser> = new BehaviorSubject<IUser>(
		null
	);
	public user: Observable<IUser> = this.userSubject.asObservable();
	private tokenSubject: BehaviorSubject<string> = new BehaviorSubject<string>(
		null
	);
	private rolesSubject: BehaviorSubject<string[]> = new BehaviorSubject<
		string[]
	>(null);
	public roles: Observable<string[]> = this.rolesSubject.asObservable();
	private storageUserKey = 'currentIUser';
	private storageTokenKey = 'token';
	private storageRolesKey = 'roles';
	apiBaseUrl = environment.ApiBaseURL;

	isProvider: boolean;
	isEmployee: boolean;
	isAdmin: boolean;
	isDoctor: boolean;
	isTherapist: boolean;
	isGuest: boolean;

	constructor(
		private router: Router,
		private http: HttpClient,
		private translate: TranslateService,
		private socialAuthService: SocialAuthService
	) {
		// this.readLocalStorage();
		// if (this.tokenValue) {
		// 	this.startRefreshTokenTimer();
		// }
	}

	private readLocalStorage() {
		try {
			var storageIUser = JSON.parse(
				localStorage.getItem(this.storageUserKey)
			);
			var storageToken = JSON.parse(
				localStorage.getItem(this.storageTokenKey)
			);
			var storageRoles = JSON.parse(
				localStorage.getItem(this.storageRolesKey)
			);
			if (storageIUser && storageToken && storageRoles) {
				this.rolesSubject.next(storageRoles as string[]);
				this.tokenSubject.next(storageToken as string);
				this.userSubject.next(storageIUser as IUser);

				this.isProvider = this.hasRole(RoleEnum.Provider);
				this.isEmployee = this.hasRole(RoleEnum.Employee);
				this.isAdmin = this.hasRole(RoleEnum.Admin);
				this.isDoctor = this.hasRole(RoleEnum.Doctor);
				this.isTherapist = this.hasRole(RoleEnum.Therapist);
				this.isGuest = this.hasRole(RoleEnum.Guest);

				console.info(
					'Current user: ' +
					this.userValue.displayName +
					' (' +
					this.userValue.email +
					')'
				);
			}
		} catch (error) {
			console.error(error);
			// if parsing local storage fails, execution continues as if it was empty
		}
	}

	public get userValue(): IUser {
		return this.userSubject.getValue();
	}

	public get tokenValue(): string {
		return this.tokenSubject.getValue();
	}

	public get rolesValue(): string[] {
		return this.rolesSubject.getValue();
	}

	login(email: string, password: string) {
		return this.http
			.post<IApiResponse>(
				`${this.apiBaseUrl}/users/authenticate`,
				{ email, password },
				{ withCredentials: true }
			)
			.pipe(
				map((response) => {
					const user = response.result as IUser;
					this.updateCurrentToken(user.jwtToken);
					this.updateCurrentRoles(user.roles);
					this.updateCurrentUser(user);
					// this.startRefreshTokenTimer();
					return user;
				})
			);
	}

	// refreshToken() {
	// 	return this.http
	// 		.post<IApiResponse>(
	// 			`${this.apiBaseUrl}/users/refresh-token`,
	// 			{},
	// 			{ withCredentials: true }
	// 		)
	// 		.pipe(
	// 			map((response) => {
	// 				// this.stopRefreshTokenTimer();
	// 				const user = response.result as IUser;
	// 				this.updateCurrentUser(user);
	// 				this.updateCurrentToken(user.jwtToken);
	// 				this.updateCurrentRoles(user.roles);
	// 				// this.startRefreshTokenTimer();
	// 				return user;
	// 			})
	// 		);
	// }

	// helper methods

	// private refreshTokenTimeout;

	// private startRefreshTokenTimer() {
	// 	// parse json object from base64 encoded jwt token
	// 	const jwtToken = JSON.parse(atob(this.tokenValue.split('.')[1]));

	// 	// set a timeout to refresh the token a minute before it expires
	// 	const expires = new Date(jwtToken.exp * 1000).getTime();
	// 	const timeout = expires - Date.now() - 60000;

	// 	this.refreshTokenTimeout = setTimeout(
	// 		() => this.refreshToken().subscribe(),
	// 		timeout
	// 	);
	// }

	// private stopRefreshTokenTimer() {
	// 	clearTimeout(this.refreshTokenTimeout);
	// }

	private updateCurrentToken(token: string) {
		try {
			localStorage.setItem(this.storageTokenKey, JSON.stringify(token));
			this.tokenSubject.next(token);
		} catch (error) {
			throw error;
		}
	}

	private updateCurrentRoles(roles: string[]) {
		try {
			localStorage.setItem(this.storageRolesKey, JSON.stringify(roles));
			this.rolesSubject.next(roles);

			this.isProvider = this.hasRole(RoleEnum.Provider);
			this.isEmployee = this.hasRole(RoleEnum.Employee);
			this.isAdmin = this.hasRole(RoleEnum.Admin);
			this.isDoctor = this.hasRole(RoleEnum.Doctor);
			this.isTherapist = this.hasRole(RoleEnum.Therapist);
			this.isGuest = this.hasRole(RoleEnum.Guest);
		} catch (error) {
			throw error;
		}
	}

	public updateAppearAsOffline(isActive: boolean) {
		var user = this.userValue;
		user.appearOffline = isActive;
		this.updateCurrentUser(user);
	}

	public updateCurrentUser(user: IUser) {
		try {
			localStorage.setItem(this.storageUserKey, JSON.stringify(user));
			this.userSubject.next(user);
		} catch (error) {
			throw error;
		}
	}

	externalLoginWithGoogle() {
		return this.socialAuthService.signIn(GoogleLoginProvider.PROVIDER_ID);
	}

	internalLoginWithGoogle(res: SocialUser): Observable<IUser> {
		return this.http
			.post<IApiResponse>(
				`${this.apiBaseUrl}/users/authenticateWithGoogle`,
				{ loginProvider: res.provider, token: res.idToken },
				{ withCredentials: true }
			)
			.pipe(
				map((response) => {
					const user = response.result as IUser;
					this.updateCurrentUser(user);
					this.updateCurrentToken(user.jwtToken);
					this.updateCurrentRoles(user.roles);
					// this.startRefreshTokenTimer();
					return user;
				})
			);
	}

	logout(): Observable<IApiResponse> {
		return this.http
			.post<IApiResponse>(
				`${this.apiBaseUrl}/users/logout`,
				{},
				{ withCredentials: true }
			)
			.pipe(
				tap(() => {
					this.clearLocalStorage();
					this.socialAuthService.authState.subscribe(
						(su: SocialUser) => {
							if (su) {
								this.socialAuthService.signOut(true);
							}
						}
					);
					this.userSubject.next(null);

					this.isProvider = false;
					this.isEmployee = false;
					this.isAdmin = false;
					this.isDoctor = false;
					this.isTherapist = false;
					this.isGuest = false;
				})
			);
	}

	getEnvironmentByRole(role: string) {
		if (role === RoleEnum.Doctor) {
			return environment.ApiBaseURL;
		}
		if (role === RoleEnum.Employee) {
			return environment.ApiBaseURL;
		} else if (role === RoleEnum.Provider) {
			return environment.ApiBaseURL;
		} else if (role === RoleEnum.Guest) {
			return environment.ApiBaseURL;
		}
	}

	private hasRole(role: string) {
		return this.rolesValue?.indexOf(role) >= 0;
	}

	hasSomeRole(...roles: string[]) {
		return this.rolesValue?.some((r) => roles.indexOf(r) >= 0);
	}

	setLanguage() {
		this.translate.setDefaultLang('pt');
		this.readLocalStorage();
		if (this.userValue) {
			this.fileExists(this.userValue.language?.code).subscribe(
				(response) => {
					if (response) {
						this.translate.use(this.userValue.language?.code);
					} else {
						this.fileExists(
							this.translate.getBrowserLang()
						).subscribe((responseBrowser) => {
							if (responseBrowser) {
								this.translate.setDefaultLang(
									this.translate.getBrowserLang()
								);
							} else {
								this.translate.setDefaultLang('en');
							}
						});
					}

					console.info(
						'i18n configured for:',
						this.translate.defaultLang
					);
				}
			);
		} else {
			this.fileExists(this.translate.getBrowserLang()).subscribe(
				(responseBrowser) => {
					if (responseBrowser) {
						this.translate.setDefaultLang(
							this.translate.getBrowserLang()
						);
					} else {
						this.translate.setDefaultLang('en');
					}
					console.info(
						'i18n configured for:',
						this.translate.defaultLang
					);
				}
			);
		}

		console.info(
			'Browser culture language:',
			this.translate.getBrowserCultureLang()
		);
	}

	fileExists(lang: string): Observable<boolean> {
		const path = `${environment.baseUrl}/assets/i18n/` + lang + `.json`;
		return this.http.get(path).pipe(
			map((response) => {
				return true;
			}),
			catchError((error) => {
				return of(false);
			})
		);
	}

	forgotPassword(email: string) {
		return this.http
			.post<any>(
				`${this.apiBaseUrl}/users/ForgotPassword`,
				{ email: email },
				{ withCredentials: true }
			)
			.pipe(
				map((response) => {
					this.clearLocalStorage();
				})
			);
	}

	clearLocalStorage() {
		if (this.userSubject.getValue() != null) {
			this.userSubject.next(null);
		}
		this.tokenSubject.next(null);
		this.rolesSubject.next(null);
		localStorage.removeItem(this.storageUserKey);
		localStorage.removeItem(this.storageTokenKey);
		localStorage.removeItem(this.storageRolesKey);

		this.isProvider = false;
		this.isEmployee = false;
		this.isAdmin = false;
		this.isDoctor = false;
		this.isTherapist = false;
		this.isGuest = false;
	}

	resetPassword(model: IResetPassword) {
		return this.http
			.post<any>(
				`${this.apiBaseUrl}/users/resetPassword`,
				{ model },
				{ withCredentials: true }
			)
			.pipe(
				map((response) => {
					this.router.navigate([RouteList.login]);
				})
			);
	}

	matchValues(
		matchTo: string // name of the control to match to
	): (AbstractControl) => ValidationErrors | null {
		return (control: AbstractControl): ValidationErrors | null => {
			return !!control.parent &&
				!!control.parent.value &&
				control.value === control.parent.controls[matchTo].value
				? null
				: { isMatching: false };
		};
	}

	register(user: IUser) {
		return this.http
			.post<IApiResponse>(`${this.apiBaseUrl}/users/create`, user)
			.ToApiResult();
	}

	update(id: number, user: IUser): Observable<IUser> {
		return this.http
			.put<IApiResponse>(`${this.apiBaseUrl}/users/${id}`, user)
			.pipe(tap((x) => this.updateCurrentUser(x.result as IUser)))
			.ToApiResult();
	}

	setDefaultCardId(cardId: number) {
		var user = this.userValue;
		user.defaultCardId = cardId;
		this.updateCurrentUser(user);
	}

	createPhoneNumberVerificationToken() {
		return this.http
			.put<IApiResponse>(
				`${this.apiBaseUrl}/users/createPhoneNumberVerificationToken`,
				{}
			)
			.ToApiResult();
	}

	confirmPhoneNumber(token: string) {
		return this.http
			.post<IApiResponse>(
				`${this.apiBaseUrl}/users/confirmPhoneNumber`,
				{
					token,
				},
				{ withCredentials: true }
			)
			.pipe(
				map((response) => {
					const user = response.result as IUser;
					this.updateCurrentUser(user);
					return user;
				})
			);
	}

	confirmEmail(id: number, token: string)
	{
		const result = this.http
		.post<IApiResponse>(
			`${this.apiBaseUrl}/users/confirmEmail/${id}/${token}`,
			{}
		)
		.ToApiResult();

		return result;
	}
}
