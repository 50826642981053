<div class="files-container h-100 o-hidden" [perfectScrollbar]="{ suppressScrollX: true }" ngxInfiniteScroller
	strategy="scrollingToTop" (onScrollUp)="onScroll()">
	<div class="timeline-wrapper">
		<span *ngIf="loadingUp" class="loader-bubble loader-bubble-info ml-4 mt-n2"></span>
		<ul class="timeline clearfix">
			<li class="timeline-line"></li>
			<li class="timeline-item" *ngFor="let item of documents$ | async" style="margin-top: -5rem; width: 51%">
				<div class="timeline-badge">
					<i class="badge-icon text-white i-File-Cloud bg-info"></i>
				</div>
				<div class="timeline-card card mx-2">
					<div class="card-body">
						<div class="card-body text-center">
							<div class="avatar box-shadow-2 mb-3 p-2 d-inline-block" *ngIf="isUrlImage(item.url)">
								<img [src]="item.url" alt="{{ item.name }}" style="height: 15rem" />
							</div>
							<h5 class="m-0">{{ item.name }}</h5>
							<p class="mt-0">{{ item.description }}</p>
							<p>
								{{ item.createdDate | localizedDate: 'full' }}
							</p>
							<a href="{{ item.url }}" target="_blank" class="btn btn-primary btn-rounded text-white"
								data-toggle="tooltip" data-placement="bottom" title="{{'Download' | translate}}">
								<i class="i-Download"></i>
								{{ 'Download' | translate }}
							</a>
						</div>
					</div>
				</div>
			</li>
		</ul>
		<ul class="timeline clearfix">
			<li class="timeline-line"></li>
			<li class="timeline-group text-center">
				<span *ngIf="loading" class="loader-bubble loader-bubble-info ml-4 mt-n2"></span>
			</li>
		</ul>
		<ul class="timeline clearfix">
			<li class="timeline-line"></li>
			<li class="timeline-group text-center">
				<button class="btn btn-icon-text btn-warning">
					<i class="i-Calendar-4"></i> {{ userJoinDate | localizedDate: 'yyyy' }}
				</button>
			</li>
		</ul>
	</div>
</div>