<div class="card card-profile-image">
	<div class="card-img-top">
		<table-list-thumbnail [source]="thumb.value ?? './assets/images/default-user.png'">
		</table-list-thumbnail>
	</div>
	<div class="card-body py-0" style="height: 22rem;">
		<div class="row no-gutters" style="height: 18rem;">
			<div class="col">
				<ul ngbNav #nav="ngbNav" [activeId]="1" class="nav-tabs justify-content-center"
					style="min-height: 3.5rem;">
					<li [ngbNavItem]="1">
						<a ngbNavLink>
							<h5 class="mb-0" *ngIf="title.value">
								<i class="{{tableSettings.icon}}"></i>
								{{title.value}}
							</h5>
						</a>
						<ng-template ngbNavContent>
							<div *ngFor="let col of firstTabInfoColumns">
								<p class="text-muted mb-1"
									*ngIf="col.displayMode == TableListColumnMode.text && col.value">
									<strong>{{col.name}}:</strong>&nbsp;{{col.value}}
								</p>
								<p class="text-muted mb-1"
									*ngIf="col.displayMode == TableListColumnMode.date && col.value">
									<strong>{{col.name}}:</strong>&nbsp;{{col.text}
								</p>
								<div *ngIf="col.displayMode == TableListColumnMode.checkbox">
									<div class="form-check">
										<input class="form-check-input" type="checkbox" id="{{col.name}}"
											checked="{{col.value}}" disabled>
										<label class="form-check-label" for="{{col.name}}">
											{{col.name}}
										</label>
									</div>
								</div>
								<div *ngIf="col.displayMode == TableListColumnMode.list">
									<ul class="list-group list-group-flush">
										<li class="list-group-item" *ngFor="let item of col.value">
											{{item.text}}
										</li>
									</ul>
								</div>
								<div *ngIf="col.displayMode == TableListColumnMode.checkboxSwitch">
									<div class="ml-3 form-check form-switch d-inline">
										<input class="form-check-input" type="checkbox" role="switch" id="{{col.name}}"
											[checked]=col.value disabled>
									</div>
									<label class="form-check-label" for="{{col.name}}">{{col.name}}</label>
								</div>
							</div>
						</ng-template>
					</li>
					<li *ngFor="let htmlCol of htmlColumns; let i = index" [ngbNavItem]="i + 2">
						<a ngbNavLink>{{htmlCol.name}}</a>
						<ng-template ngbNavContent>
							<div [innerHtml]="htmlCol.value"></div>
						</ng-template>
					</li>
				</ul>
				<div [ngbNavOutlet]="nav" style="height: 12.5rem; overflow: hidden;"></div>
			</div>
		</div>
		<div class="row no-gutters border-top pt-3" style="height: 4rem;">
			<div class="col text-center">
				<ng-container *ngFor="let btn of row.actionButtons">
					<table-list-btn [btn]="btn" [rowId]="row.id" [tableIdentifier]="tableIdentifier">
					</table-list-btn>
				</ng-container>
			</div>
		</div>
	</div>
</div>