import { DatePipe } from '@angular/common';
import { HttpParams } from '@angular/common/http';
import { ITableListService } from '@app/table-list/services/iservice';
import { DatatableFilters } from '../models/DatatableFilters';

export abstract class BaseService extends ITableListService {
	private datePipe: DatePipe = new DatePipe('pt');
	paginationToHttpParams(source: DatatableFilters): HttpParams {
		var params = new HttpParams();

		params = params.append('CurrentPage', source.currentPage);
		params = params.append('PageSize', source.pageSize);
		// if (source.totalItems) {
		params = params.append('TotalItems', source.totalItems);
		// }
		params = params.append(
			'TotalPages',
			source.totalPages ?? (source.totalItems / source.pageSize) >> 0
		);
		params = params.append('SearchTerm', source.searchTerm ?? '');

		if (source.readFromWhoId) {
			params = params.append('readFromWhoId', source.readFromWhoId);
		}
		if (source.specialtyId) {
			params = params.append('SpecialtyId', source.specialtyId);
		}
		if (source.docType) {
			params = params.append('DocType', source.docType);
		}
		if (source.inProgress) {
			params = params.append('InProgress', source.inProgress);
		}
		if (source.availableSlotsOnly) {
			params = params.append(
				'AvailableSlotsOnly',
				source.availableSlotsOnly
			);
		}
		if (source.startMinimumDate) {
			params = params.append(
				'startMinimumDate',
				this.datePipe.transform(
					new Date(
						source.startMinimumDate.getFullYear(),
						source.startMinimumDate.getMonth(),
						source.startMinimumDate.getDate(),
						source.startMinimumDate.getHours(),
						source.startMinimumDate.getMinutes(),
						source.startMinimumDate.getSeconds()
					)?.toString(),
					'MM-dd-yyyy HH:mm:ss'
				)
			);
		}
		if (source.startDate) {
			params = params.append(
				'StartDate',
				this.datePipe.transform(
					new Date(
						source.startDate.getFullYear(),
						source.startDate.getMonth(),
						source.startDate.getDate(),
						source.startDate.getHours(),
						source.startDate.getMinutes(),
						source.startDate.getSeconds()
					)?.toString(),
					'MM-dd-yyyy HH:mm:ss'
				)
			);
		}
		if (source.startMaximumDate) {
			params = params.append(
				'startMaximumDate',
				this.datePipe.transform(
					new Date(
						source.startMaximumDate.getFullYear(),
						source.startMaximumDate.getMonth(),
						source.startMaximumDate.getDate(),
						source.startMaximumDate.getHours(),
						source.startMaximumDate.getMinutes(),
						source.startMaximumDate.getSeconds()
					)?.toString(),
					'MM-dd-yyyy HH:mm:ss'
				)
			);
		}
		if (source.endDate) {
			params = params.append(
				'EndDate',
				this.datePipe.transform(
					new Date(
						source.endDate.getFullYear(),
						source.endDate.getMonth(),
						source.endDate.getDate(),
						source.endDate.getHours(),
						source.endDate.getMinutes(),
						source.endDate.getSeconds()
					)?.toString(),
					'MM-dd-yyyy HH:mm:ss'
				)
			);
		}
		if (source.recieved) {
			params = params.append('Recieved', source.recieved);
		}
		if (source.requireAction) {
			params = params.append('RequireAction', source.requireAction);
		}
		if (source.sent) {
			params = params.append('Sent', source.sent);
		}
		if (source.trash) {
			params = params.append('Trash', source.trash);
		}
		if (source.appointmentStatusId) {
			params = params.append(
				'AppointmentStatusId',
				source.appointmentStatusId
			);
		}
		if (source.onlyWithMessages) {
			params = params.append('onlyWithMessages', source.onlyWithMessages);
		}
		if (source.onlyActive) {
			params = params.append('onlyActive', source.onlyActive);
		}
		if (source.onlyArchived) {
			params = params.append('onlyArchived', source.onlyArchived);
		}
		if (source.providersOnly) {
			params = params.append('providersOnly', source.providersOnly);
		}
		if (source.branchesOnly) {
			params = params.append('branchesOnly', source.branchesOnly);
		}
		if (source.professionalsOnly) {
			params = params.append(
				'professionalsOnly',
				source.professionalsOnly
			);
		}

		if (source.hasPermanentService) {
			params = params.append(
				'hasPermanentService',
				source.hasPermanentService
			);
		}
		if (source.consultationTypeId) {
			params = params.append(
				'consultationTypeId',
				source.consultationTypeId
			);
		}
		if (source.employeeAccountId) {
			params = params.append(
				'employeeAccountId',
				source.employeeAccountId
			);
		}
		if (source.providerAccountId) {
			params = params.append(
				'providerAccountId',
				source.providerAccountId
			);
		}
		if (source.channelId) {
			params = params.append('channelId', source.channelId);
		}
		if (source.specialtyId) {
			params = params.append('specialtyId', source.specialtyId);
		}

		return params;
	}
}
