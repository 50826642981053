import { Component, Input, OnInit } from '@angular/core';

@Component({
	selector: 'app-card-overlay',
	templateUrl: './card-overlay.component.html',
	styleUrls: ['./card-overlay.component.scss']
})
export class CardOverlayComponent implements OnInit {
	constructor() { }
	@Input('photoUrl') photoUrl: string = './assets/images/default-user.png';
	@Input('bgClass') bgClass: string = 'bg-dark';
	@Input() cardHeight;

	ngOnInit(): void {
	}

}
