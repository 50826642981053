import { Component, Input, OnInit } from '@angular/core';
import { IDocument } from '@app/01.global/interfaces/IDocument';
import { AuthenticationService } from '@app/01.global/services/authentication.service';
import { AttendanceStatusService } from '@app/01.global/status-services/attendance-status.service';
import { Observable } from 'rxjs';

@Component({
	selector: 'app-attendance-documents',
	templateUrl: './attendance-documents.component.html',
	styleUrls: ['./attendance-documents.component.scss'],
})
export class AttendanceDocumentsComponent implements OnInit {
	@Input() channelId: number;

	loading: boolean = false;
	loadingUp: boolean = false;
	documents$: Observable<IDocument[]>;
	userJoinDate: Date;

	constructor(
		private statusService: AttendanceStatusService,
		private authService: AuthenticationService
	) {}

	ngOnInit() {
		this.documents$ = this.statusService.getChannelDocuments$(
			this.channelId
		);
		this.userJoinDate = this.authService.userValue?.createdDate;
	}

	onScroll() {
		this.loadingUp = true;
		this.statusService.loadMoreDocuments(this.channelId);
	}

	isUrlImage(url: string) {
		if (
			url.endsWith('.gif') ||
			url.endsWith('.jpeg') ||
			url.endsWith('.png') ||
			url.endsWith('.jpg')
		) {
			return true;
		}
	}
}
