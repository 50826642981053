import {
	ChangeDetectorRef,
	Component,
	EventEmitter,
	Input,
	Output,
	TemplateRef,
	ViewChild,
} from '@angular/core';
import { UntypedFormControl } from '@angular/forms';
import { ControlTypeEnum } from '@app/01.global/helpers/control-enum';
import { DocTypesEnum } from '@app/01.global/helpers/doc-types-enum';
import { IDocument } from '@app/01.global/interfaces/IDocument';
import { ISelectListItem } from '@app/01.global/interfaces/helpers/iSelectListItem';
import { DocumentsService } from '@app/01.global/services/documents.service';
import { NgbModal, NgbTypeahead } from '@ng-bootstrap/ng-bootstrap';
import {
	debounceTime,
	distinctUntilChanged,
	filter,
	forkJoin,
	map,
	merge,
	Observable,
	OperatorFunction,
	Subject,
	switchMap,
} from 'rxjs';
import { CropperModalComponent } from './cropper-modal/cropper-modal.component';
import { TranslateService } from '@ngx-translate/core';

/*
Dates processing
	const startDate = Utils.dateToNgbDate(this.row.source.startDate);
		const startTime = Utils.dateToNgbTime(this.row.source.startDate);

		const endDate = Utils.dateToNgbDate(this.row.source.endDate);
		const endTime = Utils.dateToNgbTime(this.row.source.endDate);

		this.form.get('startDate').setValue(startDate);
		this.form.get('endDate').setValue(endDate);
		this.form.get('startTime').setValue(startTime);
		this.form.get('endTime').setValue(endTime);


*/

@Component({
	selector: 'app-form-control',
	templateUrl: './form-control.component.html',
	styleUrls: ['./form-control.component.scss'],
})
export class CustomFormControlComponent {
	@Input() type: ControlTypeEnum = ControlTypeEnum.Text;
	@Input() documentType: DocTypesEnum = DocTypesEnum.ProfilePhoto;
	private _control = new UntypedFormControl();
	@Input() get control(): UntypedFormControl {
		return this._control;
	}
	set control(v) {
		this._control = v;
		this.ref.detectChanges();
	}
	@Input() hasInvalidOption:boolean;
	@Input() name: string;
	@Input() label: string;
	@Input() value: string;
	@Input() submitted: boolean = false;
	@Input() options: ISelectListItem[] = [];
	@Input() identifyBy: string = 'value';
	@Input() displayBy: string = 'text';
	@Input() removable: boolean = true;
	@Input() disable: boolean = false;
	@Input() displayMode: 'vertical' | 'horizontal' = 'vertical';
	@Input() themeClass:
		| 'primary'
		| 'secondary'
		| 'info'
		| 'warning'
		| 'success'
		| 'danger'
		| 'dark'
		| 'light' = 'primary';
	@Input() placeholder: string = '';
	@Input() secondaryPlaceholder: string = '';
	@Input() maxFiles: number = 1;
	@Input() isEdit = false;
	@Input() rows = 4;
	@Input() step = 1;
	@Input() min = 0;
	@Input() max = 0;
	@Input() showErrorMessages = true;
	@Input() showLabel = true;
	@Input() rounded = false;
	@Input() onlyFromAutocomplete = true;
	@Input('size') size: 'default' | 'lg' | 'sm' = 'default';
	@Input() deleteOnRemove = true;

	@Input('serviceReadMethod') serviceReadMethod: () => Observable<
		{ id: number; displayText?: string; name?: string; title?: string }[]
	>;
	@Input('formatterTypeahead') formatterTypeahead: (model: any) => string = (
		model: any
	) => model.text as string;
	@Input('typeaheadResultTemplate') typeaheadResultTemplate: TemplateRef<any>;

	@Output('itemAdded') itemAdded = new EventEmitter<ISelectListItem>();
	@Output('itemRemoved') itemRemoved = new EventEmitter<ISelectListItem>();
	@Output('textChange') textChange = new EventEmitter<ISelectListItem>();
	@Output('typeaheadSearchChange') typeaheadSearchChange =
		new EventEmitter<string>();

	ControlTypeEnum = ControlTypeEnum;
	loading = false;
	currentYear: number = new Date().getFullYear();

	get useImageCropper(): boolean {
		switch (this.documentType) {
			case DocTypesEnum.ProfilePhoto:
			case DocTypesEnum.MedicalOfficeLogo:
			case DocTypesEnum.AgreementLogo:
			case DocTypesEnum.ChannelLogo:
			case DocTypesEnum.AgreementCardImage:
				return true;
			default:
				return false;
		}
	}

	get fileInputAccepts(): string {
		switch (this.documentType) {
			case DocTypesEnum.ProfilePhoto:
			case DocTypesEnum.MedicalOfficeLogo:
			case DocTypesEnum.AgreementLogo:
			case DocTypesEnum.ChannelLogo:
				return 'image/*';
			default:
				return '*';
		}
	}

	@ViewChild('typeaheadControl', { static: true })
	typeaheadControl: NgbTypeahead;

	focusTypeahead$ = new Subject<string>();
	clickTypeahead$ = new Subject<string>();

	constructor(
		private documentService: DocumentsService,
		private modalService: NgbModal,
		private ref: ChangeDetectorRef,
		private translateService: TranslateService
	) { }

	capitalize(s: string) {
		if (s) {
			return s[0]?.toUpperCase() + s?.slice(1);
		}

		return '';
	}

	usesFormGroupClass() {
		return (
			this.type != ControlTypeEnum.Checkbox &&
			this.type != ControlTypeEnum.CheckboxOutline &&
			this.type != ControlTypeEnum.CheckboxSwitch &&
			this.type != ControlTypeEnum.Radio &&
			this.type != ControlTypeEnum.RadioOutline
		);
	}

	setCheckboxClass() {
		switch (this.type) {
			case ControlTypeEnum.Checkbox:
				return `checkbox checkbox-${this.themeClass}`;
			case ControlTypeEnum.CheckboxOutline:
				return `checkbox checkbox-outline-${this.themeClass}`;
			case ControlTypeEnum.CheckboxSwitch:
				return `switch switch-${this.themeClass}`;
			case ControlTypeEnum.Radio:
				return `radio radio-${this.themeClass}`;
			case ControlTypeEnum.RadioOutline:
				return `radio radio-outline-${this.themeClass}`;
			default:
				return '';
		}
	}

	setRowClass() {
		return this.displayMode == 'horizontal' ? 'row' : '';
	}

	setLabelColClass() {
		return this.displayMode == 'horizontal' && this.showLabel
			? 'col-sm-3 col-form-label'
			: '';
	}

	setInputColClass() {
		return this.displayMode == 'horizontal' && this.showLabel
			? 'col-sm-9'
			: '';
	}

	setInvalidClass() {
		return this.control?.errors &&
			(this.control.dirty || this.control.touched || this.submitted)
			? 'is-invalid'
			: '';
	}

	onItemAdded(event) {
		this.itemAdded.emit(event);
	}
	onItemRemoved(event) {
		this.itemRemoved.emit(event);
	}
	onTextChange(event) {
		this.textChange.emit(event);
	}

	onSelectFiles(event) {
		if (this.maxFiles == 1 && this.control.value) {
			return;
		}

		var currentFiles: IDocument[] = !this.control.value
			? ([] as IDocument[])
			: (this.control.value as IDocument[]);
		if (currentFiles.length == this.maxFiles) {
			return;
		}

		if (this.useImageCropper) {
			var file = event.addedFiles[0];
			const modalRef = this.modalService.open(CropperModalComponent);
			modalRef.componentInstance.imageFile = file;
			modalRef.componentInstance.documentType = this.documentType;

			modalRef.result.then(
				(data) => {
					fetch(data)
						.then((res) => res.blob())
						.then((blob) => {
							const newFile = new File([blob], file.name, {
								type: file.type,
							});
							this.uploadFiles(currentFiles, [newFile]);
						});
				},
				(reason) => { }
			);

			return;
		}

		this.uploadFiles(currentFiles, event.addedFiles);
	}

	onRemove(doc: IDocument) {
		this.loading = true;
		if (this.deleteOnRemove) {
			this.documentService.delete(doc.id).subscribe({
				next: () => {
					var newValue =
						this.maxFiles == 1
							? null
							: this.control.value.filter((d) => d.id != doc.id);
					this.control.setValue(newValue);
					this.loading = false;
				},
				error: () => {
					this.loading = false;
				},
			});
		} else {
			var newValue =
				this.maxFiles == 1
					? null
					: this.control.value.filter((d) => d.id != doc.id);
			this.control.setValue(newValue);
			this.loading = false;
		}
	}

	uploadFiles(currentFiles: IDocument[], addedFiles: File[]) {
		this.loading = true;

		var files = addedFiles.slice(0, this.maxFiles - currentFiles.length);

		var httpRequests: Observable<IDocument>[] = [];
		files.forEach((file) => {
			httpRequests.push(
				this.documentService.create(file, this.documentType)
			);
		});

		forkJoin(httpRequests).subscribe({
			next: (docs: IDocument[]) => {
				var concatDocs: IDocument[] = currentFiles.concat(docs);
				this.control.setValue(
					this.maxFiles > 1 ? concatDocs : concatDocs[0]
				);
				this.loading = false;
			},
			error: () => {
				this.loading = false;
			},
		});
	}

	typeaheadSearch: OperatorFunction<
		string,
		readonly {
			id: number;
			displayText?: string;
			name?: string;
			title?: string;
		}[]
	> = (text$: Observable<string>) => {
		const debouncedText$ = text$.pipe(
			debounceTime(200),
			distinctUntilChanged()
		);
		const clicksWithClosedPopup$ = this.clickTypeahead$.pipe(
			filter(() => !this.typeaheadControl?.isPopupOpen())
		);
		const inputFocus$ = this.focusTypeahead$;
		return merge(debouncedText$, inputFocus$, clicksWithClosedPopup$).pipe(
			switchMap((term: string) => {
				this.typeaheadSearchChange.emit(term);
				return this.serviceReadMethod().pipe(
					map((arr) => {
						arr.forEach((element) => {
							if (element.displayText) {
								element.displayText = this.translateService.instant(
									element.displayText
								);
							}
							if (element.name) {
								element.name = this.translateService.instant(
									element.name
								);
							}
							if (element.title) {
								element.title = this.translateService.instant(
									element.title
								);
							}
						});

						return arr;
					})
				);
			})
		);
	};

	getSize(): string {
		switch (this.size) {
			case 'lg':
				return 'form-control-lg';
			case 'sm':
				return 'form-control-sm';
			default:
				return '';
		}
	}
}
