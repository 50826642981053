import { Pipe, PipeTransform } from '@angular/core';

@Pipe({ name: 'wordExcerpt' })
export class WordExcerptPipe implements PipeTransform {
	transform(text: string, limit: number = 100) {
		if (text.length <= limit) {
			return text;
		}

		var split = text.split(', ');
		var final = split.shift();
		while (final.length < limit) {
			final += ', ' + split.shift();
		}
		return `${final} (+${split.length})`;
	}
}
