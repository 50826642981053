import {
	Component,
	ElementRef,
	EventEmitter,
	Input,
	OnInit,
	Output,
	ViewChild,
} from '@angular/core';
import { IAddress } from '@app/01.global/interfaces/iAddress';
import { MapService } from '@app/01.global/services/map.service';

@Component({
	selector: 'app-address-picker',
	templateUrl: './address-picker.component.html',
	styleUrls: ['./address-picker.component.scss'],
})
export class AddressPickerComponent implements OnInit {
	@Input() showLabel: boolean;
	@Input() center: google.maps.LatLngLiteral;
	@Output('onPlaceSelected') onPlaceSelected: EventEmitter<IAddress> =
		new EventEmitter<IAddress>();

	apiLoaded: boolean;
	autocomplete: google.maps.places.Autocomplete;

	@ViewChild('pacInput') pacInput: ElementRef<HTMLInputElement>;

	constructor(private service: MapService) {}

	ngOnInit(): void {
		this.service.loadGoogleApiWithAutoComplete().subscribe(() => {
			this.apiLoaded = true;
			this.service.getLocation().then((r) => {
				this.center = r;
				// Create a bounding box with sides ~100km away from the center point
				const defaultBounds = {
					north: this.center.lat + 1,
					south: this.center.lat - 1,
					east: this.center.lng + 1,
					west: this.center.lng - 1,
				};

				const options = {
					bounds: defaultBounds,
					fields: ['address_components', 'geometry', 'icon', 'name'],
					strictBounds: false,
					types: ['geocode', 'establishment'],
				};

				this.autocomplete = new google.maps.places.Autocomplete(
					this.pacInput.nativeElement,
					options
				);
				this.autocomplete.addListener('place_changed', () => {
					const place = this.autocomplete.getPlace();

					var address: IAddress = {
						id: 0,
						name: place.name,
						line1: place.address_components.find((ac) =>
							ac.types.includes('route')
						)?.long_name,
						line2: place.address_components.find((ac) =>
							ac.types.includes('street_number')
						)?.long_name,
						city: place.address_components.find((ac) =>
							ac.types.includes('locality')
						)?.long_name,
						postalCode: place.address_components.find((ac) =>
							ac.types.includes('postal_code')
						)?.long_name,
						district: place.address_components.find((ac) =>
							ac.types.includes('administrative_area_level_1')
						)?.long_name,
						country: place.address_components.find((ac) =>
							ac.types.includes('country')
						)?.long_name,
						latitude: place.geometry.location.lat(),
						longitude: place.geometry.location.lng(),
						north: place.geometry.viewport.getNorthEast().lat(),
						south: place.geometry.viewport.getSouthWest().lat(),
						west: place.geometry.viewport.getSouthWest().lng(),
						east: place.geometry.viewport.getNorthEast().lng(),
					};

					this.onPlaceSelected.emit(address);
				});
			});
		});
	}
}
