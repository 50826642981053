import {
	Component,
	OnInit,
	OnDestroy,
	ViewChild,
	Input,
	Output,
	EventEmitter,
} from '@angular/core';
import {
	UntypedFormBuilder,
	UntypedFormGroup,
	Validators,
} from '@angular/forms';
import { Router } from '@angular/router';
import { SharedAnimations } from '@app/01.global/animations/shared-animations';
import { ControlTypeEnum } from '@app/01.global/helpers/control-enum';
import { RouteList } from '@app/01.global/helpers/route-enum';
import { Utils } from '@app/01.global/helpers/utils';
import { DatatableFilters } from '@app/01.global/models/DatatableFilters';
import { IAppointment } from '@app/01.global/interfaces/IAppointment';
import { IConsultationType } from '@app/01.global/interfaces/iConsultation-type';
import { IUser } from '@app/01.global/interfaces/IUser';
import { AuthenticationService } from '@app/01.global/services/authentication.service';
import { BookingService } from '@app/01.global/services/booking.service';
import { BookingStatusService } from '@app/01.global/status-services/booking-status.service';
import {
	NgbDate,
	NgbDateStruct,
	NgbModal,
	NgbModalRef,
} from '@ng-bootstrap/ng-bootstrap';
import {
	Subscription,
} from 'rxjs';
import { AttendanceStatusService } from '@app/01.global/status-services/attendance-status.service';

@Component({
	selector: 'app-booking-session',
	templateUrl: './booking-session.component.html',
	styleUrls: ['./booking-session.component.scss'],
	animations: [SharedAnimations],
})
export class BookingSessionComponent implements OnInit, OnDestroy {
	RouteList = RouteList;
	ControlTypeEnum = ControlTypeEnum;
	bookingByConsultationTypeForm: UntypedFormGroup;

	filters: DatatableFilters = {
		pageSize: 10,
		totalItems: 0,
		totalPages: 0,
		currentPage: 0,
	};

	dropdownValues: {
		provider?: IUser;
		consultationType?: IConsultationType;
		employee?: IUser;
	} = {};

	subs: Subscription[] = [];
	nextAppointments: IAppointment[];
	daysWithSluts: Date[];
	daysLoaded: { day: NgbDate; apps: IAppointment[] }[] = [];

	@Input('activeChannel') activeChannel: number;

	@Output('onSetSchedule') onSetSchedule = new EventEmitter<string>();

	@ViewChild('confirmBookSessionModal') confirmBookSessionModal: NgbModalRef;

	constructor(
		private formBuilder: UntypedFormBuilder,
		private service: BookingService,
		private statusService: BookingStatusService,
		private authService: AuthenticationService,
		private modalService: NgbModal,
		private attendanceStatusService: AttendanceStatusService
	) { }

	ngOnInit(): void {
		this.buildForm();

		this.subs.push(
			this.bookingByConsultationTypeForm.controls.provider.valueChanges.subscribe(
				(v) => {
					if (v) {
						this.filters.providerAccountId =
							v.providerInfoAccountId;
					} else {
						this.filters.providerAccountId = 0;
					}
					this.dropdownValues.provider = v;
					this.readAvailableData();
				}
			)
		);
		this.subs.push(
			this.bookingByConsultationTypeForm.controls.consultationType.valueChanges.subscribe(
				(v) => {
					if (v) {
						this.filters.consultationTypeId = v.id;
					} else {
						this.filters.consultationTypeId = 0;
					}
					this.dropdownValues.consultationType = v;
					this.readAvailableData();
				}
			)
		);
		this.subs.push(
			this.bookingByConsultationTypeForm.controls.employee.valueChanges.subscribe(
				(v) => {
					if (v) {
						this.filters.employeeAccountId = v.id;
					} else {
						this.filters.employeeAccountId = 0;
					}
					this.dropdownValues.employee = v;
					this.readAvailableData();
				}
			)
		);

		this.subs.push(
			this.statusService.consultationSettingsObs.subscribe(() => { })
		);

		this.bookingByConsultationTypeForm.controls.employee.setValue(
			this.authService.userValue
		);
	}
	ngOnDestroy(): void {
		this.subs.forEach((s) => s?.unsubscribe());
	}

	formatterProvider = (provider: IUser) =>
		provider.providerInfo?.commercialName;
	formatterConsultationType = (ct: IConsultationType) => ct.name;
	formatterEmployee = (emp: IUser) => emp.displayName;

	updateSearchProvider(term: string) {
		this.filters.searchTerm = term;
	}

	searchProvider = () => this.service.readProviders(this.filters);

	updateSearchConsultationType(term: string) {
		this.filters.searchTerm = term;
	}

	searchConsultationType = () =>
		this.service.readConsultationTypes(this.filters);

	updateSearchEmployee(term: string) {
		this.filters.searchTerm = term;
	}

	searchEmployee = () => this.service.readEmployees(this.filters);

	readAvailableData() {
		this.service
			.readNextEmptySlots(this.filters)
			.subscribe((data: IAppointment[]) => {
				this.nextAppointments = data;
			});
		this.service
			.readAvailableDays(this.filters)
			.subscribe((data: Date[]) => {
				this.daysWithSluts = data;
			});
	}

	hasEmptySlots(date: NgbDateStruct): boolean {
		if (this.daysWithSluts) {
			for (var i = 0; i < this.daysWithSluts.length; i++) {
				var taskDate = new Date(this.daysWithSluts[i]);
				var day: number = taskDate.getDate();
				var month: number = taskDate.getMonth() + 1;
				var year: number = taskDate.getFullYear();

				if (
					day === date.day &&
					month === date.month &&
					year === date.year
				) {
					return true;
				}
			}
		}
	}

	chosenDay(date: NgbDate) {
		var dayLoaded = this.daysLoaded.find((d) => d.day == date);
		if (dayLoaded) {
			this.nextAppointments = dayLoaded.apps;
			return;
		}
		this.filters.startDate = Utils.ngbDateToDate(date);

		this.service
			.readNextEmptySlots(this.filters)
			.subscribe((data: IAppointment[]) => {
				this.nextAppointments = data;
				this.daysLoaded.push({ day: date, apps: data });
				this.filters.startDate = null;
			});
	}

	setSchedule(emptySlot: IAppointment) {
		emptySlot.consultationTypeId = this.dropdownValues.consultationType.id;
		emptySlot.channelId = this.activeChannel;
		this.modalService
			.open(this.confirmBookSessionModal, {
				backdrop: false,
				modalDialogClass: 'up-front-modal',
				ariaLabelledBy: 'modal-basic-title',
				centered: true,
			})
			.result.then(
				() => {
					this.service
						.finishMultiple(emptySlot)
						.subscribe(() => {
							this.bookingByConsultationTypeForm.reset();
							this.onSetSchedule.emit('close');
							this.attendanceStatusService.updateSidebarMainLayout();
						});
				},
				() => { }
			);
	}

	submitForm() { }

	private buildForm() {
		this.bookingByConsultationTypeForm = this.formBuilder.group({
			provider: [],
			consultationType: [, [Validators.required]],
			employee: [{ value: '', disabled: true }],
		});
	}
}
