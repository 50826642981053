import {
	Component,
	EventEmitter,
	Input,
	OnInit,
	Output,
	ViewChild,
} from '@angular/core';
import { MapService } from '@app/01.global/services/map.service';
import { MarkerOptions } from '@app/01.global/models/marker-options';

@Component({
	selector: 'app-map',
	templateUrl: './map.component.html',
	styleUrls: ['./map.component.scss'],
})
export class MapComponent implements OnInit {
	@ViewChild('map') map: google.maps.Map;

	@Input() longitude: number;
	@Input() latitude: number;
	@Input() center: google.maps.LatLngLiteral;
	@Input() zoom: number;
	@Input() markers: MarkerOptions[] = [];

	_target: google.maps.LatLngBoundsLiteral;

	@Input() get target(): google.maps.LatLngBoundsLiteral {
		return this._target;
	}

	set target(v: google.maps.LatLngBoundsLiteral) {
		this._target = v;
		setTimeout(() => {
			if (this.map && this.target) {
				this.map.fitBounds(this.target);
			}
		}, 100);
	}

	@Output() onMarkerClicked: EventEmitter<{
		label: string;
		index: number;
	}> = new EventEmitter<{
		label: string;
		index: number;
	}>();
	@Output() onMarkerDragEnd: EventEmitter<{
		marker: google.maps.Marker;
		event: MouseEvent;
	}> = new EventEmitter<{
		marker: google.maps.Marker;
		event: MouseEvent;
	}>();

	apiLoaded: boolean;

	options: google.maps.MapOptions = {
		maxZoom: 20,
		minZoom: 8,
		center: { lat: 38, lng: -9 },
		zoom: 8,
	};

	constructor(
		private service: MapService,
	) { }

	ngOnInit(): void {
		this.service.loadGoogleApi().subscribe(() => {
			this.apiLoaded = true;
			if (!this.target) {
				if (this.longitude && this.latitude) {
					this.center = {
						lat: this.latitude,
						lng: this.longitude,
					};
				} else {
					this.service.getLocation().then((r) => {
						this.center = r;
					});
				}
			}
		});
	}
}
