import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { RouteList } from '@app/01.global/helpers/route-enum';
import { IAppointment } from '@app/01.global/interfaces/IAppointment';
import { AttendanceStatusService } from '@app/01.global/status-services/attendance-status.service';
import { environment } from '@environments/environment';
import { NgbModal, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { TranslateService } from '@ngx-translate/core';
import {
	addMinutes,
	differenceInMinutes,
	differenceInSeconds,
	subMinutes,
} from 'date-fns';
import { ToastrService } from 'ngx-toastr';
import {
	finalize,
	interval,
	Observable,
	Subscription,
	takeWhile,
	tap,
} from 'rxjs';

@Component({
	selector: 'app-main-sidebar-available-attendances',
	templateUrl: './main-sidebar-available-attendances.html',
	styleUrls: ['./main-sidebar-available-attendances.scss'],
})
export class MainSidebarAvailableAttendancesComponent
	implements OnInit, OnDestroy {
	appointments$: Observable<IAppointment[]>;
	private subs: Subscription[] = [];
	@ViewChild('nearAppointmentModal') nearAppointmentModal: NgbModalRef;
	nearAppointmentModalRef: NgbModalRef;
	private previousNearestAppointmentSub: Subscription;
	secondsUntilStart: number = 0;
	patientIsDelayed: boolean = false;
	nearestAppointment: IAppointment;

	constructor(
		private attendanceStatusService: AttendanceStatusService,
		private router: Router,
		private toastrService: ToastrService,
		private translateService: TranslateService,
		private modalService: NgbModal
	) { }

	ngOnInit(): void {
		this.appointments$ = this.attendanceStatusService.rooms$;

		this.subs.push(
			this.attendanceStatusService.rooms$.subscribe(
				(appointments: IAppointment[]) => {
					if (!appointments || appointments.length == 0) {
						return;
					}

					var now = new Date();
					this.nearestAppointment = appointments.find(
						(a) =>
							//IF Patient is delayed, FInd an appointment that
							//started until 1 "duration" ago
							new Date(a.startDate) >
							subMinutes(
								now,
								a.medicalOffice.appointmentDuration
									.appointmentMinutesDuration
							) &&
							//and ends until 1 "duration" after now
							new Date(a.endDate) <
							addMinutes(
								now,
								a.medicalOffice.appointmentDuration
									.appointmentMinutesDuration
							)
					);
					if (!this.nearestAppointment) {
						//Patient is not delayed yet
						this.nearestAppointment = appointments.find(
							(a) => new Date(a.startDate) > now
						);
					}

					if (this.nearestAppointment) {
						this.previousNearestAppointmentSub?.unsubscribe();
						this.previousNearestAppointmentSub = interval(1000)
							.pipe(
								takeWhile(
									() =>
										differenceInMinutes(
											new Date(
												this.nearestAppointment.startDate
											),
											now
										) >
										this.nearestAppointment.medicalOffice
											?.appointmentDuration
											?.appointmentMinutesDuration
								),
								tap({
									complete: () => {
										this.secondsUntilStart =
											differenceInSeconds(
												new Date(
													this.nearestAppointment.startDate
												),
												new Date()
											);

										if (this.secondsUntilStart < 0) {
											this.secondsUntilStart =
												-this.secondsUntilStart;
											this.patientIsDelayed = true;
										}
										if (environment.production) {
											this.nearAppointmentModalRef =
												this.modalService.open(
													this.nearAppointmentModal,
													{
														ariaLabelledBy:
															'modal-basic-title',
														centered: true,
														size: 'lg',
														backdrop: 'static',
														keyboard: false,
													}
												);

											this.nearAppointmentModalRef.result.then(
												(result) => {
													this.modalService.dismissAll();
												},
												(reason) => { }
											);
										}
									},
								})
							)
							.subscribe();
					}
				}
			)
		);
	}

	ngOnDestroy(): void {
		this.subs.forEach((s) => s?.unsubscribe());
		this.previousNearestAppointmentSub?.unsubscribe();
	}

	goToWaitingRoom(item: IAppointment) {
		if (!item) {
			item = this.nearestAppointment;
		}
		if (
			(item.medicalOffice.branch.mainProvider.account.publicIdentifier ??
				item.medicalOffice.branch.account.publicIdentifier) &&
			item.medicalOffice.doctor.publicIdentifier
		) {
			this.router.navigate([
				RouteList.attendance,
				item.medicalOffice.branch.mainProvider?.account
					.publicIdentifier ??
				item.medicalOffice.branch.account.publicIdentifier,
				item.medicalOffice.doctor.publicIdentifier,
			]);
		} else {
			this.toastrService.error(
				this.translateService.instant('ErrorOccurredContactSupport'),
				this.translateService.instant('Error'),
				{
					positionClass: 'toast-bottom-right',
					timeOut: 10000,
					progressBar: true,
					progressAnimation: 'decreasing',
				}
			);
		}
	}

	setPatientAsRetarded() {
		this.patientIsDelayed = true;
	}

	getTranslatableTitle(title: string) {
		var splitter = title.split(' - ');
		return (
			this.translateService.instant(splitter[0].trim()) +
			' - ' +
			splitter[1]
		);
	}
}
