export class ReviewStatistic {
	total: number;
	average: number;
	reviewsSummary: ReviewSummary[];
}

export class ReviewSummary {
	rating: number;
	count: number;
}
