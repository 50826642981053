import { Injectable } from "@angular/core"
import { Observable, Subject } from "rxjs"

@Injectable({ providedIn: 'root' })
export class HttpCancelService {
	private cancelPendingRequestsSubject = new Subject<void>();
	cancelPendingRequests$: Observable<void>;

	constructor() {
		this.cancelPendingRequests$ = this.cancelPendingRequestsSubject.asObservable();
	}

	public cancelPendingRequests() {
		this.cancelPendingRequestsSubject.next()
	}

}