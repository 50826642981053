<div class="attendance-chat-content o-hidden" [perfectScrollbar]="{ suppressScrollX: true }" ngxInfiniteScroller
	strategy="scrollingToTop" initialScrollPosition="BOTTOM" (onScrollUp)="onScroll()">
	<span *ngIf="loadingUp" class="loader-bubble loader-bubble-info ml-4 mt-n2"></span>
	<div *ngFor="let message of messages$ | async" class="px-3">
		<app-chat-message [message]="message"></app-chat-message>
	</div>
</div>
<div *ngIf="whoIsTyping$ | async as whoIsTyping">
	<!-- to do label like string format -->
	<p class="m-1 ml-4 position-relative">{{whoIsTyping}} {{'IsTyping' | translate }} <span
			class=" loader-bubble loader-bubble-info ml-4 mt-n2"></span></p>
</div>
<div class="pl-3 pr-3 pt-3 pb-3 box-shadow-1 chat-input-area">
	<form [formGroup]="form" class="inputForm" (ngSubmit)="sendMessage()" (keyup.enter)="sendMessage()">
		<div class="form-group">
			<textarea class="form-control form-control-rounded" formControlName="content"
				placeholder="{{ 'TypeYourMessage' | translate }}" cols="30" rows="3"></textarea>
		</div>
		<div class="d-flex">
			<div class="flex-grow-1"></div>
			<button type="submit" class="btn btn-icon btn-rounded btn-primary mr-2">
				<i class="i-Paper-Plane"></i>
			</button>
			<input style="display: none" type="file" (change)="onFileChanged($event)" #fileInput
				accept="application/msword, application/vnd.ms-excel, application/vnd.ms-powerpoint, text/plain, application/pdf, image/*" />
			<button class="btn btn-icon btn-rounded btn-outline-info" type="button" (click)="fileInput.click()">
				<i class="i-Add-File"></i>
			</button>
		</div>
	</form>
</div>