import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormControl } from '@angular/forms';
import { Router, ActivatedRoute } from '@angular/router';
import { RouteList } from '@app/01.global/helpers/route-enum';
import { AuthenticationService } from '@app/01.global/services/authentication.service';
import { TableListSettings, TableListMode } from '@app/table-list/models/table-list';
import { TableListColumnMode } from '@app/table-list/models/table-list-column';
import { TableListFilters } from '@app/table-list/models/table-list-filters';
import { TableListRow } from '@app/table-list/models/table-list-row';
import { TableListStatusService } from '@app/table-list/services/table-list-status.service';
import { take, debounceTime, distinctUntilChanged, switchMap, Subscription } from 'rxjs';

@Component({
	selector: 'table-list-tiles',
	templateUrl: './table-list-tiles.component.html',
	styleUrls: ['./table-list-tiles.component.scss']
})
export class TableListTilesComponent implements OnInit {
	constructor(private authService: AuthenticationService, private router: Router, private activatedRoute: ActivatedRoute, public statusService: TableListStatusService) { }
	@Input('tableIdentifier') tableIdentifier: string;
	private subs: Subscription[] = [];
	tableSettings: TableListSettings;
	tableFilters: TableListFilters;
	TableListMode = TableListMode;
	TableListColumnMode = TableListColumnMode;
	searchTerm: FormControl = new FormControl('');
	loading: boolean = false;
	@Output('onLoading') onLoading: EventEmitter<boolean> = new EventEmitter();

	ngOnInit(): void {
		this.loading = true;
		this.onLoading.emit(true);
		this.statusService.getFiltersObservable(this.tableIdentifier).pipe(take(1))
			.subscribe((filters: TableListFilters) => {
				this.tableFilters = filters;
			});

		this.subs.push(this.statusService.getSettingsObservable(this.tableIdentifier)
			.subscribe(settings => this.tableSettings = settings))

		this.statusService.getFiltersObservable(this.tableIdentifier).pipe(take(1))
			.subscribe((filters: TableListFilters) => {
				this.searchTerm.setValue(filters?.searchTerm ?? '');
			})

		this.subs.push(this.searchTerm.valueChanges
			.pipe(
				debounceTime(500),
				distinctUntilChanged(),
				switchMap((value: string) => {
					this.statusService.search(this.tableIdentifier, value);
					return value;
				})
			)
			.subscribe(() => { }));

		this.subs.push(this.statusService.getLoadingObservable(this.tableIdentifier).subscribe((isLoading: boolean) => {
			this.loading = isLoading;
			this.onLoading.emit(isLoading);
		}));
		console.log("isGuestRolePresent = (): boolean => this.authService?.isGuest;", this.authService?.isGuest);
	}

	isGuestRolePresent = (): boolean => this.authService?.isGuest;

	ngOnDestroy(): void {
		this.subs.forEach(s => s?.unsubscribe());
	}

	onScroll(): void {
		if (this.tableFilters.currentPage + 1 < this.tableFilters.totalPages) {
			this.statusService.readPage(this.tableIdentifier, ++this.tableFilters.currentPage);
		}
	}

	setTableListButtonClicked(row: TableListRow) {
		this.statusService.setTableListButtonClicked({
			order: 0,
			loading: false,
			showText: false,
			text: '',
			route: RouteList._routeCrudEnum.Details,
			btnClass: ''
		}, this.tableIdentifier, row.id);
	}

	loadMore() {
		this.statusService.readPage(this.tableIdentifier, ++this.tableFilters.currentPage)
	}
}
