<div class="card card-icon-bg card-icon-bg-{{iconColor}} dashboard-widget" ngClass="{{multiple ? 'mb-4':''}}"
	[perfectScrollbar]="{ suppressScrollX: true }">
	<div class="card-body text-center">
		<app-local-loader [enabled]="loading"></app-local-loader>
		<i class="i-Doctor mr-4"></i>
		<div class="container-fluid">
			<div class="row">
				<div class="col">
					<p class="card-title">
						{{title | translate }}
					</p>
				</div>
			</div>
			<ng-container *ngIf="users.length > 0">
				<div class="align-items-center border-bottom-dotted-dim" *ngFor="let user of users">
					<app-label-with-popover [triggers]="'mouseenter:mouseleave'" [placement]="placement"
						[animation]="false" [container]="'body'" [targetLink]="">
						<div slot="label">
							<div class="d-flex flex-row">
								<div class="profile-picture avatar-lg mr-2 profileImgContainer" [ngStyle]="{
									'background-image':
										'url(' +
										(user?.photo?.url ??
										'./assets/images/default-user.png') +
										')'
								}"></div>
								<div class="flex-grow-1 my-auto">
									<h5 class="m-0 text-left">{{user.displayName}}</h5>
									<p class="m-0 text-left text-small text-muted"><a
											href="mailto:{{user.email}}">{{user.email}}</a></p>
									<p class="m-0 text-left text-small text-muted">{{user.specialty?.name}}</p>
								</div>
								<div class="flex-grow-1 my-auto ml-auto text-right">
									<button class="btn btn-rounded btn-icon btn-info m-1"
										(click)="navigateToDetails(user)"><i class="i-Find-User text-white"
											style="font-size: 1rem;"></i></button>
								</div>
							</div>
						</div>
					</app-label-with-popover>
				</div>
			</ng-container>
			<ng-container *ngIf="users.length === 0">
				<h5 class="m-0 text-primary text-center">
					{{'NoData' | translate}}
				</h5>
			</ng-container>
		</div>
	</div>
</div>