import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ITableListService } from '@app/table-list/services/iservice';
import { environment } from '@environments/environment';
import { Observable } from 'rxjs';
import { ReviewSummary } from '../helpers/reviewSummary';
import { IApiResponse } from '../interfaces/helpers/iResponseWrapper';
import { IReview } from '../interfaces/IReview';
import { DatatableFilters } from '../models/DatatableFilters';
import { BaseService } from './base.service';

@Injectable({
	providedIn: 'root',
})
export class ReviewsService extends BaseService implements ITableListService {
	ApiBaseURL = environment.ApiBaseURL;

	constructor(private http: HttpClient) {
		super();
	}

	create(model: IReview) {
		return this.http
			.post<IApiResponse>(`${this.ApiBaseURL}/Reviews/`, model)
			.ToApiResult();
	}

	update(id: number, model: IReview) {
		return this.http
			.put<IApiResponse>(`${this.ApiBaseURL}/Reviews/${id}`, model)
			.ToApiResult();
	}

	delete(id: number) {
		return this.http
			.delete<IApiResponse>(`${this.ApiBaseURL}/Reviews/${id}`)
			.ToApiResult();
	}

	read(id: number): Observable<IReview> {
		return this.http
			.get<IApiResponse>(`${this.ApiBaseURL}/Reviews/${id}`)
			.ToApiResult();
	}

	readSummary(doctorId: number): Observable<ReviewSummary[]> {
		return this.http
			.get<IApiResponse>(`${this.ApiBaseURL}/Reviews/Summary/${doctorId}`)
			.ToApiResult();
	}

	readCount(page: DatatableFilters): Observable<number> {
		let params = this.paginationToHttpParams(page);
		return this.http
			.get<IApiResponse>(`${this.ApiBaseURL}/Reviews/count`, {
				params,
			})
			.ToApiResult();
	}

	readAll(page: DatatableFilters): Observable<IReview[]> {
		let params = this.paginationToHttpParams(page);

		return this.http
			.get<IApiResponse>(`${this.ApiBaseURL}/Reviews`, {
				params,
			})
			.ToApiResult();
	}
}
