import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { RouteList } from '@app/01.global/helpers/route-enum';
import { TableListSettings } from '@app/table-list/models/table-list';
import { TableListColumnMode, TableListColumn } from '@app/table-list/models/table-list-column';
import { TableListRow } from '@app/table-list/models/table-list-row';
import { TableListStatusService } from '@app/table-list/services/table-list-status.service';
import { Subscription } from 'rxjs';

@Component({
	selector: 'table-list-circle-card',
	templateUrl: './table-list-circle-card.component.html',
	styleUrls: ['./table-list-circle-card.component.scss']
})
export class TableListCircleCardComponent implements OnInit, OnDestroy {
	constructor(private statusService: TableListStatusService) { }

	@Input('row') row: TableListRow;
	TableListColumnMode = TableListColumnMode;
	public get thumb(): TableListColumn {
		return this.row.columns.find(c => c.displayMode == TableListColumnMode.thumbnail);
	}
	public get title(): TableListColumn {
		return this.row.columns.find(c => c.displayMode == TableListColumnMode.title);
	}
	@Input('tableIdentifier') tableIdentifier: string;
	tableSettings: TableListSettings;

	subs: Subscription[] = [];

	ngOnInit(): void {
		this.subs.push(this.statusService.getSettingsObservable(this.tableIdentifier)
			.subscribe(settings => this.tableSettings = settings))
	}

	ngOnDestroy(): void {
		this.subs.forEach(s => s?.unsubscribe());
	}

	setTableListButtonClicked() {
		this.statusService.setTableListButtonClicked({
			order: 0,
			loading: false,
			showText: false,
			text: '',
			route: RouteList._routeCrudEnum.AddUserToChannel,
			btnClass: ''
		}, this.tableIdentifier, this.row.id);
	}

}
