<div class="modal-header">
	<h4 class="modal-title" id="modal-title">
		<ng-content select="[modal-header]"></ng-content>
	</h4>
	<button type="button" class="close" aria-label="Close button" aria-describedby="modal-title"
		(click)="dismiss($event)">
		<span aria-hidden="true">&times;</span>
	</button>
</div>
<div class="modal-body m-3">
	<ng-content select="[modal-body]"></ng-content>
</div>
<div class="modal-footer"
	[ngStyle]="{ padding: showDefaultFooter ? '1rem' : '0', borderTop:showDefaultFooter ? '1px solid #eee;' : '0'}">
	<ng-content select="[modal-footer]"></ng-content>
	<ng-container *ngIf="showDefaultFooter">
		<button type="button" class="btn btn-outline-secondary" (click)="dismiss($event)" data-toggle="tooltip"
			data-placement="bottom" title="{{'Cancel' | translate}}">
			{{ 'Cancel' | translate }}
		</button>
		<button type="button" [ngClass]="'btn btn-'+ confirmButtonClass" (click)="confirm($event)"
			[disabled]="disableConfirmButton" data-toggle="tooltip" data-placement="bottom"
			title="{{ ''+confirmButtonText | translate }}">
			{{ ''+confirmButtonText | translate }}
		</button>
	</ng-container>
</div>