<div class="modal-header">
	<h4 class="modal-title" id="modal-basic-title">{{ 'CropImage' | translate}}</h4>
	<button type="button" class="close" aria-label="Close" (click)="activeModal.dismiss('Cross click')">
		<span aria-hidden="true">&times;</span>
	</button>
</div>
<div class="modal-body">
	<img-cropper #cropper [image]="data" [settings]="cropperSettings"></img-cropper><br>
	<img *ngIf="data?.image" class="mt-3 mr-3" [src]="data?.image" [width]="cropperSettings.width"
		[height]="cropperSettings.height"
		[ngStyle]="{'border-radius': documentType == DocTypesEnum.AgreementCardImage ? '10px': '0', 'box-shadow': '0 4px 20px 1px rgb(0 0 0 / 6%), 0 1px 4px rgb(0 0 0 / 8%)'}">
	<img *ngIf="data?.image && documentType !== DocTypesEnum.AgreementCardImage" class="rounded-circle mt-3"
		[src]="data?.image" [width]="cropperSettings.width" [height]="cropperSettings.height">
</div>
<div class="modal-footer">
	<button type="button" class="btn btn-rounded btn-outline-dark" (click)="activeModal.close(data.image)"
		data-toggle="tooltip" data-placement="bottom" title="{{'Save' | translate}}">Save</button>
</div>