<app-local-loader [enabled]="loading"></app-local-loader>
<div [ngSwitch]="type" *ngIf="usesFormGroupClass()">
	<div class="form-group" [ngClass]="setRowClass()">
		<ng-container *ngIf="showLabel">
			<label *ngIf="label" for="{{name}}" [ngClass]="setLabelColClass()">{{ label }}</label>
			<label *ngIf="!label" for="{{name}}" [ngClass]="setLabelColClass()">{{ '' + capitalize(name) |
				translate
				}}</label>

			<em *ngIf="type==ControlTypeEnum.Password && isEdit">
				({{'LeaveBlankToKeepSamePassword' | translate}})
			</em>
		</ng-container>

		<div [ngClass]="setInputColClass()">

			<!-- ControlTypeEnum.Text -->
			<input *ngSwitchCase="ControlTypeEnum.Text" name="{{name}}" type="text" [formControl]="control"
				[class]="'form-control' + ' ' + (rounded ? 'form-control-rounded' : '') + ' ' + getSize()"
				[ngClass]="setInvalidClass()" placeholder="{{placeholder | translate}}" />

			<!-- ControlTypeEnum.Number -->
			<input *ngSwitchCase="ControlTypeEnum.Number" name="{{name}}" type="number" [formControl]="control"
				[class]="'form-control' + ' ' + (rounded ? 'form-control-rounded' : '') + ' ' + getSize()"
				[ngClass]="setInvalidClass()" placeholder="{{placeholder}}" step="step" min="min" max="max" />

			<!-- ControlTypeEnum.Textarea -->
			<textarea *ngSwitchCase="ControlTypeEnum.Textarea" name="{{name}}" [formControl]="control"
				[class]="'form-control' + ' ' + (rounded ? 'form-control-rounded' : '') + ' ' + getSize()"
				[ngClass]="setInvalidClass()" placeholder="{{placeholder}}" [rows]="rows"></textarea>

			<!-- ControlTypeEnum.Email -->
			<input *ngSwitchCase="ControlTypeEnum.Email" name="{{name}}" type="email" [formControl]="control"
				[class]="'form-control' + ' ' + (rounded ? 'form-control-rounded' : '') + ' ' + getSize()"
				[ngClass]="setInvalidClass()" placeholder="{{placeholder}}" />

			<!-- ControlTypeEnum.Password -->
			<input *ngSwitchCase="ControlTypeEnum.Password" name="{{name}}" type="password"
				[class]="'form-control' + ' ' + (rounded ? 'form-control-rounded' : '') + ' ' + getSize()"
				placeholder="{{placeholder}}" [formControl]="control" [ngClass]="setInvalidClass()" />

			<!-- ControlTypeEnum.Dropdown -->
			<select *ngSwitchCase="ControlTypeEnum.Dropdown" name="{{name}}"
				[class]="'form-control' + ' ' + (rounded ? 'form-control-rounded' : '') + ' ' + getSize()"
				[formControl]="control" [ngClass]="setInvalidClass()">
				<option value="0" disabled>
					{{ 'Select' + capitalize(name) | translate }}
				</option>
				<option *ngFor="let option of options" [ngValue]="+option.value">
					{{ option.text | translate }}
				</option>
			</select>

			<!-- ControlTypeEnum.Typeahead -->
			<input *ngSwitchCase="ControlTypeEnum.Typeahead" name="{{name}}" [formControl]="control"
				[class]="'form-control' + ' ' + (rounded ? 'form-control-rounded' : '') + ' ' + getSize()" type="text"
				[placeholder]='placeholder' [ngbTypeahead]="typeaheadSearch"
				(click)="clickTypeahead$.next($any($event).target.value)" #typeaheadControl="ngbTypeahead"
				[inputFormatter]="formatterTypeahead" [resultFormatter]="formatterTypeahead" [editable]="false"
				[resultTemplate]="typeaheadResultTemplate" [popupClass]="'form-control-typeahead'"
				[ngClass]="setInvalidClass()" />
				<label class="text-danger" *ngIf="hasInvalidOption">{{'invalidoptionError'| translate }}</label>

				<!-- ControlTypeEnum.Date -->
			<div *ngSwitchCase="ControlTypeEnum.Date" class="input-group">
				<input name="{{name}}" [formControl]="control" [ngClass]="setInvalidClass()" placement="bottom-right"
					container="body" placeholder="{{placeholder}}" ngbDatepicker #dp="ngbDatepicker"
					[minDate]="{year: 1900 , month: 12, day: 31}"
					[maxDate]="{year: currentYear + 15, month:12, day: 31}"
					[class]="'form-control cursor-pointer date-picker-input' + ' ' + (rounded ? 'form-control-rounded' : '') + ' ' + getSize()"
					readonly (click)="dp.toggle()" />
				<div class="input-group-append">
					<button class="btn btn-secondary" (click)="dp.toggle()" type="button">
						<i class="icon-regular i-Calendar-4"></i>
					</button>
				</div>
			</div>

			<!-- ControlTypeEnum.Autocomplete -->
			<tag-input *ngSwitchCase="ControlTypeEnum.Autocomplete" [formControl]="control"
				[maxItems]='max == 0 ? 99 : max' [onlyFromAutocomplete]="onlyFromAutocomplete"
				[placeholder]="placeholder" [secondaryPlaceholder]="secondaryPlaceholder" [identifyBy]="identifyBy"
				[displayBy]="displayBy" [removable]="control?.disabled ? false : removable"
				[disable]="control?.disabled ? true : !removable" (onAdd)="onItemAdded($event)"
				(onRemove)="onItemRemoved($event)" (onTextChange)="onTextChange($event)">
				<tag-input-dropdown [identifyBy]="identifyBy" [displayBy]="displayBy" [showDropdownIfEmpty]="true"
					[autocompleteItems]="options" [appendToBody]="false" [keepOpen]="false"></tag-input-dropdown>
			</tag-input>

			<!-- ControlTypeEnum.File -->
			<div *ngSwitchCase="ControlTypeEnum.Document" ngx-dropzone [accept]="fileInputAccepts"
				[disabled]="control?.disabled" class="app-dropzone-wrapper bg-gray-200"
				(change)="onSelectFiles($event)">
				<ngx-dropzone-label>
					<div>
						<span *ngIf="!!placeholder; else icon" class="text-20">{{placeholder}}</span>
						<ng-template #icon>
							<i class="i-Upload text-50"></i>
						</ng-template>
					</div>
				</ngx-dropzone-label>
				<ng-container *ngIf="control.value?.length > 0; else single">
					<app-dropzone-image-preview ngProjectAs="ngx-dropzone-preview" *ngFor="let f of control.value"
						[file]="f" [removable]="removable" (removed)="onRemove(f)">
					</app-dropzone-image-preview>
				</ng-container>
				<ng-template #single>
					<app-dropzone-image-preview *ngIf="!!control.value && control.value?.length != 0"
						ngProjectAs="ngx-dropzone-preview" [file]="control.value" [removable]="removable"
						(removed)="onRemove(control.value)">
					</app-dropzone-image-preview>
				</ng-template>
			</div>

			<ngb-rating *ngSwitchCase="ControlTypeEnum.Review" max="5" [formControl]="control" [readonly]="disable"
				class="rating-{{themeClass}} text-30"></ngb-rating>






			<div *ngIf="showErrorMessages && (control?.dirty || control?.touched || submitted) && control?.errors"
				class="invalid-feedback">
				<div *ngIf="control.errors.required">
					{{ 'FieldIsRequired' | translate }}
				</div>
				<div *ngIf="control.errors.minlength">
					{{ 'MinLength' | translate }}
				</div>
				<div *ngIf="control.errors.maxlength">
					{{ 'MaxLength' | translate }}
				</div>
				<div *ngIf="control.errors.min">
					{{ 'FieldIsRequired' | translate }}
				</div>
				<div *ngIf="control.errors.max">
					{{ 'Max' | translate }}
				</div>
				<div *ngIf="control.errors.email">
					{{ 'EmailInvalid' | translate }}
				</div>
				<div *ngIf="control.errors?.equalTo">
					{{ 'confirmPassword_matchValues' | translate }}
				</div>
				<div *ngIf="control.errors?.startDateIsBiggerThanEndDate">
					{{ 'StartDateIsBiggerThanEndDate' | translate }}
				</div>
				<ng-container *ngIf="control.errors.pattern">
					<div [ngSwitch]="type">
						<div *ngSwitchCase="ControlTypeEnum.Password">
							{{ 'Password_pattern' | translate }}
						</div>
						<div *ngSwitchCase="ControlTypeEnum.Email">
							{{ 'EmailInvalidPattern' | translate }}
						</div>
						<div *ngSwitchDefault>
							{{ 'InvalidPattern' | translate }}
						</div>
					</div>
				</ng-container>
			</div>
		</div>
	</div>
</div>

<div [ngSwitch]="type" *ngIf="!usesFormGroupClass()">

	<label [ngClass]="setCheckboxClass()">
		<span *ngIf="label">{{ label }}</span>
		<span *ngIf="!label">{{ '' + capitalize(name) | translate }}</span>

		<!-- ControlTypeEnum.Checkbox -->
		<input *ngSwitchCase="ControlTypeEnum.Checkbox" value="{{value}}" type="checkbox" [formControl]="control" />
		<span *ngSwitchCase="ControlTypeEnum.Checkbox" class="checkmark"></span>

		<!-- ControlTypeEnum.CheckboxOutline -->
		<input *ngSwitchCase="ControlTypeEnum.CheckboxOutline" value="{{value}}" type="checkbox"
			[formControl]="control" />
		<span *ngSwitchCase="ControlTypeEnum.CheckboxOutline" class="checkmark"></span>

		<!-- ControlTypeEnum.CheckboxSwitch -->
		<input *ngSwitchCase="ControlTypeEnum.CheckboxSwitch" value="{{value}}" type="checkbox"
			[formControl]="control" />
		<span *ngSwitchCase="ControlTypeEnum.CheckboxSwitch" class="slider"></span>

		<!-- ControlTypeEnum.Radio -->
		<input *ngSwitchCase="ControlTypeEnum.Radio" [value]="value" [name]="name" type="radio" [formControl]="control"
			[checked]="control.value == value" />
		<span *ngSwitchCase="ControlTypeEnum.Radio" class="checkmark"></span>

		<!-- ControlTypeEnum.RadioOutline -->
		<input *ngSwitchCase="ControlTypeEnum.RadioOutline" [value]="value" [name]="name" type="radio"
			[formControl]="control" [checked]="control.value == value" />
		<span *ngSwitchCase="ControlTypeEnum.RadioOutline" class="checkmark"></span>
	</label>

</div>