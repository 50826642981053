import {
	Component,
	EventEmitter,
	OnDestroy,
	OnInit,
	Output,
} from '@angular/core';
import {
	UntypedFormBuilder,
	UntypedFormControl,
	UntypedFormGroup,
	Validators,
} from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { ControlTypeEnum } from '@app/01.global/helpers/control-enum';
import { PositionEnum } from '@app/01.global/helpers/position-enum';
import { RoleEnum } from '@app/01.global/helpers/role-enum';
import { RouteList } from '@app/01.global/helpers/route-enum';
import { Utils } from '@app/01.global/helpers/utils';
import { ISelectListItem } from '@app/01.global/interfaces/helpers/iSelectListItem';
import { IAddress } from '@app/01.global/interfaces/iAddress';
import { IUser } from '@app/01.global/interfaces/IUser';
import { UsersService } from '@app/01.global/services/users.service';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { of, Subscription } from 'rxjs';
import { CustomValidators } from 'ng2-validation';
import { ISpecialty } from '@app/01.global/interfaces/ISpecialty';
import { TranslateService } from '@ngx-translate/core';
import { DocTypesEnum } from '@app/01.global/helpers/doc-types-enum';
import { IConsultationType } from '@app/01.global/interfaces/iConsultation-type';

@Component({
	selector: 'app-user-form',
	templateUrl: './user-form.component.html',
	styleUrls: ['./user-form.component.scss'],
})
export class UserFormComponent implements OnInit, OnDestroy {
	@Output() onSuccess: EventEmitter<number> = new EventEmitter<number>();
	@Output() onFail: EventEmitter<void> = new EventEmitter();

	submitted = false;
	form: UntypedFormGroup;
	isDoctor = new UntypedFormControl({ value: false, disabled: true });
	positionEnum = PositionEnum;
	positionName: string;
	availableCountryCodes: ISelectListItem[];
	availableTitles: ISelectListItem[];
	availableLanguages: ISelectListItem[];
	availableGenders: ISelectListItem[];
	availableConsultationTypes: IConsultationType[];
	availableSpecialties: ISelectListItem[];
	resolverSpecialties: ISpecialty[];
	availablePositions: ISelectListItem[];
	availablePositionsWithoutTranslation: ISelectListItem[];
	availableMedicalOffices: ISelectListItem[];
	availableBranches: ISelectListItem[];
	user: IUser;
	isAddMode: boolean = false;
	isEditMode: boolean = false;
	isDeleteMode: boolean = false;
	isEmployee = false;
	isProvider = false;
	isPatient = false;
	isEditconsultationTypesMode = false;
	showHasNoSpecialtiesWarning = false;
	showHasNoMedicalOfficesWarning = false;
	RouteList = RouteList;
	ControlTypeEnum = ControlTypeEnum;
	DocTypesEnum = DocTypesEnum;
	subs: Subscription[] = [];
	searchTerm: string;

	constructor(
		private formBuilder: UntypedFormBuilder,
		private route: ActivatedRoute,
		private router: Router,
		private userService: UsersService,
		private modalService: NgbModal,
		private translateService: TranslateService
	) { }

	ngOnInit() {
		this.populateDropdowns();
		this.subs.push(
			this.translateService.onLangChange.subscribe(() => {
				this.populateDropdowns();
			})
		);

		this.route.data.subscribe((d) => {
			var routeData = Utils.readRoute(d);
			this.isAddMode = !!routeData.isAddMode;
			this.isEditMode = !!routeData.isEditMode;
			this.isDeleteMode = !!routeData.isDeleteMode;
			this.isEmployee = !!routeData.isEmployee;
			this.isProvider = !!routeData.isProvider;
			this.isPatient = !!routeData.isPatient;
			this.isEditconsultationTypesMode =
				!!routeData.isEditconsultationTypesMode;
			this.isEditconsultationTypesMode = Utils.getRouteData(
				this.route,
				'isEditconsultationTypesMode'
			);

			this.buildForm();
			if (this.isEmployee) {
				if (
					this.resolverSpecialties == null ||
					this.resolverSpecialties.length == 0
				) {
					this.showHasNoSpecialtiesWarning = true;
				}
			}
			if (this.isPatient) {
				if (
					this.availableMedicalOffices == null ||
					this.availableMedicalOffices.length == 0
				) {
					this.showHasNoMedicalOfficesWarning = true;
				}
			}
			if (d.entity) {
				this.user = d.entity;
				this.form.patchValue(this.user);
				this.form.controls.email.disable();
				this.form.controls.titleId.setValue(
					this.availableTitles.find(
						(t) => t.value == this.user.titleId.toString()
					)
				);
				this.form.controls.languageId.setValue(
					this.availableLanguages.find(
						(l) => l.value == this.user.languageId.toString()
					)
				);
				this.form.controls.genderId.setValue(
					this.availableGenders.find(
						(g) => g.value == this.user.genderId.toString()
					)
				);
				if (this.user.phoneNumberCountryCode) {
					this.form.controls.phoneNumberCountryCode.setValue(
						this.availableCountryCodes.find(
							(c) =>
								c.value ==
								this.user.phoneNumberCountryCode.toString()
						)
					);
				}
				if (this.isProvider) {
					return;
				}
				if (this.isEmployee) {
					this.form.controls.positionId.setValue(
						this.availablePositions.find(
							(p) => p.value == this.user.positionId.toString()
						)
					);

					this.positionName = this.availablePositionsWithoutTranslation.find(p =>
						p.value == this.form.controls.positionId.value.value).text;

					this.isDoctor.setValue(
						this.positionName.includes(PositionEnum.Doctor) ||
						this.positionName.includes(PositionEnum.Therapist)
					);
					if (this.isDoctor.value) {
						this.form.controls.specialtyId.setValue(
							this.availableSpecialties.find(
								(s) =>
									s.value == this.user.specialtyId.toString()
							)
						);
					} else {
						this.form.controls.branchAccountId.setValue(
							this.availableBranches.find(
								(b) =>
									b.value ==
									this.user.branchAccountId.toString()
							)
						);
					}
					return;
				}
			}

			this.form.controls.email.enable();
			this.user = null;
			this.form.reset({
				branchAccountId: '0',
				genderId: '0',
				specialtyId: '0',
				titleId: '0',
				positionId: '0',
				languageId: '0',
				medicalOfficeId: '0',
				phoneNumberCountryCode: '0',
				address: {
					latitude: 0,
					longitude: 0,
					north: 0,
					south: 0,
					west: 0,
					east: 0,
				},
			});
			this.isDoctor.setValue(false);
		});
	}

	ngOnDestroy(): void {
		this.subs.forEach((s) => s?.unsubscribe());
	}

	buildForm() {
		this.subs.forEach((s) => s?.unsubscribe());
		this.form = this.formBuilder.group({
			roleInProgress: [
				{
					value: '',
					disabled: this.isDeleteMode,
				},
			],
			password: [
				{
					value: '',
					disabled: this.isDeleteMode,
				},
				[
					this.isAddMode
						? Validators.required
						: Validators.nullValidator,
					Validators.minLength(8),
					Validators.pattern(
						'^(?=.*[0-9])(?=.*[a-z])(?=.*[A-Z])([a-zA-Z0-9]{1,})$'
					),
				],
			],
			confirmPassword: [
				{
					value: '',
					disabled: this.isDeleteMode,
				},
			],
			displayName: [
				{
					value: '',
					disabled: this.isDeleteMode,
				},
				[Validators.required],
			],
			email: [
				{ value: '', disabled: !this.isAddMode },
				[
					Validators.required,
					Validators.pattern(
						'^[a-z0-9._%+-]+@[a-z0-9.-]+\\.[a-z]{2,4}$'
					),
				],
			],
			phoneNumber: [{ value: '', disabled: !this.isAddMode }, [Validators.required]],
			phoneNumberCountryCode: [{ value: '0', disabled: !this.isAddMode }],
			firstName: [
				{
					value: '',
					disabled: this.isDeleteMode,
				},
				[Validators.required],
			],
			genderId: [
				{
					value: '0',
					disabled: this.isDeleteMode,
				},
				[Validators.min(1)],
			],
			languageId: [
				{
					value: '0',
					disabled: this.isDeleteMode,
				},
				[Validators.min(1)],
			],
			lastName: [
				{
					value: '0',
					disabled: this.isDeleteMode,
				},
				[Validators.required],
			],
			titleId: [
				{
					value: '0',
					disabled: this.isDeleteMode,
				},
				[Validators.min(1)],
			],
		});

		if (this.isEmployee) {
			this.form = this.formBuilder.group({
				...this.form.controls,
				address: this.formBuilder.group({
					line1: [
						{
							value: '',
							disabled: this.isDeleteMode,
						},
						[Validators.required],
					],
					line2: [
						{
							value: '',
							disabled: this.isDeleteMode,
						},
					],
					postalCode: [
						{
							value: '',
							disabled: this.isDeleteMode,
						},
						[Validators.required],
					],
					city: [
						{
							value: '',
							disabled: this.isDeleteMode,
						},
						[Validators.required],
					],
					district: [
						{
							value: '',
							disabled: this.isDeleteMode,
						},
						[Validators.required],
					],
					country: [
						{
							value: '',
							disabled: this.isDeleteMode,
						},
						[Validators.required],
					],
					latitude: [
						{
							value: 0,
							disabled: this.isDeleteMode,
						},
					],
					longitude: [
						{
							value: 0,
							disabled: this.isDeleteMode,
						},
					],
					north: [
						{
							value: 0,
							disabled: this.isDeleteMode,
						},
					],
					south: [
						{
							value: 0,
							disabled: this.isDeleteMode,
						},
					],
					west: [
						{
							value: 0,
							disabled: this.isDeleteMode,
						},
					],
					east: [
						{
							value: 0,
							disabled: this.isDeleteMode,
						},
					],
				}),
				consultationTypes: [
					{
						value: [],
						disabled: this.isDeleteMode,
					},
				],
				specialtyId: [
					{
						value: '0',
						disabled: this.isDeleteMode,
					},
					[Validators.min(1)],
				],
				positionId: [
					{
						value: '0',
						disabled: this.isDeleteMode,
					},
					[Validators.min(1)],
				],
				branchAccountId: [
					{
						value: '0',
						disabled: this.isDeleteMode,
					},
					[Validators.min(1)],
				],
				photo: [
					{
						value: null,
						disabled: this.isDeleteMode,
					},
				],
			});

			this.subs.push(
				this.form.controls.positionId.valueChanges.subscribe((v) => {
					if (!v || v == '0') {
						return;
					}
					this.positionName = this.availablePositionsWithoutTranslation.find(p =>
						p.value == v || p.value == v.value).text;

					this.isDoctor.setValue(
						this.positionName.includes(PositionEnum.Doctor) ||
						this.positionName.includes(PositionEnum.Therapist)
					);

					if (this.positionName.includes(PositionEnum.Employee)) {
						this.form.get('consultationTypes').reset();
						this.form.get('specialtyId').setValue('0');
					} else if (this.isDoctor.value) {
						this.form.get('branchAccountId').setValue('0');
					}
				})
			);

			this.subs.push(
				this.form.controls.specialtyId.valueChanges.subscribe((v) => {
					var specialtyId = +v?.value;
					if (specialtyId) {
						this.availableConsultationTypes =
							this.resolverSpecialties
								.filter((s) => s.id == specialtyId)
								.flatMap(
									(s) =>
										s.specialtyStandard
											?.consultationTypes ??
										s.consultationTypes
								);
						this.availableConsultationTypes.forEach(ct => ct.name = this.translateService.instant(ct.name));
						if (specialtyId == this.user?.specialtyId) {
							this.form.get('consultationTypes').reset();
							this.form
								.get('consultationTypes')
								.setValue(
									this.availableConsultationTypes.filter(
										(act: IConsultationType) => {
											var userCTSIds = this.user.consultationTypes
												.map(
													(uct) =>
														uct.consultationTypeStandardId
												);
											return userCTSIds.includes(act.id) || userCTSIds.includes(act.consultationTypeStandardId)
										}
									)
								);
						} else {
							this.form
								.get('consultationTypes')
								.setValue(this.availableConsultationTypes);
						}
					}
				})
			);
		}

		if (this.isPatient) {
			this.form = this.formBuilder.group({
				...this.form.controls,
				medicalOfficeId: [
					{
						value: '0',
						disabled: this.isDeleteMode,
					},
					[Validators.min(1)],
				],
			});
		}

		if (!this.isDeleteMode) {
			this.form
				.get('confirmPassword')
				.setValidators([
					this.isAddMode
						? Validators.required
						: Validators.nullValidator,
					Validators.minLength(8),
					Validators.pattern(
						'^(?=.*[0-9])(?=.*[a-z])(?=.*[A-Z])([a-zA-Z0-9]{1,})$'
					),
					CustomValidators.equalTo(this.form.controls.password),
				]);
			this.form.controls.confirmPassword.updateValueAndValidity();
			this.form.updateValueAndValidity();
		}
	}

	populateDropdowns() {
		var snapData = this.route.snapshot.data;
		this.availableCountryCodes = snapData.countryCodes;

		if (snapData.genders) {
			this.availableGenders = snapData.genders.map((s) => {
				return {
					value: s.value.toString(),
					text: this.translateService.instant(s.text),
				} as ISelectListItem;
			});
		}

		if (snapData.languages) {
			this.availableLanguages = snapData.languages.map((s) => {
				return {
					value: s.value.toString(),
					text: this.translateService.instant(s.text),
				} as ISelectListItem;
			});
		}

		if (snapData.positions) {
			this.availablePositionsWithoutTranslation = snapData.positions.map((s) => {
				return {
					value: s.value.toString(),
					text: s.text
				} as ISelectListItem;
			});
			this.availablePositions = snapData.positions.map((s) => {
				return {
					value: s.value.toString(),
					text: this.translateService.instant(s.text),
				} as ISelectListItem;
			});
		}

		if (snapData.titles) {
			this.availableTitles = snapData.titles.map((s) => {
				return {
					value: s.value.toString(),
					text: this.translateService.instant(s.text),
				} as ISelectListItem;
			});
		}

		this.availableMedicalOffices = snapData.medicalOffices;
		this.availableBranches = snapData.availableBranches;

		if (snapData.specialties) {
			this.resolverSpecialties = snapData.specialties;
			this.availableSpecialties = this.resolverSpecialties.map((s) => {
				return {
					value: s.id.toString(),
					text: this.translateService.instant(s.name),
				} as ISelectListItem;
			});
		}
	}

	submitForm() {
		if (this.isDeleteMode) {
			this.onSuccess.emit();
			return;
		}

		var formValue = Object.assign({}, this.form.value);

		if (this.positionName == PositionEnum.Employee) {
			this.form.get('specialtyId').setValidators(null);
			this.form.get('specialtyId').setErrors(null);
		} else if (this.isDoctor.value) {
			this.form.get('branchAccountId').setValidators(null);
			this.form.get('branchAccountId').setErrors(null);
			formValue.specialtyId = +this.form.get('specialtyId').value?.value;
		}

		this.submitted = true;
		// stop here if form is invalid
		if (!this.form.valid) {
			for (const field in this.form.controls) {
				const control = this.form.get(field);
				// touch invalid control to active required validation
				if (control && control.invalid) {
					control.markAsTouched();
				}
			}
			return;
		}

		var roleInProgress;
		if (this.isEmployee) {
			roleInProgress = RoleEnum.Employee;
			var providerParam =
				this.route.snapshot.queryParams['providerAccountId'];
			if (providerParam) {
				formValue.providerAccountId = providerParam;
			}
		}
		if (this.isProvider) {
			roleInProgress = RoleEnum.Provider;
		}
		if (this.isPatient) {
			roleInProgress = RoleEnum.Guest;
		}

		formValue.roleInProgress = roleInProgress;

		var request = this.isAddMode
			? this.userService.create(formValue)
			: this.userService.update(this.user.id, formValue);

		request.subscribe({
			next: (user: IUser) => {
				this.onSuccess.emit(user.id);
			},
			error: () => {
				this.onFail.emit();
			},
		});
	}

	placeSelected(address: IAddress) {
		this.form.controls.address.patchValue(address);
	}

	dismiss(route, reason: string) {
		this.modalService.dismissAll(reason);
		this.router.navigate(route, {
			queryParams: {
				providerAccountId:
					this.route.snapshot.queryParams['providerAccountId'],
			},
		});
	}

	formatterBranch = (emp: ISelectListItem) => emp.text;

	updateSearchBranch(term: string) {
		this.searchTerm = term;
	}

	searchBranch = () =>
		of(
			this.availableBranches.filter(
				(v) =>
					v.text
						.toLowerCase()
						.indexOf(this.searchTerm.toLowerCase()) > -1
			)
		);

	formatterTitle = (emp: ISelectListItem) => emp.text;

	updateSearchTitle(term: string) {
		this.searchTerm = term;
	}

	searchTitle = () =>
		of(
			this.availableTitles.filter(
				(v) =>
					v.text
						.toLowerCase()
						.indexOf(this.searchTerm.toLowerCase()) > -1
			)
		);

	formatterLanguage = (emp: ISelectListItem) => emp.text;

	updateSearchLanguage(term: string) {
		this.searchTerm = term;
	}

	searchLanguage = () =>
		of(
			this.availableLanguages.filter(
				(v) =>
					v.text
						.toLowerCase()
						.indexOf(this.searchTerm.toLowerCase()) > -1
			)
		);

	formatterGender = (emp: ISelectListItem) => emp.text;

	updateSearchGender(term: string) {
		this.searchTerm = term;
	}

	searchGender = () =>
		of(
			this.availableGenders.filter(
				(v) =>
					v.text
						.toLowerCase()
						.indexOf(this.searchTerm.toLowerCase()) > -1
			)
		);

	formatterMedicalOffice = (emp: ISelectListItem) => emp.text;

	updateSearchMedicalOffice(term: string) {
		this.searchTerm = term;
	}

	searchMedicalOffice = () =>
		of(
			this.availableMedicalOffices.filter(
				(v) =>
					v.text
						.toLowerCase()
						.indexOf(this.searchTerm.toLowerCase()) > -1
			)
		);

	formatterPosition = (emp: ISelectListItem) => emp.text;

	updateSearchPosition(term: string) {
		this.searchTerm = term;
	}

	searchPosition = () =>
		of(
			this.availablePositions.filter(
				(v) =>
					v.text
						.toLowerCase()
						.indexOf(this.searchTerm.toLowerCase()) > -1
			)
		);

	formatterSpecialty = (emp: ISelectListItem) => emp.text;

	updateSearchSpecialty(term: string) {
		this.searchTerm = term;
	}

	searchSpecialty = () =>
		of(
			this.availableSpecialties.filter(
				(v) =>
					v.text
						.toLowerCase()
						.indexOf(this.searchTerm.toLowerCase()) > -1
			)
		);

	updateSearchPhoneNumber(term: string) {
		this.searchTerm = term;
	}

	searchPhoneNumber = () =>
		of(
			this.availableCountryCodes.filter(
				(v) =>
					v.text
						.toLowerCase()
						.indexOf(this.searchTerm.toLowerCase()) > -1
			)
		);
}
