import {
	ChangeDetectorRef,
	Component,
	ElementRef,
	OnDestroy,
	OnInit,
	ViewChild,
} from '@angular/core';
import {
	UntypedFormBuilder,
	UntypedFormGroup,
	Validators,
} from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { SharedAnimations } from '@app/01.global/animations/shared-animations';
import { MessageTypes } from '@app/01.global/helpers/message-types';
import { RouteList } from '@app/01.global/helpers/route-enum';
import { DatatableFilters } from '@app/01.global/models/DatatableFilters';
import { IChatMessage } from '@app/01.global/interfaces/iChatMessage';
import { IDocument } from '@app/01.global/interfaces/iDocument';
import { IUser } from '@app/01.global/interfaces/IUser';
import { IChannel } from '@app/01.global/interfaces/iChannel';
import { AuthenticationService } from '@app/01.global/services/authentication.service';
import { ChatMessageService } from '@app/01.global/services/chat-message.service';
import { SignalrService } from '@app/01.global/services/signalr.service';
import { TranslateService } from '@ngx-translate/core';
import { ToastrService } from 'ngx-toastr';
import { finalize, interval, Observable, share, startWith, take } from 'rxjs';
import { debounceTime, Subscription } from 'rxjs';
import { ConsultationStatusService } from '@app/01.global/status-services/consultation-status.service';
import { ActiveChannel } from '@app/01.global/models/active-channel';
import { ConsultationSettings } from '@app/01.global/models/consultation-settings';

@Component({
	selector: 'app-chat',
	templateUrl: './chat.component.html',
	styleUrls: ['./chat.component.scss'],
	animations: [SharedAnimations],
})
export class ChatComponent implements OnInit, OnDestroy {
	loading: boolean = false;
	loadingUp: boolean = false;
	readOnly: boolean = false;
	archiveMode: boolean = false;
	chatCollection: IChatMessage[];
	activeChannel: IChannel;
	filters: DatatableFilters = {
		pageSize: 10,
		totalItems: 0,
		totalPages: 0,
		currentPage: 0,
	};
	throttle = 0;
	distance = 2;
	subs: Subscription[] = [];
	request$: Observable<IChatMessage[]>;
	currentUser: IUser;
	activeChannelImgUrl: string;
	activeChannelName: string;

	whoIsTyping: string;
	isStillTypingSub: Subscription;

	iAmTyping: Observable<number>;
	iAmTypingSub: Subscription;
	routeList = RouteList;
	messageTypes = MessageTypes;
	form: UntypedFormGroup;
	@ViewChild('fileInput') fileInput: ElementRef;

	contentSub: Subscription;

	constructor(
		private service: ChatMessageService,
		private authenticationService: AuthenticationService,
		private translateService: TranslateService,
		private toastrService: ToastrService,
		private signalRService: SignalrService,
		private route: ActivatedRoute,
		private formBuilder: UntypedFormBuilder,
		private changeDetectorRef: ChangeDetectorRef,
		private statusService: ConsultationStatusService
	) { }

	ngOnInit(): void {
		this.subs.push(
			this.authenticationService.user.subscribe((u: IUser) => {
				this.currentUser = u;
			})
		);

		this.subs.push(
			this.statusService.settingsObs.subscribe(
				(response: ConsultationSettings) => {
					this.archiveMode = response.archiveMode;
				}
			)
		);

		this.subs.push(
			this.statusService.activeChannelObs.subscribe(
				(c: ActiveChannel) => {
					if (this.activeChannel?.id == c?.channel.id) {
						return;
					}
					this.activeChannel = c?.channel;
					if (this.activeChannel) {
						this.buildForm();
						this.readOnly = c.readOnly;

						this.filters.currentPage = 0;
						this.filters.readFromWhoId = this.activeChannel.id;
						this.form.controls.channelId.setValue(
							this.activeChannel.id
						);
						this.readAll();
					} else {
						this.chatCollection = null;
					}
				}
			)
		);

		this.subs.push(
			this.statusService.activeChannelNewMessageObs.subscribe(
				(message: IChatMessage) => {
					if (!message) {
						return;
					}
					if (message.sender.displayName == this.whoIsTyping) {
						this.whoIsTyping = null;
					}
					this.readAll();
				}
			)
		);

		this.subs.push(
			this.statusService.activeChannelMessageReadObs.subscribe(
				(message: IChatMessage) => {
					if (!message) {
						return;
					}
					var index = this.chatCollection?.indexOf(
						this.chatCollection.find((m) => m.id == message.id)
					);
					if (index != -1 && this.chatCollection?.length > 0) {
						this.chatCollection[index] = message;
					}
				}
			)
		);

		this.subs.push(
			this.statusService.activeChannelIsTypingObs.subscribe(
				(userName: string) => {
					if (!userName) {
						return;
					}
					var participants: IUser[] =
						this.activeChannel.doctors.concat(
							this.activeChannel.users as IUser[]
						);
					this.statusService.hideParticipantsDisplayName(
						participants
					);

					this.whoIsTyping = participants.find(
						(p) => p.userName == userName
					).displayName;

					this.isStillTypingSub?.unsubscribe();
					let isStillTypingObs = interval(2500);
					this.isStillTypingSub = isStillTypingObs
						.pipe(take(1))
						.subscribe(() => (this.whoIsTyping = null));
				}
			)
		);
	}

	ngOnDestroy(): void {
		this.subs.forEach((s) => s?.unsubscribe());
		this.iAmTypingSub?.unsubscribe();
		this.contentSub?.unsubscribe();
	}

	readAll() {
		this.request$ = null;
		this.filters.currentPage = 0;
		this.getMessages().subscribe((results) => {
			this.chatCollection = [...results];
			var participants = this.chatCollection.map((x) => x.sender);
			this.statusService.hideParticipantsDisplayName(participants);
			this.changeDetectorRef.detectChanges();
		});
	}

	onScroll(): void {
		this.loadingUp = true;
		++this.filters.currentPage;
		this.getMessages().subscribe((results) => {
			this.loadingUp = false;
			this.chatCollection = results.concat(this.chatCollection);
			var participants = this.chatCollection.map((x) => x.sender);
			this.statusService.hideParticipantsDisplayName(participants);
		});
	}

	// Prevent duplicate requests on scroll.
	// More: https://stackoverflow.com/a/50865911/6441494
	private getMessages(): Observable<IChatMessage[]> {
		if (this.request$) {
			return this.request$;
		} else {
			this.request$ = this.service.readAll(this.filters).pipe(
				share(),
				finalize(() => (this.request$ = null))
			);
			return this.request$;
		}
	}

	sendMessage() {
		if (
			this.form.invalid ||
			(this.form.controls.content.value as string).trim().length <= 0
		) {
			return;
		}
		this.service.create(this.form.getRawValue()).subscribe((result) => {
			this.readAll();
			this.form.controls.content.setValue('');
		});
	}

	onFileChanged(event) {
		const allowedTypes: Array<string> = [
			'image/gif',
			'image/jpeg',
			'image/png',
			'video/mp4',
			// TODO accept other video types
			'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
			'application/msword',
			'application/vnd.ms-excel',
			'application/vnd.ms-powerpoint',
			'text/plain',
			'application/pdf',
		];
		const file = event.target.files[0];
		if (allowedTypes.indexOf(file.type) === -1) {
			this.toastrService.error(
				this.translateService.instant('FileTypeIsNotAllowed'),
				'Error',
				{ positionClass: 'toast-bottom-right' }
			);

			return;
		}
		this.service
			.upload(event.target.files[0], this.activeChannel.id)
			.subscribe((x: IDocument) => {
				this.readAll();
				this.fileInput.nativeElement.value = '';
			});
	}

	private buildForm() {
		this.form = this.formBuilder.group({
			content: ['', Validators.required],
			channelId: [this.activeChannel.id, Validators.required],
			senderId: [this.currentUser?.id, Validators.required],
		});

		this.contentSub?.unsubscribe();
		this.contentSub = this.form.controls.content.valueChanges
			.pipe(debounceTime(500))
			.subscribe((value) => {
				if (value == '') {
					if (this.iAmTyping) {
						this.iAmTypingSub.unsubscribe();
						this.iAmTyping = null;
					}
					return;
				}
				if (!this.iAmTyping) {
					this.iAmTyping = interval(1500).pipe(startWith(0));
					this.iAmTypingSub = this.iAmTyping.subscribe(() => {
						this.signalRService.sendTypingInChannelToHub(
							this.activeChannel.id
						);
					});
				}
			});
	}
}
