<router-outlet></router-outlet>
<ng-template #callModal let-modal>
	<app-modal (onClose)="modal.dismiss($event)" (onConfirmClose)="modal.close($event)" [showDefaultFooter]="false">
		<div modal-header>{{ 'IncomingVideoCall' | translate }}</div>
		<div modal-body>
			<div class="row">
				<div class="col">
					<div class="profile-picture mr-2 profileImgContainer d-flex mx-auto"
						style="width: 10rem; height: 10rem;" [ngStyle]="{
						'background-image':
							'url(' +
							(incomingCallMessage?.channelLogo?.url ??
							'./assets/images/default-chat-bubbles-group.png') +
							')'
					}"></div>
				</div>
			</div>
			<div class="row">
				<div class="col">
					<h5 class="text-center mt-3">
						{{incomingCallMessage?.channelName ?? incomingCallMessage?.sender?.displayName}}
					</h5>
				</div>
			</div>
			<div class="row">
				<div class="col text-center">
					<div class="wrapper">
						<div class="ring">
							<div class="coccoc-alo-phone coccoc-alo-green coccoc-alo-show mx-auto"
								(click)="modal.close($event)">
								<div class="coccoc-alo-ph-circle"></div>
								<div class="coccoc-alo-ph-circle-fill"></div>
								<div class="coccoc-alo-ph-img-circle"></div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	</app-modal>
</ng-template>
<ng-template #ConfirmUpdateModal let-modal>
	<div class="modal-header">
		<h4 class="modal-title" id="modal-title">
			{{ 'ApplicationUpdateAvailable' | translate }}
		</h4>
		<button type="button" class="close" aria-label="Close button" aria-describedby="modal-title"
			(click)="modal.dismiss($event)">
			<span aria-hidden="true">&times;</span>
		</button>
	</div>
	<div class="modal-body mt-0 ml-3 mb-3 mr-1 mr-lg-3">
		<span class="text-warning">{{
			'ApplicationUpdateAvailable' | translate
			}}</span>
	</div>
	<div class="modal-footer" style="padding: 1rem; border-top: 1px solid #eee;">
		<button type="button" class="btn btn-outline-secondary" (click)="modal.dismiss($event)" data-toggle="tooltip"
			data-placement="bottom" title="{{'Cancel' | translate}}">
			{{ 'Cancel' | translate }}
		</button>
		<button type="button" class="'btn btn-primary" data-toggle="tooltip" data-placement="bottom"
			title="{{'Update' | translate}}" (click)="modal.close($event)">
			{{ 'Update' | translate }}
		</button>
	</div>
</ng-template>