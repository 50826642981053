import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { ConsultationRoutingModule } from './consultation-routing.module';
import { ConsultationComponent } from './consultation/consultation.component';
import { ConsultationSidebarComponent } from './consultation-sidebar/consultation-sidebar.component';
import { SharedModule } from '@app/00.shared/shared.module';
import { ChatComponent } from './chat/chat.component';
import { TopBarComponent } from './top-bar/top-bar.component';
import { NotesComponent } from './notes/notes.component';
import { FilesComponent } from './files/files.component';
import { ChatMessageComponent } from './chat/chat-message/chat-message.component';
import { VideoComponent } from './video/video.component';
import { PublisherComponent } from './video/publisher/publisher.component';
import { SubscriberComponent } from './video/subscriber/subscriber.component';
import { AddUserToChannelComponent } from './add-user-to-channel/add-user-to-channel.component';
import { CallLauncherComponent } from './call-launcher/call-launcher.component';
import { BookingSessionComponent } from './booking-session/booking-session.component';

@NgModule({
	declarations: [
		ConsultationComponent,
		ConsultationSidebarComponent,
		ChatComponent,
		TopBarComponent,
		NotesComponent,
		FilesComponent,
		ChatMessageComponent,
		VideoComponent,
		PublisherComponent,
		SubscriberComponent,
		AddUserToChannelComponent,
		CallLauncherComponent,
		BookingSessionComponent
	],
	imports: [CommonModule, SharedModule, ConsultationRoutingModule],
	exports: [ChatComponent,
		ChatMessageComponent,
		BookingSessionComponent,
		PublisherComponent,
		SubscriberComponent],
})
export class ConsultationModule { }
