<ng-container>
	<span
		[routerLink]="targetLink"
		class="pr-2"
		[ngClass]="cursorPointer ? 'cursor-pointer' : ''"
		[ngbPopover]="popContent"
		[triggers]="triggers"
		[placement]="[placement, 'auto']"
		[animation]="animation"
		[container]="container"
	>
		<ng-content select="[slot=label]"></ng-content>
	</span>
</ng-container>
<ng-template #popContent>
	<ng-content select="[slot=popover]"> </ng-content>
</ng-template>
