import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { DocTypesEnum } from '@app/01.global/helpers/doc-types-enum';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { CropperSettings, ImageCropperComponent } from 'ngx-img-cropper';

@Component({
	selector: 'app-cropper-modal',
	templateUrl: './cropper-modal.component.html',
	styleUrls: ['./cropper-modal.component.scss']
})
export class CropperModalComponent implements OnInit {

	private _imageFile;
	@Input() set imageFile(v) { this._imageFile = v; this.setImage() } get imageFile() { return this._imageFile };
	@Input() documentType;

	DocTypesEnum = DocTypesEnum;

	data: any = {};

	cropperSettings: CropperSettings;

	@ViewChild('cropper') cropper: ImageCropperComponent;

	constructor(public activeModal: NgbActiveModal) {

	}

	ngOnInit() {
		this.cropperSettings = new CropperSettings();
		this.cropperSettings.width = 200;
		this.cropperSettings.height = 200;
		this.cropperSettings.croppedWidth = 200;
		this.cropperSettings.croppedHeight = 200;
		this.cropperSettings.canvasWidth = 400;
		this.cropperSettings.canvasHeight = 300;
		this.cropperSettings.noFileInput = true;
		switch (this.documentType) {
			case DocTypesEnum.AgreementCardImage:
				this.cropperSettings.width = 320;
				this.cropperSettings.height = 200;
				this.cropperSettings.croppedWidth = 320;
				this.cropperSettings.croppedHeight = 200;
				break;
			default:
		}
	}

	setImage() {
		var image: any = new Image();
		var myReader: FileReader = new FileReader();
		var that = this;

		myReader.onloadend = function (loadEvent: any) {
			image.src = loadEvent.target.result;
			that.cropper.setImage(image);
		};

		myReader.readAsDataURL(this.imageFile);
	}
}
