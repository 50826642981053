import {
	AfterViewInit,
	Component,
	HostListener,
	OnDestroy,
	OnInit,
	ViewChild,
} from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { RouteList } from '@app/01.global/helpers/route-enum';
import { IUser } from '@app/01.global/interfaces/IUser';
import { AttendanceWindow } from '@app/01.global/models/attendance-window';
import { AuthenticationService } from '@app/01.global/services/authentication.service';
import { AttendanceStatusService } from '@app/01.global/status-services/attendance-status.service';
import { NgbModal, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { Subscription } from 'rxjs';
import { SharedAnimations } from '@app/01.global/animations/shared-animations';
import { IPatientWaiting } from '@app/01.global/interfaces/iPatientWaiting';
import { SignalrService } from '@app/01.global/services/signalr.service';

@Component({
	selector: 'app-attendance-home',
	templateUrl: './attendance-home.component.html',
	styleUrls: ['./attendance-home.component.scss'],
	animations: [SharedAnimations],
})
export class AttendanceHomeComponent
	implements AfterViewInit, OnDestroy, OnInit {
	subs: Subscription[] = [];
	isDoctorOrTherapist: boolean;
	isGuest: boolean;
	openedDevicesModal: boolean;
	devicesAlreadyChecked: string;
	consultationChannelId: number;
	private guestEntering: IPatientWaiting;

	private activeWindows: AttendanceWindow[] = [];
	get minimizedWindows() {
		return this.activeWindows.filter((w) => w.isMinimized);
	}
	get notMinimizedWindows() {
		return this.activeWindows.filter((w) => !w.isMinimized);
	}

	@ViewChild('authModal') authModal: NgbModalRef;
	@ViewChild('devicesModal') devicesModal: NgbModalRef;
	openedAuthModal: NgbModalRef;


	// @HostListener("window:beforeunload", ["$event"]) unloadHandler() {
	// 	if (this.isGuest) {
	// 		this.setGuestLeaving();
	// 	}
	// 	this.subs.forEach((s) => s?.unsubscribe());
	// }

	constructor(
		private route: ActivatedRoute,
		private router: Router,
		public modalService: NgbModal,
		private authService: AuthenticationService,
		private statusService: AttendanceStatusService,
		private signalRService: SignalrService
	) { }

	ngOnInit(): void {
		this.modalService.dismissAll();
		this.isDoctorOrTherapist =
			this.authService.isDoctor || this.authService.isTherapist;
		this.isGuest = this.authService.isGuest;

		this.subs.push(
			this.statusService.windows$.subscribe((newWindows) => {
				this.activeWindows = newWindows;
			})
		);

		this.devicesAlreadyChecked = localStorage.getItem(
			'devicesAlreadyChecked'
		);
	}

	ngAfterViewInit() {
		this.openedDevicesModal = false;
		this.subs.push(
			this.authService.user.subscribe((u: IUser) => {
				if (u == null) {
					this.openedAuthModal = this.modalService.open(
						this.authModal,
						{
							ariaLabelledBy: 'modal-basic-title',
							size: 'xl',
							backdrop: 'static',
							modalDialogClass: 'account-modal',
						}
					);

					this.router.navigate(
						['auth', RouteList._route0Enum.Login],
						{ relativeTo: this.route }
					);
				} else {
					this.signalRService.startIfNotConnected().subscribe(() => {
						this.statusService.subscribeToSignalR();
					});
					this.isGuest = this.authService.isGuest;
					this.isDoctorOrTherapist =
						this.authService.isDoctor ||
						this.authService.isTherapist;
					this.openedAuthModal?.close();
					// this.router.navigate([''], { relativeTo: this.route })

					if (this.isGuest) {
						this.statusService
							.getChannelsAsPatient(
								this.route.snapshot.params.doctorPublicId
							)
							.subscribe((c) => {
								this.consultationChannelId = c.id;

								this.guestEntering = {
									doctorCameraChecked: false,
									doctorMicChecked: false,
									doctorPublicId:
										this.route.snapshot.params
											.doctorPublicId,
									doctorId: c.doctors[0]?.id,
									doctorUsername: c.doctors[0]?.userName,
									userCameraChecked: false,
									userMicChecked: false,
									channelId: c.id,
									userId: this.authService.userValue.id,
								};

								this.openDevicesModal();
							});
					} else if (this.isDoctorOrTherapist) {
						this.statusService.getChannelsAsDoctor();
						this.openDevicesModal();
					}
				}
			})
		);
	}

	ngOnDestroy() {
		if (this.isGuest) {
			this.setGuestLeaving();
		}
		this.subs.forEach((s) => s?.unsubscribe());
	}

	openDevicesModal() {
		if (
			!this.devicesAlreadyChecked ||
			this.devicesAlreadyChecked == 'false'
		) {
			var modalRef = this.modalService.open(this.devicesModal, {
				ariaLabelledBy: 'modal-basic-title',
				size: 'lg',
				backdrop: 'static',
				modalDialogClass: 'devices-modal',
			});
			this.openedDevicesModal = true;

			modalRef.dismissed.subscribe((reason) => {
				if (this.isGuest) {
					this.guestEntering.userCameraChecked = reason.cameraStatus;
					this.guestEntering.userMicChecked = reason.micStatus;
					this.setGuestEntering();
				}
			});
		} else {
			if (this.isGuest) {
				this.guestEntering.userCameraChecked = true;
				this.guestEntering.userMicChecked = true;
				this.setGuestEntering();
			}
		}
	}

	private setGuestEntering() {
		this.statusService.setGuestEntering(this.guestEntering);
	}

	private setGuestLeaving() {
		this.statusService.setGuestLeaving(this.guestEntering);
	}
}
