import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import {
	Router,
	RouteConfigLoadStart,
	ResolveStart,
	RouteConfigLoadEnd,
	ResolveEnd,
	ActivatedRoute,
} from '@angular/router';
import { PerfectScrollbarDirective } from 'ngx-perfect-scrollbar';
import { SearchService } from '@app/01.global/services/search.service';
import { NavigationService } from '@app/01.global/services/navigation.service';
import { LoaderService } from '@app/01.global/services/loader.service';
import { Subscription } from 'rxjs';
import { RouteList } from '@app/01.global/helpers/route-enum';
import { ConsultationComponent } from '@app/consultation/consultation/consultation.component';
import { AuthenticationService } from '@app/01.global/services/authentication.service';
import { RoleEnum } from '@app/01.global/helpers/role-enum';

@Component({
	selector: 'app-main-layout',
	templateUrl: './main-layout.component.html',
	styleUrls: ['./main-layout.component.scss'],
})
export class MainLayoutComponent implements OnInit, OnDestroy {
	moduleLoading: boolean;
	rightChatVisible: boolean = false;
	isGuest: boolean = false;
	// @ViewChild(PerfectScrollbarDirective, { static: true })
	// perfectScrollbar: PerfectScrollbarDirective;

	loaderSub: Subscription;
	constructor(
		public navService: NavigationService,
		public searchService: SearchService,
		private router: Router,
		private route: ActivatedRoute,
		private loaderService: LoaderService,
		private authenticationService: AuthenticationService
	) { }

	ngOnInit() {
		this.isGuest = this.authenticationService.isGuest;
		this.router.events.subscribe((event) => {
			if (
				event instanceof RouteConfigLoadStart ||
				(event instanceof ResolveStart &&
					!event.url.includes(RouteList.consultation))
			) {
				this.moduleLoading = true;
			}
			if (
				event instanceof RouteConfigLoadEnd ||
				event instanceof ResolveEnd
			) {
				this.moduleLoading = false;
				// this.perfectScrollbar.scrollToTop(0, 250)
			}
		});

		this.loaderSub = this.loaderService.loaderSubject.subscribe(
			(loaderVisible) => {
				this.moduleLoading = loaderVisible;
			}
		);
	}

	ngOnDestroy(): void {
		this.loaderSub.unsubscribe();
	}

	showRightPane(event) {
		this.rightChatVisible =
			event &&
			this.route.snapshot?.firstChild?.firstChild?.component !=
			ConsultationComponent;
	}
}
