<div class="app-admin-wrap layout-sidebar-large">
	<app-messenger *ngIf="isGuest" (onShowRightPane)="showRightPane($event)"></app-messenger>
	<div>
		<app-header></app-header>
	</div>
	<div class="side-content-wrap">
		<app-sidebar></app-sidebar>
	</div>
	<div class="main-content-wrap d-flex flex-column" [ngClass]="{
			'sidenav-open': navService.sidebarState.sidenavOpen,
			'right-chat-visible': rightChatVisible
		}">
		<!-- <app-breadcrumb></app-breadcrumb> -->
		<router-outlet></router-outlet>
		<div class="flex-grow-1"></div>
		<app-footer></app-footer>
		<div class="module-loader" *ngIf="moduleLoading">
			<img src="assets/images/loading.gif" class="loading" alt="" />
		</div>
	</div>
</div>
<app-search *ngIf="searchService.searchOpen"></app-search>