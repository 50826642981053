import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { AuthLayoutComponent } from './auth-layout/auth-layout.component';
import { BlankLayoutComponent } from './blank-layout/blank-layout.component';
import { FooterComponent } from './footer/footer.component';
import { BreadcrumbComponent } from './breadcrumb/breadcrumb.component';
import { SharedModule } from '@app/00.shared/shared.module';
import { RouterModule } from '@angular/router';
import { MainLayoutComponent } from './main-layout/main-layout.component';
import { SidebarComponent } from './main-layout/sidebar/sidebar.component';
import { HeaderComponent } from './main-layout/header/header.component';
import { MessengerModule } from './messenger/messenger.module';
import { AttendanceModule } from '@app/attendance/attendance.module';
import { AttendanceLayoutComponent } from './attendance-layout/attendance-layout.component';

const components = [
	HeaderComponent,
	SidebarComponent,
	FooterComponent,
	MainLayoutComponent,
	AuthLayoutComponent,
	BlankLayoutComponent,
	BreadcrumbComponent,
	AttendanceLayoutComponent,
];

@NgModule({
	imports: [
		CommonModule,
		SharedModule,
		RouterModule,
		MessengerModule,
		AttendanceModule,
	],
	declarations: components,
	exports: components,
})
export class LayoutsModule { }
