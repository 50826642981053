<div class="app-footer">
	<div class="row">
		<div class="col-md-9">
			<p><strong>mHealth</strong></p>
			<p>{{ 'mHealthDescription' | translate }}</p>
		</div>
	</div>
	<div class="
			footer-bottom
			border-top
			pt-3
			d-flex
			flex-column flex-sm-row
			align-items-center
		">
		<a class="
				btn btn-info
				text-white
				btn-rounded
				mb-3 mb-sm-0
				mr-0 mr-sm-3
			" [routerLink]="routeList.bookingAppointment" target="" data-toggle="tooltip" data-placement="bottom"
			title="{{ 'BookAnAppointment' | translate }}"
			*ngIf="!isProviderRolePresent()">
			{{ 'BookAnAppointment' | translate }}
		</a>
		<a class="btn btn-warning btn-rounded mb-3 mb-sm-0" href="mailto:info@kooli.io" target="_blank"
			data-toggle="tooltip" data-placement="bottom" title="{{ 'Feedback' | translate }}">
			{{ 'Feedback' | translate }}
		</a>

		<span class="flex-grow-1"></span>
		<div class="d-flex align-items-center">
			<div class="main-color-logo logo" style="width:4rem; height: 4rem;"></div>
			<div>
				<p class="m-0">&copy; {{ getCurrentYear() }} mHealth</p>
				<p class="m-0">{{ 'CopyRight' | translate }}</p>
				<p class="m-0 text-center">
					<a [routerLink]="routeList.privacyPolicy" class="text-muted mr-2" data-toggle="tooltip"
						data-placement="bottom" title="{{'PrivacyPolicy' | translate}}"><u>{{
							'PrivacyPolicy' | translate }}</u></a>
					<a [routerLink]="routeList.termsService" class="text-muted ml-2" data-toggle="tooltip"
						data-placement="bottom" title="{{'TermsService' | translate}}"><u>{{
							'TermsService' | translate }}</u></a>
				</p>
			</div>
		</div>
	</div>
</div>